import { GLOBAL_LOCALE } from "./remoteConfig";

const country_flag = {
  AF: "🇦🇫",
  ZA: "🇿🇦",
  AX: "🇦🇽",
  AL: "🇦🇱",
  DZ: "🇩🇿",
  DE: "🇩🇪",
  AD: "🇦🇩",
  AO: "🇦🇴",
  AI: "🇦🇮",
  AQ: "🇦🇶",
  AG: "🇦🇬",
  SA: "🇸🇦",
  AR: "🇦🇷",
  AM: "🇦🇲",
  AW: "🇦🇼",
  AU: "🇦🇺",
  AT: "🇦🇹",
  AZ: "🇦🇿",
  BS: "🇧🇸",
  BH: "🇧🇭",
  BD: "🇧🇩",
  BB: "🇧🇧",
  BE: "🇧🇪",
  BZ: "🇧🇿",
  BJ: "🇧🇯",
  BM: "🇧🇲",
  BT: "🇧🇹",
  BY: "🇧🇾",
  MM: "🇲🇲",
  BO: "🇧🇴",
  BA: "🇧🇦",
  BW: "🇧🇼",
  BR: "🇧🇷",
  BN: "🇧🇳",
  BG: "🇧🇬",
  BF: "🇧🇫",
  BI: "🇧🇮",
  KH: "🇰🇭",
  CM: "🇨🇲",
  CA: "🇨🇦",
  CL: "🇨🇱",
  CN: "🇨🇳",
  CY: "🇨🇾",
  VA: "🇻🇦",
  CO: "🇨🇴",
  KM: "🇰🇲",
  CG: "🇨🇬",
  CD: "🇨🇩",
  KP: "🇰🇵",
  KR: "🇰🇷",
  CR: "🇨🇷",
  CI: "🇨🇮",
  HR: "🇭🇷",
  CU: "🇨🇺",
  CW: "🇨🇼",
  CK: "🇩🇰",
  DJ: "🇩🇯",
  DM: "🇩🇲",
  EG: "🇪🇬",
  AE: "🇦🇪",
  EC: "🇪🇨",
  ER: "🇪🇷",
  ES: "🇪🇸",
  EE: "🇪🇪",
  US: "🇺🇸",
  ET: "🇪🇹",
  FJ: "🇫🇯",
  FI: "🇫🇮",
  FR: "🇫🇷",
  GA: "🇬🇦",
  GM: "🇬🇲",
  GE: "🇬🇪",
  GS: "🇬🇸",
  GH: "🇬🇭",
  GI: "🇬🇮",
  GR: "🇬🇷",
  GD: "🇬🇩",
  GL: "🇬🇱",
  GP: "🇬🇵",
  GU: "🇬🇺",
  GT: "🇬🇹",
  GG: "🇬🇬",
  GN: "🇬🇳",
  GQ: "🇬🇶",
  GW: "🇬🇼",
  GY: "🇬🇾",
  GF: "🇬🇫",
  HT: "🇭🇹",
  HN: "🇭🇳",
  HK: "🇭🇰",
  HU: "🇭🇺",
  BV: "🇧🇻",
  CX: "🇨🇽",
  IM: "🇮🇲",
  MU: "🇲🇺",
  NF: "🇳🇫",
  KY: "🇰🇾",
  CC: "🇨🇨",
  CV: "🇨🇻",
  FO: "🇫🇴",
  HM: "🇭🇲",
  FK: "🇫🇰",
  MP: "🇲🇵",
  MH: "🇲🇭",
  UM: "🇺🇲",
  PN: "🇵🇳",
  SB: "🇸🇧",
  TC: "🇹🇨",
  VG: "🇻🇬",
  VI: "🇻🇮",
  IN: "🇮🇳",
  ID: "🇮🇩",
  IQ: "🇮🇶",
  IR: "🇮🇷",
  IE: "🇮🇪",
  IS: "🇮🇸",
  IL: "🇮🇱",
  IT: "🇮🇹",
  JM: "🇯🇲",
  JP: "🇯🇵",
  JE: "🇯🇪",
  JO: "🇯🇴",
  KZ: "🇰🇿",
  KE: "🇰🇪",
  KG: "🇰🇬",
  KI: "🇰🇮",
  XK: "🇽🇰",
  KW: "🇰🇼",
  LA: "🇱🇦",
  LS: "🇱🇸",
  LV: "🇱🇻",
  LB: "🇱🇧",
  LR: "🇱🇷",
  LY: "🇱🇾",
  LI: "🇱🇮",
  LT: "🇱🇹",
  LU: "🇱🇺",
  MO: "🇲🇴",
  MK: "🇲🇰",
  MG: "🇲🇬",
  MY: "🇲🇾",
  MW: "🇲🇼",
  MV: "🇲🇻",
  ML: "🇲🇱",
  MT: "🇲🇹",
  MA: "🇲🇦",
  MQ: "🇲🇶",
  MR: "🇲🇷",
  YT: "🇾🇹",
  MX: "🇲🇽",
  FM: "🇫🇲",
  MD: "🇲🇩",
  MC: "🇲🇨",
  MN: "🇲🇳",
  ME: "🇲🇪",
  MS: "🇲🇸",
  MZ: "🇲🇿",
  NA: "🇳🇦",
  NR: "🇳🇷",
  NP: "🇳🇵",
  NI: "🇳🇮",
  NE: "🇳🇪",
  NG: "🇳🇬",
  NU: "🇳🇺",
  NO: "🇳🇴",
  NC: "🇳🇨",
  NZ: "🇳🇿",
  OM: "🇴🇲",
  UG: "🇺🇬",
  UZ: "🇺🇿",
  PK: "🇵🇰",
  PW: "🇵🇼",
  PS: "🇵🇸",
  PA: "🇵🇦",
  PG: "🇵🇬",
  PY: "🇵🇾",
  NL: "🇳🇱",
  BQ: "🇧🇶",
  PE: "🇵🇪",
  PH: "🇵🇭",
  PL: "🇵🇱",
  PF: "🇵🇫",
  PR: "🇵🇷",
  PT: "🇵🇹",
  QA: "🇶🇦",
  CF: "🇨🇫",
  DO: "🇩🇴",
  RE: "🇷🇪",
  RO: "🇷🇴",
  GB: "🇬🇧",
  RU: "🇷🇺",
  RW: "🇷🇼",
  EH: "🇪🇭",
  BL: "🇧🇱",
  KN: "🇰🇳",
  SM: "🇸🇲",
  MF: "🇲🇫",
  SX: "🇸🇽",
  PM: "🇵🇲",
  VC: "🇻🇨",
  SH: "🇸🇭",
  LC: "🇱🇨",
  SV: "🇸🇻",
  WS: "🇼🇸",
  AS: "🇦🇸",
  ST: "🇸🇹",
  SN: "🇸🇳",
  RS: "🇷🇸",
  SC: "🇸🇨",
  SL: "🇸🇱",
  SG: "🇸🇬",
  SK: "🇸🇰",
  SI: "🇸🇮",
  SO: "🇸🇴",
  SD: "🇸🇩",
  SS: "🇸🇸",
  LK: "🇱🇰",
  SE: "🇸🇪",
  CH: "🇨🇭",
  SR: "🇸🇷",
  SJ: "🇸🇯",
  SZ: "🇸🇿",
  SY: "🇸🇾",
  TJ: "🇹🇯",
  TW: "🇹🇼",
  TZ: "🇹🇿",
  TD: "🇹🇩",
  CZ: "🇨🇿",
  TF: "🇹🇫",
  IO: "🇮🇴",
  TH: "🇹🇭",
  TL: "🇹🇱",
  TG: "🇹🇬",
  TK: "🇹🇰",
  TO: "🇹🇴",
  TT: "🇹🇹",
  TN: "🇹🇳",
  TM: "🇹🇲",
  TR: "🇹🇷",
  TV: "🇹🇻",
  UA: "🇺🇦",
  UY: "🇺🇾",
  VU: "🇻🇺",
  VE: "🇻🇪",
  VN: "🇻🇳",
  WF: "🇼🇫",
  YE: "🇾🇪",
  ZM: "🇿🇲",
  ZW: "🇿🇼",
};

export function createMessageOnSlack(data, type, mail, userSlice) {
  const text = data;
  if (!text) return;
  postMessageOnSlack(text, mail, type, userSlice);
}

export function postMessageOnSlack(message, email, type, userSlice) {
  let url;
  let payload;
  if (type === "contactSales") {
    payload = buildContactSalesPayload(message, email, userSlice);
    url = process.env.REACT_APP_SLACK_BUSINESS_CONTACT;
  } else if (type === "clapped") {
    payload = buildClappedPayload(message, email, userSlice);
    url = process.env.REACT_APP_SLACK_CLAPPED_FEATURE;
  }
  const body = JSON.stringify(payload);
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json"
    },
    body,
  };
  fetch(url, options)
    .then((response) => response.text())
    .then((responseText) => {
      if (responseText !== "ok") {
        console.log("slack ERROR " + responseText);
        // error list : https://api.slack.com/messaging/webhooks#handling_errors
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

function buildContactSalesPayload(message, email, userSlice) {
  return {
    type: "section",
    text: "New business contact submission",
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "📨 New business contact message 📨",
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "From user 👤 : " + (userSlice?.fullName || "Undefined"),
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: " Email 📧 : " + (email || "Undefined"),
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "Message  📨 : " + (message || "Undefined"),
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "ID 🔢 : " + userSlice?.userID,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text:
            "User Type ⚙️ : " +
            (userSlice?.typology || userSlice?.userType || "Undefined"),
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text:
            "Specialization ⚒️ : " + (userSlice?.specialization || "Undefined"),
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text:
            "User Country 🚩 : " +
            GLOBAL_LOCALE.split("-")[1] +
            " " +
            country_flag[GLOBAL_LOCALE.split("-")[1]],
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "💻FROM WEBAPP💻",
        },
      },
      {
        type: "divider",
      },
    ],
  };
}

function buildClappedPayload(message, email, userSlice) {
  return {
    type: "section",
    text: "New 💻webapp💻 clap submission",
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "👏 New WebApp clap 👏",
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "From user 👤 : " + (userSlice?.fullName || "Undefined"),
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: " Email 📧 : " + (email || "Undefined"),
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text:
            "Message  📨 : " +
            (message || "Undefined") +
            "Clapped Button Clicked",
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "ID 🔢 : " + userSlice?.userID,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "User Type ⚙️ : " + (userSlice?.userType || "Undefined"),
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text:
            "User Country 🚩 : " +
            GLOBAL_LOCALE.split("-")[1] +
            " " +
            country_flag[GLOBAL_LOCALE.split("-")[1]],
        },
      },
      {
        type: "divider",
      },
    ],
  };
}
