import React from 'react';

import {Typography} from '@mui/material';
import {useSprings, animated} from 'react-spring';

import TextComponent from 'components/atoms/TextComponent';

import {onBoardingTranslate, translate} from 'services/remoteConfig';

import {store} from 'store';

import {createTextObjectReusable} from 'utils/utils';

import {BODY_2, BOLD, H3, H5} from 'constant/constants';

import {colors} from 'themes';

function OnBoardingCoachComment() {
  const AnimatedText = ({text}) => {
    // Create an array of springs, one for each letter
    const springs = useSprings(
      text.length,
      text.split('').map((_, index) => ({
        from: {opacity: 0, transform: 'translate3d(0,40px,0)'},
        to: {opacity: 1, transform: 'translate3d(0,0px,0)'},
        delay: index * 20, // Stagger the animation
      })),
    );

    return (
      <Typography
        variant={BODY_2}
        color={colors?.WHITE_OPACITY_60}
        component="span">
        {springs.map((props, index) => (
          <animated.span key={index} style={props}>
            {text[index]}
          </animated.span>
        ))}
      </Typography>
    );
  };

  return (
    <>
      <TextComponent
        multipleText={createTextObjectReusable(
          onBoardingTranslate('CoachWriteComments'),
          {
            variant: H5,
            weight: BOLD,
          },
          {
            variant: H5,
            weight: BOLD,
            style: {textAlign: 'center', color: colors.SECONDARY},
          },
        )}
        variant={H3}
        weight={BOLD}
        center={true}
      />
      <div style={styles.container}>
        <TextComponent
          text={store?.getState()?.user?.fullName}
          sx={{marginBottom: 1}}
        />
        <AnimatedText text={onBoardingTranslate('CoachComment')} />
        <TextComponent
          text={translate('CoachTimeComment')}
          sx={styles.coachTimeComment}
        />
      </div>
    </>
  );
}
const styles = {
  coachTimeComment: {
    color: colors.WHITE_OPACITY_40,
    fontSize: 12,
    marginTop: 1,
  },
  container: {
    alignSelf: 'center',
    backgroundColor: 'black',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    width: '95%',
  },
};
export default OnBoardingCoachComment;
