import PickerComponent from "components/atoms/PickerComponent";
import TextInput from "components/atoms/TextInput";
import { FRENCH } from "constant/constants";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { USER_LOCALE, translate } from "services/remoteConfig";
import { lbsToKg } from "utils/converter";

const WeightTextInput = forwardRef(
  ({ displayInTextInput, handleValueChanges, weightUnityRef }, ref) => {
    const yup = require("yup");

    const unity = USER_LOCALE === FRENCH.code ? "fr" : "en";

    const [formValues, setFormValues] = useState({
      weight: null,
    });

    useImperativeHandle(ref, () => ({
      handleSubmit,
      setWeight: (weight) => {
        setFormValues({
          ...formValues,
          weight: weight,
        });
      },
      setWeightClear: (weight) => {
        setFormValues({ weight: weight });
      },
      getWeight: () => {
        return formValues?.weight;
      },
    }));

    const [formErrors, setFormErrors] = useState({});

    const athleteInfoSchema = yup.object({
      weight: yup
        .number(translate("WeightWarning"))
        .required(translate("WeightError"))
        .integer(translate("WeightError"))
        .typeError(translate("WeightError"))
        .min(0, translate("WeightError")),
    });

    const handleSubmit = async (e) => {
      if (e) e.preventDefault();
      try {
        await athleteInfoSchema?.validate(formValues, { abortEarly: false });
        let validWeight;
        if (displayInTextInput) {
          validWeight = lbsToKg(parseFloat(formValues?.weight));
        } else validWeight = validWeight = parseFloat(formValues?.weight);
        return validWeight;
      } catch (err) {
        if (err.inner) {
          const errors = {};
          err.inner.forEach((error) => {
            errors[error.path] = error.message;
          });
          setFormErrors(errors);
        }
      }
    };

    const handleChange = (e) => {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
      });
      if (formErrors[e.target.name]) {
        setFormErrors({
          ...formErrors,
          [e.target.name]: "",
        });
      }
    };

    return (
      <div style={{ display: "flex" }}>
        <TextInput
          id={"weightTextInput"}
          name={"weight"}
          label={displayInTextInput ? "lbs" : "kg"}
          value={formValues?.weight}
          onChange={handleChange}
          schema={athleteInfoSchema}
          error={!!formErrors?.weight}
          helperText={formErrors?.weight}
          type="number"
        //handleSubmit={handleSubmit}
        />
        <div style={{ marginLeft: 10 }}>
          <PickerComponent
            config={["lbs", "kg"]}
            ref={weightUnityRef}
            unity={unity}
            handleValueChanges={handleValueChanges}
            type={"Metrics"}
          />
        </div>
      </div>
    );
  }
);

export default WeightTextInput;
