import TextComponent from "components/atoms/TextComponent";
import { BODY_1, H5 } from "constant/constants";
import React, { useEffect } from "react";
import { GLOBAL_LOCALE, USER_LOCALE } from "services/remoteConfig";

function CardThumbnail({ analysis, thumbnail }) {
  const [thumb, setThumb] = React.useState(null);

  useEffect(() => {
    setThumb(thumbnail);
  }, [thumbnail])
  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const analysisDate = analysis?.date
    ? new Date(analysis?.date).toLocaleDateString(USER_LOCALE, options)
    : "";

  return (
    <div style={{ ...styles.container, backgroundImage: `url(${thumb})` }}>
      <div style={styles.backgroundOpacity}></div>
      <div style={styles.textContainer}>
        <TextComponent
          variant={H5}
          sx={styles.text}
          weight={"500"}
          text={analysis?.data?.tag?.name}
        />
        <TextComponent variant={BODY_1} text={analysisDate} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    backgroundSize: "cover",
    borderRadius: 30,
    height: "80%",
    position: "relative",
    width: "100%",
  },
  backgroundOpacity: {
    borderRadius: 30,
    backgroundColor: "rgb(0,0,0,0.3)",
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  text: {
    marginBottom: "2%",
  },
  textContainer: {
    marginLeft: "2%",
    marginTop: "3%",
    position: "absolute",
  },
};
export default CardThumbnail;
