export const onBoardingWelcomeTypologieText = {
  injured: ['WelcomeTypologieTextInjured', 'WelcomeTypologieTextInjured2'],
  performer: ['WelcomeTypologieTextPerfomer', 'WelcomeTypologieTextPerfomer2'],
  lifestyle: [
    'WelcomeTypologieTextLifestyle',
    'WelcomeTypologieTextLifestyle2',
  ],
};

export const onboardingAnalysisPresentation = {
  man: {first: 'AnalysisPresentation', second: 'AnalysisPresentationSecondary'},
  woman: {
    first: 'AnalysisPresentationWoman',
    second: 'AnalysisPresentationSecondary',
  },
};

export const analysisPresentationScore = {
  performer: 'AnalysisPresentationScorePerformer',
  injured: 'AnalysisPresentationScoreInjured',
  lifestyle: 'AnalysisPresentationScoreLifestyle',
};

export const segments = [
  {
    score_text: 'score_good',
    item: 'RightAnkle',
    text: 'Feet',
    explanation: 'FeetExpla',
    desc: {
      fr: "Pose médio-pied (C'est a dire la partie la plus large de votre pied). Avoir une telle attaque au sol est idéale car elle limite les tensions musculaires et permets ainsi un bon équilibre corporel avec l'intension d'aller vers l'avant. ",
      en: 'Midfoot pose (i.e. the widest part of your foot). Having such an attack on the ground is ideal because it limits muscular tension and allows a good body balance with the intention of going forward.',
      zh: '中脚掌姿势（即脚掌最宽的部分）。这种击球方式非常理想，因为它可以限制肌肉紧张，让身体在向前移动的同时保持适当的平衡。',
      de: 'Midfoot-Haltung (d.h. der breiteste Teil Ihres Fußes). Eine solche Bodenberührung ist ideal, weil sie muskuläre Spannung begrenzt und eine gute Körperbalance mit der Absicht, nach vorne zu gehen, ermöglicht.',
      pt: 'Apoio no mediopé (ou seja, a parte mais larga do seu pé). Ter esse tipo de contato com o solo é ideal porque limita a tensão muscular e permite um bom equilíbrio corporal com a intenção de avançar.',
      it: "Appoggio sulla parte centrale del piede (ovvero la parte più larga del piede). Avere questo tipo di contatto con il suolo è ideale perché limita la tensione muscolare e permette un buon equilibrio corporeo con l'intenzione di andare avanti.",
      es: 'Apoyo en el mediopié (es decir, la parte más ancha de tu pie). Tener un ataque de este tipo en el suelo es ideal porque limita la tensión muscular y permite un buen equilibrio corporal con la intención de avanzar.',
    },
  },
  {
    score_text: 'score_okay',
    scoreNumber: 0.58,
    item: 'RightEar',
    text: 'Head',
    explanation: 'HeadExpla',
    desc: {
      angle: -8,
      fr: "Votre regard est un peu porté vers le sol. Vous devriez être entre 1° et -9° . Elevez votre regard et pensez à le porter loin à l'horizon: 20/30m devant vous",
      en: 'Your eyes are slightly down to the ground. You should be between 2° and -8°. Raise your sight and think of carrying it far to the horizon: 20/30m in front of you',
      zh: '您正俯视地面。你的视线应在 1° 和 -9° 之间。抬起视线，记住要向前看：20/30 米。',
      de: 'Ihre Augen sind leicht zum Boden geneigt. Sie sollten zwischen 2° und -8° sein. Heben Sie Ihren Blick und denken Sie daran, ihn weit bis zum Horizont zu tragen: 20/30m vor Ihnen.',
      pt: 'Seus olhos estão um pouco para baixo no chão. Você deveria estar entre 1° e -9°. Levante o olhar e pense em levá-lo longe até o horizonte: 20/30m na sua frente.',
      it: 'I tuoi occhi sono leggermente rivolti verso il basso. Dovresti essere tra 1° e -9°. Alza lo sguardo e pensa di portarlo lontano all’orizzonte: 20/30m davanti a te.',
      es: 'Tu mirada está ligeramente hacia el suelo. Deberías estar entre 1° y -9°. Levanta tu mirada y piensa en llevarla lejos hacia el horizonte: 20/30m delante de ti.',
    },
  },
  {
    score_text: 'score_okay',
    scoreNumber: 0.58,
    item: 'trunk',
    text: 'Trunk',
    explanation: 'TrunkExpla',
    desc: {
      angle: 4,
      fr: 'Vous êtes penché en avant mais pas de manière optimale. Vous devriez être entre 5° et 11°.',
      en: 'In running, the intention should always be to move forward. Having an upper body posture that is too upright does not fully contribute to this. You should be between 4° and 10°. This can cause muscle tension. So remember to relax your shoulders and tilt your chest slightly forward.',
      zh: '您的身体前倾，但不是最佳状态。您应该在 5° 到 11° 之间。',
      de: 'Beim Laufen sollte die Absicht immer sein, nach vorne zu bewegen. Eine zu aufrechte Oberkörperhaltung trägt nicht vollständig dazu bei. Sie sollten zwischen 4° und 10° sein. Dies kann Muskelspannungen verursachen. Denken Sie also daran, Ihre Schultern zu entspannen und Ihre Brust leicht nach vorne zu neigen.',
      pt: 'Na corrida, a intenção deve sempre ser avançar. Ter uma postura do tronco muito ereta não contribui totalmente para isso. Você deveria estar entre 5° e 11°. Isso pode causar tensão muscular. Portanto, lembre-se de relaxar os ombros e inclinar levemente o peito para frente.',
      it: 'Nella corsa, l’intenzione dovrebbe essere sempre quella di andare avanti. Avere una postura del tronco troppo eretta non contribuisce pienamente a questo. Dovresti essere tra 5° e 11°. Questo può causare tensioni muscolari. Quindi ricorda di rilassare le spalle e inclinare leggermente il petto in avanti.',
      es: 'En la carrera, la intención siempre debe ser avanzar. Tener una postura del tronco demasiado erguida no contribuye plenamente a esto. Deberías estar entre 5° y 11°. Esto puede causar tensión muscular. Por lo tanto, recuerda relajar los hombros e inclinar ligeramente el pecho hacia adelante.',
    },
  },
  {
    score_text: 'score_okay',
    scoreNumber: 0.41,
    item: 'arm',
    text: 'Arm',
    explanation: 'ArmExpla',
    desc: {
      angle: 68,
      fr: "L'angle au coude est trop grand. Vous devriez être entre 75° et 85°. Une bonne position + coordination des bras et des jambes sont essentielles. Ils permettent de réduire la consommation d'énergie ainsi que les tensions musculaires. ",
      en: 'The angle at the elbow is too large. You should be between 75° and 85°. A good position + coordination of arms and legs are essential. They help to reduce energy consumption and muscle tension. It is thus necessary to work the position of the arms with an angle with the elbows close to 90° (right angle).',
      zh: '肘部角度过大。应在 75° 至 85° 之间。良好的姿势和手脚协调至关重要。它们有助于减少能量消耗和肌肉紧张。',
      de: 'Der Winkel am Ellenbogen ist zu groß. Sie sollten zwischen 75° und 85° sein. Eine gute Position + Koordination von Armen und Beinen sind essenziell. Sie helfen, den Energieverbrauch und die Muskelspannung zu reduzieren. Es ist daher notwendig, die Position der Arme mit einem Winkel nahe 90° (rechter Winkel) an den Ellenbogen zu arbeiten.',
      pt: 'O ângulo no cotovelo é muito grande. Você deveria estar entre 75° e 85°. Uma boa posição + coordenação dos braços e pernas são essenciais. Eles ajudam a reduzir o consumo de energia e a tensão muscular. Portanto, é necessário trabalhar a posição dos braços com um ângulo com os cotovelos próximos a 90° (ângulo reto).',
      it: 'L’angolo al gomito è troppo grande. Dovresti essere tra 75° e 85°. Una buona posizione + coordinazione di braccia e gambe sono essenziali. Aiutano a ridurre il consumo di energia e la tensione muscolare. È quindi necessario lavorare sulla posizione delle braccia con un angolo con i gomiti vicino a 90° (angolo retto).',
      es: 'El ángulo en el codo es demasiado grande. Deberías estar entre 75° y 85°. Una buena posición + coordinación de brazos y piernas son esenciales. Ayudan a reducir el consumo de energía y la tensión muscular. Por lo tanto, es necesario trabajar la posición de los brazos con un ángulo con los codos cerca de 90° (ángulo recto).',
    },
  },
  {
    score_text: 'score_good',
    scoreNumber: 0.92,
    item: 'forwardCycle',
    text: 'FrontKnee',
    explanation: 'FrontKneeExpla',
    desc: {
      angle: 146,
      fr: "Quelque soit votre pose du pied au sol, un tel angle au niveau du genou montre que vous avez l'intension d'aller vite vers l'avant et de 'griffer le sol'. Gardez cette intention de rester haut sur vos appuis. ",
      en: "Whatever your foot placement on the ground, such an angle at the knee shows that you intend to move forward quickly and 'claw the ground'. Keep that intention to stay high on your feet.",
      zh: '无论你的脚在地面上的位置如何，膝盖的这个角度都表明你打算快速向前移动并 "抓地"。保持你的意图，保持脚的高度。',
      de: '“Dein Kniewinkel ist optimal und zeigt deine Absicht schnell voran zu schreiten.',
      pt: 'Independentemente da posição do seu pé no chão, tal ângulo no joelho mostra que você pretende avançar rapidamente e "arranhar o solo". Mantenha essa intenção de ficar alto nos pés.',
      it: 'Qualunque sia la posizione del tuo piede a terra, un tale angolo al ginocchio mostra che hai intenzione di muoverti rapidamente in avanti e "graffiare il suolo". Mantieni questa intenzione di rimanere alto sui piedi.',
      es: 'Independientemente de la posición de tu pie en el suelo, tal ángulo en la rodilla muestra que tienes la intención de avanzar rápidamente y "arañar el suelo". Mantén esa intención de mantenerte alto en tus pies.',
    },
  },
  {
    score_text: 'score_bad',
    scoreNumber: 0.3,
    item: 'backCycle',
    text: 'BackKnee',
    explanation: 'BackKneeExpla',
    desc: {
      angle: 118,
      fr: 'La jambe arrière à un cycle trop bas ou trop rasant par rapport au sol. Vous aurez tendance a faire des petits pas. Vous devriez avoir un angle au genou de 110° et moins. Gardez pour intention de rester haut sur vos appuis. ',
      en: 'The back leg cycles too closely to the ground. You will tend to take small steps. You should have a knee angle of 101° and less. Keep your intention to stay high on your feet.',
      zh: '后腿循环过低或离地面过低。你会倾向于迈小步。膝关节角度应为 110°或更小。目标是保持高抬腿。',
      de: 'Dein aktives Bein ist zu niedrig bei seiner Rückkehr und führt dazu kleinere Schritte zu machen.',
      pt: 'A perna de trás faz um ciclo muito próximo ao chão. Você tenderá a dar passos pequenos. Você deveria ter um ângulo de joelho de 101° ou menos. Mantenha sua intenção de ficar alto nos pés.',
      it: 'La gamba posteriore fa un ciclo troppo vicino al suolo. Tenderai a fare passi piccoli. Dovresti avere un angolo di ginocchio di 101° o meno. Mantieni l’intenzione di rimanere alto sui piedi.',
      es: 'La pierna trasera hace un ciclo demasiado cerca del suelo. Tenderás a dar pasos pequeños. Deberías tener un ángulo de rodilla de 101° o menos. Mantén tu intención de mantenerte alto en tus pies.',
    },
  },
];

export const tutoVideoIntro = {
  athlete: 'AthleteIntroTutoVideo',
  coach: 'CoachIntroTutoVideo',
  professional: 'CoachIntroTutoVideo',
};

export const accountCreationHeadline = {
  athlete: 'AccountCreationHeadline',
  coach: 'AccountCreationHeadlineCoach',
  professional: 'AccountCreationHeadlineCoach',
};
