import React from 'react';

import {useLocation, useNavigate} from 'react-router-dom';

import TextComponent from 'components/atoms/TextComponent';
import MultipleButton from 'components/molecules/MultipleButton';
import InfosCard from './components/InfosCard';
import RoundedScoreMeter from 'screen/analysis/AnalysisListCard/components/RoundedScoreMeter';
import VideoComponent from 'screen/analysis/AnalysisViewDetailsScreen/components/VideoComponent';

import {translate} from 'services/remoteConfig';

import {createTextObjectReusable} from 'utils/utils';

import {BOLD, H4, H6, MEDIUM, ORANGE} from 'constant/constants';

import {colors} from 'themes';
import OnBoardingBottomArrow from 'screen/auth/registration/onBoarding/components/OnBoardingBottomArrow';
import {isMobile} from 'react-device-detect';
import {Grid} from '@mui/material';
import ButtonComponent from 'components/atoms/ButtonComponent';

function ExerciceScreen() {
  const location = useLocation();
  const item = location?.state?.item;
  const improvedSegments = location?.state?.improvedSegments;
  const [navigation, setNavigation] = React.useState('Benefits');
  const navigate = useNavigate();

  function handleBackArrowPressed() {
    navigate('/registration/athlete/analysis-exercises');
  }

  const DOUBLE_BUTTON_CONFIG = [{title: 'Benefits'}, {title: 'Instructions'}];

  const InfosCardData = [
    {title: translate(item?.difficulty), subtitle: 'Difficulty'},
    {title: item?.timeTotal, subtitle: 'Duration'},
    {title: item?.timePerRep?.match(/{{(.*?)}}/)[1], subtitle: 'Series'},
    {title: item?.numberOfSeries, subtitle: 'Reps'},
  ];

  return (
    <div style={isMobile ? {} : styles.container}>
      <div style={isMobile ? {} : styles.videoContainer}>
        {isMobile && (
          <div style={styles.textContainer}>
            <TextComponent
              text={
                translate('ViewMobileApp') +
                "Pour une meilleure expérience, nous vous recommandons d'utiliser l'application mobile"
              }
              sx={{marginRight: 1}}
            />
            <ButtonComponent
              onClick={() => {
                window.location.replace('https://ochy.page.link/kgNQ');
              }}
              type={ORANGE}
              title={translate('GoInApp')}
            />
          </div>
        )}
        <VideoComponent video={item?.video} exercice={true} speed={1} />
      </div>
      <div
        style={
          isMobile
            ? {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '95%%',
                alignSelf: 'center',
              }
            : styles.infosContainer
        }>
        <div style={styles.header}>
          <TextComponent text={item?.exerciseTitle} variant={H4} />
          <div style={styles.roundedScore}>
            {improvedSegments?.map((segment, index) => (
              <RoundedScoreMeter
                item={segment?.type}
                size={100}
                key={index}
                scoreText={segment?.scoreText}
                scoreNumber={segment?.score_number}
              />
            ))}
          </div>
        </div>
        <Grid container spacing={2} alignItems={'center'}>
          {InfosCardData.map((card, index) => (
            <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
              <InfosCard title={card.title} subtitle={card.subtitle} />
            </Grid>
          ))}
        </Grid>

        <div style={styles.multipleButton}>
          <MultipleButton
            config={DOUBLE_BUTTON_CONFIG}
            navigation={navigation}
            setNavigation={setNavigation}
          />
        </div>
        <TextComponent
          multipleText={createTextObjectReusable(
            navigation === 'Benefits' ? item?.benefits : item?.instructions,
            {weight: MEDIUM, variant: H6, style: styles.benefitsText},
            {},
            {weight: BOLD, variant: H6, style: styles.benefitsTextBold},
          )}
        />
      </div>
      {location?.state?.onBoarding && (
        <OnBoardingBottomArrow
          previous={true}
          onPress={handleBackArrowPressed}
        />
      )}
    </div>
  );
}

const styles = {
  benefitsText: {
    color: colors.WHITE_OPACITY_70,
  },
  benefitsTextBold: {
    color: colors.WHITE,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: '20px',
    width: '100%',
  },
  infosContainer: {
    flex: 1,
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',
  },
  infosCard: {
    display: 'grid',
    alignItems: 'center',
    width: '98%',
    gridTemplateColumns: 'repeat(auto-fit, minmax(130px, 1fr))',
    gridAutoFlow: 'dense',
    gridGap: '1vw',
  },
  multipleButton: {
    width: '80%',
    height: '4vh',
    marginTop: 20,
    display: 'flex',
    marginBottom: 20,
  },
  roundedScore: {
    display: 'flex',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    float: 'left',
    alignItems: 'center',
    height: '100%',
    marginLeft: 20,
    paddingTop: 10,
    marginBottom: 20,
  },
  videoContainer: {
    height: '90vh',
    display: 'flex',
  },
};

export default ExerciceScreen;
