import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import NoCredits from './components/NoCredits';
import CircularProgressComponent from './components/CircularProgress';
import ChoseAnalysisType from './ChoseAnalysisType';

import { translate } from 'services/remoteConfig';
import { createNewAnalysis, startVideoAnalysis } from 'services/analysisApi';
import { addNewAnalysisInUserProject } from 'services/projectApi';
import { removeCredit } from 'services/usageApi';
import { uploadFile } from 'services/upload';

import { useUsageChanges } from 'hooks/useUsageChanges';

import { canUserUseAnalysis, checkUserPermission } from 'utils/utils';

import { mpTrack } from 'services/mixpanel';
import {
  COACH,
  PROFESSIONAL,
  USERTYPE_ATHLETE,
  USERTYPE_PATIENT,
} from 'constant/constants';

import { resetAthleteSelected, selectAthleteSelected } from 'slices/athleteSlice';
import { selectUsage, updateUsageStore } from 'slices/usageSlice';
import { setUserAttributeOnAllPlatforms } from 'services/tracking';
import { analysisListState } from 'slices/analysisSlice';
import ContactSales from 'screen/profile/settings/components/ContactSales';

function UploadScreen() {
  useUsageChanges();

  const usage = useSelector(selectUsage);
  const navigate = useNavigate();

  const athleteSlice = useSelector(selectAthleteSelected);
  const analysisList = useSelector(analysisListState);

  const [uploadProgress, setUploadProgress] = useState(undefined);
  const [videoUploading, setVideoUploading] = useState(false);

  const user = useSelector(state => state.user);
  const usageSlice = useSelector(state => state.usage);
  const dispatch = useDispatch();

  async function AddAnalysisInUsersProjectAndAnalysis(fileId) {
    const data = {
      uploadVideoId: fileId,
      locked:
        checkUserPermission([USERTYPE_ATHLETE]) &&
          usageSlice?.creditNumber <= 0 &&
          usageSlice?.monthlyCreditNumber <= 0 &&
          usageSlice?.yearlyPlan === false
          ? true
          : false,
    };
    if (usage?.nTrial > 0) data.trial = true;
    let tagData = {};

    if (checkUserPermission([USERTYPE_ATHLETE])) {
      tagData = {
        email: user?.emailAddress,
        height: user?.height,
        weight: user?.weight,
        id: user?.userID,
        name: user?.fullName,
        type: USERTYPE_ATHLETE,
      };
    } else if (athleteSlice?.type === USERTYPE_PATIENT) {
      tagData = {
        height: athleteSlice?.height,
        weight: athleteSlice?.weight,
        id: athleteSlice?.id,
        name: athleteSlice?.name,
        type: USERTYPE_PATIENT,
      };
    } else if (athleteSlice?.id !== undefined) {
      tagData = {
        email: athleteSlice?.emailAddress,
        height: athleteSlice?.height,
        weight: athleteSlice?.weight,
        id: athleteSlice?.id,
        name: athleteSlice?.fullName,
        type: USERTYPE_ATHLETE,
      };
    }
    createNewAnalysis(data, tagData).then(() => {
      addNewAnalysisInUserProject(
        fileId,
        athleteSlice?.id ? athleteSlice?.id : false,
      ).then(() => {
        removeCredit().then(creditNumber => {
          dispatch(updateUsageStore(creditNumber));
        });
      });
    });
  }

  function handleUploadProgress(progress) {
    setUploadProgress(progress);
  }
  function handleUploadError(error) {
    alert(translate('ErrorUploading') + error.message);
  }

  async function handleUploadIsFinished(fileId, analysisData) {
    await AddAnalysisInUsersProjectAndAnalysis(fileId);
    if (athleteSlice && Object.keys(athleteSlice).length !== 0) {
      const data = {
        height: athleteSlice?.height,
        weight: athleteSlice?.weight,
        analysisType: analysisData?.type,
        pace: analysisData?.pace,
      };
      startVideoAnalysis(fileId, data);
    } else if (checkUserPermission([USERTYPE_ATHLETE])) {
      const data = {
        height: user?.height,
        weight: user?.weight,
        analysisType: analysisData?.type,
        pace: analysisData?.pace,
      };
      startVideoAnalysis(fileId, data);
    } else {
      await startVideoAnalysis(fileId);
    }
    const date = new Date();
    setUserAttributeOnAllPlatforms('last_analysis_date', date.toDateString());
    setUserAttributeOnAllPlatforms('number_of_analysis', analysisList.length);
    setUploadProgress(undefined);
    setVideoUploading(false);
  }

  async function startUpload(localVideoUrl, analysisData) {
    mpTrack('Analysis Submitted');
    uploadFile(
      localVideoUrl,
      handleUploadProgress,
      handleUploadError,
      handleUploadIsFinished,
      analysisData,
    );
    setUploadProgress(0);
    setVideoUploading(true);
  }

  if (videoUploading && uploadProgress >= 0 && uploadProgress < 1) {
    return <CircularProgressComponent progress={uploadProgress} />;
  } else {
    if (uploadProgress === 1) {
      setTimeout(() => {
        dispatch(resetAthleteSelected());
        navigate('/');
      }, 500);
    }
  }



  if (
    !canUserUseAnalysis(usageSlice) &&
    checkUserPermission([COACH, PROFESSIONAL])
  ) {
    return <ContactSales big={true} />;
  }

  if (!canUserUseAnalysis(usageSlice)) {
    return <NoCredits />;
  }
  return <ChoseAnalysisType startUpload={startUpload} />;
}

export default UploadScreen;
