import React, { useEffect, useState } from "react";

import {
    XAxis,
    YAxis,
    LineChart,
    Line,
    ResponsiveContainer,
    Tooltip,
} from "recharts";

import TextComponent from "components/atoms/TextComponent";
import LoadingComponent from "components/atoms/LoadingComponent";

import { translate } from "services/remoteConfig";

import { colors } from "themes";

function AnalysisGraph({ analysisListDatas, loading }) {

    const [data, setData] = useState(null);

    useEffect(() => {

        if (analysisListDatas?.length === 0) return;
        const counts = countAnalysisByMonth(analysisListDatas);

        const resultArray = Object.entries(counts)
            ?.map(([date, analysis]) => {
                const translatedKey = translate('Analysis');
                return { date, [translatedKey]: analysis };
            })
            ?.sort((a, b) => {
                const [monthA, yearA] = a?.date?.split('/');
                const [monthB, yearB] = b?.date?.split('/');
                return new Date(`${yearA}-${monthA}-01`) - new Date(`${yearB}-${monthB}-01`);
            });

        setData(resultArray);
    }, [analysisListDatas]);


    function countAnalysisByMonth(analysisList) {
        const counts = {};
        let startDate = new Date();
        let endDate = new Date();

        analysisList?.forEach(obj => {
            const date = new Date(obj?.date);
            if (date < startDate) startDate = date;
            if (date > endDate) endDate = date;
            const year = date?.getFullYear();
            const monthKey = `${(date?.getMonth() + 1).toString().padStart(2, '0')}/${year?.toString()}`;
            counts[monthKey] = (counts[monthKey] || 0) + 1;
        });

        const lastDayOfMonth = new Date(endDate?.getFullYear(), endDate?.getMonth() + 1, 0)?.getDate();
        endDate?.setDate(lastDayOfMonth);

        for (let d = new Date(startDate); d <= endDate; d?.setMonth(d?.getMonth() + 1)) {
            const year = d?.getFullYear();
            const monthKey = `${(d?.getMonth() + 1)?.toString()?.padStart(2, '0')}/${year?.toString()}`;
            if (!(monthKey in counts)) counts[monthKey] = 0;
        }
        return counts;
    }

    return (
        <div style={styles.container}>
            <TextComponent text={translate('AnalysesPerformed')} sx={styles.title} />
            {analysisListDatas?.length === 0 ?
                <div style={styles.noVideo}>
                    <TextComponent text={translate('NoVideos')} sx={styles.title} />
                </div>
                :
                loading ?
                    <div style={styles.loading}>
                        <LoadingComponent />
                    </div>
                    :
                    <div style={styles.chart}>
                        <ResponsiveContainer width="90%" height="90%">
                            <LineChart data={data}>
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Line type="monotone" dataKey={translate('Analysis')} stroke={colors.SECONDARY} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
            }
        </div>
    )


}

const styles = {
    chart: {
        width: '100%',
        height: '100%',
        overflow: 'auto'
    },
    container: {
        width: '100%',
        backgroundColor: 'black',
        borderRadius: 10,
        height: '100%',
        marginLeft: '1vw'
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90%',
        width: '100%',
    },
    noVideo: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    title: {
        color: colors.TEXT_GRAY,
        marginLeft: '1vw',
        marginTop: '1vh',
        marginBottom: '1vh',
        fontSize: '1.2vw'
    },
}


export default AnalysisGraph;