import React, {useEffect} from 'react';

import {Container, Grid, Box} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import OnBoardingBottomArrow from '../../components/OnBoardingBottomArrow';
import TextComponent from 'components/atoms/TextComponent';
import ExerciceCard from 'screen/analysis/exercices/ExerciceCard';

import {USER_LOCALE, onBoardingTranslate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {createTextObjectReusable} from 'utils/utils';

import exercicesFR from 'screen/auth/registration/onBoarding/constants/exercises_fr.json';
import exercicesEn from 'screen/auth/registration/onBoarding/constants/exercises_en.json';
import exercicesDE from 'screen/auth/registration/onBoarding/constants/exercises_de.json';
import exercicesZH from 'screen/auth/registration/onBoarding/constants/exercises_zh.json';

import {BOLD, H4} from 'constant/constants';

import {colors} from 'themes';

function OnBoardingExercises() {
  const navigate = useNavigate();

  function getExercices() {
    let exercices = [];
    if (USER_LOCALE === 'fr') exercices = exercicesFR;
    else if (USER_LOCALE === 'zh') exercices = exercicesZH;
    else if (USER_LOCALE === 'de') exercices = exercicesDE;
    else exercices = exercicesEn;
    return exercices;
  }

  useEffect(() => {
    mpTrack('Web App OnBoarding Exercises List Screen Viewed');
  }, []);

  function handleArrowPressed() {
    navigate('/registration/good-video-tutorial');
  }

  function handleBackArrowPressed() {
    navigate('/registration/athlete/analysis-exercises-introduction');
  }
  return (
    <>
      <div style={styles.container}>
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('ExercicesHeadline'),
            {
              variant: H4,
              weight: BOLD,
            },
            {
              variant: H4,
              weight: BOLD,
              style: {textAlign: 'center', color: colors.SECONDARY},
            },
          )}
          center={true}
        />
      </div>
      <Container>
        <Box sx={styles.gridContainer}>
          <Grid container spacing={3} justifyContent="center">
            {getExercices().map((item, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={5}>
                  <ExerciceCard item={item} key={index} onBoarding={true} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
      <OnBoardingBottomArrow onPress={handleArrowPressed} />
      <OnBoardingBottomArrow previous={true} onPress={handleBackArrowPressed} />
    </>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5%',
    width: '80%',
    margin: 'auto',
  },
  gridContainer: {
    flexGrow: 1,
    padding: 2,
    marginTop: 15,
  },
};

export default OnBoardingExercises;
