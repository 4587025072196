import React, {useEffect, useState} from 'react';

import {useLocation, useNavigate} from 'react-router-dom';

import TextComponent from 'components/atoms/TextComponent';
import OnBoardingAnalysisContainer from './components/OnBoardingAnalysisContainer';
import RoundedScoreMeter from 'screen/analysis/AnalysisListCard/components/RoundedScoreMeter';

import {USER_LOCALE, onBoardingTranslate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {score_colors} from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import {BOLD, H2, H6} from 'constant/constants';
import {segments} from 'screen/auth/registration/onBoarding/constants/onBoardingText';

import {colors} from 'themes';

function OnBoardingAnalysisSegmentExplanation() {
  const navigate = useNavigate();
  const location = useLocation();

  const [index, setIndex] = useState(location?.state?.value || 0);

  useEffect(() => {
    if (index === 0)
      mpTrack('Web App OnBoarding Analysis Segment Good Screen Viewed');
    else mpTrack('Web App OnBoarding Analysis Segment Bad Screen Viewed');
  }, [index]);

  function handleNextArrowClicked() {
    if (index === 0) {
      setIndex(1);
    } else {
      navigate('/registration/athlete/analysis-exercises-introduction');
    }
  }

  function handlePreviousArrowClicked() {
    if (index === 1) {
      setIndex(0);
    } else {
      navigate('/registration/athlete/analysis-total-score');
    }
  }
  return (
    <OnBoardingAnalysisContainer
      handleNextArrowClicked={handleNextArrowClicked}
      handlePreviousArrowClicked={handlePreviousArrowClicked}>
      <div style={styles.container}>
        <RoundedScoreMeter
          scoreNumber={index === 0 ? 0.92 : 0.3}
          scoreText={index === 0 ? 'score_good' : 'score_bad'}
          item={index === 0 ? 'RightKnee' : 'LeftKnee'}
          size={150}
        />
        <div style={styles.titleContainer}>
          <TextComponent
            text={onBoardingTranslate('TotalScoreText')}
            variant={H6}
            sx={{color: colors.WHITE_OPACITY_60}}
          />

          <TextComponent
            text={index === 0 ? '92%' : '30%'}
            variant={H2}
            weight={BOLD}
            sx={{
              color:
                index === 0
                  ? score_colors['score_good'].stroke
                  : score_colors['score_bad'].stroke,
            }}
          />
        </div>
      </div>
      <div style={styles.greyBar} />
      <div style={styles.textContainer}>
        <TextComponent
          text={
            index === 0
              ? '“' + segments[4].desc[USER_LOCALE] + '"'
              : '“' + segments[5].desc[USER_LOCALE] + '"'
          }
          sx={{color: colors.SECONDARY}}
          variant={H6}
        />
      </div>
    </OnBoardingAnalysisContainer>
  );
}

const styles = {
  container: {
    display: 'flex',
  },
  greyBar: {
    alignSelf: 'center',
    backgroundColor: colors.WHITE_OPACITY_40,
    height: 1,
    display: 'flex',
    marginTop: 20,
    marginBottom: 20,
    width: '95%',
  },
  textContainer: {
    alignSelf: 'center',
    display: 'flex',
    textAlign: 'center',
    width: '95%',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

export default OnBoardingAnalysisSegmentExplanation;
