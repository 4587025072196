import TextComponent from "components/atoms/TextComponent";
import { BODY_1, BODY_2, H3 } from "constant/constants";
import React from "react";
import RoundedScoreMeter from "screen/analysis/AnalysisListCard/components/RoundedScoreMeter";
import ScoreTextWithBackground from "../../CardComponent/components/ScoreTextWithBackground";
import { translate } from "services/remoteConfig";

function AnalysisPanelTopContent({ displayData, personalised }) {
  return (
    <div>
      <div style={styles.container}>
        <div style={{ width: "33%" }}>
          <TextComponent
            text={
              displayData?.type !== "RightAnkle"
                ? parseFloat(displayData?.angle) + "°"
                : null
            }
            sx={styles.text}
            variant={H3}
          />
        </div>

        {personalised ? (
          <div>
            <RoundedScoreMeter
              scoreNumber={displayData?.score_number}
              scoreText={displayData?.score}
              item={displayData?.type}
              size={100}
            />
          </div>
        ) : (
          <div>
            <RoundedScoreMeter
              scoreText={displayData?.score}
              item={displayData?.type}
              size={100}
            />
          </div>
        )}

        <div style={{ width: "33%" }}>
          <TextComponent
            text={translate("YourPosition")}
            sx={styles.yourPosition}
            variant={BODY_1}
          />
          <TextComponent
            text={displayData?.text1}
            sx={styles.textOne}
            variant={BODY_2}
          />
        </div>
      </div>
      {displayData?.type !== "RightAnkle" ? (
        <>
          <div style={styles.rightAnkle}></div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ScoreTextWithBackground
              scoreNumber={
                personalised ? displayData?.score_number : displayData?.score
              }
            />
          </div>
        </>
      ) : null}
    </div>
  );
}
const styles = {
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  text: {
    textAlign: "center",
    color: "white",
    margin: 0,
  },
  yourPosition: {
    textAlign: "right",
    color: "#737373",
    marginRight: 1,
  },
  textOne: {
    textAlign: "right",
    color: "white",
    marginRight: 1,
  },
  rightAnkle: {
    width: "50%",
    height: "2vh",
    margin: 0,
    borderRight: "#737373 1px solid",
    marginTop: -10,
    marginBottom: 10,
  },
};
export default AnalysisPanelTopContent;
