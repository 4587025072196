import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  athlete: {},
};

export const athleteSelectedSlice = createSlice({
  name: "athleteSelected",
  initialState,
  reducers: {
    resetAthleteSelected: (state, action) => {
      state.athlete = {};
      state.listAthlete = [];
    },
    setAthleteSelected: (state, action) => {
      state.athlete = action?.payload;
    },
  },
});

export const { resetAthleteSelected, setAthleteSelected } =
  athleteSelectedSlice.actions;

export const selectAthleteSelected = (state) => state?.athleteSelected?.athlete;

export default athleteSelectedSlice.reducer;
