import React, { useRef, useState } from "react";

import ClickableTextComponent from "components/atoms/ClickableTextComponent";
import DragAndDrop from "./components/DragAndDrop";

import { translate } from "services/remoteConfig";

import { useUsageChanges } from "hooks/useUsageChanges";

import { colors } from "themes";

import {
  BODY_1,
} from "constant/constants";

import VideoChecker from "./components/VideoChecker";

export default function VideoValidation({ startUpload, analysisType }) {
  useUsageChanges();
  const [source, setSource] = useState(undefined);
  const inputRef = useRef(null);


  return (
    <div style={styles.container}>
      <div style={styles.dragNdropContainer}>
        <div>
          <DragAndDrop
            callback={setSource}
            inputRef={inputRef}
            source={source}
            setSource={setSource}
            analysisType={analysisType}
          />
          {source ? (
            <div
              style={styles.textContainer}
            >
              <ClickableTextComponent
                title={translate("ChoseAnotherVideoWebApp")}
                variant={BODY_1}
                sx={styles.textStyles}
                onPress={() => {
                  inputRef?.current?.click();
                }}
              />
            </div>
          ) : null}
        </div>
        <VideoChecker analysisType={analysisType} selectedVideo={source} startUpload={startUpload} />
      </div>
    </div>
  );
}


const styles = {
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    width: "35%",
    marginTop: "3%",
    justifyContent: "space-around",
  },
  container: {
    minHeight: "100vh",
    //backgroundColor: colors.BACKGROUND,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
  },
  dragNdropContainer: {
    marginTop: "3%",
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "3rem",
  },
  headTitle: {
    color: colors.WHITE,
    marginBottom: 5,
    textAlign: "center",
  },
  orangeButton: {
    backgroundColor: colors.SECONDARY,
    borderRadius: 3,
    border: 1,
    borderColor: colors.SECONDARY,
    color: colors.WHITE,
  },
  textContainer: {
    margin: "auto",
    marginTop: 10,
    width: 120,
  },
  textStyles: {
    textAlign: "center",
    textDecoration: "underline",
  },
  title: {
    color: colors.WHITE,
    marginTop: 10,
    marginLeft: 1,
  },
  smallTitle: {
    color: "white",
    marginTop: "3%",
    marginBottom: "1%",
    marginLeft: "2.5%",
  },
};
