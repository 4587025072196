
import React from "react";

import PersonnalInfos from "./PersonnalInfos";
import Brand from "./Brand";
import AnalysisGraph from "./AnalysisGraph";
import Statistics from "./Statistics";

import { SUCCESS } from "constant/constants";


function ProfileCoach({ analysisListDatas, userSlice, loading }) {

    return (
        <div style={styles.container}>
            <div style={styles.subContainer}>
                <PersonnalInfos userSlice={userSlice} />
                <div style={styles.dashboard}>
                    <div style={styles.topDashboard}>
                        <Brand />
                        <AnalysisGraph analysisListDatas={analysisListDatas?.filter((obj) => obj?.exemple !== true && obj?.status === SUCCESS)} loading={loading} />
                    </div>
                    <Statistics analysisListDatas={analysisListDatas?.filter((obj) => obj?.exemple !== true && obj?.status === SUCCESS)} loading={loading} />
                </div>
            </div>
        </div>

    );
}

const styles = {
    container: {
        display: 'flex',
        height: '92vh',
        alignItems: 'center'
    },
    dashboard: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '1vw'
    },
    subContainer: {
        width: '100%',
        height: '94%',
        display: 'flex',
        marginRight: '1vw',
        marginLeft: '1vw'
    },
    topDashboard: {
        width: '100%',
        height: '60%',
        display: 'flex',
        flexDirection: 'row'
    }
}
export default ProfileCoach;