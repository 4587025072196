import React, {useEffect, useRef, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import HeightTextInput from 'components/molecules/HeightTextInput';
import WeightTextInput from 'components/molecules/WeightTextInput';
import TextComponent from 'components/atoms/TextComponent';

import {onBoardingTranslate} from 'services/remoteConfig';

import {selectAthleteSelected} from 'slices/athleteSlice';
import {selectUnityType, setUnity, setUnityType} from 'slices/unitySlice';

import {feetInchToCm, kgToLbs, lbsToKg, mToftIn} from 'utils/converter';
import {createTextObjectReusable} from 'utils/utils';

import {BOLD, EN, FR, H4} from 'constant/constants';

import {colors} from 'themes';

function OnBoardingAthleteHeightAndWeightTextInput({heightRef, weightRef}) {
  const heightUnityRef = useRef();
  const weightUnityRef = useRef();

  const unityType = useSelector(selectUnityType);
  const dispatch = useDispatch();

  const [displayInTextInput, setDisplayInTextInput] = useState(
    unityType === FR ? false : true,
  );

  const athleteSlice = useSelector(selectAthleteSelected);

  useEffect(() => {
    if (Object.keys(athleteSlice).length === 0) return;
    if (displayInTextInput) {
      weightRef?.current.setWeight(kgToLbs(athleteSlice?.weight)?.toFixed(0));
      const height = mToftIn(athleteSlice?.height);
      heightRef?.current?.setHeightAndCompl(height?.ft, height?.inch);
    } else {
      weightRef?.current.setWeight(athleteSlice?.weight?.toFixed(0));
      heightRef?.current.setHeight((athleteSlice?.height * 100)?.toFixed(0));
    }
  }, [displayInTextInput]);

  function convertHeightCM() {
    const heightInM = parseFloat(heightRef?.current?.getHeight()) / 100;
    const height = mToftIn(heightInM);
    heightRef?.current?.setHeightAndCompl(height?.ft, height?.inch);
  }

  function convertHeightFT() {
    const heightFT = parseFloat(heightRef?.current?.getHeight());
    const heightcmplFT = parseFloat(heightRef?.current?.getHeightComplement());
    const height = feetInchToCm(heightFT, heightcmplFT) * 100;
    heightRef?.current?.setHeight(height.toFixed(0));
  }

  function handleValueChanges(value) {
    if (value === 'ft') {
      weightUnityRef?.current?.setValue('lbs');
      setDisplayInTextInput(true);
      dispatch(setUnityType(EN));
      dispatch(setUnity(EN));
      convertHeightCM();
      weightRef?.current?.setWeightClear(
        kgToLbs(weightRef?.current?.getWeight())?.toFixed(0),
      );
    } else if (value === 'cm') {
      weightUnityRef?.current?.setValue('kg');
      setDisplayInTextInput(false);
      dispatch(setUnityType(FR));
      dispatch(setUnity(FR));
      convertHeightFT();
      weightRef?.current?.setWeightClear(
        lbsToKg(weightRef?.current?.getWeight())?.toFixed(0),
      );
    } else if (value === 'lbs') {
      heightUnityRef?.current?.setValue('ft');
      setDisplayInTextInput(true);
      dispatch(setUnityType(EN));
      dispatch(setUnity(EN));
      convertHeightCM();
      weightRef?.current?.setWeightClear(
        kgToLbs(weightRef?.current?.getWeight())?.toFixed(0),
      );
    } else if (value === 'kg') {
      heightUnityRef?.current?.setValue('cm');
      setDisplayInTextInput(false);
      dispatch(setUnityType(FR));
      dispatch(setUnity(FR));
      convertHeightFT();
      weightRef?.current?.setWeightClear(
        lbsToKg(weightRef?.current?.getWeight())?.toFixed(0),
      );
    }
  }
  return (
    <>
      <div style={styles.textContainer}>
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('HeightHeadline'),
            {
              variant: H4,
              weight: BOLD,
            },
            {
              variant: H4,
              weight: BOLD,
              style: {textAlign: 'center', color: colors.SECONDARY},
            },
          )}
          center={true}
        />
      </div>
      <HeightTextInput
        displayInTextInput={displayInTextInput}
        handleValueChanges={handleValueChanges}
        heightUnityRef={heightUnityRef}
        ref={heightRef}
        idNext="weightTextInput"
      />
      <div style={{marginTop: '10vh'}} />
      <div style={styles.textContainer}>
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('WeightHeadline'),
            {
              variant: H4,
              weight: BOLD,
            },
            {
              variant: H4,
              weight: BOLD,
              style: {textAlign: 'center', color: colors.SECONDARY},
            },
          )}
          center={true}
        />
      </div>
      <WeightTextInput
        displayInTextInput={displayInTextInput}
        handleValueChanges={handleValueChanges}
        weightUnityRef={weightUnityRef}
        ref={weightRef}
      />
    </>
  );
}
const styles = {
  textContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '3vh',
  },
};
export default OnBoardingAthleteHeightAndWeightTextInput;
