import React, {useEffect, useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {colors} from 'themes';
import TextComponent from './TextComponent';
import {ReactComponent as Arrow} from 'assets/svg/arrow-right-bold.svg';

function AccordionComponent({children, buttonContent, open, onToggle}) {
  const [isExpanded, setIsExpanded] = useState(open);

  // This effect keeps internal state synced with the external "open" prop
  useEffect(() => {
    setIsExpanded(open);
  }, [open]);

  const handleChange = () => {
    setIsExpanded(!isExpanded);
    onToggle(!isExpanded); // Notify the parent when the user toggles the accordion
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleChange}
      sx={{backgroundColor: colors.TRANSPARENT}}>
      <AccordionSummary
        sx={{
          backgroundColor: colors.WHITE_OPACITY_04,
          borderRadius: 1,
        }}
        expandIcon={
          <Arrow
            color={colors.WHITE}
            height={25}
            width={25}
            strokeWidth={5}
            stroke={colors.WHITE}
            style={{
              cursor: 'pointer',
              transform: 'rotate(90deg)',
            }}
          />
        }
        aria-controls="panel-content"
        id="panel-header">
        {buttonContent}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: colors.WHITE_OPACITY_04,
        }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionComponent;
