import React from "react";
import TextComponent from "./TextComponent";
import { BODY_2 } from "constant/constants";
import { ReactComponent as Weight } from "assets/svg/weight.svg";
import { kgToLbs } from "utils/converter";
import { useSelector } from "react-redux";
import { selectWeightUnity } from "slices/unitySlice";

function WeightComponent({ weight }) {
  const weightUnity = useSelector(selectWeightUnity);

  const getWeight = () => {
    if (!weight || weight === 0) {
      return 0;
    }
    if (typeof weight === "string") return 0;
    if (weightUnity === "lbs") {
      return kgToLbs(weight)?.toFixed(0);
    }
    return weight?.toFixed(0);
  };
  /*     !weight || weight === 0
      ? 0
      : unityType === EN
      ? kgToLbs(weight)?.toFixed(0)
      : weight?.toFixed(0); */

  return (
    <>
      <Weight />
      <TextComponent sx={styles.textWeight} text={getWeight()} variant={BODY_2} />
      <TextComponent
        sx={styles.textUnity}
        text={weightUnity}
        variant={BODY_2}
      />
    </>
  );
}

const styles = {
  textWeight: {
    marginLeft: 1,
    marginRight: 0.5,
  },
  textUnity: {
    color: "#737373",
    margin: 0,
    marginTop: 2,
  },
};

export default WeightComponent;
