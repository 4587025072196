import { useEffect, useState } from "react";

import { collection, doc, onSnapshot } from "firebase/firestore";

import { firestore } from "utils/firebaseInit";
import { useDispatch } from "react-redux";
import { setLstItems } from "slices/userItemsSlice";
import { store } from "store";

const ITEMS_COLLECTION = "userItems";

export function useItemChanges() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const user = store?.getState()?.user;
    if (!user) return;
    try {
      const unsub = onSnapshot(
        doc(collection(firestore, ITEMS_COLLECTION), user?.userID),
        (doc) => {
          if (doc.exists()) {
            dispatch(setLstItems(doc?.data()?.items));
          }
          setLoading(false);
        }
      );
      return () => unsub();
    } catch (error) {
      setError(error);
    }
  }, []);

  return { loading, error };
}
