import ButtonComponent from "components/atoms/ButtonComponent";
import ClickableTextComponent from "components/atoms/ClickableTextComponent";
import TextComponent from "components/atoms/TextComponent";
import { H1, H4, H6, ORANGE } from "constant/constants";
import React from "react";
import { useNavigate } from "react-router-dom";
import { translate } from "services/remoteConfig";
import { colors } from "themes";

function HowToDeleteAccount() {
  const navigate = useNavigate();
  return (
    <>
      <div style={styles.container}>
        <div style={styles.textContainer}>
          <TextComponent text={"How to delete my account"} variant={H4} />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "20%", marginLeft: 20 }}>
          <TextComponent
            text="FIRST STEP : Go in your profile in the bottom bar, then go in the settings part "
            sx={{ marginBottom: 2 }}
          />
          <img
            src={require("assets/images/howToDeleteAccount/1.jpg")}
            style={{
              width: "100%",
            }}
            alt={"correct position"}
          />
        </div>
        <div style={{ width: "20%", marginLeft: 20 }}>
          <TextComponent
            text="SECOND STEP : In the settings scroll a bit until you see the delete account button then click on it "
            sx={{ marginBottom: 2 }}
          />
          <img
            src={require("assets/images/howToDeleteAccount/2.jpg")}
            style={{
              width: "100%",
            }}
            alt={"correct position"}
          />
        </div>
        <div style={{ width: "20%", marginLeft: 20 }}>
          <TextComponent
            text="THIRD STEP : Here a modal should appear to confirm to delete your account"
            sx={{ marginBottom: 2 }}
          />
          <img
            src={require("assets/images/howToDeleteAccount/3.jpg")}
            style={{
              width: "100%",
            }}
            alt={"correct position"}
          />
        </div>
        <div style={{ width: "20%", marginLeft: 20 }}>
          <TextComponent
            text="FOURTH STEP : In order to delete you account you need to type your password then once it is validate your account will be deleted "
            sx={{ marginBottom: 2 }}
          />
          <img
            src={require("assets/images/howToDeleteAccount/4.jpg")}
            style={{
              width: "100%",
            }}
            alt={"correct position"}
          />
        </div>
      </div>
      <div style={{ marginLeft: 20 }}>
        <TextComponent
          text={"What happend when my account is deleted ?"}
          variant={H6}
          sx={{ marginBottom: 2 }}
        />

        <TextComponent
          text={
            "When your account is deleted we make sure to delete the account and every personal informations you have informed in Ochy. That means, profile, pictures, videos, analysis..."
          }
        />
        <TextComponent
          text={"If you encounter any probleme while deleting you account"}
          variant={H6}
          sx={{ marginTop: 5, marginBottom: 2 }}
        />
        <TextComponent
          text={
            "Please send us a message throught the support in the following section of the web site. We will make sure to answer as quick as possible."
          }
        />
        <img
          src={require("assets/images/howToDeleteAccount/5.png")}
          style={{
            width: "10%",
          }}
          alt={"correct position"}
        />
      </div>
    </>
  );
}
const styles = {
  textContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
  },
  buttonContainer: {
    display: "flex",
    float: "right",
    alignItems: "center",
    height: "100%",
    marginRight: 20,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: colors.BACKGROUND,
    zIndex: 10,
    position: "relative",
    borderRadius: 10,
    marginBottom: 7,
    height: 60,
  },
};
export default HowToDeleteAccount;
