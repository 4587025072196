import React from "react";

import TextComponent from "components/atoms/TextComponent";

import { translate } from "services/remoteConfig";

import { colors } from "themes";
import SvgSelector from "themes/SvgSelector";

import { ReactComponent as Arrow } from 'assets/svg/arrow.svg';

function SettingsButton({ element, onClick }) {

    return (
        <div style={{ ...styles.button, ...(element?.name === 'DeleteAccount' ? { backgroundColor: colors.RED_OPACITY15 } : { backgroundColor: colors.BLACK }) }}
            onClick={onClick}>
            <div style={styles.icon}>
                <SvgSelector name={element?.icon} color={colors.WHITE} width={'2vh'} height={'2vh'} />
            </div>
            <TextComponent
                text={translate(element?.name)}
                sx={styles.text}
            />
            <Arrow style={styles.arrow} height={'2vh'} width={'2vh'} />
        </div>
    )
}

const styles = {
    arrow: {
        position: 'absolute',
        right: '0.5vw',
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 5,
        position: 'relative',
        cursor: 'pointer',
        marginBottom: '1vh',
        height: '5vh',
    },
    icon: {
        marginLeft: '1vh',
        alignItems: 'center',
        display: 'flex'
    },
    text: {
        marginLeft: '0.5vw',
        fontSize: '1.5vw'
    },
}


export default SettingsButton;