import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import io from 'socket.io-client';

import './styles/AnalysisProgressCard.css';
import TextComponent from 'components/atoms/TextComponent';
import { BODY_2, BODY_3, MEDIUM, REAR_FOOT } from 'constant/constants';
import { translate } from 'services/remoteConfig';
import { colors } from 'themes';

const URL = process.env.REACT_APP_API_KEY;

const ANALYSIS_STAGE_UNKNOWN = 'unknown';
const ANALYSIS_STAGE_ENCODING = 'encoding';
const ANALYSIS_STAGE_ANALYZING = 'analyzing';

function AnalysisProgressCard({ id, analysis }) {
  const [progress, setProgress] = useState(undefined);
  const [analysisStage, setAnalysisStage] = useState(ANALYSIS_STAGE_UNKNOWN);
  const socketRef = useRef(null);

  useEffect(() => {
    socketRef.current = io(URL, { autoConnect: false });
    socketRef.current.connect();
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);
  useEffect(() => {
    if (analysis === null || !socketRef.current) return;
    const socket = socketRef.current;
    socket.on('connect', () => {
      socket.emit('check_status', {
        video_id: id,
        analysis_type: analysis?.analysis_type
      });
    });
    socket.on('status_update', (data) => {
      const { status, percentage } = data;
      setProgress(percentage);
      if (status !== analysisStage) setAnalysisStage(status);
      if (
        status === 'failed' ||
        status === 'success' ||
        percentage === '100.00'
      ) {
        socketRef.current.disconnect();
      }
    });

    return () => {
      socket.off('connect');
      socket.off('status_update');
    };
  }, [analysis, id]);

  function handleRenderingProgress() {
    if (analysisStage == ANALYSIS_STAGE_ENCODING && progress >= 0.9) {
      return (
        <TextComponent
          style={styles.finalizeAnalysis}
          variant={BODY_3}
          text={translate('FinalizeAnalysis')}
        />
      );
    } else {
      return (
        <TextComponent
          text={progress + '%'}
          variant={BODY_2}
          weight={MEDIUM}
          sx={styles.progressText}
        />
      );
    }
  }

  return (
    <div className="loadingCard-container">
      <div
        className="loadingCard-imageHeight"
        style={{
          backgroundImage: `url(${require('assets/images/loadingBlur.png')})`
        }}
      >
        <Lottie
          animationData={require('assets/animations/loading_runner.json')}
          loop={true}
          style={styles.lottie}
        />
      </div>
      {(progress &&
            parseFloat(progress) < 100 &&
            analysisStage === ANALYSIS_STAGE_ANALYZING)
            ? handleRenderingProgress()
            : null}
    </div>
  );
}

export default AnalysisProgressCard;

const styles = {
  finalizeAnalysis: {
    color: colors.BLACK,
    margin: 10,
    marginLeft: 15,
    marginRight: 15,
    textAlign: 'center'
  },
  progressText: {
    display: 'flex',
    flexDirection: 'column',
    color: colors?.SECONDARY,
    textAlign: 'center',
  },
  lottie: {
    margin: 'auto',
    width: '50%'
  }
};
