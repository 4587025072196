import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import TextComponent from 'components/atoms/TextComponent';
import RoundedScoreMeter from 'screen/analysis/AnalysisListCard/components/RoundedScoreMeter';
import OnBoardingAnalysisContainer from './components/OnBoardingAnalysisContainer';

import {onBoardingTranslate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {createTextObjectReusable} from 'utils/utils';

import {BOLD, H2, H5, H6, REGISTRATION_DATA} from 'constant/constants';
import {score_colors} from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import {analysisPresentationScore} from 'screen/auth/registration/onBoarding/constants/onBoardingText';

import {colors} from 'themes';

function OnBoardingAnalysisTotalScore() {
  const navigate = useNavigate();

  useEffect(() => {
    mpTrack(
      'Web App OnBoarding Analysis Total Score Presentation Screen Viewed',
    );
  }, []);

  const userTypology = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.typology;

  function handleArrowClicked() {
    navigate('/registration/athlete/analysis-segment');
  }
  function handlePreviousArrowClicked() {
    navigate('/registration/athlete/analysis-introduction');
  }
  return (
    <OnBoardingAnalysisContainer
      handleNextArrowClicked={handleArrowClicked}
      handlePreviousArrowClicked={handlePreviousArrowClicked}>
      <div style={{display: 'flex'}}>
        <RoundedScoreMeter
          scoreNumber={0.56}
          scoreText={'score_okay'}
          item={'score'}
          size={150}
        />
        <div style={styles.topContentContainer}>
          <TextComponent
            text={onBoardingTranslate('TotalScoreText')}
            variant={H6}
            sx={{color: colors.WHITE_OPACITY_60}}
          />
          <TextComponent
            text={'56%'}
            variant={H2}
            weight={BOLD}
            sx={{color: score_colors['score_okay'].stroke}}
          />
        </div>
      </div>
      <div style={styles.greyBar} />
      <div style={styles.contentContainer}>
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate(analysisPresentationScore[userTypology]),
            {
              variant: H5,
            },
            {
              variant: H5,
              style: {
                color: colors.SECONDARY,
              },
            },
          )}
          center={true}
        />
      </div>
    </OnBoardingAnalysisContainer>
  );
}
const styles = {
  contentContainer: {
    alignSelf: 'center',
    display: 'flex',
    textAlign: 'center',
    width: '95%',
  },
  greyBar: {
    alignSelf: 'center',
    backgroundColor: colors.WHITE_OPACITY_40,
    display: 'flex',
    height: 1,
    marginTop: 20,
    marginBottom: 20,
    width: '95%',
  },
  topContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

export default OnBoardingAnalysisTotalScore;
