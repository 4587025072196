import React, {useEffect, useState} from 'react';

import {useLocation, useNavigate} from 'react-router-dom';

import OnBoardingAnalysisContainer from '../../athleteOnBoarding/analysis/components/OnBoardingAnalysisContainer';
import TextComponent from 'components/atoms/TextComponent';
import OnBoardingCoachMetrics from './OnBoardingCoachMetrics';
import OnBoardingCoachComment from './OnBoardingCoachComment';
import OnBoardingCoachAnalysisSegments from './OnBoardingCoachAnalysisSegments';

import {onBoardingTranslate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {createTextObjectReusable} from 'utils/utils';

import {BOLD, COACH, H3, H5, REGISTRATION_DATA} from 'constant/constants';

import {colors} from 'themes';

function OnBoardingCoachAnalysisWrapper() {
  const location = useLocation();
  const [step, setStep] = useState(location?.state?.value || 0);
  const navigate = useNavigate();

  const onBoardingUserData = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  );

  useEffect(() => {
    if (onBoardingUserData?.userType === COACH) {
      switch (step) {
        case 0:
          mpTrack('Web App OnBoarding Coach Markup Presentation Screen Viewed');
          break;
        case 1:
          mpTrack('Web App OnBoarding Coach Metrics List Screen Viewed');
          break;
        case 2:
          mpTrack('Web App OnBoarding Coach Segment List Screen Viewed');
          break;
        case 3:
          mpTrack('Web App OnBoarding Coach Write Comment Screen Viewed');
          break;
        default:
          break;
      }
    } else {
      switch (step) {
        case 0:
          mpTrack(
            'Web App OnBoarding Profesional Markup Presentation Screen Viewed',
          );
          break;
        case 1:
          mpTrack('Web App OnBoarding Profesional Metrics List Screen Viewed');
          break;
        case 2:
          mpTrack('Web App OnBoarding Profesional Write Comment Screen Viewed');
          break;
        default:
          break;
      }
    }
  }, [step]);

  const steps = [
    <div style={styles.container} key="step-1">
      <TextComponent
        multipleText={createTextObjectReusable(
          onBoardingTranslate('CoachAutoMarkupPresentation'),
          {
            variant: H5,
          },
          {
            variant: H5,
            style: {textAlign: 'center', color: colors.SECONDARY},
          },
        )}
        center={true}
      />
    </div>,
    <OnBoardingCoachMetrics key="step-2" />,
    onBoardingUserData?.userType === COACH ? (
      <OnBoardingCoachAnalysisSegments key="step-3" />
    ) : null,
    <OnBoardingCoachComment key="step-4" />,
  ].filter(Boolean);

  const nextStep = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      navigate('/registration/coach/share');
    }
  };

  const previousStep = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate('/registration/coach/analysis-introduction');
    }
  };

  return (
    <OnBoardingAnalysisContainer
      handleNextArrowClicked={nextStep}
      handlePreviousArrowClicked={previousStep}>
      {steps[step]}
    </OnBoardingAnalysisContainer>
  );
}

const styles = {
  container: {
    marginTop: '5vh',
  },
};

export default OnBoardingCoachAnalysisWrapper;
