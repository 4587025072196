import React from "react";
import { colors } from "themes";
import AnalysisRightPanelAnalysis from "screen/analysis/AnalysisViewDetailsScreen/components/analysisRightPanel/AnalysisRightPanelAnalysis";
import AnalysisRightPanelMetrics from "./AnalysisRightPanelMetrics";
import AnalysisRightPanelGraph from "./AnalysisRightPanelGraph";
import AnalysisRightPanelRunningStyles from "./AnalysisRightPanelRunningStyles";

function AnalysisRightPanelWrapper({ displayData, exercices, analysisTexts, overstrideData }) {
  function analysisPanelContent() {
    if (displayData === undefined) {
      return;
    } else if (displayData?.title) {
      return (
        <AnalysisRightPanelAnalysis
          displayData={displayData}
          exercices={exercices}
          analysisTexts={analysisTexts}
          overstrideData={overstrideData}
        />
      );
    } else if (displayData?.metricKey) {
      return <AnalysisRightPanelMetrics displayData={displayData} />;
    } else if (displayData?.graphData) {
      return <AnalysisRightPanelGraph displayData={displayData} />;
    }
    else if (displayData?.stylesExplanation) {
      return <AnalysisRightPanelRunningStyles displayData={displayData} />;
    }
  }
  return analysisPanelContent();
}

export default AnalysisRightPanelWrapper;
