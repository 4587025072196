import React from 'react';

import {ToggleButton} from '@mui/material';

import TextComponent from 'components/atoms/TextComponent';

import {BODY_1, BODY_2, BOLD} from 'constant/constants';

import {colors} from 'themes';

function OnBoardingButton({
  onPress,
  text,
  subText,
  icon: Icon,
  selected,
  disabled,
}) {
  return (
    <ToggleButton
      sx={{
        ...styles.container,
        ...{
          backgroundColor: selected
            ? colors?.OCHY_BLUE
            : colors.CARD_BACKGROUND,
          '&:hover': {
            backgroundColor: colors?.OCHY_BLUE,
          },
        },
      }}
      disabled={disabled}
      onClick={onPress}>
      {Icon ? (
        <div style={{marginBottom: 50}}>
          <Icon height={40} width={40} stroke={colors.WHITE} />
        </div>
      ) : null}
      <TextComponent
        text={text}
        variant={BODY_1}
        weight={BOLD}
        sx={styles.textCenter}
      />
      {subText && (
        <TextComponent text={subText} variant={BODY_2} sx={styles.subText} />
      )}
    </ToggleButton>
  );
}
const styles = {
  container: {
    alignItems: 'center',
    border: '3px solid rgba(255, 255, 255, 0.4)',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    height: '12rem',
    justifyContent: 'center',
    width: '100%',
  },
  subText: {
    color: colors.WHITE_OPACITY_50,
    marginTop: 2,
    textAlign: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
};
export default OnBoardingButton;
