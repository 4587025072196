import {translate} from 'services/remoteConfig';
import {images} from 'themes';

export function metricsConfig(key, value) {
  const config = {
    time_between_steps: {
      translation: translate('TimeBetweenBteps'),
      unity: translate('Sec'),
      description: translate('TimeBetweenStepsDescription'),
      image: images.METRICS_TIME_STEPS,
    },
    ground_contact_time: {
      translation: translate('GroundContactTime'),
      unity: translate('Sec'),
      description: translate('GroundContactTimeDescription'),
      image: images.METRICS_GROUND_CONTACT_TIME,
    },
    steps_per_min: {
      translation: translate('NumberOfStep'),
      unity: translate('PerMin'),
      description: translate('StepFrequencyDescription'),
      image: images.METRICS_NUMBER_STEPS,
    },
    duty_factor: {
      translation: 'Duty factor',
      unity: getDutyFactorContent(value)?.title,
      description: translate('DutyFactorDesc'),
      value: getDutyFactorContent(value)?.metric,
    },
    stride_length: {
      translation: translate('StrideLength'),
      unity: translate('Meters'),
      description: translate('StrideLengthDesc'),
      image: images.METRICS_STRIDE_LENGTH,
    },
    step_length: {
      translation: translate('StepLength'),
      unity: translate('Meters'),
      description: translate('StepLengthDesc'),
      image: images.METRICS_STEP_LENGTH,
    },
  };

  if (config[key]) return config[key];
}

function getDutyFactorContent(input) {
  if (input === -1 || !input) {
    return {
      metric: -1,
      title: '',
    };
  }
  // Vérification si le nombre est < 0.5
  if (input < 0.5) {
    return {
      metric: ((1 - input) * 100)?.toFixed(0) + '%',
      title: translate('Aerial'),
    };
  }
  // Si le nombre est > 0.5
  return {
    metric: (input * 100)?.toFixed(0) + '%',
    title: translate('Terrestrial'),
  };
}
