import React from 'react';

function OnBoardingContainer({children}) {
  return <div style={styles.container}>{children}</div>;
}
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
  },
};
export default OnBoardingContainer;
