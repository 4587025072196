import React, { useEffect, useState } from 'react';

import ErrorCard from './components/ErrorCard';
import LoadingCard from './components/LoadingCard';
import SuccessCard from './components/SuccessCard';

import { addCredit } from 'services/usageApi';
import { getDowloadUrlFromGsUrl, updateAnalysis } from 'services/analysisApi';

import { isOwnerOfItem } from 'utils/utils';

import { FAILED, PENDING, SUCCESS } from 'constant/constants';

import { fetchData } from 'services/jsonApi';
import { useAnalysisListener } from 'hooks/useAnalysisChanges';
import AnalysisProgressCard from './components/AnalysisProgressCard';
import { mpTrack } from 'services/mixpanel';

function AnalysisCard({ analysisId, comparedAnalysis, setComparedAnalysis }) {
  const [loading, setLoading] = useState(false);
  const { analysis } = useAnalysisListener(analysisId);


  //If analysis is failed and user is owner of the item then add credit
  //If analysis is success then get the data urls and set it to the state and storage
  useEffect(() => {
    if (analysis?.status === FAILED) {
      mpTrack('Web App Analysis Failed', analysis?.data?.uploadVideoId);
      if (!analysis?.refund && isOwnerOfItem(analysis?.data?.owner)) {
        addCredit(1);
        updateAnalysis({ refund: true }, analysis);
      }
    } else if (analysis?.status === SUCCESS) {
      if (localStorage?.getItem(analysis?.data?.uploadVideoId)) return;
      setDataToLocalStorage();
    }
  }, [analysis?.status, analysis]);

  async function setDataToLocalStorage() {
    setLoading(true);
    //get all urls of relevant data in storage
    const dataUrl = await getDowloadUrlFromGsUrl(analysis?.analysis_url);
    const videoDataUrl = await getDowloadUrlFromGsUrl(analysis?.video_url);
    const thumbnailUrl = await getDowloadUrlFromGsUrl(analysis?.thumbnail);
    //set the urls to the state
    //set the data to the local storage
    const fetchedData = await fetchData(dataUrl);
    if (!fetchedData) return;
    let data = null;
    if (analysis?.analysis_type === 'pronation') {
      data = fetchedData;
    } else {
      data = fetchedData[0];
    }
    data.videoUrl = videoDataUrl;
    data.thumbnail = thumbnailUrl;
    localStorage?.setItem(analysis?.data?.uploadVideoId, JSON.stringify(data));
    setLoading(false);
  }

  if (analysis?.status === PENDING && analysis) {
    return (
      <AnalysisProgressCard
        analysis={analysis}
        id={analysis?.data?.uploadVideoId}
      />
    );
  } else if (analysis?.status === SUCCESS && !loading) {
    return (
      <SuccessCard
        analysis={analysis}
        comparedAnalysis={comparedAnalysis}
        setComparedAnalysis={setComparedAnalysis}
      />
    );
  } else if (analysis?.status === FAILED)
    return <ErrorCard analysis={analysis} />;
  else
    return (
      <LoadingCard id={analysis?.data?.uploadVideoId} analysis={analysis} />
    );
}

export default AnalysisCard;
