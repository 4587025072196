import React from "react";

import { ReactComponent as Globe } from 'assets/svg/globe.svg';
import { ReactComponent as Height } from 'assets/svg/height.svg';
import { ReactComponent as Weight } from 'assets/svg/weight.svg';
import { ReactComponent as Tc } from 'assets/svg/t&c.svg';
import { ReactComponent as Pp } from 'assets/svg/pp.svg';
import { ReactComponent as Logout } from 'assets/svg/logout.svg';


function SvgSelector({ name, color, width, height }) {
    if (!color) color = '#FFFFFF';

    switch (name) {
        case 'Language':
            return <Globe width={width} height={height} fill={color} />;
        case 'Height':
            return <Height width={width} height={height} />;
        case 'Weight':
            return <Weight width={width} height={height} />;
        case 'T&C':
            return <Tc width={width} height={height} fill={color} />;
        case 'Pp':
            return <Pp width={width} height={height} fill={color} />;
        case 'LogOut':
            return (
                <div style={{ transform: 'rotate(180deg)' }}>
                    <Logout width={width} height={height} fill={color} />
                </div>
            );
        default:
            return <></>;

    }

}


export default SvgSelector;