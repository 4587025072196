import * as React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { colors } from "themes";
import TextComponent from "components/atoms/TextComponent";
import { H5 } from "constant/constants";

export default function CircularProgressComponent({ progress }) {
  const progressNumber = Math.floor(progress * 100);
  return (
    <Box sx={styles.container}>
      <CircularProgress
        value={progressNumber}
        variant={"determinate"}
        size={200}
        sx={styles.circular}
      />

      <Box sx={styles.textContainer}>
        <TextComponent text={`${progressNumber}%`} variant={H5} />
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    display: "inline-flex",
    left: "50%",
    position: "relative",
    top: "50%",
    transform: "translate(-50%, 100%)",
  },

  circular: {
    borderRadius: 10,
    color: colors.PRIMARY,
  },

  textContainer: {
    left: "50%",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },

  text: {
    color: colors.WHITE,
  },
};
