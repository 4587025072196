import * as React from "react";

import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";

import TextComponent from "components/atoms/TextComponent";
import { BODY_2 } from "constant/constants";
import { colors } from "themes";

export default function ProgressBar({ progress }) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress * 100} />
    </Box>
  );
}
const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 6,
  borderRadius: 20,
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.linearContainer}>
        <BorderLinearProgress
          variant="determinate"
          {...props}
          sx={{
            backgroundColor: colors?.PRIMARY,
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: colors?.SECONDARY,
              borderRadius: 10,
            },
          }}
        />
      </Box>
      <Box sx={styles.textContainer}>
        <TextComponent variant={BODY_2} text={`${Math.round(props.value)}%`} />
      </Box>
    </Box>
  );
}

const styles = {
  box: {
    width: "100%",
  },
  container: {
    alignItems: "center",
    display: "flex",
  },
  linearContainer: {
    mr: 1,
    width: "100%",
  },

  textContainer: {
    minWidth: 35,
  },
};
