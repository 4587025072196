import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import TextComponent from "components/atoms/TextComponent";
import MultipleButton from "components/molecules/MultipleButton";

import { translate } from "services/remoteConfig";

import { selectHeightUnity, selectWeightUnity } from "slices/unitySlice";
import { setHeightUnity, setWeightUnity } from "slices/unitySlice";

import { colors } from "themes";
import SvgSelector from "themes/SvgSelector";

function SettingsButtonHeightAndWeight({ element }) {

    const heightUnity = useSelector(selectHeightUnity);
    const weightUnity = useSelector(selectWeightUnity);
    const dispatch = useDispatch();

    const DOUBLE_BUTTON_CONFIG = element?.name === 'HeightUnit' ? [
        { title: 'cm' },
        { title: 'ft' }
    ] : [
        { title: 'kg' },
        { title: 'lbs' }
    ];
    let initialState;
    if (element?.name === 'HeightUnit') {
        initialState = heightUnity;
    } else {
        initialState = weightUnity;
    }
    const [navigation, setNavigation] = useState(initialState);

    useEffect(() => {
        if (element?.name === 'HeightUnit') {
            dispatch(setHeightUnity(navigation));
        } else {
            dispatch(setWeightUnity(navigation));
        }
    }, [dispatch, element?.name, navigation])


    return (
        <div style={styles.container}
        >
            <div style={styles.icon}>
                <SvgSelector name={element?.icon} color={colors.WHITE} width={'2vh'} height={'2vh'} />
            </div>
            <TextComponent
                text={translate(element?.name)}
                sx={styles.title}
            />
            <div style={styles.button}>
                <MultipleButton config={DOUBLE_BUTTON_CONFIG} navigation={navigation} setNavigation={setNavigation} />
            </div>
        </div>
    )

}

const styles = {
    button: {
        width: "10vw",
        display: "flex",
        position: 'absolute',
        right: '0.5vw',
        height: '3.5vh',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.BLACK,
        borderRadius: 5,
        position: 'relative',
        marginBottom: '1vh',
        height: '5vh',
    },
    icon: {
        marginLeft: '1vh',
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        marginLeft: '0.5vw',
        fontSize: '1.5vw'
    },
}

export default SettingsButtonHeightAndWeight;