import React from "react";

import { ReactComponent as ShareIcon } from 'assets/svg/shareIcon.svg';
import { ReactComponent as PdfIcon } from 'assets/svg/pdf.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';
import ShareModalContent from "./ShareModalContent";
import PDFSelectionList from "screen/analysis/AnalysisViewDetailsScreen/components/PDFSelectionList";
import DeleteAnalysisModalContent from "./DeleteAnalysisModalContent";
import SuccessCard from "./SuccessCard";
import OptionButton from "./OptionButton";

import { checkUserPermission } from "utils/utils";

import { getAnalysisDataWithPanel } from "services/analysis";

import { ANALYSIS, ATHLETE, RUNNING } from "constant/constants";

import './styles/AnalysisOptions.css';
import { translate } from "services/remoteConfig";

function AnalysisOptionsContent({ analysis, modalRef, setOpen, modalContent, setModalContent }) {

    const analysis_type = analysis?.analysis_type;
    const jsonData = JSON.parse(localStorage?.getItem(analysis?.data?.uploadVideoId));
    const data = analysis_type === RUNNING && getAnalysisDataWithPanel(jsonData, ANALYSIS);

    const buttonOptions = [
        {
            icon: ShareIcon,
            text: translate('ShareAnalysisByMail'),
            component: <ShareModalContent analysis={analysis} shareRef={modalRef} />,
            type: 'basic',
            permission: analysis_type === RUNNING && analysis?.data?.locked !== true,
        },
        {
            icon: PdfIcon,
            text: translate('ExportToPDF'),
            component: (
                <PDFSelectionList
                    data={data}
                    jsonData={jsonData}
                    setOpen={setOpen}
                />
            ),
            type: 'basic',
            permission: analysis_type === RUNNING && !checkUserPermission([ATHLETE]),
        },
        {
            icon: TrashIcon,
            text: translate('DeleteAnalysis'),
            component: <DeleteAnalysisModalContent analysis={analysis} modalRef={modalRef} />,
            type: 'delete',
            permission: true,
        },
    ];


    return (
        <div>
            {modalContent === null ? (
                <div style={styles.containerStyle}>
                    {analysis_type && (
                        <div style={styles.succesCard}>
                            <SuccessCard analysis={analysis} disabled />
                        </div>)}
                    <div style={styles.buttonList}>
                        <div className="button-container">
                            {buttonOptions?.map(
                                ({ icon: Icon, text, component, type, permission }, index) =>
                                    permission && (
                                        <OptionButton
                                            key={index}
                                            Icon={Icon}
                                            text={text}
                                            type={type}
                                            onClick={() => setModalContent(component)}
                                        />
                                    )
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <>{modalContent}</>
            )}
        </div>
    );
}

const styles = {

    buttonList: { display: 'flex', flexDirection: 'column', gap: '10px' },
    containerStyle: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    succesCard: {
        height: '100%',
        width: '20vw',
    },
};

export default AnalysisOptionsContent;
