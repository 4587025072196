import {firestore} from 'utils/firebaseInit';
import {collection, doc, onSnapshot} from 'firebase/firestore';
import {useEffect, useState} from 'react';

const ANALYSIS_COLLECTION = 'analysis';

export function getAnalysisChanges(id, callback) {
  try {
    const unsub = onSnapshot(
      doc(collection(firestore, ANALYSIS_COLLECTION), id),
      doc => {
        if (!doc?.data()) return () => unsub();
        callback(doc?.data());
      },
    );
    return () => unsub();
  } catch (error) {
    console.log(error);
  }
}

//create a hook that will listen to the changes in the analysis collection according to the ID
export function useAnalysisListener(id) {
  const [analysis, setAnalysis] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (id === null) return;

    setLoading(true);

    const unsubscribe = onSnapshot(
      doc(collection(firestore, ANALYSIS_COLLECTION), id),
      docSnapshot => {
        if (docSnapshot?.exists()) {
          setAnalysis(docSnapshot?.data());
        } else {
          setError('Document does not exist');
        }
        setLoading(false);
      },
      error => {
        console.log('error', error);
        setError(error);
        setLoading(false);
      },
    );

    // Cleanup function to unsubscribe from the snapshot listener when the component unmounts
    return () => unsubscribe();
  }, [id]);

  return {analysis, loading, error};
}
