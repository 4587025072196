import {
  DISTANCE_METRIC_KM,
  DISTANCE_METRIC_MIL,
  PACE_METRIC_KM_H,
  PACE_METRIC_MIL_H,
  PACE_METRIC_MIN_KM,
  PACE_METRIC_MIN_MIL,
} from 'constant/constants';
import {store} from 'store';

const userProfile = store?.getState()?.user;

export function feetInchToCm(inputFt, inputIn) {
  return inputFt / 3.2808 + inputIn * 0.0254;
}
export function lbsToKg(input) {
  return input * 0.45359237;
}

export function createDate(month, day, year) {
  const date = new Date(year, month - 1, day).toISOString();
  return date;
}

export function kgToLbs(input) {
  return input / 0.45359237;
}

export function mToftIn(input) {
  const ft = Math.floor(input * 3.2808);
  const inch = Math.round((input * 3.2808 - ft) * 12);

  return {ft, inch};
}

export function convertToMinKm(value, unity) {
  if (unity === PACE_METRIC_MIN_KM) return value;
  if (unity === PACE_METRIC_MIN_MIL) {
    return minPerMilesToMinPerKm(value);
  }
  if (unity === PACE_METRIC_KM_H) {
    return kmPerhourToMinPerKm(value);
  }
  if (unity === PACE_METRIC_MIL_H) {
    return milesPerHourToMinPerKm(value);
  }
}

export function milesToKm(input) {
  return Math.round(input * 1.60934);
}

export function convertPaceToUserMetric(pace) {
  if (userProfile?.settings?.selectedPaceMetric === PACE_METRIC_MIN_KM) {
    return pace;
  }
  if (userProfile?.settings?.selectedPaceMetric === PACE_METRIC_MIN_MIL) {
    return minPerKmToMinPerMiles(pace);
  }
  if (userProfile?.settings?.selectedPaceMetric === PACE_METRIC_KM_H) {
    return minPerKmToKmh(pace);
  }
  if (userProfile?.settings?.selectedPaceMetric === PACE_METRIC_MIL_H) {
    return minPerKmToMilesPerHour(pace);
  }
  return pace;
}

export function kmToMiles(input) {
  return Math.round(input / 1.60934);
}
export function convertDistanceToUserMetric(distance) {
  if (userProfile?.settings?.selectedDistanceMetric === DISTANCE_METRIC_KM) {
    return distance;
  }
  if (userProfile?.settings?.selectedDistanceMetric === DISTANCE_METRIC_MIL) {
    return kmToMiles(distance);
  }
  return distance;
}

export function minPerMilesToMinPerKm(pace) {
  let parts = pace.split(':');
  let minutes = parseInt(parts[0], 10);
  let seconds = parseInt(parts[1], 10);

  let totalMinutes = minutes + seconds / 60;

  let conversionFactor = 1.60934;

  let totalMinutesPerKm = totalMinutes / conversionFactor;

  let minPerKm = Math.floor(totalMinutesPerKm);
  let secPerKm = Math.round((totalMinutesPerKm - minPerKm) * 60);

  if (secPerKm < 10) {
    secPerKm = '0' + secPerKm;
  }

  return minPerKm + ':' + secPerKm;
}

export function minPerKmToMinPerMiles(pace) {
  let parts = pace.split(':');
  let minutes = parseInt(parts[0], 10);
  let seconds = parseInt(parts[1], 10);

  let totalMinutes = minutes + seconds / 60;

  let conversionFactor = 0.621371;

  let totalMinutesPerMile = totalMinutes / conversionFactor;

  let minPerMile = Math.floor(totalMinutesPerMile);
  let secPerMile = Math.round((totalMinutesPerMile - minPerMile) * 60);

  if (secPerMile < 10) {
    secPerMile = '0' + secPerMile;
  }

  return minPerMile + ':' + secPerMile;
}

export function kmPerhourToMinPerKm(speedKmh) {
  let minPerKm = 60 / speedKmh;

  let minutes = Math.floor(minPerKm);
  let seconds = Math.round((minPerKm - minutes) * 60);

  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return minutes + ':' + seconds;
}

export function minPerKmToKmh(pace) {
  let parts = pace.split(':');
  let minutes = parseInt(parts[0], 10);
  let seconds = parseInt(parts[1], 10);

  let totalMinutes = minutes + seconds / 60;

  let speedKmh = 60 / totalMinutes;

  return speedKmh.toFixed(1);
}

export function milesPerHourToMinPerKm(speedMph) {
  let speedKmh = speedMph * 1.60934;

  let minPerKm = 60 / speedKmh;

  let minutes = Math.floor(minPerKm);
  let seconds = Math.round((minPerKm - minutes) * 60);

  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return minutes + ':' + seconds;
}

export function minPerKmToMilesPerHour(pace) {
  let parts = pace.split(':');
  let minutes = parseInt(parts[0], 10);
  let seconds = parseInt(parts[1], 10);

  let totalMinutes = minutes + seconds / 60;

  let speedKmh = 60 / totalMinutes;

  let speedMph = speedKmh / 1.60934;

  return speedMph.toFixed(2);
}
