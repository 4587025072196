import TextComponent from "components/atoms/TextComponent";
import React from "react";
import { colors } from "themes";
import "./styles/MultipleButton.css";
import { translate } from "services/remoteConfig";

function MultipleButton({ config, navigation, setNavigation }) {


  return (
    <div style={styles?.container}>
      {config?.map((item, index) => {
        return (
          <div
            className="multiple-button-container"
            style={{
              borderBottom:
                navigation === item?.title ? "1px solid #FF681D" : null,
              backgroundColor:
                navigation === item?.title ? colors?.SELECTED : null,
            }}
            onClick={() => {
              setNavigation(item?.title);
            }}
            key={index}
          >
            <TextComponent
              className="multiple-button-text"
              text={translate(item?.title) ? translate(item?.title) : item?.title}
              sx={{
                color:
                  navigation === item?.title ? colors.WHITE : colors.TEXT_GRAY,
              }}
            />
            {item?.subtitle ? (
              <div style={styles?.subtitleContainer}>
                <TextComponent text={item?.subtitle} sx={styles.subtitle} />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: colors?.CARD_BACKGROUND,
    borderRadius: 10,
    display: "flex",
    overflow: "hidden",
    width: "100%",
  },
  subtitle: {
    color: colors.PRIMARY,
    fontWeight: "bold",
    marginLeft: 0,
    textAlign: "center",
  },
  subtitleContainer: {
    alignItems: "center",
    backgroundColor: "#F5D000",
    borderRadius: 200,
    display: "flex",
    height: "1.6rem",
    justifyContent: "center",
    marginLeft: 10,
    width: "1.5rem",
  },
};

export default MultipleButton;
