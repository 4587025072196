import Lottie from 'lottie-react';
import React from 'react';
import TextComponent from './TextComponent';
import {translate} from 'services/remoteConfig';
import {BOLD, H4, H6, MEDIUM} from 'constant/constants';
import {createTextObjectReusable} from 'utils/utils';
import {colors} from 'themes';

function WelcomeModal() {
  return (
    <>
      <Lottie
        animationData={require('assets/animations/fireworks.json')}
        loop={true}
        style={styles.lottie}
      />
      <div style={styles.textContainer}>
        <TextComponent
          text={translate('Welcome')}
          sx={{marginBottom: 4}}
          weight={BOLD}
          variant={H4}
        />
        <TextComponent
          multipleText={createTextObjectReusable(
            translate('CoachWelcomeModal'),
            {
              weight: MEDIUM,
              style: {
                color: colors.WHITE,
                textAlign: 'center',
              },
              variant: H6,
            },
            {
              variant: H6,
              style: {color: colors.SECONDARY, textAlign: 'center'},
            },
            {},
          )}
        />
      </div>
    </>
  );
}

const styles = {
  lottie: {
    margin: 'auto',
    width: '50%',
  },
  textContainer: {
    position: 'absolute',
    textAlign: 'center',
    width: '90%',
  },
};

export default WelcomeModal;
