import {store} from 'store';
import {USER_LOCALE} from './remoteConfig';

export function intercomInit() {
  const user = store?.getState()?.user;
  if (!user) return;

  const {userID, emailAddress, fullName} = user;
  const APP_ID = process.env.REACT_APP_INTERCOM;
  window.Intercom('boot', {
    app_id: APP_ID,
    email: emailAddress,
    name: fullName,
    user_id: userID,
  });
}

export function intercomLogEvent(event, attributes) {
  try {
    window.Intercom('logEvent', {
      event: event,
      attributes: attributes,
    });
  } catch (error) {
    console.log(error);
  }
}

export function intercomUpdate(userProfile, attributes) {
  try {
    window.Intercom('update', {
      email: userProfile.emailAddress,
      userId: userProfile.userID,
      name: userProfile.fullName,
      languageOverride: USER_LOCALE,
      customAttributes: attributes,
    });
  } catch (error) {
    console.log('Error intercom Update = ', error);
  }
}
