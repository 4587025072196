import React from "react";

import GradientScoreSystem from "./GradientScoreSystem";
import RoundedScoreMeter from "./RoundedScoreMeter";
import TextComponent from "components/atoms/TextComponent";
import { BOLD, H5 } from "constant/constants";
import { colors } from "themes";
import { translate } from "services/remoteConfig";
import { getAverageScore } from "utils/utils";

function RunningAnalysisBottomCard({ analysisData, locked }) {
  const entriesArray =
    !analysisData?.analyse?.content ||
      Object.keys(analysisData?.analyse?.content).length < 1
      ? []
      : Object?.entries(analysisData?.analyse?.content);

  if (locked) {
    return (
      <div style={styles.locked}
      >
        <TextComponent
          variant={H5}
          sx={{ color: colors.YELLOW }}
          text={translate('FreeAnalysis')}
        />
      </div>
    );
  }

  return (
    <>
      <GradientScoreSystem scoreNumber={getAverageScore(entriesArray)} />
      <div style={styles.container}>
        {entriesArray?.map(([key, value]) => {
          if (key === "foot") return null;
          return (
            <RoundedScoreMeter
              key={key}
              scoreNumber={value?.score_number}
              score_text={value?.texts?.score}
              item={key}
              size={'3.2vw'}
            />
          );
        })}
      </div>
    </>
  );
}

const styles = {
  container: {
    display: "flex",
    right: 0,
    float: "right",
    margin: "0px 5px 0px auto",
  },
  locked: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '100%'
  }
};

export default RunningAnalysisBottomCard;
