import PickerComponent from "components/atoms/PickerComponent";
import TextComponent from "components/atoms/TextComponent";
import { CHINESE, ENGLISH, FRENCH, GERMAN, ITALIAN, PORTUGUESE, SPANISH } from "constant/constants";
import React, { useRef } from "react";
import { translate, useRemoteConfigInit } from "services/remoteConfig";
import { colors } from "themes";
import SvgSelector from "themes/SvgSelector";
import { useDispatch } from "react-redux";
import { setSettings } from "slices/userSlice";
import { updateUserInDB } from "services/authApi";
import { getLanguageCode } from "utils/utils";

function SettingsButtonLanguage({ element }) {

    const languageRef = useRef();
    const dispatch = useDispatch();

    useRemoteConfigInit();

    const handleValueChanges = (newValue) => {
        const countryCode = getLanguageCode(newValue);
        dispatch(setSettings({ selectedLanguage: countryCode }));
        updateUserInDB({ settings: { selectedLanguage: countryCode } });
    };


    return (
        <div style={styles.container}>
            <div style={styles.icon}>
                <SvgSelector name={element?.icon} color={colors.WHITE} width={'2vh'} height={'2vh'} />
            </div>
            <TextComponent
                text={translate(element?.name)}
                sx={styles.title}
            />
            <div style={styles.picker}>
                <PickerComponent
                    config={[ENGLISH.value, FRENCH.value, GERMAN.value, CHINESE.value, ITALIAN.value, SPANISH.value, PORTUGUESE.value]}
                    height={'4vh'}
                    handleValueChanges={handleValueChanges}
                    ref={languageRef}
                    type={'Language'} />
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.BLACK,
        borderRadius: 5,
        position: 'relative',
        cursor: 'pointer',
        marginBottom: '1vh',
        height: '5vh',
    },
    icon: {
        marginLeft: '1vh',
        alignItems: 'center',
        display: 'flex'
    },
    picker: {
        display: "flex",
        position: 'absolute',
        right: '3vw',
    },
    title: {
        marginLeft: '0.5vw',
        fontSize: '1.5vw'
    },
}


export default SettingsButtonLanguage;