import React from "react";

import SwitchComponent from "components/atoms/SwitchComponent";
import TextComponent from "components/atoms/TextComponent";

import { translate } from "services/remoteConfig";

import { BODY_PARTS, BOLD } from "constant/constants";

function JointAnglesSelector({ data, toggleSelected }) {
    return (
        <div style={styles.container}>
            <div style={styles.rowTitle}>
                <TextComponent
                    text={translate('JointAngles')}
                    sx={styles.midWidth}
                />
                <TextComponent
                    text={translate('Left')}
                    sx={styles.quarterWidth}
                />
                <TextComponent
                    text={translate('Right')}
                    sx={styles.quarterWidth}
                />
            </div>

            <div style={styles.switchView}>
                {data?.map((item, index) => {
                    if (index % 2 === 1) return null;
                    return (
                        <div
                            key={index}
                            style={styles.bodyPart}
                        >
                            <TextComponent
                                text={translate(BODY_PARTS[index / 2])}
                                weight={BOLD}
                                sx={styles.midWidth}
                            />

                            <div style={styles.switchContainer}>
                                <SwitchComponent onChange={() => toggleSelected(index, item)} checked={item?.selected && !item?.disabled} color={item?.color} />
                            </div>

                            <div style={styles.switchContainer}>
                                <SwitchComponent onChange={() => toggleSelected(index + 1, data[index + 1])} color={data[index + 1]?.color} checked={data[index + 1]?.selected && !data[index + 1]?.disabled} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

const styles = {
    bodyPart: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'space-between',
        marginBottom: 15,
        width: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    },
    midWidth: {
        fontSize: '1vw',
        textAlign: 'center',
        width: '50%'
    },
    quarterWidth: {
        fontSize: '1vw',
        textAlign: 'center',
        width: '25%'
    },
    rowTitle: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '3vh',
        width: '100%'
    },
    switchContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '25%',
    },
    switchView: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
}


export default JointAnglesSelector;
