import React from "react";

import TextComponent from "components/atoms/TextComponent";

import { translate } from "services/remoteConfig";

import { ReactComponent as PronationIcon } from "assets/svg/pronation.svg";
import { ReactComponent as SupinationIcon } from "assets/svg/supination.svg";
import { ReactComponent as NeutralIcon } from "assets/svg/neutral.svg";

// Assuming 'colors' are defined in your CSS or a JS module for styles
import { colors } from "themes";

export default function PronationMetricsPanel({
  posture,
  meanAngle,
  angles_list,
  side,
}) {
  const icon = {
    Supination: <SupinationIcon width="80" height="100" />,
    Pronation: <PronationIcon width="80" height="100" />,
    Neutral: <NeutralIcon width="80" height="100" />,
  };

  return (
    <div style={styles.footContainer}>
      <div style={side === "left" ? { ...styles.flipIcon, ...styles.footIcon } : { ...styles.footIcon }}>
        {icon[posture]}
      </div>
      <TextComponent
        weight="bold"
        variant="h4"
        sx={{ alignSelf: 'center', textAlign: 'center' }}
        text={translate(posture)}
      />
      <div style={styles.meanAngleContainer}>
        <TextComponent
          sx={{ color: colors.WHITE_OPACITY_60 }}
          text={translate("MeanAngleDuringFootLanding")}
        />
        <TextComponent
          text={`${meanAngle?.toFixed(2)}°`}
          variant="h2"
          weight="light"
        />
      </div>

      {angles_list?.map((item, index) => (
        <div key={index} className="accordionItem">
          <TextComponent
            sx={{ color: colors.SECONDARY }}
            text={translate("FootLanding") + `#${index + 1}`}
          />
          {item?.map((angle, index) => (
            <TextComponent
              key={index}
              className="accordionText"
              text={`${angle?.toFixed(2)}°`}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

const styles = {
  aCenter: {
    //alignSelf: "center",
  },
  footContainer: {
    backgroundColor: colors.CARD_BACKGROUND /* Define colors in :root or directly */,
    flex: 1,
    marginBottom: 10,
    paddingTop: 15,
    margin: 10,
    borderRadius: 15,
  },
  footIcon: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
  flipIcon: {
    transform: "scaleX(-1)",
  },
  meanAngleContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    marginTop: 10,
    padding: 10,
  },
  meanAngleText: {
    color: colors.WHITE,
    margin: 10,
  },
  meanAngleText: {
    marginBottom: 10,
    marginLeft: 10,
  },
  middleBorder: {
    borderRightWidth: 1,
    borderStyle: "dashed",
    borderColor: colors.SECONDARY,
  },
};
