import {
  getArmText,
  getBackText,
  getHeadText,
  getKneeBackText,
  getKneeFrontText,
  getFootText,
} from 'utils/textFromAngles';
import {getAnalysis, translate} from './remoteConfig';
import {ANALYSIS, GRAPH, METRICS, STYLE} from 'constant/constants';

//changer tout on récupère le Json => renvoyer les data des analyses et puis point barre
// pas besoin du text from angles et tout on a le json
//vu qu'on a le json on a besoin de rien d'autre
// si on

export function getAnalysisTexts(
  frame,
  foot_landing,
  direction,
  trunkHeadAngles,
  json,
) {
  const json_version = json?.json_version;

  if (json_version > 3) {
    if (
      (json?.mean_angles_at_foot_strike?.trunk_mean_angle ||
        json?.mean_angles_at_foot_strike?.trunk_mean_angle === 0) &&
      (json?.mean_angles_at_foot_strike?.head_mean_angle ||
        json?.mean_angles_at_foot_strike?.head_mean_angle === 0) &&
      (json?.mean_angles_at_foot_strike?.arm_mean_angle ||
        json?.mean_angles_at_foot_strike?.arm_mean_angle === 0) &&
      (json?.mean_angles_at_foot_strike?.front_leg_mean_angle ||
        json?.mean_angles_at_foot_strike?.front_leg_mean_angle === 0) &&
      (json?.mean_angles_at_foot_strike?.back_leg_mean_angle ||
        json?.mean_angles_at_foot_strike?.back_leg_mean_angle === 0)
    ) {
      const texts = [
        getFootText(json),
        getHeadText(json),
        getBackText(json),
        getArmText(json),
        getKneeFrontText(json),
        getKneeBackText(json),
      ];

      return texts;
    }
  }

  const angles = frame?.angles;
  const headAngle = trunkHeadAngles?.head?.toFixed(2);
  const backAngle = trunkHeadAngles?.trunk?.toFixed(2);
  const kneeRightAngle = angles['RightKnee']?.toFixed(2);
  const kneeLeftAngle = angles['LeftKnee']?.toFixed(2);
  let legFront;
  let legBack;
  if (frame?.side === 'left') {
    legBack = kneeRightAngle;
    legFront = kneeLeftAngle;
  } else if (frame?.side === 'right') {
    legFront = kneeRightAngle;
    legBack = kneeLeftAngle;
  } else {
    legBack = kneeRightAngle;
    legFront = kneeLeftAngle;
  }

  let armAngle = 0;
  if (direction === 'right') armAngle = angles['RightElbow']?.toFixed(2);
  else armAngle = angles['LeftElbow']?.toFixed(2);

  if (
    !headAngle ||
    !backAngle ||
    !armAngle ||
    !kneeRightAngle ||
    !kneeLeftAngle
  ) {
    return null;
  }

  const texts = [
    getFootText(json, foot_landing, frame?.foot_under_center_of_mass),
    getHeadText(json, headAngle),
    getBackText(json, backAngle),
    getArmText(json, armAngle),
    getKneeFrontText(json, legFront),
    getKneeBackText(json, legBack),
  ];
  return texts;
}

export function prepareTexts(
  text,
  angle,
  color,
  image,
  type,
  idealImage,
  scoreNumber,
) {
  return {
    angle: angle,
    title: text?.title,
    subtitile1: 'Position',
    text1: text?.position,
    subtitile2: translate('Explanation'),
    text2: text?.explanation,
    subtitile3: translate('Exercice'),
    exercice: text?.exercice,
    advice: text?.advice,
    backgroundColor: color,
    image: image,
    score: text?.score,
    scoreNumber: scoreNumber,
    type: type,
    idealImage: idealImage,
  };
}

export function getAnalysisDataWithPanel(jsonData, panel) {
  if (!jsonData) return;
  const angles = {
    head: jsonData?.head,
    trunk: jsonData?.trunk,
  };
  let groupedFrames = jsonData?.foot_landing;
  if (panel === ANALYSIS) {
    return getAnalysisTexts(
      groupedFrames[Math?.round((groupedFrames?.length - 1) / 2)],
      groupedFrames[Math?.round((groupedFrames?.length - 1) / 2)]?.type,
      jsonData?.direction,
      angles,
      jsonData,
    );
  } else if (panel === STYLE) {
    if (jsonData?.json_version < 4) {
      return {
        running_styles:
          jsonData?.styles[0] > jsonData?.styles[1]
            ? jsonData?.styles[0]
            : jsonData?.styles[1],
      };
    }
    if (jsonData?.running_styles && jsonData?.running_styles?.length > 1)
      return {
        running_styles:
          jsonData?.running_styles[0]?.percentage >
          jsonData?.running_styles[1]?.percentage
            ? jsonData?.running_styles[0]
            : jsonData?.running_styles[1],
      };
    else if (jsonData?.running_styles?.length === 1)
      return {
        running_styles: jsonData?.running_styles[0],
      };
    else return null;
  } else if (panel === METRICS) {
    if (jsonData?.json_version < 4) {
      return {
        time_between_steps: jsonData?.time_between_steps,
        ground_contact_time: jsonData?.ground_contact_time?.toFixed(2),
        steps_per_min: jsonData?.steps_per_min,
        duty_factor: jsonData?.duty_factor * 100,
      };
    } else
      return {
        time_between_steps: jsonData?.metrics?.time_of_flight,
        ground_contact_time: jsonData?.metrics?.ground_contact_time?.toFixed(2),
        steps_per_min: jsonData?.metrics?.steps_per_min,
        duty_factor: jsonData?.metrics?.duty_factor,
        stride_length: jsonData?.metrics?.step_length * 2,
        step_length: jsonData?.metrics?.step_length,
      };
  } else if (panel === GRAPH) {
    return {
      poulaine: jsonData?.poulaine,
      vertical: jsonData?.center_of_mass,
      joint_angles: jsonData?.joint_angles,
      direction: jsonData?.direction,
    };
  }

  if (!groupedFrames && !angles) return;
}

export function getOverstrideData(jsonData) {
  const jsonVersion = jsonData?.json_version;

  if (jsonVersion < 3) return {};
  else if (
    jsonVersion > 3 &&
    jsonData?.mean_foot_landing?.overstride_score !== 'score_good'
  )
    return jsonData?.mean_foot_landing;
  else if (jsonVersion === 3 && jsonData?.overstride_score !== 'score_good')
    return {
      overstride_score: jsonData?.overstride_score,
      overstride_value: jsonData?.overstride_value,
    };
}
