import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import FadinView from 'components/atoms/FadinView';
import TextComponent from 'components/atoms/TextComponent';
import OnBoardingButtonList from '../components/OnBoardingButtonList';
import OnBoardingBottomArrow from '../components/OnBoardingBottomArrow';

import {onBoardingTranslate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {createTextObjectReusable} from 'utils/utils';

import {BOLD, H4} from 'constant/constants';
import {onBoardingAthleteButtonGender} from 'screen/auth/registration/onBoarding/constants/onBoardingButtonData';

import {colors} from 'themes';

function OnBoardingAthleteGender() {
  const navigate = useNavigate();

  useEffect(() => {
    mpTrack('Web App OnBoarding Gender Selection Screen Viewed');
  }, []);

  function handleBackArrowPressed() {
    navigate('/registration/athlete/typology-welcome', {
      state: {value: 1},
    });
  }

  return (
    <FadinView>
      <div style={styles.container}>
        <div style={styles.titleContainer}>
          <TextComponent
            multipleText={createTextObjectReusable(
              onBoardingTranslate('GenderHeadline'),
              {
                variant: H4,
                weight: BOLD,
              },
              {
                variant: H4,
                weight: BOLD,
                style: {textAlign: 'center', color: colors.SECONDARY},
              },
            )}
            center={true}
          />
        </div>
        <div style={styles.buttonListContainer}>
          <OnBoardingButtonList data={onBoardingAthleteButtonGender} />
        </div>
      </div>
      <OnBoardingBottomArrow previous={true} onPress={handleBackArrowPressed} />
    </FadinView>
  );
}

const styles = {
  buttonListContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '100%',
    paddingTop: '5%',
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5%',
    width: '100%',
  },
};

export default OnBoardingAthleteGender;
