import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import TextComponent from 'components/atoms/TextComponent';
import OnBoardingContainer from './components/OnBoardingContainer';
import OnBoardingBottomArrow from './components/OnBoardingBottomArrow';
import FadinView from 'components/atoms/FadinView';

import {onBoardingTranslate} from 'services/remoteConfig';

import {useUsageChanges} from 'hooks/useUsageChanges';
import {useProfileChanges} from 'hooks/useProfileChanges';

import {createTextObjectReusable} from 'utils/utils';

import {ATHLETE, BOLD, H1, H3, REGISTRATION_DATA} from 'constant/constants';

import {colors} from 'themes';
import {mpIdentify, mpTrack} from 'services/mixpanel';
import {useDispatch, useSelector} from 'react-redux';
import {selectUser, setUserType} from 'slices/userSlice';

function OnBoardingWelcomeScreen() {
  const navigate = useNavigate();
  useUsageChanges();
  useProfileChanges();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    mpTrack('Web App OnBoarding Welcome To Ochy Screen Viewed');
    localStorage.removeItem(REGISTRATION_DATA);
    if (user?.userType === 'Unknown' || !user?.userType) {
      dispatch(setUserType(ATHLETE));
    }
  }, []);

  function handleNavigate() {
    navigate('/registration/select-user-type');
  }

  return (
    <OnBoardingContainer>
      <FadinView>
        <TextComponent
          text={onBoardingTranslate('WelcomeToOchy')}
          variant={H1}
          weight={BOLD}
          sx={styles.title}
        />
        <div style={{width: '80%', margin: 'auto'}}>
          <TextComponent
            multipleText={createTextObjectReusable(
              onBoardingTranslate('OchyPresentation'),
              {
                variant: H3,
                weight: BOLD,
              },
              {
                variant: H3,
                weight: BOLD,
                style: {textAlign: 'center', color: colors.SECONDARY},
              },
            )}
            variant={H3}
            weight={BOLD}
            center={true}
          />
        </div>
        <OnBoardingBottomArrow onPress={handleNavigate} />
      </FadinView>
    </OnBoardingContainer>
  );
}

const styles = {
  title: {
    textAlign: 'center',
  },
};

export default OnBoardingWelcomeScreen;
