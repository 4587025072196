import { useEffect, useState } from 'react';

import { collection, doc, onSnapshot } from 'firebase/firestore';
import { useDispatch } from 'react-redux';

import { resetProfileStore, updateProfileStore } from 'slices/userSlice';

import { firestore } from 'utils/firebaseInit';
import { getAuth } from 'firebase/auth';
import { store } from 'store';
const USER_COLLECTION = 'users';

export function useProfileChanges() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  //const auth = getAuth();
  useEffect(() => {
    setLoading(true);
    const user = store?.getState()?.user;
    if (user?.userID === 'Unknown') return;
    try {
      onSnapshot(
        doc(collection(firestore, USER_COLLECTION), user?.userID),
        (doc) => {
          const data = doc.data();
          if (!data) {
            dispatch(resetProfileStore());
            setLoading(false);
            return;
          }
          data.userType = data?.type;
          dispatch(updateProfileStore(data));
          setLoading(false);
        }
      );
    } catch (error) {
      setError(error);
    }
  }, []);

  return { loading, error };
}
