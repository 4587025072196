import React, { useEffect, useState } from "react";

import { Fade, Modal } from "@mui/material";

import TextComponent from "components/atoms/TextComponent";
import { ReactComponent as Close } from 'assets/svg/close.svg';
import JointAnglesChart from "./JointAnglesChart";
import JointAnglesSelector from "./JointAnglesSelector";

import { createJointAnglesData } from "utils/analysis";

import { translate } from "services/remoteConfig";

import { BOLD, NONE } from "constant/constants";

import { colors } from "themes";

function JointAngles({ jointAngles, direction, setPDFData, chartRef = null }) {
    const [data, setData] = useState(createJointAnglesData(jointAngles, direction));
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    function toggleSelected(index, item) {
        if (item?.disabled) {
            setOpen(true);
        } else {
            setData(prevData =>
                prevData?.map((item, i) =>
                    i === index ? { ...item, selected: !item?.selected } : item,
                ),
            );
        }
        if (setPDFData)
            setPDFData(data);
    }

    useEffect(() => {
        if (chartRef && setPDFData) {
            setPDFData(data);
        }
    }, [data, setPDFData, chartRef]);

    if (!jointAngles || !jointAngles?.side) return (
        <div >
            <TextComponent
                text={translate('JointAngles')}
                sx={styles.title}
            />
            <TextComponent
                text={translate('NoJointAngles')}
                sx={styles.errorMessage}
            />

        </div>
    )

    return (
        <>
            {!chartRef && <TextComponent text={translate('JointAngles')} sx={styles.title} />}
            {jointAngles?.side === NONE ? (
                <TextComponent
                    text={translate('ErrorMetrics')}
                    sx={styles.errorMessage}
                    weight={BOLD}
                />
            ) : (
                <div style={styles.container}>
                    <div style={{ width: '70%' }} ref={chartRef}>
                        <JointAnglesChart data={data} />
                    </div>
                    <div style={{ width: '30%' }}>
                        <JointAnglesSelector data={data} toggleSelected={toggleSelected} />
                    </div>
                </div>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: { backgroundColor: 'transparent' },
                    },
                }}
                sx={styles.modal}
            >
                <Fade in={open}>
                    <div style={styles.modalContainer}>
                        <div
                            style={styles.closeCross}
                            onClick={event => {
                                event?.preventDefault();
                                event?.stopPropagation();
                                handleClose();
                            }}
                        >
                            <Close stroke="white" width={50} height={50} />
                        </div>
                        <TextComponent
                            text={translate('InsufficientDataJointAngles')}
                            weight={BOLD}
                            sx={styles.modalTitle}
                        />
                        <TextComponent
                            text={translate('NotEnoughRunningCycle')}
                            sx={styles.modalContent}
                        />
                    </div>
                </Fade>
            </Modal>
        </>
    );
}

const styles = {
    closeCross: {
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 2,
    },
    container: {
        display: 'flex',
        height: '50vh',
        width: '100%',
    },
    errorMessage: {
        color: colors.RED,
        fontSize: '1.5vw',
        marginBottom: '30vh',
        textAlign: 'center',
        width: '100%',
    },
    modal: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'center',
    },
    modalContainer: {
        alignItems: 'center',
        backgroundColor: colors.BLACK,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20vh',
        position: 'relative',
        width: '30vw',
    },
    modalContent: {
        fontSize: '1vw',
        marginBottom: '2vh',
        width: '80%',
    },
    modalTitle: {
        color: colors.RED,
        fontSize: '1.3vw',
        marginBottom: '2vh',
        marginTop: '2vh',
        textAlign: 'center',
        width: '80%',
    },
    title: {
        fontSize: '1.5vw',
        marginBottom: '2vh',
        textAlign: 'center',
        width: '100%',
    },
}


export default JointAngles;
