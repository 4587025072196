export function settingsConfig() {
  const config = [
    {
      Category: 'Options',
      Button: [
        {
          name: 'Language',
          icon: 'Language',
          picker: true
        },
        {
          name: 'HeightUnit',
          icon: 'Height',
          switch: true
        },
        {
          name: 'WeightUnit',
          icon: 'Weight',
          switch: true
        }
      ],
      permission: ['athlete', 'coach', 'professional']
    },
    {
      Category: 'AboutUs',
      Button: [
        {
          name: 'TermsConditions',
          icon: 'T&C'
        },
        {
          name: 'PrivacyTitle',
          icon: 'Pp'
        }
      ],
      permission: ['athlete', 'coach', 'professional']
    },
    {
      Category: '',
      Button: [
        {
          name: 'DeleteAccount',
          icon: 'LogOut'
        }
      ],
      permission: ['athlete', 'coach', 'professional']
    }
  ];
  return config;
}
