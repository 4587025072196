import mixpanel from 'mixpanel-browser';

export function setUserAttributeOnAllPlatforms(key, value) {
  if (!key || !value) return;

  try {
    mixpanel.people.set(key, value);
    window.Intercom('update', {
      [key]: value,
    });
  } catch (error) {
    console.log(error);
  }
}
