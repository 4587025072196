import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import OnBoardingContainer from '../components/OnBoardingContainer';
import FadinView from 'components/atoms/FadinView';
import TextComponent from 'components/atoms/TextComponent';
import OnBoardingBottomArrow from '../components/OnBoardingBottomArrow';

import {onBoardingTranslate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {createTextObjectReusable} from 'utils/utils';

import {BOLD, COACH, H3, H4, REGISTRATION_DATA} from 'constant/constants';

import {colors} from 'themes';

function OnBoardingCoachAnalysisIntro() {
  const navigate = useNavigate();

  const userType = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.userType;

  function handleArrowPressed() {
    navigate('/registration/coach/analysis');
  }
  function handleBackArrowPressed() {
    navigate('/registration/coach/place-of-work');
  }

  useEffect(() => {
    userType === COACH
      ? mpTrack('Web AppOnBoarding Coach Analysis Intro Screen Viewed')
      : mpTrack('Web AppOnBoarding Profesional Analysis Intro Screen Viewed');
  }, []);

  return (
    <OnBoardingContainer>
      <FadinView>
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('CoachAnalysisIntro'),
            {
              variant: H4,
            },
            {
              variant: H4,
              style: {textAlign: 'center', color: colors.SECONDARY},
            },
          )}
          variant={H3}
          weight={BOLD}
          center={true}
        />
        <OnBoardingBottomArrow onPress={handleArrowPressed} />
        <OnBoardingBottomArrow
          previous={true}
          onPress={handleBackArrowPressed}
        />
      </FadinView>
    </OnBoardingContainer>
  );
}

export default OnBoardingCoachAnalysisIntro;
