import React, { useEffect, useState } from "react";

import Lottie from "lottie-react";

import loading_runner from "assets/animations/loading_runner.json";

import { getVideoAnalysisStatus } from "services/analysisApi";

import ProgressBar from "./ProgressBar";

import "./styles/LoadingCard.css";
import LoadingComponent from "components/atoms/LoadingComponent";
import AnalysisOptionsButton from "./AnalysisOptionsButton";

function LoadingCard({ id, analysis }) {

  const ANALYSIS_STAGE_UNKNOWN = "unknown";
  const ANALYSIS_STAGE_ENCODING = "encoding";
  const ANALYSIS_STAGE_UPLOADED = "uploaded";
  const ANALYSIS_STAGE_COMPLETED = "completed";
  const ANALYSIS_STAGE_ANALYZING = "analyzing";
  const ANALYSIS_STAGE_ERROR = "error";

  const [progress, setProgress] = useState(undefined);
  const [analysisStage, setAnalysisStage] = useState(ANALYSIS_STAGE_UNKNOWN);

  useEffect(() => {
    if (
      analysisStage === ANALYSIS_STAGE_COMPLETED ||
      analysisStage === ANALYSIS_STAGE_ERROR
    ) {
      return;
    }
    const progressCheckTimer = setInterval(() => {
      refreshItemStatus();
    }, 1000);

    return () => clearInterval(progressCheckTimer);
  }, []);

  const refreshItemStatus = async () => {
    /*     const response = await getVideoAnalysisStatus(id);
        if (response?.percentage) {
          const percentage = parseFloat(response?.percentage) / 100;
          if (!isNaN(percentage && percentage >= 0 && percentage <= 1))
            setProgress(percentage);
        }
        if (
          response?.percentage === 100.0 &&
          analysisStage === ANALYSIS_STAGE_UNKNOWN
        )
          setAnalysisStage(ANALYSIS_STAGE_UPLOADED);
        if (analysisStage !== ANALYSIS_STAGE_COMPLETED) {
          setAnalysisStage(response?.status);
        } */
  };
  if (
    progress &&
    progress < 100 &&
    (analysisStage === ANALYSIS_STAGE_ENCODING ||
      analysisStage === ANALYSIS_STAGE_ANALYZING)
  ) {
    return (
      <div className="loading-Card">
        <Lottie
          animationData={loading_runner}
          loop={true}
          style={styles.lottie}
        />
        <div style={{ width: "90%" }}>
          <ProgressBar progress={progress} />
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="loading-Card">
        <LoadingComponent />
        <AnalysisOptionsButton analysis={analysis} />

      </div>
    );
  }
}

const styles = {
  lottie: {
    margin: "auto",
    width: "50%",
  },
};
export default LoadingCard;
