import React, { useEffect, useState } from "react";

import TextComponent from "components/atoms/TextComponent";
import JointAngles from "./JointAngles";

import { translate } from "services/remoteConfig";


function PDFJointAnglesSelection({ jointAngles, direction, setJointAnglesData, chartRef }) {
    const [showContent, setShowContent] = useState(false);
    const [textPosition, setTextPosition] = useState('30vh');
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        setTextPosition('0');
        const timer = setTimeout(() => {
            setOpacity(1);
            setShowContent(true);
        }, 800);
        return () => clearTimeout(timer);
    }, []);



    return (
        <div style={styles.container}>
            <div style={{ ...styles.textContainer, transform: `translateY(${textPosition})` }}>
                <TextComponent
                    text={translate('SelectJointAnglesToExport')}
                    sx={styles.title}
                />
            </div>
            <div style={{ ...styles.content, opacity: opacity }}>
                {showContent && (
                    <JointAngles jointAngles={jointAngles} direction={direction} setPDFData={setJointAnglesData} chartRef={chartRef} />
                )}
            </div>
        </div >
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60vh',
        width: '100%',
    },
    textContainer: {
        transition: 'transform 1s ease-out',
        zIndex: 1,
        textAlign: 'center',
        marginBottom: '20px',
        marginTop: '2vh',
    },
    tittle: { fontSize: '2vw', marginBottom: '2vh', marginTop: '2vh' },
    content: {
        opacity: 0,
        transition: 'opacity 1s ease-in-out',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        marginBottom: '2vh',

    },
    chartWrapper: {
        width: '65%',
        height: '100%',
    },
};

export default PDFJointAnglesSelection