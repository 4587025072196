import TextComponent from 'components/atoms/TextComponent';
import { BODY_2, H3,H6,MEDIUM } from 'constant/constants';
import React from 'react'
import { score_colors } from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import { translate } from 'services/remoteConfig';
import ScoreTextWithBackground from '../../CardComponent/components/ScoreTextWithBackground';

function OverstrideContent({overstrideData}) {

  if ((!overstrideData?.overstride_score && !overstrideData?.overstride_value) ||  overstrideData.overstride_score === 'score_good')
    return <></>;
  return (
    <div style={{
        alignSelf: 'center',
        marginTop: 20,
        display : 'flex',
        flexDirection : 'column',
        width : '85%',
      }}>
            <div style={{ display : 'flex', marginTop : 15, marginBottom : 20,}}>
      <TextComponent
        text={parseInt(overstrideData?.overstride_value) + '°'}
        variant={H3}
        sx={{
          color: score_colors[overstrideData?.overstride_score]?.stroke,
        }}
      />
      <div style={{marginLeft : 20,}}>
        <TextComponent
          text={'Overstride'}
          weight={MEDIUM}
          variant={H6}
        />            
        <div>
          <ScoreTextWithBackground scoreNumber={overstrideData?.overstride_score} />
        </div>
      </div>
    </div>
    <img
      src={
        overstrideData?.overstride_score === 'score_okay'
          ? require('assets/images/overstride_okay.png')
          : require('assets/images/overstride_bad.png')
      }
      style={{width : '50%', alignSelf : 'center', marginBottom : 30,}}
      resizeMode="contain"
      alt={'overstride'}
    />

    <TextComponent
      text={
        overstrideData?.overstride_score === 'score_okay'
          ? translate('OverstrideOkay')
          : translate('OverstrideBad')
      }
      variant={BODY_2}
      style={{}}
    />
  </div>
  )
}
export default OverstrideContent