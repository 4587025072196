import React, {useState} from 'react';

import {Box, Container, Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import OnBoardingButton from './OnBoardingButton';
import ButtonComponent from 'components/atoms/ButtonComponent';
import TextInput from 'components/atoms/TextInput';

import {onBoardingTranslate, translate} from 'services/remoteConfig';

import {ORANGE, PROFESSIONAL, REGISTRATION_DATA} from 'constant/constants';
import {mpTrack} from 'services/mixpanel';
import {setUserAttributeOnAllPlatforms} from 'services/tracking';

function OnBoardingButtonList({data, mutlipleSelection = false}) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [other, setOther] = useState('');

  console.log(data);

  function handlePressed(item) {
    if (mutlipleSelection) {
      setSelected(prev => {
        if (!prev) return;
        const newArr = [...prev];
        if (newArr.includes(item?.text)) {
          const index = newArr.findIndex(obj => obj === item?.text);
          newArr.splice(index, 1);
        } else if (!newArr.includes(item?.text)) {
          newArr.push(item?.text);
        }
        return newArr;
      });
    } else {
      mpTrack(item?.tracking);
      if (item?.attributes) {
        setUserAttributeOnAllPlatforms(
          item?.attributes.key,
          item?.attributes.value,
        );
      }
      navigate(item?.path);

      const onBoardingUserData = JSON.parse(
        localStorage.getItem(REGISTRATION_DATA),
      );
      if (onBoardingUserData) {
        localStorage.setItem(
          REGISTRATION_DATA,
          JSON.stringify({
            ...onBoardingUserData,
            ...item?.userData,
          }),
        );
      } else {
        localStorage.setItem(REGISTRATION_DATA, JSON.stringify(item?.userData));
      }
    }
  }

  function handleSubmitMultiple() {
    const onBoardingUserData = JSON.parse(
      localStorage.getItem(REGISTRATION_DATA),
    );
    const specializations = selected.filter(item => item !== 'Other');
    if (selected.includes('Other')) {
      specializations.push(other);
    }
    if (onBoardingUserData) {
      localStorage.setItem(
        REGISTRATION_DATA,
        JSON.stringify({
          ...onBoardingUserData,
          ...{specialization: specializations},
        }),
      );
    }
    if (onBoardingUserData?.userType === PROFESSIONAL)
      mpTrack('Web App Onboarding Professional Specialization Validated');
    else {
      mpTrack('Web App Onboarding Coach Specialization Validated');
      setUserAttributeOnAllPlatforms(
        'coach_specialisation',
        specializations.join(', '),
      );
    }
    navigate(data[0]?.path);
  }

  return (
    <>
      <Container>
        <Box sx={styles.container}>
          <Grid container spacing={3} justifyContent="center">
            {data.map((button, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <OnBoardingButton
                  selected={selected.includes(button?.text)}
                  key={index}
                  disabled={button?.disabled}
                  onPress={() => {
                    handlePressed(button);
                  }}
                  text={
                    button.text === '1'
                      ? '1'
                      : mutlipleSelection
                      ? translate(button?.text)
                      : onBoardingTranslate(button?.text)
                  }
                  subText={onBoardingTranslate(button?.subText)}
                  icon={button?.icon}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      {selected?.includes('Other') && (
        <div style={styles.textInputContainer}>
          <TextInput
            label={translate('Other')}
            name={translate('Other')}
            value={other}
            onChange={item => {
              setOther(item?.target?.value);
            }}
          />
        </div>
      )}
      {mutlipleSelection && (
        <div style={styles.validateContainer}>
          <ButtonComponent
            type={ORANGE}
            title={translate('Validate')}
            onClick={handleSubmitMultiple}
            disabled={selected.length === 0}
          />
        </div>
      )}
    </>
  );
}
const styles = {
  container: {
    flexGrow: 1,
    padding: 2,
    width: '100%',
  },
  textInputContainer: {
    margin: 'auto',
    marginTop: 20,
    width: '30%',
  },
  validateContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5vh',
    width: '100%',
  },
};
export default OnBoardingButtonList;
