import React from "react";

import TextComponent from "components/atoms/TextComponent";

import { translate } from "services/remoteConfig";

import { colors } from "themes";

function UnlimitedAnalysisButton() {

    return (
        <div style={styles.unlimitedContainer}>
            <TextComponent
                sx={styles.unlimitedText}
                text={translate('UnlimitedAnalysis')}
            />
        </div>
    )

}

const styles = {
    unlimitedContainer: {
        alignItems: 'center',
        backgroundColor: colors.PRIMARY,
        borderRadius: 10,
        padding: 10,
    },
    unlimitedText: {
        alignSelf: 'center',
        fontSize: 18,
        textAlign: 'center',
        textAlignVertical: 'center',
    },
}

export default UnlimitedAnalysisButton;