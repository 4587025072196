import React from "react";
import TextComponent from "components/atoms/TextComponent";
import { translate } from "services/remoteConfig";
import { colors } from "themes";

function AnalysisButton({selected, setSelected}) {
  return (
    <div style={{ marginTop: "5%" }}>
      <div
        style={{
          width: "95%",
          height: "5vh",
          backgroundColor: colors?.HOVER,
          margin: "auto",
          borderRadius: 10,
          border: " 2px solid #38393C",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          onClick={() => setSelected(!selected)}
          style={{
            width: "49.5%",
            height: "90%",
            borderRadius: 7,
            backgroundColor: selected ? colors?.SELECTED : null,
            transition: "all 0.4s",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextComponent
            text={translate("Explanation")}
            sx={{ color: selected ? "white" : "#737373", margin: 0 }}
          />
        </div>
        <div
          onClick={() => setSelected(!selected)}
          style={{
            width: "49.5%",
            height: "90%",
            backgroundColor: !selected ? colors?.SELECTED: null,
            borderRadius: 7,
            transition: "all .3s",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextComponent
            text={"Exercices"}
            sx={{ color: !selected ? "white" : "#737373", margin: 0 }}
          />
        </div>
      </div>
    </div>
  );
}

export default AnalysisButton;
