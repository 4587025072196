import React from "react";

import TextComponent from "components/atoms/TextComponent";

import { translate } from "services/remoteConfig";

import { BOLD } from "constant/constants";

import { colors } from "themes";


function CreditsRecap({ number, monthly }) {

    return (
        <div style={styles.textContainer}>
            <TextComponent
                text={monthly ? translate('MonthlyAnalysis') + ' : ' : translate('BoughtAnalysis') + ' : '}
                sx={styles.analysisType}
            />
            <TextComponent
                sx={styles.creditNumberText}
                text={monthly ? number + ' / 3' : number}
                weight={BOLD}
            />
        </div>
    );

}

const styles = {
    analysisType: {
        fontSize: '1vw',
        marginRight: '1vw'
    },
    creditNumberText: {
        alignSelf: 'flex-end',
        color: colors.SECONDARY,
        fontSize: '1vw',
        marginBottom: '1vh',
        marginLeft: '1vw',
        width: '3vw',
    },
    textContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        display: 'flex',
        marginTop: '2vh',
    },
}

export default CreditsRecap;