import React from "react";
import TextComponent from "components/atoms/TextComponent";
import { colors } from "themes";


const OptionButton = ({ Icon, text, type, onClick }) => (
    <div className={`button button-${type}`} onClick={onClick}>
        <Icon className="button-icon" />
        <TextComponent text={text} sx={{ color: colors.WHITE }} />
    </div>
);

export default OptionButton;