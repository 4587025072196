import React, { useEffect, useState } from "react";

import PoulaineGraph from "../../PoulaineGraph";
import JointAngles from "../../JointAngles";

function GraphPanel({ graphData }) {
  const [cleanData, setCleanData] = useState([]);
  const poulaine = graphData?.poulaine;

  useEffect(() => {
    let XminValue = poulaine[0]?.x;
    let YminValue = poulaine[0]?.y;
    let pData = [];

    poulaine?.sort((a, b) => (a.x > b.x ? 1 : -1));

    poulaine?.forEach((element) => {
      pData?.push({ x: element?.x, y: element?.y });
      if (element?.x < XminValue) XminValue = element?.x;
      if (element?.y < YminValue) YminValue = element?.y;
    });

    pData?.forEach((element) => {
      element.x = element?.x - XminValue;
      element.y = element?.y - YminValue;
    });

    setCleanData(pData);
  }, []);
  return (
    <div style={styles.container}>
      {/* <PoulaineGraph data={cleanData} /> */}
      <JointAngles jointAngles={graphData?.joint_angles} direction={graphData?.direction} />


    </div>
  );
}

const styles = {
  container: {
    marginTop: 10,
  },

};

export default GraphPanel;
