import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import ProfileCoach from "./ProfileCoach";
import ProfileAthlete from "./ProfileAthlete";

import { setAnalysisData } from "utils/analysis";
import { useUsageChanges } from "hooks/useUsageChanges";
import { useProfileChanges } from "hooks/useProfileChanges";
import { useItemChanges } from "hooks/useItemsChanges";

import { analysisListData, analysisListState } from "slices/analysisSlice";

import { checkUserPermission } from "utils/utils";

import { ATHLETE } from "constant/constants";

function ProfileScreen() {

    useUsageChanges();
    useProfileChanges();
    useItemChanges();

    const [loading, setLoading] = useState(true);

    const analysisList = useSelector(analysisListState);
    const userSlice = useSelector((state) => state?.user);
    const datas = useSelector(analysisListData);
    const dispatch = useDispatch();
    const [analysisListDatas, setAnalysisListDatas] = useState(datas);

    useEffect(() => {
        if (analysisList && analysisList?.length > 0 && userSlice?.fullName) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
        setAnalysisData(analysisList, datas, setAnalysisListDatas, dispatch, setLoading);
    }, [analysisList]);

    if (checkUserPermission([ATHLETE]))
        return (
            <ProfileAthlete analysisListDatas={analysisListDatas} userSlice={userSlice} loading={loading} />
        )
    else {
        return (
            <ProfileCoach analysisListDatas={analysisListDatas} userSlice={userSlice} loading={loading} />
        )
    }


}


export default ProfileScreen;