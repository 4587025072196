import React, { useState } from "react";
import { useDispatch } from "react-redux";

import ButtonComponent from "components/atoms/ButtonComponent";
import TextComponent from "components/atoms/TextComponent";
import LoadingComponent from "components/atoms/LoadingComponent";

import { deleteAnalysis } from "services/analysisApi";
import { removeFromCommentCollection } from "services/commentApi";
import { mpTrack } from "services/mixpanel";
import { deleteAnalysisFromProjects } from "services/projectApi";
import { translate } from "services/remoteConfig";

import { removeItemFromListAnalysisData } from "slices/analysisSlice";

import { isOwnerOfItem } from "utils/utils";
import { RED } from "constant/constants";

import { EXEMPLE_ANALYSIS } from "constant/product";

function DeleteAnalysisModalContent({ analysis, modalRef }) {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);

    function handleConfirm() {
        setLoader(true);
        setTimeout(() => {
            if (!Object.values(EXEMPLE_ANALYSIS).includes(analysis?.data?.uploadVideoId)) {
                dispatch(removeItemFromListAnalysisData({
                    data: { uploadVideoId: analysis?.data?.uploadVideoId },
                }))
            }

            deleteAnalysisFromProjects(analysis?.data?.uploadVideoId);
            if (isOwnerOfItem(analysis?.data?.owner)) {
                deleteAnalysis(analysis?.data?.uploadVideoId);
                removeFromCommentCollection(analysis?.data?.uploadVideoId);
            }
            mpTrack('WebApp Analysis Deleted ', analysis);

            modalRef.current?.close();
            setLoader(false);
        }, 1000);

    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '50px',
        }}>

            {loader ? (
                <>
                    <TextComponent
                        text={translate('DeleteInProgress')}
                        sx={{ color: 'white', alignSelf: 'center', marginBottom: '3vh', textAlign: 'center', fontSize: '1.5vw' }} />
                    <LoadingComponent />
                </>
            ) : (
                <>

                    <TextComponent
                        text={translate('DeleteThisActivity')}
                        sx={{ color: 'white', alignSelf: 'center', marginBottom: '3vh', textAlign: 'center', fontSize: '1.5vw' }} />

                    <ButtonComponent
                        type={RED}
                        title={translate('Delete')}
                        onClick={handleConfirm}
                    />
                </>
            )

            }
        </div>
    );
}

export default DeleteAnalysisModalContent;