import { useEffect, useState } from "react";

import { collection, doc, onSnapshot } from "firebase/firestore";
import { useDispatch } from "react-redux";

import {updateUsageStore} from "slices/usageSlice"
import { store } from "store";

import { firestore } from "utils/firebaseInit";

const USAGE_COLLECTION = 'usages';

export function useUsageChanges() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
      useEffect(() => {
        setLoading(true);
        const user = store?.getState()?.user
        if(user?.userID){
            try {
              onSnapshot(doc(collection(firestore,USAGE_COLLECTION),user?.userID), (doc)=> {
                if(doc.exists()) dispatch(updateUsageStore(doc?.data()))
              })
            } catch (error) {
              setError(error)
            }
          }  
    }, []);
    
    return { loading, error };
}

export function useUsageStatus() {
  const [usageData, setUsageData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const user = store?.getState()?.user

    if (!user?.userID) {
      setLoading(false);
      setError('No user ID provided');
      return;
    }

    setLoading(true);

    const unsubscribe = onSnapshot(
      doc(collection(firestore, USAGE_COLLECTION), user?.userID),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setUsageData(docSnapshot.data());
        } else {
          setError('Document does not exist');
        }
        setLoading(false);
      },
      (error) => {
        setError(error);
        setLoading(false);
      }
    );

    // Cleanup function to unsubscribe from the snapshot listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return { usageData, loading, error };
}