import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Carrousel({data, children}) {
  const renderChildren = (item, index) => {
    return React.Children.map(children, child => {
      return React.cloneElement(child, { item, index });
    });
  };
  return (
    <Carousel showStatus={false}>
      {data.map((item, index) => (
        <div>{renderChildren(item, index)}</div>
      ))}
    </Carousel>
  )
}

export default Carrousel