import ClickableTextComponent from "components/atoms/ClickableTextComponent";
import TextComponent from "components/atoms/TextComponent";
import React from "react";
import { useNavigate } from "react-router-dom";
import { translate } from "services/remoteConfig";

function TermsAndPrivacy() {
  const navigate = useNavigate();
  return (
    <div style={styles.container}>
      <TextComponent
        variant={"body1"}
        text={translate("TermsP1")}
        sx={styles.text}
      />
      <ClickableTextComponent
        title={translate("PrivacyTitle")}
        style={{ float: "left" }}
        option={{ weight: "bold" }}
        onPress={() => {
          navigate("/privacyPolicy");
        }}
      />
      <TextComponent text={translate("And")} sx={styles.and} />

      <ClickableTextComponent
        title={translate("TermsTitle")}
        option={styles.clickOption}
        onPress={() => {
          navigate("/termsAndConditions");
        }}
      />
    </div>
  );
}

const styles = {
  and: { marginLeft: 1, marginRight: 1 },
  container: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    float: "left",
    marginTop: "3%",
    width: "80%",
  },
  clickOption: {
    marginLeft: 0,
    weight: "bold",
  },
  text: {
    marginLeft: 0,
    marginRight: 1,
    whiteSpace: "nowrap",
  },
};

export default TermsAndPrivacy;
