import React, { useState } from 'react';
import AnalysisPanelTopContent from './AnalysisPanelTopContent';
import AnalysisButton from '../AnalysisButton';
import TextComponent from 'components/atoms/TextComponent';
import { BODY_1, BODY_2, BOLD, H6 } from 'constant/constants';
import ExerciceContainer from 'screen/analysis/exercices/ExerciceContainer';
import OverstrideContent from '../analysisRightPanelUpdated/OverstrideContent';

function AnalysisRightPanelAnalysis({
  displayData,
  exercices,
  analysisTexts,
  overstrideData
}) {
  const [selected, setSelected] = useState(true);

  if (displayData?.type === 'RightAnkle' && overstrideData?.overstride_score) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            width: '85%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: 40
          }}
        >
          <TextComponent
            text={displayData?.text1}
            weight={BOLD}
            sx={{ marginBottom: 2 }}
            variant={H6}
          />
          <img
            src={displayData?.image}
            alt="ankle"
            style={{ width: '70%', alignSelf: 'center', marginBottom: 30 }}
          />

          <TextComponent text={displayData?.text2} variant={BODY_2} />
        </div>
        <div
          style={{
            width: '95%',
            border: '1px dashed rgba(255, 255, 255, 0.1)',
            marginTop: 40,
            marginBottom: 40
          }}
        />
        <OverstrideContent overstrideData={overstrideData} />
      </div>
    );
  }

  return (
    <>
      <img
        src={displayData?.idealImage}
        style={{
          borderRadius: 10,
          marginLeft: '1%',
          width: '98%'
        }}
        alt={'correct position'}
      />
      <AnalysisPanelTopContent displayData={displayData} />
      <AnalysisButton selected={selected} setSelected={setSelected} />
      {selected ? (
        <TextComponent
          text={displayData?.text2}
          sx={{
            textAlign: 'left',
            color: 'white',
            marginTop: 2,
            marginRight: 2,
            lineHeight: 1.75,
            marginLeft: 1
          }}
          variant={BODY_1}
        />
      ) : (
        <ExerciceContainer
          data={displayData}
          exercices={exercices}
          analysisTexts={analysisTexts}
        />
      )}
    </>
  );
}

export default AnalysisRightPanelAnalysis;
