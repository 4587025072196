import React, { forwardRef, useImperativeHandle, useState } from "react";

import Lottie from "lottie-react";

import success from "assets/animations/success.json";

const SuccessLottieComponent = forwardRef(({ onComplete, addedStyles }, ref) => {
  const [open, setOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    getOpen() {
      return open;
    },
    setOpen() {
      setOpen(true);
    },
    setClose() {
      setOpen(false);
    },
  }));

  return (
    <>
      {open ? (
        <div style={styles.container}>
          <Lottie
            animationData={success}
            loop={false}
            style={{ width: "100%", ...addedStyles, }}
            onComplete={onComplete}
          />
        </div>
      ) : null}
    </>
  );
});

const styles = {
  container: {
    display: "flex",
    minHeight: "50vh",
    justifyContent: "center",
    alignContent: "center",
  },
};

export default SuccessLottieComponent;
