import { store } from "store";
import uuid from "services/uuid.js";
import { collection, doc, getDoc, setDoc } from "@firebase/firestore";
import { firestore } from "utils/firebaseInit";

const ITEMS_COLLECTION = "userItems";

export async function updateItem(item) {
  const userProfile = store?.getState()?.user;
  let arr = [];
  if (userProfile?.userID) {
    try {
      const collectionRef = collection(firestore, ITEMS_COLLECTION);
      const ref = doc(collectionRef, userProfile?.userID);
      const itemsList = (await getDoc(ref)).data();

      if (itemsList) arr = [...itemsList?.items];
      let ind = arr?.findIndex((ele) => ele?.id === item?.id);
      if (ind !== -1) arr[ind] = item;
      else {
        item.id = uuid();
        arr?.push(item);
      }
      setDoc(ref, { items: arr }, { merge: true });
    } catch (e) {
      return e
    }
  }
}
export async function deleteItem(item, targetID) {
  const userProfile = store.getState().user;
  //Should delete all analysis related to this patient
  let id = "";
  let itemID = "";

  if (targetID) {
    id = targetID;
    itemID = userProfile?.userID;
  } else {
    id = userProfile?.userID;
    itemID = item?.id;
  }
  if (userProfile?.userID) {
    try {
      const collectionRef = collection(firestore, ITEMS_COLLECTION);
      const ref = doc(collectionRef, id);
      const itemsList = (await getDoc(ref)).data();
      let ind = itemsList?.items?.findIndex((ele) => ele?.id === itemID);
      if (ind === -1) return;
      itemsList?.items?.splice(ind, 1);
      setDoc(ref, { items: itemsList?.items }, { merge: true });
    } catch (e) {
      return e
    }
  }
}

export async function addItem(item) {
  const userProfile = store.getState().user;
  if (userProfile?.userID === undefined) item.id = uuid();

  let arr = [];
  try {
    const collectionRef = collection(firestore, ITEMS_COLLECTION);
    const ref = doc(collectionRef, userProfile?.userID);
    const docs = getDoc(ref);
    if ((await docs).exists()) {
      const itemsList = (await docs)?.data();
      if (!itemsList) arr = [];
      else arr = [...itemsList?.items];
      if (item?.id === undefined) {
        arr?.push(item);
      } else {
        if (!arr?.find((ele) => ele?.id === item?.id)) arr?.push(item);
      }

      setDoc(ref, { items: arr }, { merge: true });
    }
    else {
      arr = [item]
      setDoc(ref, { items: arr }, { merge: true });
    }
  } catch (e) {
    console.log("error in add", e);
    return e
  }
}

export async function addCoach(athleteID) {
  const userProfile = store.getState().user;
  if (userProfile?.userID === undefined) return;
  let arr = [];
  try {
    const collectionRef = collection(firestore, ITEMS_COLLECTION);
    const ref = doc(collectionRef, athleteID);
    const docs = getDoc(ref);
    if ((await docs).exists()) {
      const itemsList = (await docs)?.data();
      if (!itemsList) arr = [];
      else arr = [...itemsList?.items];
      if (!arr?.find((ele) => ele?.id === userProfile?.userID))
        arr?.push({
          id: userProfile?.userID,
          name: userProfile?.fullName,
          email: userProfile?.emailAddress,
        });
      setDoc(ref, { items: arr }, { merge: true });
    }
  } catch (e) {
    console.log(e);
  }
}
