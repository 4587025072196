import React, {useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';

import OnBoardingBottomArrow from './components/OnBoardingBottomArrow';
import TextComponent from 'components/atoms/TextComponent';

import {getGoodViedeoTutorial} from 'services/uiApi';
import {onBoardingTranslate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {
  createTextObjectReusable,
  replaceUserNameByActualName,
} from 'utils/utils';

import {ATHLETE, BOLD, H5, REGISTRATION_DATA} from 'constant/constants';
import {tutoVideoIntro} from './constants/onBoardingText';

import {colors} from 'themes';

const MP4 = 'video/mp4';

function OnBoardingGoodVideo() {
  const navigate = useNavigate();
  const [videoUri, setVideoUri] = useState('');

  useEffect(() => {
    mpTrack('Web App OnBoarding Good Video Tuto Screen Viewed');
    getVideoUri();
  }, []);

  async function getVideoUri() {
    const uri = await getGoodViedeoTutorial();
    if (!uri) return;
    setVideoUri(uri);
  }

  const userType = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.userType;

  function handleArrowClicked() {
    navigate('/registration/profile-creation');
  }

  function handlePreviousArrowClicked() {
    if (userType === ATHLETE)
      navigate('/registration/athlete/analysis-exercises');
    else navigate('/registration/coach/share');
  }

  if (!videoUri) return null;
  return (
    <div style={styles.container}>
      <div style={styles.textContainer}>
        <TextComponent
          multipleText={createTextObjectReusable(
            replaceUserNameByActualName(
              onBoardingTranslate(tutoVideoIntro[userType]),
            ),
            {
              variant: H5,
              weight: BOLD,
            },
            {
              variant: H5,
              weight: BOLD,
              style: {textAlign: 'center', color: colors.SECONDARY},
            },
          )}
          center={true}
        />
      </div>
      <video
        style={styles.video}
        controls={true}
        loop={true}
        autoPlay={true}
        muted={true}>
        <source src={videoUri} type={MP4} />
      </video>
      <OnBoardingBottomArrow onPress={handleArrowClicked} />
      <OnBoardingBottomArrow
        previous={true}
        onPress={handlePreviousArrowClicked}
      />
    </div>
  );
}
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textContainer: {
    width: '50%',
    textAlign: 'center',
    marginBottom: 40,
  },
  video: {
    height: '80vh',
  },
};
export default OnBoardingGoodVideo;
