const images = {
  ATHLETE_BASE: require('assets/images/athlete.png'),
  PERFECT_FEMALE_ARM:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2Farm_female.png?alt=media&token=42163217-b9e6-40a2-bc99-eb8fbc09def4',
  PERFECT_FEMALE_BACK:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2Fback_female.png?alt=media&token=151f99f7-063c-4804-850c-3ede9a1d9953',
  PERFECT_FEMALE_BACK_CYCLE:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2Fback_leg_female.png?alt=media&token=31cd3e61-c631-4a27-8e16-803a390c31f9',
  PERFECT_FEMALE_FOOT:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2Ffoot_female.png?alt=media&token=56ae41e3-5416-48ec-8901-23d14ea60472',
  PERFECT_FEMALE_FRONT_CYCLE:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2Fleg_front_female.png?alt=media&token=37ac7d01-d8ed-4de7-acc6-7161d323dcd6',
  PERFECT_FEMALE_HEAD:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2Fhead_female.png?alt=media&token=a5e033f6-6e42-4a71-a1fd-68c6be479692',
  PERFECT_MALE_ARM:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2Fbras_homme.png?alt=media&token=80e94b5a-4364-498c-b55f-24c477609539',
  PERFECT_MALE_BACK:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2Fdos_homme.png?alt=media&token=cc8f012c-84f2-4f91-899c-e1ce6d5a840f',
  PERFECT_MALE_BACK_CYCLE:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2Fjambe_arri%C3%A8re_homme.png?alt=media&token=e47e0aa6-bacd-4a0b-8edf-698401dff4c3',
  PERFECT_MALE_FOOT:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2Fpied_homme.png?alt=media&token=107d8685-af68-4829-997e-fd77672ef26e',
  PERFECT_MALE_FRONT_CYCLE:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2Fjambe_avant_homme.png?alt=media&token=d44ed558-8207-43f7-b278-0d4e0d365834',
  PERFECT_MALE_HEAD:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2Ftete_homme.png?alt=media&token=898c0be0-a932-40e1-8bf9-af3f720868ae',
  VIDEO_TUTO_50m:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2FvideoTuto50.png?alt=media&token=937e0b1c-dec9-4339-9367-d484e430d8ab',
  VIDEO_TUTO_7m:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2FvideoTuto7m.png?alt=media&token=b27291b4-7a75-4827-941e-ea28c9c41ce3',
  VIDEO_TUTO_FREEZE:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2FvideoTutoFreeze.png?alt=media&token=c69650bc-35cc-470f-8a7b-8d578564ffa1',
  VIDEO_TUTO_FULL:
    'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2FvideoTuto%2FvideoTutoFull.png?alt=media&token=91c0e368-0bd7-4931-acdf-e15631150a2e',
  QRCODE: require('assets/images/QrCodeApp.png'),
  MAIL: require('assets/images/mail.png'),
  METRICS_TIME_STEPS: require('assets/images/metrics_time_steps.png'),
  METRICS_NUMBER_STEPS: require('assets/images/metrics_number_steps.png'),
  METRICS_GROUND_CONTACT_TIME: require('assets/images/metrics_ground_contact_time.png'),
  METRICS_STRIDE_LENGTH: require('assets/images/metrics_stride_length.png'),
  METRICS_STEP_LENGTH: require('assets/images/metrics_step_length.png'),
  MID_FOOT: require('assets/images/mid_foot.png'),
  FORE_FOOT: require('assets/images/fore_foot.png'),
  REAR_FOOT: require('assets/images/rear_foot.png'),
  MAN_ATHLETE_DEFAULT_PICTURE: require('assets/icons/athleteMan.png'),
  WOMAN_ATHLETE_DEFAULT_PICTURE: require('assets/icons/athleteWoman.png'),
  PROFESSIONAL_DEFAULT_PICTURE: require('assets/icons/doctor.png'),
  COACH_DEFAULT_PICTURE: require('assets/icons/coach.png'),
  PERRINE_ONBOARDING: require('assets/images/perrineOnBoarding.jpg'),
  OCHY_LOGO: require('assets/images/Ochy_Logo.png'),

  SHARE_ICON: require('assets/images/shareAnalysis.jpg'),

  ONBOARDING_BENCH_PLANK: require('assets/images/onBoardingExercices/BenchPlankSingleLegKneeTucks-0.jpg'),
  ONBOARDING_SEATED_UPPER_TRAP: require('assets/images/onBoardingExercices/SeatedUpperTrapStretch-0.jpg'),
  ONBOARDING_SIDE_LYING_SINGLE: require('assets/images/onBoardingExercices/SideLyingSingleLegAdduction-0.jpg'),
  ONBOARDING_STANDING_SINGLE_ARM: require('assets/images/onBoardingExercices/StandingSingleArmBiceps-ForearmFlexorStretchwithHandFlat-0.jpg'),

  ArmBD: require('assets/images/analysisImages/Arms/ArmBadDown.png'),
  ArmOD: require('assets/images/analysisImages/Arms/ArmOkayDown.png'),
  ArmG: require('assets/images/analysisImages/Arms/ArmGood.png'),
  ArmOT: require('assets/images/analysisImages/Arms/ArmOkayTop.png'),
  ArmBT: require('assets/images/analysisImages/Arms/ArmBadTop.png'),

  BackCycleB: require('assets/images/analysisImages/BackLeg/BackLegBad.png'),
  BackCycleO: require('assets/images/analysisImages/BackLeg/BackLegOkay.png'),
  BackCycleG: require('assets/images/analysisImages/BackLeg/BackLegGood.png'),

  ForwardCycleB: require('assets/images/analysisImages/FrontLeg/FrontLegBad.png'),
  ForwardCycleO: require('assets/images/analysisImages/FrontLeg/FrontLegOkay.png'),
  ForwardCycleG: require('assets/images/analysisImages/FrontLeg/FrontLegGood.png'),

  HeadBD: require('assets/images/analysisImages/Head/HeadBadDown.png'),
  HeadOD: require('assets/images/analysisImages/Head/HeadOkayDown.png'),
  HeadGZ: require('assets/images/analysisImages/Head/HeadGoodZ.png'),
  HeadG: require('assets/images/analysisImages/Head/HeadGood.png'),
  HeadOT: require('assets/images/analysisImages/Head/HeadOkayTop.png'),
  HeadOTSZ: require('assets/images/analysisImages/Head/HeadOkayTopSupZ.png'),
  HeadBT: require('assets/images/analysisImages/Head/HeadBadTop.png'),
  HeadBTIF: require('assets/images/analysisImages/Head/HeadBadTopInfZ.png'),

  TrunkBD: require('assets/images/analysisImages/Trunk/TrunkBadDown.png'),
  TrunkOD: require('assets/images/analysisImages/Trunk/TrunkOkayDown.png'),
  TrunkG: require('assets/images/analysisImages/Trunk/TrunkGood.png'),
  TrunkOT: require('assets/images/analysisImages/Trunk/TrunkOkayTop.png'),
  TrunkOTC: require('assets/images/analysisImages/Trunk/TrunkOkayTopClose.png'),
  TrunkOTIF: require('assets/images/analysisImages/Trunk/TrunkOkayTopInf.png'),
  TrunkBT: require('assets/images/analysisImages/Trunk/TrunkBadTop.png'),
  TrunkBTZ: require('assets/images/analysisImages/Trunk/TrunkBadTopZ.png'),
  TrunkBTIF: require('assets/images/analysisImages/Trunk/TrunkBadTopInf.png'),

  ADIDAS_LOGO_WHITE: require('assets/images/adidasLogoWhite.png'),
  OCHY_LOGO_VERTICAL_WHITE: require('assets/images/OchyLogoVerticalWhite.png'),

  //
};

export default images;
