import TextInput from "components/atoms/TextInput";
import React from "react";
import { translate } from "services/remoteConfig";

function TextInputContainer({
  formValues,
  formErrors,
  handleChange,
  userSchema,
  handleSubmit,
}) {
  return (
    <div style={styles.container}>
      <TextInput
      title={translate("NameAndLastName")}
        id="nameTextInput"
        name="name"
        label={translate("NameAndLastName")}
        value={formValues?.name}
        onChange={handleChange}
        schema={userSchema}
        error={!!formErrors?.name}
        helperText={formErrors?.name}
        image={require("assets/images/guy.png")}
        handleSubmit={() => {
          document.getElementById("emailTextInput").focus();
        }}
      />

      <div style={styles.textInputContainer}>
        <TextInput
          id="emailTextInput"
          name="email"
          title={translate("EmailAdress")}
          label={translate("EmailAdress")}
          value={formValues?.email}
          onChange={handleChange}
          schema={userSchema}
          error={!!formErrors?.email}
          helperText={formErrors?.email}
          image={require("assets/images/mail.png")}
          handleSubmit={() => {
            document.getElementById("passwordTextInput").focus();
          }}
        />
      </div>
      <div style={styles.textInputContainer}>
        <TextInput
          id="passwordTextInput"
          name="password"
          title={translate("Password")}
          label={translate("Password")}
          type="password"
          value={formValues?.password}
          onChange={handleChange}
          schema={userSchema}
          error={!!formErrors?.password}
          helperText={formErrors?.password}
          focusedImage={require("assets/images/view.png")}
          image={require("assets/images/hide.png")}
          handleSubmit={() => {
            document.getElementById("repeatPasswordTextInput").focus();
          }}
        />
      </div>
      <div style={styles.textInputContainer}>
        <TextInput
          id="repeatPasswordTextInput"
          name="repeatPassword"
          title={translate("RepeatPassword")}
          label={translate("RepeatPassword")}
          type="password"
          value={formValues?.repeatPassword}
          onChange={handleChange}
          schema={userSchema}
          error={!!formErrors?.repeatPassword}
          helperText={formErrors?.repeatPassword}
          focusedImage={require("assets/images/view.png")}
          image={require("assets/images/hide.png")}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}
const styles = {
  container: {
    marginTop: "10%",
    width: "80%",
  },
  textInputContainer: {
    marginTop: "6%",
  },
};
export default TextInputContainer;
