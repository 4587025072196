import React from "react";

import { useNavigate } from "react-router-dom";

import ButtonComponent from "components/atoms/ButtonComponent";

import { translate } from "services/remoteConfig";
import { colors } from "themes";
import TextComponent from "components/atoms/TextComponent";
import { H4, ORANGE } from "constant/constants";

function NoVideos() {
  const navigate = useNavigate();
  return (
    <div style={styles.container}>
      <TextComponent
        text={translate("NoVideos")}
        variant={H4}
        sx={styles.title}
      />
      <ButtonComponent
        type={ORANGE}
        title={translate("NewVideo")}
        onClick={() => {
          navigate("/upload-video/");
        }}
      />
    </div>
  );
}

const styles = {
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "80vh",
  },
  title: {
    color: colors.WHITE,
    marginBottom: "5%",
    textAlign: "center",
  },
};

export default NoVideos;
