import React, {useEffect, useState} from 'react';

import {Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useSpring, animated} from 'react-spring';

import FadinView from 'components/atoms/FadinView';
import TextComponent from 'components/atoms/TextComponent';
import OnBoardingContainer from './components/OnBoardingContainer';
import OnBoardingBottomArrow from './components/OnBoardingBottomArrow';

import {getUserTypeNumber} from 'services/uiApi';
import {onBoardingTranslate} from 'services/remoteConfig';

import {
  createTextObjectReusable,
  replaceUserNameByActualName,
} from 'utils/utils';

import {ATHLETE, BOLD, H1, H4, REGISTRATION_DATA} from 'constant/constants';

import {colors} from 'themes';
import {mpIdentify, mpTrack} from 'services/mixpanel';

function OnBoardingUserNumber() {
  const navigate = useNavigate();
  const [numberOfAthletes, setNumberOfAthletes] = useState(0);
  mpIdentify();

  const userType = JSON.parse(localStorage.getItem(REGISTRATION_DATA)).userType
    ? JSON.parse(localStorage.getItem(REGISTRATION_DATA)).userType
    : ATHLETE;

  useEffect(() => {
    if (userType === 'athlete') {
      mpTrack('Web App OnBoarding Welcoming Athlete Screen Viewed');
    } else {
      mpTrack('Web App OnBoarding Welcoming Coach Screen Viewed');
    }
  }, []);

  useEffect(() => {
    const getUserTypeNumbers = async () => {
      const data = await getUserTypeNumber();
      if (data && userType) {
        setNumberOfAthletes(data[userType]);
      }
    };
    getUserTypeNumbers();
  }, [userType]);

  function Number({n}) {
    const {number} = useSpring({
      from: {number: 0},
      number: n,
      config: {mass: 1, tension: 20, friction: 10, duration: 1000},
      delay: 200,
    });
    return <animated.div>{number.to(n => n.toFixed(0))}</animated.div>;
  }

  const getText = () => {
    if (userType === ATHLETE) {
      return (
        replaceUserNameByActualName(onBoardingTranslate('WelcomingUser')) +
        onBoardingTranslate('WelcomingUserAthlete')
      );
    } else {
      return replaceUserNameByActualName(onBoardingTranslate('WelcomingCoach'));
    }
  };

  function handlePressed() {
    if (userType === ATHLETE) {
      navigate('/registration/athlete/typology');
    } else {
      navigate('/registration/coach/specialization');
    }
  }
  function handleGoBack() {
    navigate('/registration/select-user-type');
  }

  return (
    <OnBoardingContainer>
      <FadinView>
        <TextComponent
          multipleText={createTextObjectReusable(
            getText(),
            {
              variant: H4,
              weight: BOLD,
            },
            {
              variant: H4,
              weight: BOLD,
              style: {textAlign: 'center', color: colors.SECONDARY},
            },
          )}
          center={true}
        />
      </FadinView>
      <Typography
        variant={H1}
        component={'div'}
        fontWeight={BOLD}
        sx={{color: colors.SECONDARY, marginTop: 5}}>
        <Number n={numberOfAthletes} />
      </Typography>
      <FadinView>
        <TextComponent
          multipleText={createTextObjectReusable(
            userType === ATHLETE
              ? onBoardingTranslate('WelcomingUserAthlete2')
              : onBoardingTranslate('WelcomingUserCoach2'),
            {
              variant: H4,
              weight: BOLD,
            },
          )}
          center={true}
        />
      </FadinView>
      <OnBoardingBottomArrow onPress={handlePressed} />
      <OnBoardingBottomArrow previous={true} onPress={handleGoBack} />
    </OnBoardingContainer>
  );
}

export default OnBoardingUserNumber;
