import React from 'react';
import TextComponent from 'components/atoms/TextComponent';
import {BODY_2} from 'constant/constants';

function LinedTextComponent({text}) {
  return (
    <div style={styles.container}>
      <div style={styles.lineLeft} />
      <div>
        <TextComponent text={text} variant={BODY_2} style={styles.text} />
      </div>
      <div style={styles.lineRight} />
    </div>
  );
}
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  lineLeft: {
    backgroundColor: 'white',
    flex: 1,
    height: '1px',
    marginRight: '10px',
  },
  lineRight: {
    backgroundColor: 'white',
    flex: 1,
    height: '1px',
    marginLeft: '10px',
  },
  text: {
    alignSelf: 'center',
  },
};

export default LinedTextComponent;
