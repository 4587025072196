import React from 'react';

import TextComponent from 'components/atoms/TextComponent';
import StatusTag from './StatusTag';

import { translate } from 'services/remoteConfig';

import { BOLD } from 'constant/constants';

import { colors } from 'themes';

const UsageInfosDisplay = ({ legacy, usageSlice }) => {
    const {
        yearly10,
        yearly30,
        monthly10,
        monthly30,
        yearlyPlan,
        monthlyPlan,
        monthlyCreditNumber,
        creditNumber,
    } = usageSlice || {};

    const getMaxCred = () => {
        if (yearly10 || monthly10) return '10';
        if (yearly30 || monthly30) return '30';
        return '0';
    };

    const renderSubscriptionDisplay = (planType, planAmount) => (
        <>
            <div style={styles.subContainer}>
                <TextComponent
                    sx={styles.boldText}
                    text={translate(planType) + ' ' + planAmount}
                />
                <StatusTag />
            </div>
            {planAmount === 'Unlimited' && (
                <div style={styles.planContainer}>
                    <div style={styles.unlimitedContainer}>
                        <TextComponent
                            sx={styles.unlimitedText}
                            text={translate('UnlimitedAnalysis')}
                        />
                    </div>
                </div>
            )}
        </>
    );

    const displaySubscription = () => {
        if (yearlyPlan && !yearly10 && !yearly30)
            return renderSubscriptionDisplay('YearlyPlan', 'Unlimited');

        if (monthlyPlan && !monthly10 && !monthly30)
            return renderSubscriptionDisplay('MonthlyPlan', 'Unlimited');

        if (yearlyPlan || yearly10 || yearly30)
            return renderSubscriptionDisplay('YearlyPlan', yearly10 ? '10' : '30');

        if (monthlyPlan || monthly10 || monthly30)
            return renderSubscriptionDisplay('MonthlyPlan', monthly10 ? '10' : '30');

        return (
            <div style={styles.subContainer}>
                <TextComponent
                    sx={styles.boldText}
                    text={translate('NoActivePlan')}
                />
            </div>
        );
    };

    return (
        <div style={{ width: '100%' }}>
            <div>
                <TextComponent
                    text={translate('YourPlan') + ':'}
                    sx={styles.planTitle}
                />
                {displaySubscription()}
            </div>
            {!legacy && (
                <div style={styles.planContainer}>
                    <div style={styles.textContainer}>
                        <TextComponent
                            text={translate('MonthlyAnalysis') + ' : '}
                        />
                        <TextComponent
                            sx={styles.creditNumberText}
                            text={monthlyCreditNumber + ' / ' + getMaxCred()}
                            weight={BOLD}
                        />
                    </div>
                    <div style={styles.textContainer}>
                        <TextComponent
                            sx={styles.MB10}
                            text={translate('BoughtAnalysis') + ' : '}
                        />
                        <TextComponent
                            sx={styles.creditNumberText}
                            text={creditNumber || 0}
                            weight={BOLD}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    boldText: {
        fontWeight: 'bold',
    },
    creditNumberText: {
        alignSelf: 'flex-end',
        color: colors.SECONDARY,
        marginLeft: 5,
    },
    planContainer: {
        alignSelf: 'center',
        marginTop: 20,
        width: '100%',
    },
    planTitle: {
        color: colors.WHITE_OPACITY_40,
    },
    subContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        padding: 8,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    unlimitedContainer: {
        alignItems: 'center',
        backgroundColor: colors.PRIMARY,
        borderRadius: 10,
        padding: 10,
    },
    unlimitedText: {
        alignSelf: 'center',
        textAlign: 'center',
        textAlignVertical: 'center',
    },
}

export default UsageInfosDisplay;
