import TextComponent from "components/atoms/TextComponent";
import { BODY_1, BODY_2 } from "constant/constants";
import React from "react";
import { colors } from "themes";
import { ReactComponent as Arrow } from "assets/svg/arrow-right-bold.svg";
import { ReactComponent as Pending } from "assets/svg/pending.svg";
import "./styles/AthleteCard.css"
import { translate } from "services/remoteConfig";

function PendingAthleteCard({ item }) {
  return (
    <div
     className="pending-athlete-card-container"
    >
      <div
        style={{
          width: "70%",
          marginLeft: 15,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div style={{ display: "flex" }}>
          <Pending style={{ marginTop: 2 }} />
          <TextComponent
            sx={{
              textTransform: "capitalize",
              color: "#CE65FF",
              marginLeft: 1,
            }}
            text={translate('Waiting')}
            variant={BODY_1}
          />
        </div>
        <TextComponent
          sx={{ margin: 0, color: "#A3A3A3", marginBottom: 0.5 }}
          text={item?.email}
          variant={BODY_2}
        />
      </div>
      <div
        style={{
          width: "30%",
          alignContent: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
      </div>
    </div>
  );
}

export default PendingAthleteCard;
