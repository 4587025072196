import {collection, doc, setDoc} from 'firebase/firestore';
import {getAnalysisTexts} from './analysis';
import {USER_LOCALE, translate} from './remoteConfig';
import {getDownloadURL, getStorage, ref} from 'firebase/storage';
import {checkUserPermission} from 'utils/utils';
import {store} from 'store';
import {firestore} from 'utils/firebaseInit';

import {getDownloadUrlFromGsUrl} from './urlApi';

const MAIL_COLLECTION = 'mails';
const PENDING_MAILS = 'pendingMails';

function setEmailTemplate(totalScore, comments) {
  const language = USER_LOCALE;
  var template = '';

  if (checkUserPermission(['athlete'])) {
    if (totalScore >= 66) {
      template = `athlete_share_analysis_${language}_good`;
    } else if (totalScore <= 33) {
      template = `athlete_share_analysis_${language}_bad`;
    } else template = `athlete_share_analysis_${language}_okay`;
  } else {
    if (comments !== null) {
      template = `coach_share_analysis_${language}`;
    } else {
      template = `coach_share_analysis_${language}_noComment`;
    }
  }

  return template;
}

async function createHtmlPage(
  totalScore,
  videoLink,
  name,
  videoUrl,
  thumbnailUrl,
  emails,
  comments,
) {
  const emailTemplate = setEmailTemplate(totalScore, comments);
  const userProfile = store?.getState()?.user;

  sendMail({
    to: emails,
    from: userProfile?.brandMail
      ? userProfile.brandMail
      : 'Ochy <no-reply@ochy.io>',
    template: {
      name: emailTemplate,
      data: {
        TotalScore: totalScore,
        comments: comments,
        videoLink: videoUrl,
        thumbNail: thumbnailUrl
          ? thumbnailUrl
          : 'https://firebasestorage.googleapis.com/v0/b/ochy-7871a.appspot.com/o/assets%2Fimages%2FmailCards%2Fthumb_placeholder.png?alt=media&token=97c3b1fb-f60f-462a-a6e2-8425b9fe4876',
        from: userProfile?.fullName,
      },
    },
  });
}

export async function sendAnalysis(
  totalScore,
  link,
  thumbnail,
  videoId,
  emails,
  name,
  comments,
) {
  const deeplinkbuild = `https://app.ochy.io/analysis-shared/${videoId}`;

  if (emails?.length === 0) return;

  const url = await getDownloadUrlFromGsUrl(link);
  const thumbnailUrl = await getDownloadUrlFromGsUrl(thumbnail);

  if (thumbnailUrl && deeplinkbuild) {
    createHtmlPage(
      totalScore,
      url,
      name,
      deeplinkbuild,
      thumbnailUrl,
      emails,
      comments,
    );
  }
}

export async function sendMail(mail) {
  if (mail) {
    try {
      const db = firestore;
      const mailCollection = collection(db, MAIL_COLLECTION);
      const newMailDoc = doc(mailCollection);
      await setDoc(newMailDoc, mail);
      console.log('Mail sent successfully');
    } catch (e) {
      console.log('error in sendMail ', e);
    }
  }
}
export const buildLink = async analysisId => {
  const response = await fetch(
    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCKRPDmnbQdSSKVVWnllpnRzwOF3sYATBo',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dynamicLinkInfo: {
          domainUriPrefix: 'https://ochy.page.link',
          link: `https://app.ochy.io/analysis-shared/${analysisId}`,
          androidInfo: {
            androidPackageName: 'fr.ochy.app',
          },
          iosInfo: {
            iosBundleId: 'fr.ochy.app',
          },
        },
        suffix: {
          option: 'SHORT',
        },
      }),
    },
  );
  const data = await response.json();
  return data.shortLink;
};
// export async function createPendingMail(date, email, name, timeToRunningDate) {
//   const language = USER_LOCALE;
//   const userProfile = store?.getState()?.user;

//   try {
//     const db = firestore;
//     const mailCollection = collection(db, PENDING_MAILS);
//     if (!mailCollection) return;
//     const newMailDoc = doc(mailCollection);
//     await setDoc(newMailDoc, {
//       to: email,
//       from: userProfile?.brandMail
//         ? userProfile.brandMail
//         : 'Ochy <no-reply@ochy.io>',
//       template: {
//         name: `email_running_date_${language}`,
//         data: {
//           userName: name,
//           timeToRunningDate: timeToRunningDate,
//           appLink: 'https://ochy.page.link/running_date',
//         },
//       },
//       date: date,
//     });
//   } catch (e) {
//     console.log(e);
//   }
// }
