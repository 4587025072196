import {Route, Routes, useLocation} from 'react-router-dom';
import ErrorPage from './Error-page';
import Analysis from './screen/analysis/AnalysisViewDetailsScreen/Analysis';
import Login from './screen/auth/Login';
import AnalysisList from './screen/analysis/AnalysisList';
import Header from 'components/template/Header';
import UploadScreen from 'screen/upload/UploadScreen';
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth} from 'utils/firebaseInit';
import {ErrorBoundary} from 'react-error-boundary';
import {USER_LOCALE, useRemoteConfigInit} from 'services/remoteConfig';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateProfileStore} from 'slices/userSlice';
import {getUserInfoFromId} from 'services/userApi';

import PrivacyPolicy from 'screen/pp&tos/PrivacyPolicy';
import TermsNConditions from 'screen/pp&tos/TermsNConditions';
import ForgotPassword from 'screen/auth/registration/ForgotPassword';

import {EN, ENGLISH, FR, FRENCH} from 'constant/constants';

import {ThemeProvider, createMuiTheme} from '@mui/material';
import NavigationPanel from 'components/template/NavigationPanel';
import {colors} from 'themes';
import AthletesList from 'screen/athlete/AthletesList';
import AddAthlete from 'screen/athlete/AddAthlete';

import {isMobile} from 'react-device-detect';
import MobileRedirection from 'screen/auth/components/MobileRedirection';
import {selectUnityType, setUnity, setUnityType} from 'slices/unitySlice';
import AnalysisShared from 'screen/analysis/AnalysisViewDetailsScreen/AnalysisShared';
import HowToDeleteAccount from 'screen/howToDeleteAccount/HowToDeleteAccount';
import ExerciceScreen from 'screen/exercices';
import ProfileScreen from 'screen/profile';
import Leaderboard from 'screen/Leaderboard/Leaderboard';
import Registration from 'screen/auth/registration/Registration';
import OnBoardingWelcomeScreen from 'screen/auth/registration/onBoarding/OnBoardingWelcomeScreen';
import OnBoardingUserType from 'screen/auth/registration/onBoarding/OnBoardingUserType';
import OnBoardingAthleteTypology from 'screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteTypology';
import OnBoardingAthleteGender from 'screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteGender';
import OnBoardingAthleteRunningFrequency from 'screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteRunningFrequency';
import OnBoardingAthleteRunningLevel from 'screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteRunningLevel';
import OnBoardingAthleteAnalysisIntro from 'screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteAnalysisIntro';
import OnBoardingAthleteInfos from 'screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteInfos';
import OnBoardingAthleteHeightAndWeight from 'screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteHeightAndWeight';
import OnBoardingAnalysisTotalScore from 'screen/auth/registration/onBoarding/athleteOnBoarding/analysis/OnBoardingAnalysisTotalScore';
import OnBoardingAnalysisSegmentExplanation from 'screen/auth/registration/onBoarding/athleteOnBoarding/analysis/OnBoardingAnalysisSegmentExplanation';
import OnBoardingExercisesIntro from 'screen/auth/registration/onBoarding/athleteOnBoarding/analysis/OnBoardingExercisesIntro';
import OnBoardingExercises from 'screen/auth/registration/onBoarding/athleteOnBoarding/analysis/OnBoardingExercises';
import OnBoardingGoodVideo from 'screen/auth/registration/onBoarding/OnBoardingGoodVideo';
import OnBoardingProfileCreation from 'screen/auth/registration/onBoarding/OnBoardingProfileCreation';
import OnBoardingUserNumber from 'screen/auth/registration/onBoarding/OnBoardingUserNumber';
import OnBoardingSpecialization from 'screen/auth/registration/onBoarding/coachOnBoarding/OnBoardingSpecialization';
import OnBoardingCoachPlaceOfWork from 'screen/auth/registration/onBoarding/coachOnBoarding/OnBoardingCoachPlaceOfWork';
import OnBoardingCoachAnalysisIntro from 'screen/auth/registration/onBoarding/coachOnBoarding/OnBoardingCoachAnalysisIntro';
import OnBoardingCoachAnalysisWrapper from 'screen/auth/registration/onBoarding/coachOnBoarding/analysis/OnBoardingCoachAnalysisWrapper';
import OnBoardingCoachShare from 'screen/auth/registration/onBoarding/coachOnBoarding/OnBoardingCoachShare';
import OnBoardingAthleteTypologyWelcome from 'screen/auth/registration/onBoarding/athleteOnBoarding/OnBoardingAthleteTypologyWelcome';
import LoginOrRegister from 'screen/auth/LoginOrRegister';
import CompareAnalysis from 'screen/analysis/CompareAnalysis/CompareAnalysis';
import AdidasActivationRegistration from 'screen/auth/registration/AdidasActivationRegistration';
import AdidasActivationRegistrationSuccess from 'screen/auth/registration/AdidasActivationRegistrationSuccess';
import AdidasActivationLogin from 'screen/auth/registration/AdidasActivationLogin';

export function App() {
  const theme = createMuiTheme({
    typography: {
      fontFamily: ['Space Grotesk', 'sans-serif'].join(','),
    },
  });

  const [user, loading] = useAuthState(auth);
  const remoteLoading = useRemoteConfigInit();
  const [configLoaded, setConfigLoaded] = useState(false);
  const dispatch = useDispatch();
  const userSlice = useSelector(state => state?.user);
  const unityType = useSelector(selectUnityType);
  const navigation = useLocation();

  useEffect(() => {
    if (unityType === '' || !unityType) {
      if (USER_LOCALE === ENGLISH.code) {
        dispatch(setUnityType(EN));
        dispatch(setUnity(EN));
      } else {
        dispatch(setUnityType(FR));
        dispatch(setUnity(FR));
      }
    }
    if (userSlice?.userID === 'Unknown' && user) {
      getUserInfoFromId(user?.uid)
        .then(data => {
          const userData = data;
          userData.userID = user?.uid;
          userData.userType = userData?.type;
          dispatch(updateProfileStore(userData));
        })
        .catch(error => {
          console.log('error in getUserInfoFromId', error);
        });
    }
  }, [user]);

  useEffect(() => {
    if (remoteLoading) return;
    setConfigLoaded(true);
  }, [remoteLoading]);

  const isAffiliation = () => {
    if (navigation?.pathname?.includes('adidas')) {
      return true;
    }
    return false;
  };

  if (loading || !configLoaded) return null;

  function authRoute() {
    if (isAffiliation()) {
      return (
        <Routes>
          <Route
            path="/ochy-adidas"
            element={<AdidasActivationRegistration />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/ochy-adidas-login"
            element={<AdidasActivationLogin />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/ochy-adidas-activation-success"
            element={<AdidasActivationRegistrationSuccess />}
            errorElement={<ErrorPage />}
          />
        </Routes>
      );
    }
    if (userSlice?.userID !== 'Unknown' && userSlice?.registered === true) {
      return (
        <Routes>
          <Route path="*" element={<ErrorPage />} />
          <Route
            path="/"
            element={<AnalysisList />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/analysis-athlete/:id"
            element={<AnalysisList />}
            errorElement={<ErrorPage />}
          />

          <Route
            path="/analysis/:id"
            element={<Analysis />}
            errorElement={<ErrorPage />}
          />

          <Route
            path="/exercice/:id"
            element={<ExerciceScreen />}
            errorElement={<ErrorPage />}
          />

          <Route
            path="/analysis-shared/:id"
            element={<AnalysisShared />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/upload-video/"
            element={<UploadScreen />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/upload-video/:id"
            element={<UploadScreen />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/athletes"
            element={<AthletesList />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/profile"
            element={<ProfileScreen />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/compare-analysis"
            element={<CompareAnalysis />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/add-athlete"
            element={<AddAthlete />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/how-to-delete-account"
            element={<HowToDeleteAccount />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/leaderboard"
            element={<Leaderboard />}
            errorElement={<ErrorPage />}
          />
        </Routes>
      );
    } else if (
      userSlice?.userID !== 'Unknown' &&
      userSlice?.registered === false
    ) {
      return (
        <ErrorBoundary FallbackComponent={<ErrorPage />}>
          <Routes>
            <Route path="*" element={<ErrorPage />} />
            <Route
              path="/"
              element={<OnBoardingWelcomeScreen />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/select-user-type"
              element={<OnBoardingUserType />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/user-number"
              element={<OnBoardingUserNumber />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/athlete/typology"
              element={<OnBoardingAthleteTypology />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/athlete/typology-welcome"
              element={<OnBoardingAthleteTypologyWelcome />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/athlete/gender"
              element={<OnBoardingAthleteGender />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/athlete/running-level"
              element={<OnBoardingAthleteRunningLevel />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/athlete/running-frequency"
              element={<OnBoardingAthleteRunningFrequency />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/athlete/information"
              element={<OnBoardingAthleteInfos />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/athlete/height-weight"
              element={<OnBoardingAthleteHeightAndWeight />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/athlete/analysis-introduction"
              element={<OnBoardingAthleteAnalysisIntro />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/athlete/analysis-total-score"
              element={<OnBoardingAnalysisTotalScore />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/athlete/analysis-segment"
              element={<OnBoardingAnalysisSegmentExplanation />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/athlete/analysis-exercises-introduction"
              element={<OnBoardingExercisesIntro />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/athlete/analysis-exercises"
              element={<OnBoardingExercises />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/exercice/:id"
              element={<ExerciceScreen />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/coach/specialization"
              element={<OnBoardingSpecialization />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/coach/place-of-work"
              element={<OnBoardingCoachPlaceOfWork />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/coach/analysis-introduction"
              element={<OnBoardingCoachAnalysisIntro />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/coach/analysis"
              element={<OnBoardingCoachAnalysisWrapper />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/coach/share"
              element={<OnBoardingCoachShare />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/good-video-tutorial"
              element={<OnBoardingGoodVideo />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/registration/profile-creation"
              element={<OnBoardingProfileCreation />}
              errorElement={<ErrorPage />}
            />
          </Routes>
        </ErrorBoundary>
      );
    } else {
      return (
        <ErrorBoundary FallbackComponent={<ErrorPage />}>
          <Routes>
            <Route path="*" element={<ErrorPage />} />
            <Route
              path="/"
              element={<LoginOrRegister />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/ochy-adidas"
              element={<AdidasActivationRegistration />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/login"
              element={<Login />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/register"
              element={<Registration />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/analysis-shared/:id"
              element={<AnalysisShared />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/exercice/:id"
              element={<ExerciceScreen />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/privacyPolicy"
              element={<PrivacyPolicy />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/termsAndConditions"
              element={<TermsNConditions />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/forgotPassword"
              element={<ForgotPassword />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/how-to-delete-account"
              element={<HowToDeleteAccount />}
              errorElement={<ErrorPage />}
            />
          </Routes>
        </ErrorBoundary>
      );
    }
  }

  const letPath = () => {
    if (navigation?.pathname?.includes('exercice')) {
      return false;
    }
    if (navigation?.pathname?.includes('analysis-shared')) {
      return false;
    }
    if (navigation?.pathname?.includes('adidas')) {
      return false;
    } else return true;
  };

  const enableSide = () => {
    if (navigation?.pathname?.includes('ochy-adidas')) {
      return false;
    }
    if (navigation?.pathname === '/leaderboard') {
      return false;
    } else return true;
  };

  if (isMobile && letPath()) {
    setTimeout(() => {
      window.open('https://ochy.page.link/kgNQ');
    }, 1000);
    return <MobileRedirection />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        {userSlice?.registered === true && enableSide() ? (
          <>
            <Header isAuth={user ? true : false} />
            <NavigationPanel />
          </>
        ) : null}
        <div
          style={
            userSlice?.registered === true && enableSide()
              ? styles.containerAuth
              : styles.container
          }>
          {authRoute()}
        </div>
      </ThemeProvider>
    );
  }
}

const styles = {
  containerAuth: {
    width: '87.5%',
    float: 'right',
    marginTop: '0.5%',
    borderRadius: 10,
    minHeight: '92vh',
    position: 'relative',
    backgroundColor: colors.BACKGROUND,
  },
  container: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: colors.BACKGROUND,
  },
};
