import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  userID: 'Unknown',
  emailAddress: 'Unknown',
  fullName: 'Unknown',
  phoneNumber: '',
  screenName: '',
  userType: 'Unknown',
  typology: 'Unknown',
  photo: '',
  hubspotId: null,
  height: null,
  weight: null,
  gender: null,
  onBoarded: false,
  registered: false,
  completed: false,
  activated: false,
  loggedIn: false,
  lstSubscriptions: [],
  lstGoal: [],
  lstVideos: [],
  lstSharedVideos: [],
  specialization: [],
  lstReports: {},
  lstBest: {},
  dataLoaded: false,
  brandName: '',
  brandLogo: '',
  settings: {},
  onlyUserType: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserID: (state, action) => {
      state.userID = action?.payload;
    },
    setEmailAddress: (state, action) => {
      state.emailAddress = action?.payload;
    },
    setFullName: (state, action) => {
      state.fullName = action?.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action?.payload;
    },
    setOnlyUserType: (state, action) => {
      state.onlyUserType = action?.payload;
    },
    setScreenName: (state, action) => {
      state.screenName = action?.payload;
    },
    setUserType: (state, action) => {
      state.userType = action?.payload;
    },
    setTypology: (state, action) => {
      state.typology = action?.payload;
    },
    setPhoto: (state, action) => {
      state.photo = action?.payload;
    },
    setHubspotId: (state, action) => {
      state.hubspotId = action?.payload;
    },
    setHeight: (state, action) => {
      state.height = action?.payload;
    },
    setWeight: (state, action) => {
      state.weight = action?.payload;
    },
    setGender: (state, action) => {
      state.gender = action?.payload;
    },
    setOnBoarded: (state, action) => {
      state.onBoarded = action?.payload;
    },
    setRegistered: (state, action) => {
      state.registered = action?.payload;
    },
    setCompleted: (state, action) => {
      state.completed = action?.payload;
    },
    setActivated: (state, action) => {
      state.activated = action?.payload;
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action?.payload;
    },
    setLstSubscriptions: (state, action) => {
      state.lstSubscriptions = action?.payload;
    },
    setLstGoal: (state, action) => {
      state.lstGoal = action?.payload;
    },
    setLstVideos: (state, action) => {
      state.lstVideos = action?.payload;
    },
    setLstSharedVideos: (state, action) => {
      state.lstSharedVideos = action?.payload;
    },
    setLstSpecialization: (state, action) => {
      state.specialization = action?.payload;
    },
    setLstReports: (state, action) => {
      state.lstReports = action?.payload;
    },
    setLstBest: (state, action) => {
      state.lstBest = action?.payload;
    },
    setDataLoaded: (state, action) => {
      state.dataLoaded = action?.payload;
    },
    setBrandName: (state, action) => {
      state.brandName = action?.payload;
    },
    setBrandLogo: (state, action) => {
      state.brandLogo = action?.payload;
    },
    setSettings: (state, action) => {
      state.settings = action?.payload;
    },
    updateProfileStore: (state, action) => {
      // check de si on a un action?.payload si oui on change sinon on change pas
      //Mauvais update profile store
      if (action?.payload?.length === 0) return;
      state.userID = action?.payload?.userID
        ? action?.payload?.userID
        : state?.userID;
      state.completed = action?.payload?.completed
        ? action?.payload?.completed
        : state?.complete;
      state.gender = action?.payload?.gender
        ? action?.payload.gender
        : state?.gender;
      state.goal = action?.payload?.goal ? action?.payload.goal : state?.goal;
      state.height = action?.payload?.height
        ? action?.payload.height
        : state?.height;
      state.weight = action?.payload?.weight
        ? action?.payload.weight
        : state?.weight;
      state.fullName = action?.payload?.fullName
        ? action?.payload.fullName
        : state?.fullName;
      state.userType = action?.payload?.userType
        ? action?.payload.userType
        : state?.userType;
      state.typology = action?.payload?.typology
        ? action?.payload.typology
        : state?.typology;
      state.onBoarded =
        action?.payload?.onBoarded === true ||
        action?.payload?.onBoarded === false
          ? action?.payload.onBoarded
          : state?.onBoarded;
      state.registered =
        action?.payload?.registered === true ||
        action?.payload?.registered === false
          ? action?.payload.registered
          : state?.registered;
      state.specialization = action?.payload?.specialization
        ? action?.payload.specialization
        : state?.specialization;
      state.photo = action?.payload?.photo
        ? action?.payload.photo
        : state?.photo;
      state.hubspotId = action?.payload?.hubspotId
        ? action?.payload?.hubspotId
        : state?.hubspotId;
      state.emailAddress = action?.payload?.emailAddress
        ? action?.payload.emailAddress
        : state?.emailAddress;
      state.brandName = action?.payload?.brandName
        ? action?.payload.brandName
        : state?.brandName;
      state.brandLogo = action?.payload?.brandLogo
        ? action?.payload.brandLogo
        : state?.brandLogo;
      state.settings = action?.payload?.settings
        ? action?.payload.settings
        : state?.settings;
      state.onlyUserType = action?.payload?.onlyUserType
        ? action?.payload.onlyUserType
        : state?.onlyUserType;
    },

    resetProfileStore: (state, action) => {
      state.userID = 'Unknown';
      state.emailAddress = 'Unknown';
      state.fullName = 'Unknown';
      state.phoneNumber = '';
      state.screenName = '';
      state.userType = 'Unknown';
      state.typology = 'Unknown';
      state.photo = '';
      state.hubspotId = null;
      state.height = null;
      state.weight = null;
      state.gender = null;
      state.onBoarded = false;
      state.registered = false;
      state.completed = false;
      state.activated = false;
      state.loggedIn = false;
      state.lstSubscriptions = [];
      state.lstGoal = [];
      state.lstVideos = [];
      state.lstSharedVideos = [];
      state.specialization = [];
      state.lstReports = {};
      state.lstBest = {};
      state.dataLoaded = false;
      state.brandName = '';
      state.brandLogo = '';
      state.settings = {};
      state.onlyUserType = [];
    },
  },
});

export const {
  setUserID,
  setEmailAddress,
  setFullName,
  setPhoneNumber,
  setOnlyUserType,
  setScreenName,
  setUserType,
  setTypology,
  setPhoto,
  setHubspotId,
  setHeight,
  setWeight,
  setGender,
  setOnBoarded,
  setRegistered,
  setCompleted,
  setActivated,
  setLoggedIn,
  setLstSubscriptions,
  setLstGoal,
  setLstVideos,
  setLstSharedVideos,
  setLstSpecialization,
  setLstReports,
  setLstBest,
  setDataLoaded,
  setSettings,
  updateProfileStore,
  resetProfileStore,
} = userSlice.actions;

export const selectUserID = state => state.user.userID;
export const selectEmailAddress = state => state.user.emailAddress;
export const selectFullName = state => state.user.fullName;
export const selectPhoneNumber = state => state.user.phoneNumber;
export const selectScreenName = state => state.user.screenName;
export const selectUserType = state => state.user.userType;
export const selectTypology = state => state.user.typology;
export const selectPhoto = state => state.user.photo;
export const selectHubspotId = state => state.user.hubspotId;
export const selectHeight = state => state.user.height;
export const selectWeight = state => state.user.weight;
export const selectGender = state => state.user.gender;
export const selectOnBoarded = state => state.user.onBoarded;
export const selectRegistered = state => state.user.registered;
export const selectCompleted = state => state.user.completed;
export const selectActivated = state => state.user.activated;
export const selectLoggedIn = state => state.user.loggedIn;
export const selectLstSubscriptions = state => state.user.lstSubscriptions;
export const selectLstGoal = state => state.user.lstGoal;
export const selectLstVideos = state => state.user.lstVideos;
export const selectLstSharedVideos = state => state.user.lstSharedVideos;
export const selectLstSpecialization = state => state.user.specialization;
export const selectLstReports = state => state.user.lstReports;
export const selectLstBest = state => state.user.lstBest;
export const selectDataLoaded = state => state.user.dataLoaded;
export const selectUser = state => state.user;
export const selectBrandName = state => state.user.brandName;
export const selectBrandLogo = state => state.user.brandLogo;
export const selectSettings = state => state.user.settings;
export const selectOnlyUserType = state => state.user.onlyUserType;

export default userSlice.reducer;
