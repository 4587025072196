import React, { useContext } from "react";
import PendingAthleteCard from "./PendingAthleteCard";
import { useSelector } from "react-redux";
import { selectListItems, selectListItemsPending } from "slices/userItemsSlice";
import NoAthlete from "components/template/NoAthlete";

function AthletesListPending() {
  const pendingList = useSelector(selectListItemsPending);

  return (
    <>
      {pendingList?.length === 0 ? (
        <div style={{ marginLeft: "1.5%", marginTop: "2%" }}>
          <NoAthlete noButton={true} />
        </div>
      ) : (
        <div style={{ width: "98%", margin: "auto", marginTop : "2%" }}>
          <div
            style={{
              display: "grid",
              alignItems: "center",
              gridTemplateColumns: "repeat(auto-fit, minmax(10rem, 33rem))",
              gridGap: 20,
              marginTop: 20,
              width: "99%",
            }}
          >
            {pendingList?.map((item, index) => {
              return <PendingAthleteCard key={index} item={item} />;
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default AthletesListPending;
