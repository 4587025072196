import React, {forwardRef, useImperativeHandle, useState} from 'react';

import SidePanArrowComponent from 'components/atoms/SidePanArrowComponent';

import './styles/SidePan.css';

const SidePan = forwardRef(
  ({children, onButtonPressed, fullWidth = false}, ref) => {
    const [showPanel, setShowPanel] = useState(false);

    useImperativeHandle(ref, () => ({
      handlePanelVisibility() {
        setShowPanel(!showPanel);
      },
      open() {
        setShowPanel(true);
      },
      close() {
        setShowPanel(false);
      },
      getShowPanel() {
        return showPanel;
      },
    }));

    return (
      <>
        <div
          className={`side-panel-right-hide ${
            showPanel ? 'side-panel-right-hide--open' : ''
          }`}
          style={{zIndex: 100, width: fullWidth ? '90%' : '30%'}}>
          {showPanel && (
            <div style={styles.right}>
              <SidePanArrowComponent
                onPress={onButtonPressed}
                isOpen={showPanel}
              />
            </div>
          )}
          <div className="side-panel-content">{children}</div>
        </div>
      </>
    );
  },
);
const styles = {
  right: {
    left: -35,
    position: 'absolute',
    top: '10%',
  },
  left: {
    right: -35,
    position: 'absolute',
    top: '10%',
  },
};

export default SidePan;
