import React from "react";

import TextComponent from "components/atoms/TextComponent";
import SettingsCategory from "./SettingsCategory";

import { translate } from "services/remoteConfig";

import { checkUserPermission } from "utils/utils";

import { BOLD, H4 } from "constant/constants";

function Settings({ config, onClick }) {

    return (
        <div style={styles.container}>
            <TextComponent text={translate('SettingsTitle')} variant={H4} weight={BOLD} />
            {config?.map((element, index) => {
                if (element?.permission) {
                    if (!checkUserPermission(element?.permission)) return <></>;
                }
                if (element?.Button)
                    return (
                        <SettingsCategory
                            name={translate(element?.Category)}
                            button={element?.Button}
                            key={index}
                            onClick={onClick}
                        />
                    );
                else return null;
            })}
        </div>
    )

}

const styles = {
    container: {
        width: '85%'
    }
}

export default Settings;