import React from 'react';
import CompareAnalysisSegments from './CompareAnalysisSegments';
import {Button} from '@mui/material';
import TextComponent from 'components/atoms/TextComponent';
import {translate} from 'services/remoteConfig';
import {colors} from 'themes';
import ButtonComponent from 'components/atoms/ButtonComponent';
import {BLUE_BORDER, ORANGE} from 'constant/constants';

function CompareAnalysisScreen({
  analysisData,
  handleVideoFlip,
  handleAnalysisPressed,
}) {
  return (
    <>
      <CompareAnalysisSegments analysisData={analysisData} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 40,
          alignItems: 'center',
        }}>
        <ButtonComponent
          type={BLUE_BORDER}
          title={translate('Flip')}
          onClick={handleVideoFlip}
        />
        <ButtonComponent
          type={ORANGE}
          title={translate('GoToAnalysis')}
          onClick={handleAnalysisPressed}
          sx={{marginLeft: 10}}
        />
      </div>
    </>
  );
}

export default CompareAnalysisScreen;
