import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import userReducer from "slices/userSlice";
import usageReducer from "slices/usageSlice";
import analysisReducer from "slices/analysisSlice";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import userItemsReducer from "slices/userItemsSlice";
import athleteSelectedReducer from "slices/athleteSlice";
import unitySliceReducer from "slices/unitySlice";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "user",
    "usage",
    "analysis",
    "userItems",
    "athleteSelected",
    "unity",
  ],
};

export const rootReducer = combineReducers({
  user: userReducer,
  usage: usageReducer,
  analysis: analysisReducer,
  userItems: userItemsReducer,
  athleteSelected: athleteSelectedReducer,
  unity: unitySliceReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
