import React, { useState } from "react";
import { useSelector } from "react-redux";
import TextComponent from "components/atoms/TextComponent";
import ExistingBrand from "./components/ExistingBrand";
import UpdateBrand from "./components/UpdateBrand";
import { translate } from "services/remoteConfig";
import { colors } from "themes";

function Brand() {
    const { brandName, brandLogo } = useSelector((state) => state?.user);
    const [source, setSource] = useState(brandLogo || null);
    const [modify, setModify] = useState(false);

    return (
        <div style={styles.container}>
            <TextComponent text={translate('YourOrganization')} sx={styles.title} />
            {!modify && brandName && brandLogo && (
                <ExistingBrand brandName={brandName} brandLogo={brandLogo} onClick={() => setModify(true)} />
            )}
            {(!brandName || !brandLogo || modify) && (
                <UpdateBrand
                    source={source}
                    setSource={setSource}
                    setModify={setModify}
                />
            )}
        </div>
    );
}

const styles = {
    container: {
        width: '30%',
        backgroundColor: 'black',
        borderRadius: 10,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        color: colors.TEXT_GRAY,
        marginLeft: '1vw',
        marginTop: '1vh',
        marginBottom: '1vh',
        fontSize: '1.2vw'
    },
}

export default Brand;
