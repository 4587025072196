import React from 'react';
import TextComponent from 'components/atoms/TextComponent';
import {translate} from 'services/remoteConfig';
import {BODY_2, BOLD, H6} from 'constant/constants';
import {colors} from 'themes';
import {ReactComponent as Difficulty} from 'assets/svg/difficulty.svg';
import {ReactComponent as Duration} from 'assets/svg/duration.svg';
import {ReactComponent as Sets} from 'assets/svg/sets.svg';
import {ReactComponent as Reps} from 'assets/svg/reps.svg';

function InfosCard({title, subtitle}) {
  const IconComponent = () => {
    switch (subtitle) {
      case 'Difficulty':
        return <Difficulty />;
      case 'Duration':
        return <Duration />;
      case 'Series':
        return <Sets />;
      default:
        return <Reps />;
    }
  };

  return (
    <div style={styles.card}>
      <div style={styles.content}>
        <TextComponent
          text={title}
          variant={H6}
          sx={styles.title}
          weight={BOLD}
        />
        <TextComponent
          text={translate(subtitle).toLowerCase()}
          variant={BODY_2}
          sx={styles.subtitle}
        />
      </div>
      <div style={styles.iconContainer}>
        <IconComponent style={styles.icon} width={'2vw'} height={'2vh'} />
      </div>
    </div>
  );
}
const styles = {
  card: {
    height: '5vh',
    borderRadius: 17,
    backgroundColor: colors.BLACK,
    borderColor: colors.WHITE_OPACITY_20,
    borderWidth: '1px',
    borderStyle: 'solid',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    position: 'relative',
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    marginTop: '1vh',
    color: colors.YELLOW,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: '1vh',
    color: colors.WHITE_OPACITY_30,
  },
  iconContainer: {
    position: 'absolute',
    top: '1.2vh',
    right: 8,
  },
  icon: {
    fontSize: 24,
  },
};

export default InfosCard;
