import React, {useState} from 'react';

import {Box, Grid} from '@mui/material';

import TextComponent from 'components/atoms/TextComponent';
import RoundedScoreMeter from 'screen/analysis/AnalysisListCard/components/RoundedScoreMeter';
import {segments} from 'screen/auth/registration/onBoarding/constants/onBoardingText';
import ScoreTextWithBackground from 'screen/analysis/AnalysisViewDetailsScreen/CardComponent/components/ScoreTextWithBackground';
import {score_colors} from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';

import {
  USER_LOCALE,
  onBoardingTranslate,
  translate,
} from 'services/remoteConfig';

import {createTextObjectReusable} from 'utils/utils';

import {BODY_2, BOLD, H3, H5} from 'constant/constants';

import {colors} from 'themes';

function OnBoardingCoachAnalysisSegments() {
  const [selectedSegment, setSelectedSegment] = useState(0);
  return (
    <>
      <TextComponent
        multipleText={createTextObjectReusable(
          onBoardingTranslate('CoachOchyAnalysisIntro'),
          {
            variant: H5,
            weight: BOLD,
          },
          {
            variant: H5,
            weight: BOLD,
            style: {textAlign: 'center', color: colors.SECONDARY},
          },
        )}
        center={true}
        variant={H3}
        weight={BOLD}
      />
      <Grid container spacing={2} style={{marginTop: 20}}>
        {segments.map((segment, index) => (
          <Grid item xs={8} sm={5} md={4} lg={4} key={index}>
            <Box
              sx={{
                borderRadius: 2,
                paddingBottom: 1,
                backgroundColor:
                  selectedSegment === index ? colors.WHITE_OPACITY_10 : '',
                '&:hover': {
                  backgroundColor: colors.WHITE_OPACITY_10,
                  cursor: 'pointer',
                },
              }}
              onClick={() => setSelectedSegment(index)}>
              <div style={styles.roundedScoreContainer}>
                <RoundedScoreMeter
                  scoreNumber={segment.scoreNumber}
                  scoreText={segment.score_text}
                  item={segment.item}
                  size={100}
                />
              </div>
              <div>
                <TextComponent
                  text={onBoardingTranslate(segment?.text)}
                  sx={{textAlign: 'center'}}
                />
                <TextComponent
                  text={onBoardingTranslate(segment?.explanation)}
                  variant={BODY_2}
                  sx={styles.explanation}
                />
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
      <div style={styles.container} />
      <div style={styles.textContainer}>
        <TextComponent
          text={onBoardingTranslate(segments[selectedSegment]?.text)}
          sx={{textAlign: 'center', marginBottom: 2}}
        />
        <div style={styles.segmentContainer}>
          <div style={{width: '30%'}}>
            <TextComponent
              text={
                segments[selectedSegment]?.desc?.angle
                  ? segments[selectedSegment]?.desc?.angle + '°'
                  : ''
              }
              sx={{
                textAlign: 'center',
                color:
                  score_colors[segments[selectedSegment]?.score_text]?.stroke,
              }}
              variant={H3}
              weight={BOLD}
            />
          </div>
          <div style={styles.scoreContainer}>
            <div style={{display: 'flex'}}>
              <RoundedScoreMeter
                scoreNumber={segments[selectedSegment].scoreNumber}
                scoreText={segments[selectedSegment].score_text}
                item={segments[selectedSegment].item}
                size={100}
              />
            </div>
            <ScoreTextWithBackground
              scoreNumber={segments[selectedSegment].scoreNumber}
            />
          </div>

          <div style={{width: '30%'}}>
            <TextComponent
              text={translate('YourPosition')}
              sx={styles.yourPosition}
            />
            <TextComponent
              text={onBoardingTranslate(segments[selectedSegment]?.explanation)}
              sx={styles.explanationPerSegment}
            />
          </div>
        </div>
        <TextComponent text={segments[selectedSegment]?.desc[USER_LOCALE]} />
      </div>
    </>
  );
}
const styles = {
  container: {
    height: 1,
    backgroundColor: colors.WHITE_OPACITY_40,
    margin: 'auto',
    marginBottom: 0,
    marginTop: 40,
    width: '95%',
  },
  explanation: {
    color: colors.WHITE_OPACITY_60,
    fontSize: 12,
    textAlign: 'center',
  },
  explanationPerSegment: {
    float: 'right',
    textAlign: 'right',
  },
  roundedScoreContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  scoreContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
  },
  segmentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  textContainer: {
    backgroundColor: colors.BLACK,
    borderRadius: 10,
    margin: 'auto',
    marginTop: 20,
    padding: 10,
    width: '98%',
  },
  yourPosition: {
    color: colors.WHITE_OPACITY_60,
    textAlign: 'right',
  },
};
export default OnBoardingCoachAnalysisSegments;
