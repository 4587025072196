import React from "react";

import TextComponent from "./TextComponent";

import { colors } from "themes";

import { BODY_2 } from "constant/constants";

function ErrorMessage({ message }) {
  if (message)
    return <TextComponent sx={styles.error} variant={BODY_2} text={message} />;
}

const styles = {
  error: {
    color: colors.ERROR,
    margin: 0,
  },
};

export default ErrorMessage;
