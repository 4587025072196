import React from "react";

import AddAthleteCustom from "./components/AddAthleteCustom";
import "./components/styles/AddAthlete.css";

import addAthleteCustom from "assets/images/addAthleteCustom.png";

function AddAthlete() {

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            backgroundImage: `url(${addAthleteCustom})`,
            opacity: 1,
            position: "relative",
          }}
          className="add-athlete-container"
        >
          <div
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              height: "100%",
              width: "100%",
              position: "absolute",
              Zindex: 2,
            }}

          ></div>
          <div
            style={{
              minHeight: "50vh",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              width: "80%",
              borderRadius: 10,
              marginBottom: "8vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 3,
            }}
          >
            <AddAthleteCustom />
          </div>
        </div>    </div>
    </div>
  );
}

export default AddAthlete;
