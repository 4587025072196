import { Button } from "@mui/material";
import TextComponent from "components/atoms/TextComponent";
import { CONTAINED, H4, ORANGE } from "constant/constants";
import React from "react";
import { translate } from "services/remoteConfig";
import { colors } from "themes";

function MobileRedirection() {
  return (
    <div style={styles.container}>
      <TextComponent text={translate("MobileRedirection")} variant={H4} sx={{textAlign : 'center'}}/>
      <Button
        variant={CONTAINED}
        style={styles.button}
        href="https://ochy.page.link/kgNQ"
        sx={{
          backgroundColor:  colors.SECONDARY,
          "&:hover": {
            backgroundColor: colors.OCHY_BLUE,
            transition: "0.2s ease-in-out",
          },
        }}
      >
        {translate("GoInApp")}
      </Button>
    </div>
  );
}

const styles = {
  button: {
    textTransform: "none",
    color: colors.WHITE,
    height: 45,
    marginTop: "5rem",
    borderRadius: 10,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height : "90vh"
  },
  title: {
    alignSelf: "center",
    marginBottom: 3,
    marginTop: "10%",
    textAlign: "center",
  },
};

export default MobileRedirection;
