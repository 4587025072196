import React, {useEffect, useState} from 'react';
import {colors} from 'themes';
import TextComponent from './TextComponent';
import {BODY_2} from 'constant/constants';
import {Box, FormControl, FormHelperText, Input} from '@mui/material';

function BlackTextInput({
  label,
  name,
  value: propValue,
  error,
  helperText,
  onChange,
  shema,
  type,
  image,
  focusedImage,
  handleSubmit,
  id,
  title,
  border = false,
}) {
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      if (handleSubmit) handleSubmit();
    }
  }

  function handleChange(event) {
    setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  }
  return (
    <>
      <TextComponent sx={styles.title} variant={BODY_2} text={title} />
      <div style={styles.container}>
        <Box>
          <FormControl
            variant="standard"
            fullWidth
            shema={shema}
            error={error}
            sx={{
              ...styles.formContainer,
              ...{
                border: error
                  ? `2px solid ${colors.ERROR}`
                  : border
                  ? `2px solid ${colors.GREEN_LIGHT}`
                  : 'none',
              },
            }}>
            <Input
              id={id}
              style={type === 'longtext' ? styles.inputLongtext : styles.input}
              value={value}
              onChange={handleChange}
              name={name}
              type={type}
              helperText={helperText}
              disableUnderline={true}
              multiline={type === 'longtext' ? true : false}
              placeholder={label}
              onKeyDown={handleKeyDown}
            />
          </FormControl>
        </Box>
        <FormHelperText sx={styles.error}>
          {error && helperText ? helperText : ''}
        </FormHelperText>
      </div>
    </>
  );
}

const styles = {
  container: {
    width: '100%',
  },
  formContainer: {
    backgroundColor: colors.BLACK,
    borderRadius: 3,
  },
  input: {
    height: '3.5rem',
    color: colors.WHITE,
    marginLeft: '7px',
  },
  inputLongtext: {
    minHeight: '6.5rem',
    color: colors.WHITE,
    marginLeft: '7px',
    textAlign: 'left',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '95%',
  },
  image: {
    marginRight: '7px',
    cursor: 'pointer',
  },
  imageFocused: {
    marginRight: '7px',
  },
  title: {
    fontWeight: 'bold',
    marginLeft: '7px',
    marginBottom: 0.2,
  },
  error: {
    color: colors.ERROR,
  },
};

export default BlackTextInput;
