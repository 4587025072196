import React, {useEffect, useState} from 'react';
import './styles/AdidasActivationRegistrationSuccess.css';
import TextComponent from 'components/atoms/TextComponent';
import {BOLD, H2, H4, H5, ORANGE} from 'constant/constants';
import {colors, images} from 'themes';
import {useLocation} from 'react-router-dom';

import Lottie from 'lottie-react';
import fireworks from 'assets/animations/fireworks.json';
import ButtonComponent from 'components/atoms/ButtonComponent';
import FadinView from 'components/atoms/FadinView';
import {ReactComponent as Close} from 'assets/svg/close.svg';
import {isMobile, isTablet} from 'react-device-detect';
import {mpIdentify, mpTrack} from 'services/mixpanel';
import {translate} from 'services/remoteConfig';
import {initProjects} from 'services/projectApi';
import {consumePromoCodeAndAddInUsage} from 'services/promoCodeApi';
import {addCredit, addQuarterlyPlan} from 'services/usageApi';

function AdidasActivationRegistrationSuccess() {
  const location = useLocation();
  const {promoCode} = location.state || {};
  const [lottieVisible, setLottieVisible] = useState(true);
  const [promoCodeData, setPromoCodeData] = useState(null);

  const [animationFinished, setAnimationFinished] = useState(false);

  function handleAnimationFinished() {
    setAnimationFinished(true);
  }

  async function handlePromoCode() {
    initProjects();
    if (localStorage?.getItem(`promoCodeActivated${promoCode}`)) {
      return;
    }
    const promocodeConsumed = await consumePromoCodeAndAddInUsage(promoCode);
    if (promocodeConsumed?.success === true) {
      setPromoCodeData(promocodeConsumed?.data);
      if (
        promocodeConsumed?.data?.type === 'CREDIT' &&
        promocodeConsumed?.data?.creditGifted > 0
      ) {
        const creditAdded = await addCredit(
          promocodeConsumed?.data?.creditGifted,
        );
        if (creditAdded) {
          localStorage?.setItem(`promoCodeActivated${promoCode}`, true);
          mpIdentify();
          mpTrack('Adidas Promo Code Entered');
        }
      } else {
        const quarterlyPlanAdded = await addQuarterlyPlan();
        if (quarterlyPlanAdded) {
          //localStorage?.setItem(`promoCodeActivated${promoCode}`, true);
          mpIdentify();
          mpTrack('Adidas Promo Code Entered');
        }
      }
    }
  }

  useEffect(() => {
    handlePromoCode();
  }, []);

  useEffect(() => {
    if (animationFinished) {
      setTimeout(() => {
        setLottieVisible(false);
      }, 3000);
    }
  }, [animationFinished]);

  return (
    <FadinView>
      <div className="green-bar-container">
        <div
          style={{
            backgroundColor: colors.BLACK_OPACITY_70,
            height: '100%',
            width: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <div className="green-bar" onAnimationEnd={handleAnimationFinished} />
          <div style={{zIndex: 2}}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 20,
              }}
              className="">
              <img
                src={images.OCHY_LOGO_VERTICAL_WHITE}
                alt={'Ochy white logo'}
                style={{width: 160}}
                className=""
              />
              <Close stroke="white" className="cross" />
              <img
                src={images.ADIDAS_LOGO_WHITE}
                style={{width: 160}}
                alt={'Adidas logo partnership'}
                className=""
              />
            </div>
            {animationFinished ? (
              <FadinView>
                <TextComponent
                  text={translate('Success')}
                  variant={H2}
                  weight={BOLD}
                  sx={{
                    color: colors.GREEN_FLASH,
                    zIndex: 1,
                    textAlign: 'center',
                    padding: 2,
                  }}
                />
                {lottieVisible && (
                  <Lottie
                    animationData={fireworks}
                    style={{
                      position: 'absolute',
                      bottom: isMobile || isTablet ? '0' : '25%',
                      left: isMobile || isTablet ? '0' : '25%',
                      width: isMobile || isTablet ? '100%' : '50%',
                      height: isMobile || isTablet ? '100%' : '50%',
                      zIndex: -2,
                    }}
                  />
                )}
                <TextComponent
                  text={
                    promoCodeData?.type === 'CREDIT'
                      ? translate('AdidasCreditWelcome')
                      : translate('AdidasWelcome')
                  }
                  variant={H5}
                  weight={BOLD}
                  sx={{
                    color: colors.WHITE,
                    textAlign: 'center',
                    padding: 2,
                  }}
                />
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginTop: 30,
                  }}>
                  <ButtonComponent
                    type={ORANGE}
                    title={translate('GoToMobile')}
                    onClick={() => {
                      mpTrack(
                        'Adidas Activation Success Web App Button Clicked',
                      );
                      window.location.href = 'https://ochy.page.link/kgNQ';
                    }}
                  />
                </div>
              </FadinView>
            ) : (
              <>
                <TextComponent
                  text={translate('AdidasPromoCodeActivation')}
                  variant={H5}
                  weight={BOLD}
                  sx={{
                    color: colors.WHITE,
                    textAlign: 'center',
                    padding: 2,
                  }}
                />
                <TextComponent
                  text={promoCode}
                  variant={H4}
                  weight={BOLD}
                  sx={{
                    color: colors.GREEN,
                    zIndex: 1,
                    textAlign: 'center',
                    padding: 2,
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </FadinView>
  );
}

export default AdidasActivationRegistrationSuccess;
