import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import FadinView from 'components/atoms/FadinView';
import TextComponent from 'components/atoms/TextComponent';
import OnBoardingButtonList from 'screen/auth/registration/onBoarding/components/OnBoardingButtonList';
import OnBoardingBottomArrow from 'screen/auth/registration/onBoarding/components/OnBoardingBottomArrow';

import {onBoardingTranslate} from 'services/remoteConfig';

import {createTextObjectReusable} from 'utils/utils';
import {onBoardingAthleteTypology} from 'screen/auth/registration/onBoarding/constants/onBoardingButtonData';
import {BOLD, H4} from 'constant/constants';

import {colors} from 'themes';
import {mpTrack} from 'services/mixpanel';

function OnBoardingAthleteTypology() {
  const navigate = useNavigate();

  function handleGoback() {
    navigate('/registration/user-number');
  }

  useEffect(() => {
    mpTrack('Web App OnBoarding Athlete Typology Selection Screen Viewed');
  }, []);

  return (
    <FadinView>
      <div style={styles.container}>
        <div style={styles.titleContainer}>
          <TextComponent
            multipleText={createTextObjectReusable(
              onBoardingTranslate('WelcomingUserLastText'),
              {
                variant: H4,
                weight: BOLD,
              },
              {
                variant: H4,
                weight: BOLD,
                style: {textAlign: 'center', color: colors.SECONDARY},
              },
            )}
            center={true}
          />
        </div>
        <div style={styles.buttonContainer}>
          <OnBoardingButtonList data={onBoardingAthleteTypology} />
        </div>
      </div>
      <OnBoardingBottomArrow previous={true} onPress={handleGoback} />
    </FadinView>
  );
}

const styles = {
  buttonContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-evenly',
    paddingTop: '5%',
    width: '100%',
  },

  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5%',
    width: '100%',
  },
};

export default OnBoardingAthleteTypology;
