import HeightTextInput from 'components/molecules/HeightTextInput';
import WeightTextInput from 'components/molecules/WeightTextInput';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectAthleteSelected} from 'slices/athleteSlice';
import {selectUnityType, setUnity, setUnityType} from 'slices/unitySlice';
import {feetInchToCm, kgToLbs, lbsToKg, mToftIn} from 'utils/converter';
import {EN, FR} from 'constant/constants';

function HeightAndWeightTextInput({heightRef, weightRef}) {
  const heightUnityRef = useRef();
  const weightUnityRef = useRef();

  const unityType = useSelector(selectUnityType);
  const dispatch = useDispatch();

  const [displayInTextInput, setDisplayInTextInput] = useState(
    unityType === FR ? false : true,
  );

  const athleteSlice = useSelector(selectAthleteSelected);

  useEffect(() => {
    if (Object.keys(athleteSlice).length === 0) return;
    if (displayInTextInput) {
      weightRef?.current.setWeight(kgToLbs(athleteSlice?.weight)?.toFixed(0));
      const height = mToftIn(athleteSlice?.height);
      heightRef?.current?.setHeightAndCompl(height?.ft, height?.inch);
    } else {
      weightRef?.current.setWeight(athleteSlice?.weight?.toFixed(0));
      heightRef?.current.setHeight((athleteSlice?.height * 100)?.toFixed(0));
    }
  }, [displayInTextInput]);

  function convertHeightCM() {
    const heightInM = parseFloat(heightRef?.current?.getHeight()) / 100;
    const height = mToftIn(heightInM);
    heightRef?.current?.setHeightAndCompl(height?.ft, height?.inch);
  }

  function convertHeightFT() {
    const heightFT = parseFloat(heightRef?.current?.getHeight());
    const heightcmplFT = parseFloat(heightRef?.current?.getHeightComplement());
    const height = feetInchToCm(heightFT, heightcmplFT) * 100;
    heightRef?.current?.setHeight(height.toFixed(0));
  }

  function handleValueChanges(value) {
    if (value === 'ft') {
      weightUnityRef?.current?.setValue('lbs');
      setDisplayInTextInput(true);
      dispatch(setUnityType(EN));
      dispatch(setUnity(EN));
      convertHeightCM();
      weightRef?.current?.setWeightClear(
        kgToLbs(weightRef?.current?.getWeight())?.toFixed(0),
      );
    } else if (value === 'cm') {
      weightUnityRef?.current?.setValue('kg');
      setDisplayInTextInput(false);
      dispatch(setUnityType(FR));
      dispatch(setUnity(FR));
      convertHeightFT();
      weightRef?.current?.setWeightClear(
        lbsToKg(weightRef?.current?.getWeight())?.toFixed(0),
      );
    } else if (value === 'lbs') {
      heightUnityRef?.current?.setValue('ft');
      setDisplayInTextInput(true);
      dispatch(setUnityType(EN));
      dispatch(setUnity(EN));
      convertHeightCM();
      weightRef?.current?.setWeightClear(
        kgToLbs(weightRef?.current?.getWeight())?.toFixed(0),
      );
    } else if (value === 'kg') {
      heightUnityRef?.current?.setValue('cm');
      setDisplayInTextInput(false);
      dispatch(setUnityType(FR));
      dispatch(setUnity(FR));
      convertHeightFT();
      weightRef?.current?.setWeightClear(
        lbsToKg(weightRef?.current?.getWeight())?.toFixed(0),
      );
    }
  }
  return (
    <div style={{display: 'flex'}}>
      <HeightTextInput
        displayInTextInput={displayInTextInput}
        handleValueChanges={handleValueChanges}
        heightUnityRef={heightUnityRef}
        ref={heightRef}
        idNext="weightTextInput"
      />
      <div style={{width: 20}}></div>
      <WeightTextInput
        displayInTextInput={displayInTextInput}
        handleValueChanges={handleValueChanges}
        weightUnityRef={weightUnityRef}
        ref={weightRef}
      />
    </div>
  );
}

export default HeightAndWeightTextInput;
