import TextComponent from 'components/atoms/TextComponent';
import { BODY_2, BOLD, H6, MEDIUM } from 'constant/constants';
import React, { useState, useEffect } from 'react';
import { translate } from 'services/remoteConfig';
import OverstrideContent from './OverstrideContent';
import ImageIdealPositionDouble from './ImageIdealPositionDouble';
import Ruler from './Ruler';
import Carrousel from 'components/molecules/Carrousel';
import BodyContainerContentRecapDisplay from './BodyContainerContentRecapDisplay';
import ExerciceContainer from 'screen/analysis/exercices/ExerciceContainer';

function AnalysisRightPanelUpdateDetail({
  displayData,
  analysisTexts,
  exercices,
  version,
  overstrideData,
  locked,
  modalRef,
  exercicesLocked,
}) {
  const [key, setKey] = useState(0);
  const data = [
    { recap: displayData?.recap, score: displayData?.score },
    {
      title: translate('Consequences'),
      text: displayData?.segmentedExplanation?.consequences,
      score: displayData?.score,
    },
    {
      title: translate('Correction'),
      text: displayData?.segmentedExplanation?.correction,
      score: displayData?.score,
    },
    {
      title: translate('CoachTips'),
      text: displayData?.segmentedExplanation?.coach_tips,
      score: displayData?.score,
    },
  ];
  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [displayData]);

  if (displayData?.type === 'RightAnkle') {
    return (
      <div
        style={{
          ...{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          ...(locked && exercicesLocked ? styles.lockedContainer : null),
        }}>
        <div
          style={{
            width: '85%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: 40,
          }}>
          <TextComponent
            text={displayData?.text1}
            weight={BOLD}
            sx={{ marginBottom: 2 }}
            variant={H6}
          />
          <img
            src={displayData?.image}
            alt="ankle"
            style={{ width: '70%', alignSelf: 'center', marginBottom: 30 }}
          />

          <TextComponent text={displayData?.text2} variant={BODY_2} />
        </div>
        <div
          style={{
            width: '95%',
            border: '1px dashed rgba(255, 255, 255, 0.1)',
            marginTop: 40,
            marginBottom: 40,
          }}
        />
        <OverstrideContent overstrideData={overstrideData} />
      </div>
    );
  }
  return (
    <div
      style={{
        ...{ display: 'flex', flexDirection: 'column' },
        ...(locked && exercicesLocked ? styles.lockedContainer : null),
      }}>
      <ImageIdealPositionDouble
        analysisKey={displayData?.analysisKey}
        angle={displayData?.angle}
      />
      <div
        style={{
          marginTop: 50,
          width: '85%',
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          position: 'relative',
          marginBottom: 30,
        }}>
        <TextComponent
          text={translate('Angles')}
          weight={MEDIUM}
          variant={H6}
        />
        <Ruler
          angle={displayData?.angle}
          rangeGood={displayData?.rangeGood}
          rangeOkay={displayData?.rangeOkay}
        />
      </div>
      <div style={{ marginBottom: -40 }}>
        <Carrousel data={data} key={key}>
          <BodyContainerContentRecapDisplay />
        </Carrousel>
      </div>

      <div style={{ marginBottom: 50 }}>
        <div style={{ marginLeft: 20 }}>
          <TextComponent
            text={translate('Exercice')}
            weight={MEDIUM}
            variant={H6}
          />
        </div>
        <ExerciceContainer
          data={displayData}
          exercices={exercices}
          analysisTexts={analysisTexts}
          version={version}
          locked={exercicesLocked}
          modalRef={modalRef}
        />
      </div>
    </div>
  );
}

const styles = {
  lockedContainer: {
    filter: 'blur(30px)',
    pointerEvents: 'none',
    userSelect: 'none',
  },
};

export default AnalysisRightPanelUpdateDetail;
