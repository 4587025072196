import React from "react";

import { ReactComponent as HeadIcon } from "assets/svg/headIcon.svg";
import { ReactComponent as TrunkIcon } from "assets/svg/backIcon.svg";
import { ReactComponent as ArmIcon } from "assets/svg/armsIcon.svg";
import { ReactComponent as FrontLegIcon } from "assets/svg/leg-frontIcon.svg";
import { ReactComponent as BackLegIcon } from "assets/svg/leg-backIcon.svg";
import { ReactComponent as ScoreOrange } from "assets/svg/score_orange.svg";
import { colors } from "themes";

function OrangeScoreMeter({item, size}) {
  function getScoreIcon() {
    return (
      <ScoreOrange
        height={size}
        width={size}
        style={styles.score}
      />
    );
  }

  function svgStyle() {
    return {
      width: size / 2.5,
      height: size / 2.5,
      position: 'absolute',
      alignSelf: 'center',
    };
  }
  function getBodyPart(item) {
    if (item === 'head' || item === 'RightEar') {
      return (
        <HeadIcon
          fill={'#5B2D17'}
          stroke={colors.SECONDARY}
          style={{ ...svgStyle(), height: size / 3, width: size / 3 }}
        />
      );
    }
    if (item === 'trunk' || item === 'RightHip') {
      return (
        <TrunkIcon
          fill={'#5B2D17'}
          stroke={colors.SECONDARY}
          strokeWidth={2}
          style={svgStyle()}
        />
      );
    }
    if (item === 'arm' || item === 'RightElbow') {
      return (
        <ArmIcon
          fill={'#5B2D17'}
          stroke={colors.SECONDARY}
          strokeWidth={2}
          style={{ ...svgStyle(), height: size / 2 - 7, width: size / 2 - 7 }}
        />
      );
    }
    if (item === 'forwardCycle' || item === 'RightKnee') {
      return (
        <FrontLegIcon
          fill={'#5B2D17'}
          stroke={colors.SECONDARY}
          strokeWidth={2}
          style={svgStyle()}
        />
      );
    }
    if (item === 'backCycle' || item === 'LeftKnee') {
      return (
        <BackLegIcon
          fill={'#5B2D17'}
          stroke={colors.SECONDARY}
          strokeWidth={2}
          style={svgStyle()}
        />
      );
    }
    return <></>;
  }

  return (
    <div style={styles.container}>
      {getScoreIcon()}
      {getBodyPart(item)}
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  score: {
    alignSelf: "center",
    display: "flex",
  },
  alignCenter: {alignSelf: 'center'},
  positionAbsolute: {position: 'absolute'},
};

export default OrangeScoreMeter;

