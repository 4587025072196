import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
} from "@mui/material";
import TextComponent from "./TextComponent";
import { BODY_2 } from "constant/constants";
import { colors } from "themes";

function TextInput({
  label,
  name,
  value: propValue,
  error,
  helperText,
  onChange,
  shema,
  type,
  image,
  focusedImage,
  handleSubmit,
  id,
  title,
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      if (handleSubmit) handleSubmit();
    }
  }

  function borderColor() {
    if (error) return "2px solid #F4354B";
    else if (isFocused) return "2px solid white";
    else return "1px solid rgb(255, 255, 255, 0.7)";
  }

  function handleChange(event) {
    setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  }

  return (
    <>
      <TextComponent sx={styles.title} variant={BODY_2} text={title} />
      <div style={styles.container}>
        <Box>
          <FormControl
            variant="standard"
            fullWidth
            shema={shema}
            error={error}
            sx={{
              border: borderColor(),
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "rgb(255,255,255, 0.17)",
              },
            }}
          >
            <Input
              id={id}
              style={
                type === "longtext" ? styles.inputLongtext : styles.input
              }
              onFocus={() => {
                setIsFocused(true);
              }}
              onBlur={() => {
                if (!value) {
                  setIsFocused(false);
                }
              }}
              value={value}
              onChange={handleChange}
              name={name}
              type={focusedImage && show ? "text" : type}
              helperText={helperText}
              disableUnderline={true}
              multiline={type === "longtext" ? true : false}
              placeholder={label}
              onKeyDown={handleKeyDown}
              endAdornment={
                <InputAdornment position="end">{imageRender()}</InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <FormHelperText sx={styles.error}>
          {error && helperText ? helperText : ""}
        </FormHelperText>
      </div>
    </>
  );

  function imageRender() {
    if (!image) return null;
    if (focusedImage && isFocused) {
      return (
        <img
          src={show ? focusedImage : image}
          alt="Logo"
          height={30}
          width={30}
          style={styles.image}
          onClick={() => {
            setShow(!show);
          }}
        />
      );
    } else if (isFocused) {
      return (
        <img
          src={image}
          alt="Logo"
          height={30}
          width={30}
          style={styles.imageFocused}
        />
      );
    }
  }
}

const styles = {
  container: {
    width: "100%",
  },
  input: {
    height: "3.5rem",
    color: colors.WHITE,
    marginLeft: "7px",
  },
  inputLongtext: {
    minHeight: "6.5rem",
    color: colors.WHITE,
    marginLeft: "7px",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "95%",
  },
  image: {
    marginRight: "7px",
    cursor: "pointer",
  },
  imageFocused: {
    marginRight: "7px",
  },
  title: {
    fontWeight: "bold",
    marginBottom: 1,
  },
  error: {
    color: colors.ERROR,
  },
};

export default TextInput;
