import React from 'react';

import {EXEMPLE_VIDEO_URL} from 'constant/contentConstant';

import VideoComponent from 'screen/analysis/AnalysisViewDetailsScreen/components/VideoComponent';
import OnBoardingBottomArrow from 'screen/auth/registration/onBoarding/components/OnBoardingBottomArrow';

function OnBoardingAnalysisContainer({
  children,
  handleNextArrowClicked,
  handlePreviousArrowClicked,
}) {
  return (
    <div style={styles.container}>
      <div style={styles.videoContainer}>
        <VideoComponent video={EXEMPLE_VIDEO_URL} speed={1} loop={false} />
      </div>
      <div style={styles.contentContainer}>{children}</div>
      <OnBoardingBottomArrow
        onPress={handlePreviousArrowClicked}
        previous={true}
      />
      <OnBoardingBottomArrow onPress={handleNextArrowClicked} analysis={true} />
    </div>
  );
}

const styles = {
  container: {display: 'flex'},
  contentContainer: {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  videoContainer: {
    width: '75%',
    position: 'sticky',
    top: 10,
  },
};

export default OnBoardingAnalysisContainer;
