import React from "react";

import TextComponent from "components/atoms/TextComponent";

import { translate } from "services/remoteConfig";

import { colors } from "themes";

function ExistingBrand({ brandName, brandLogo, onClick }) {
    return (
        <div style={styles.container}>
            <img style={styles.image} src={brandLogo} alt="brandLogo" />
            <TextComponent text={brandName} sx={styles.brandName} />
            <div style={styles.button} onClick={onClick}>
                <TextComponent text={translate('Edit')} sx={styles.modify} />
            </div>
        </div>
    )
}

const styles = {
    button: {
        height: '10%',
        backgroundColor: colors.WHITE_OPACITY_20,
        borderRadius: 10,
        marginTop: '2vh',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        paddingRight: '1vh',
        paddingLeft: '1vh',
        cursor: 'pointer'
    },
    brandName: {
        color: colors.WHITE,
        marginTop: '3vh',
        fontSize: '1.1vw'
    },
    container: {
        width: '90%',
        display: 'flex',
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '2vh',
        flexDirection: 'column',
        alignItems: 'center'
    },
    image: {
        width: '100%',
        height: '60%',
        borderRadius: '2vh'
    },
    modifiy: {
        color: colors.LIGHT_GRAY,
        fontSize: '0.8vw'
    }
}


export default ExistingBrand;