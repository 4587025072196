import React from 'react';
import { images } from 'themes';

export default function ImageIdealPositionAssets({ keyName }) {
  const source = keyName ? images[keyName] : null;
  if (!source) {
    return null;
  }
  return (
    <img
      src={source}
      style={{ height: '100%', width: '100%' }}
      resizeMode="contain"
      alt="idealImage"
    />
  );
}
