import ButtonComponent from "components/atoms/ButtonComponent";
import TextComponent from "components/atoms/TextComponent";
import { ORANGE } from "constant/constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { translate } from "services/remoteConfig";

const REGISTRATION = "registration";

export default function ErrorPage() {

  const location = useLocation();
  const navigate = useNavigate();
  const userSlice = useSelector((state) => state?.user);
  useEffect(()=>{

    if(location?.pathname?.includes(REGISTRATION) && userSlice?.user?.id !== "Unknown"){
      navigate("/");
    }

  },[])

  return (
    <div style={{display : 'flex', alignItems : 'center', justifyContent : 'center', flexDirection : 'column',height : "90vh", width : '100%'}}>
      <TextComponent variant="h3" text={translate('Error404')}/>
      <TextComponent variant="h4" text={translate('BackToMainScreenClick')} sx={{marginBottom : 10,}}/>
      <ButtonComponent title={translate("BackToMainScreen")} onClick={() => window.location.href = "/"} type={ORANGE} />
      <p>
      </p>
    </div>
  );
}