import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import TextComponent from 'components/atoms/TextComponent';
import RoundedScoreMeter from '../AnalysisListCard/components/RoundedScoreMeter';
import {translate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';
import {BODY_1, H6} from 'constant/constants';
import {colors, images} from 'themes';
import {ReactComponent as ArrowBack} from 'assets/svg/arrow-right-bold.svg';
import {ReactComponent as Lock} from 'assets/svg/lock.svg';
import OrangeScoreMeter from 'components/molecules/OrangeScoreMeter';

function ExerciceCard({
  item,
  analysisTexts,
  locked = false,
  modalRef,
  onBoarding = false,
}) {
  const [improvedSegments, setImprovedSegments] = React.useState([]);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    if (!Array.isArray(analysisTexts)) return;
    const fetchData = async () => {
      const segmentsWithScores = item?.improvedSegments
        ?.map(improvedSegment => {
          const analysisText = analysisTexts?.find(
            text => text?.type === improvedSegment,
          );
          if (analysisText) {
            return {
              type: improvedSegment,
              scoreText: analysisText?.score,
              score_number: analysisText?.score_number,
            };
          }
          return null;
        })
        .filter(Boolean);

      setImprovedSegments(segmentsWithScores);
    };
    fetchData();
  }, [analysisTexts, item]);

  return (
    <div>
      {!locked ? (
        <Link
          onClick={() => {
            onBoarding
              ? mpTrack('Web App OnBoarding Exercices Card Button Clicked')
              : mpTrack('Web App Exercise Card Clicked', {
                  'Card Info': item,
                });
            localStorage.setItem('segmentToWork', item?.segmentToWork);
          }}
          to={`/exercice/${item?.exerciseTitle}`}
          state={{
            item: item,
            improvedSegments: improvedSegments,
            onBoarding: onBoarding,
          }}
          style={styles.link}>
          <div
            style={{
              ...styles.thumbnail,
              backgroundImage: !isHovered
                ? `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${
                    onBoarding ? images[item.image] : item?.image
                  })`
                : `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
                    onBoarding ? images[item.image] : item?.image
                  })`,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            alt="Exercice example">
            <div style={styles.cardContent}>
              <TextComponent
                text={item?.exerciseTitle}
                variant={BODY_1}
                sx={styles.exerciseTitle}
              />
              <TextComponent
                text={translate('Improve') + ':'}
                variant={BODY_1}
                sx={styles.improve}
              />
              <div style={styles.bottomCard}>
                <div style={styles.roundedScore}>
                  {improvedSegments?.length === 0 ? (
                    <>
                      {item?.improvedSegments.map((item, index) => (
                        <OrangeScoreMeter item={item} key={index} size={50} />
                      ))}
                    </>
                  ) : (
                    <>
                      {improvedSegments?.map((segment, index) => (
                        <RoundedScoreMeter
                          item={segment?.type}
                          size={50}
                          key={index}
                          scoreText={segment?.scoreText}
                          scoreNumber={segment?.score_number}
                        />
                      ))}
                    </>
                  )}
                </div>
                <div style={styles.viewMore}>
                  <TextComponent
                    text={translate('ViewMore')}
                    variant={BODY_1}
                    sx={styles.viewMoreText}
                  />
                  <ArrowBack
                    color={colors.WHITE}
                    height={16}
                    width={16}
                    strokeWidth={2}
                    stroke={colors.WHITE}
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <div
          style={styles.thumbnail}
          onClick={() => {
            mpTrack('Web App Exercise Card Locked Button Clicked');
            modalRef?.current?.open('exercice');
          }}>
          <div style={styles.lockedContainer}>
            <Lock height={20} width={20} />
            <TextComponent
              text={translate('UnlockMoreExercises')}
              variant={H6}
              sx={styles.textUnlock}
            />
          </div>
          <div
            style={{
              ...styles.thumbnail,
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${item?.image})`,
              ...styles.blur,
            }}
            alt="Exercice example"
          />
        </div>
      )}
    </div>
  );
}

const styles = {
  blur: {
    filter: 'blur(30px)',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  bottomCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  exerciseTitle: {
    textAlign: 'left',
    marginLeft: '3%',
    marginTop: '3%',
    flex: 1,
  },
  improve: {
    textAlign: 'left',
    color: colors.WHITE_OPACITY_60,
    marginLeft: '3%',
  },
  link: {
    textDecoration: 'none',
  },
  lockedContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
  lockedText: {
    textAlign: 'center',
    fontSize: '1.5rem',
    color: colors.YELLOW,
  },
  roundedScore: {
    display: 'flex',
    alignItems: 'center',
  },
  textUnlock: {
    color: colors.YELLOW,
    marginLeft: '1vh',
  },
  thumbnail: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '20vh',
    borderRadius: 10,
    width: '100%',
    position: 'relative',
    marginBottom: '3%',
    cursor: 'pointer',
  },
  viewMore: {
    display: 'flex',
    alignItems: 'center',
  },
  viewMoreText: {
    textAlign: 'right',
    color: colors.WHITE,
    marginLeft: '10px',
  },
};

export default ExerciceCard;
