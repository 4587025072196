import React from "react";
import AthletesListNoPending from "./AthletesListNoPending";
import AthletesListPending from "./AthletesListPending";

function AthleteWrapper({ pendingList, navigation }) {

  if (navigation === "AthletesList" || navigation === "PatientList") {
    return <AthletesListNoPending />;
  } else if (navigation === "WaitingList") {
    return <AthletesListPending pendingList={pendingList} />;
  }
}
export default AthleteWrapper;
