import React from "react";
import TextComponent from "components/atoms/TextComponent";

import { translate } from "services/remoteConfig";

import { BOLD } from "constant/constants";

import { colors } from "themes";

function RecapAnalysisCard({ totalAnalysis, pronationAnalysis }) {
    return (
        <div style={styles.card}>

            <TextComponent text={translate("AnalysisTotalNumber")} sx={styles.title} />
            <TextComponent text={totalAnalysis} sx={styles.number} weight={BOLD} />
            <div style={styles.details}>
                <div style={styles.runningNumber}>
                    <TextComponent text={translate('Running')} sx={styles.fs08} />
                    <TextComponent text={pronationAnalysis} sx={styles.number} weight={BOLD} />
                </div>
                <div style={styles.pronationNumber}>
                    <TextComponent text={translate('Pronation')} sx={styles.fs08} />
                    <TextComponent text={totalAnalysis - pronationAnalysis} sx={styles.number} weight={BOLD} />
                </div>
            </div>
        </div>
    )
}

const styles = {
    card: {
        width: '30%',
        height: '90%',
        backgroundColor: colors.APP_BACKGROUND,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    details: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    fs08: {
        fontSize: '0.8vw'
    },
    number: {
        color: colors.SECONDARY,
        fontSize: '1.5vw',
    },
    pronationNumber: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    runningNumber: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRight: '2px solid grey',
        width: '100%'
    },
    title: {
        fontSize: '1vw',
        marginLeft: '1vw'
    }
}


export default RecapAnalysisCard;