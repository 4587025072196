export function getFilteredExercises(
    listExercises,
    muscleGroups,
    difficulty,
    type,
  ) {
    let uniqueExercises = [];
    muscleGroups?.forEach(muscleGroup => {
      const filteredExercises = listExercises?.exercises?.filter(element => {
        return (
          element?.musclegroup?.some(group => group === muscleGroup) &&
          element?.difficulty === difficulty &&
          element?.segmentToWork === type
        );
      });
      filteredExercises?.forEach(element => {
        const existingExercise = uniqueExercises?.find(
          exercise => exercise?.exerciseTitle === element?.exerciseTitle,
        );
  
        if (!existingExercise) {
          uniqueExercises?.push(element);
        }
      });
    });
    return uniqueExercises;
  }
  
  export function getExerciseDifficulty(scoreText) {
    if (isNaN(scoreText)) {
      if (scoreText === 'score_bad') return 'Easy';
      else if (scoreText === 'score_okay') return 'Medium';
      else return 'Hard';
    } else {
      if (scoreText < 0.33) return 'Easy';
      else if (scoreText < 0.66) return 'Medium';
      else if (scoreText <= 0.9) return 'Hard';
    }
  }
  