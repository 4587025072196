import { colors, images } from 'themes';
import { translate } from 'services/remoteConfig';
import { isWoman } from './utils';
import { FORE_FOOT, MID_FOOT, REAR_FOOT } from 'constant/constants';

export function getHeadText(jsonData, angle) {
  let color = colors.HEAD_REPORT_BACKGROUND;
  let image = images.ANALYSIS_HEAD_ICON;
  let text = '';
  let type = 'RightEar';
  let scoreNumber = 0;
  let versionFourData = {};
  let version = jsonData?.json_version;

  if (jsonData?.analyse?.content?.head) {
    text = jsonData?.analyse?.content?.head?.texts;
    scoreNumber = jsonData?.analyse?.content?.head?.score_number;
    if (version > 3) {
      angle = jsonData?.mean_angles_at_foot_strike?.head_mean_angle;
      versionFourData.analysisKey =
        jsonData?.analyse?.content?.head?.explanation;
      versionFourData.rangeGood = jsonData?.analyse?.content?.head?.range_good;
      versionFourData.rangeOkay = jsonData?.analyse?.content?.head?.range_okay;
      versionFourData.videos = jsonData?.analyse?.content?.head?.videos;
      versionFourData.recap = jsonData?.analyse?.content?.head?.texts?.recap;
      versionFourData.segmentedExplanation =
        jsonData?.analyse?.content?.head?.texts?.segmented_explanation;
    }
  }

  return prepareTexts(
    text,
    angle,
    color,
    image,
    type,
    isWoman() ? images.PERFECT_FEMALE_HEAD : images.PERFECT_MALE_HEAD,
    scoreNumber,
    versionFourData
  );
}

export function getBackText(jsonData, angle) {
  let color = colors.BACK_REPORT_BACKGROUND;
  let text = '';
  let image = images.ANALYSIS_BODY_BACK_ICON;
  let type = 'RightHip';
  let scoreNumber = 0;
  let versionFourData = {};
  let version = jsonData?.json_version;

  if (jsonData?.analyse?.content?.trunk) {
    text = jsonData?.analyse?.content?.trunk?.texts;
    scoreNumber = jsonData?.analyse?.content?.trunk?.score_number;
    if (version > 3) {
      angle = jsonData?.mean_angles_at_foot_strike?.trunk_mean_angle;
      versionFourData.analysisKey =
        jsonData?.analyse?.content?.trunk?.explanation;
      versionFourData.rangeGood = jsonData?.analyse?.content?.trunk?.range_good;
      versionFourData.rangeOkay = jsonData?.analyse?.content?.trunk?.range_okay;
      versionFourData.videos = jsonData?.analyse?.content?.trunk?.videos;
      versionFourData.recap = jsonData?.analyse?.content?.trunk?.texts?.recap;
      versionFourData.segmentedExplanation =
        jsonData?.analyse?.content?.trunk?.texts?.segmented_explanation;
    }
  }

  return prepareTexts(
    text,
    angle,
    color,
    image,
    type,
    isWoman() ? images.PERFECT_FEMALE_BACK : images.PERFECT_MALE_BACK,
    scoreNumber,
    versionFourData
  );
}

export function getArmText(jsonData, angle) {
  let color = colors.ARMS_REPORT_BACKGROUND;
  let text = '';
  let image = images.ANALYSIS_ARM_ICON;
  let type = 'RightElbow';
  let scoreNumber = 0;
  let version = jsonData?.json_version;
  let versionFourData = {};

  if (jsonData?.analyse?.content?.arm) {
    text = jsonData?.analyse?.content?.arm?.texts;
    scoreNumber = jsonData?.analyse?.content?.arm?.score_number;
    if (version > 3) {
      angle = jsonData?.mean_angles_at_foot_strike?.arm_mean_angle;
      versionFourData.analysisKey =
        jsonData?.analyse?.content?.arm?.explanation;
      versionFourData.rangeGood = jsonData?.analyse?.content?.arm?.range_good;
      versionFourData.rangeOkay = jsonData?.analyse?.content?.arm?.range_okay;
      versionFourData.videos = jsonData?.analyse?.content?.arm?.videos;
      versionFourData.recap = jsonData?.analyse?.content?.arm?.texts?.recap;
      versionFourData.segmentedExplanation =
        jsonData?.analyse?.content?.arm?.texts?.segmented_explanation;
    }
  }

  return prepareTexts(
    text,
    angle,
    color,
    image,
    type,
    isWoman() ? images.PERFECT_FEMALE_ARM : images.PERFECT_MALE_ARM,
    scoreNumber,
    versionFourData
  );
}

export function getFootText(jsonData, type) {
  let text = '';
  let image;
  let enume = 'RightAnkle';
  let version = jsonData?.json_version;

  if (jsonData?.analyse?.content?.foot) {
    text = jsonData?.analyse?.content?.foot?.texts;
    if (version > 3) {
      if (jsonData?.mean_foot_landing?.foot_strike_pattern === REAR_FOOT)
        image = images.REAR_FOOT;
      else if (jsonData?.mean_foot_landing?.foot_strike_pattern === FORE_FOOT)
        image = images.FORE_FOOT;
      else if (jsonData?.mean_foot_landing?.foot_strike_pattern === MID_FOOT)
        image = images.MID_FOOT;
    } else if (jsonData?.personalised) {
      text = jsonData?.analyse?.content?.foot?.texts;
      if (type === REAR_FOOT) image = images.REAR_FOOT;
      else if (type === FORE_FOOT) image = images.FORE_FOOT;
      else if (type === MID_FOOT) image = images.MID_FOOT;
    }
  }
  return {
    title: text.title,
    subtitile1: 'Position',
    text1: text.position,
    subtitile2: translate('Explanation'),
    text2: text.explanation,
    subtitile3: translate('Exercice'),
    exercice: text.exercice,
    advice: text.advice,
    image: image,
    score: text.score,
    type: enume,
    idealImage: isWoman()
      ? images.PERFECT_FEMALE_FOOT
      : images.PERFECT_MALE_FOOT
  };
}

export function getKneeBackText(jsonData, angle) {
  let color = colors.BACK_LEG_REPORT_BACKGROUND;
  let text = '';
  let image = images.ANALYSIS_BACK_LEG_ICON;
  let type = 'LeftKnee';
  let scoreNumber = 0;
  let version = jsonData?.json_version;
  let versionFourData = {};
  if (jsonData?.analyse?.content?.backCycle) {
    text = jsonData?.analyse?.content?.backCycle?.texts;
    scoreNumber = jsonData?.analyse?.content?.backCycle?.score_number;
    if (version > 3) {
      angle = jsonData?.mean_angles_at_foot_strike?.back_leg_mean_angle;
      versionFourData.analysisKey =
        jsonData?.analyse?.content?.backCycle?.explanation;
      versionFourData.rangeGood =
        jsonData?.analyse?.content?.backCycle?.range_good;
      versionFourData.rangeOkay =
        jsonData?.analyse?.content?.backCycle?.range_okay;
      versionFourData.videos = jsonData?.analyse?.content?.backCycle?.videos;
      versionFourData.recap =
        jsonData?.analyse?.content?.backCycle?.texts?.recap;
      versionFourData.segmentedExplanation =
        jsonData?.analyse?.content?.backCycle?.texts?.segmented_explanation;
    }
  }

  return prepareTexts(
    text,
    angle,
    color,
    image,
    type,
    isWoman()
      ? images.PERFECT_FEMALE_BACK_CYCLE
      : images.PERFECT_MALE_BACK_CYCLE,
    scoreNumber,
    versionFourData
  );
}

export function getKneeFrontText(jsonData, angle) {
  let color = colors.FRONT_LEG_REPORT_BACKGROUND;
  let text = '';
  let image = images.ANALYSIS_FRONT_LEG_ICON;
  let type = 'RightKnee';
  let scoreNumber = 0;
  let version = jsonData?.json_version;
  let versionFourData = {};
  if (jsonData?.analyse?.content?.forwardCycle) {
    text = jsonData?.analyse?.content?.forwardCycle?.texts;
    scoreNumber = jsonData?.analyse?.content?.forwardCycle?.score_number;
    if (version > 3) {
      angle = jsonData?.mean_angles_at_foot_strike?.front_leg_mean_angle;
      versionFourData.analysisKey =
        jsonData?.analyse?.content?.forwardCycle?.explanation;
      versionFourData.rangeGood =
        jsonData?.analyse?.content?.forwardCycle?.range_good;
      versionFourData.rangeOkay =
        jsonData?.analyse?.content?.forwardCycle?.range_okay;
      versionFourData.videos = jsonData?.analyse?.content?.forwardCycle?.videos;
      versionFourData.recap =
        jsonData?.analyse?.content?.forwardCycle?.texts?.recap;
      versionFourData.segmentedExplanation =
        jsonData?.analyse?.content?.forwardCycle?.texts?.segmented_explanation;
    }
  }
  return prepareTexts(
    text,
    angle,
    color,
    image,
    type,
    isWoman()
      ? images.PERFECT_FEMALE_FRONT_CYCLE
      : images.PERFECT_MALE_FRONT_CYCLE,
    scoreNumber,
    versionFourData
  );
}

export function prepareTexts(
  text,
  angle,
  color,
  image,
  type,
  idealImage,
  scoreNumber,
  versionFourData
) {
  return {
    angle: angle,
    title: text?.title,
    subtitile1: 'Position',
    text1: text?.position,
    subtitile2: translate('Explanation'),
    text2: text?.explanation,
    subtitile3: translate('Exercice'),
    exercice: text?.exercice,
    advice: text?.advice,
    backgroundColor: color,
    image: image,
    score: text?.score,
    score_number: scoreNumber,
    type: type,
    idealImage: idealImage,
    rangeGood: versionFourData?.rangeGood,
    rangeOkay: versionFourData?.rangeOkay,
    videos: versionFourData?.videos,
    recap: versionFourData?.recap,
    segmentedExplanation: versionFourData?.segmentedExplanation,
    analysisKey: versionFourData.analysisKey
  };
}
