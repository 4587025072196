import {BOTH, LEFT, RIGHT} from 'constant/constants';
import {getAnalysisChanges} from 'hooks/useAnalysisChanges';
import {updateListAnalysisData} from 'slices/analysisSlice';

export function getAllAnalysisData(idList, setAnalysisListDatas, dispatch) {
  idList?.map(id => {
    getAnalysisChanges(id, data => {
      setAnalysisListDatas(prev => {
        const newArr = [...prev];
        if (!newArr?.map(obj => obj?.data?.uploadVideoId)?.includes(id)) {
          newArr.push(data);
          newArr?.sort((a, b) => {
            if (!a?.date && !b?.date) return 0;
            if (!a?.date) return 1;
            if (!b?.date) return -1;
            const dateA = new Date(a?.date);
            const dateB = new Date(b?.date);
            let comparison = 0;
            if (dateA < dateB) {
              comparison = 1;
            } else if (dateA > dateB) {
              comparison = -1;
            }
            return comparison;
          });
        } else if (newArr?.map(obj => obj?.data?.uploadVideoId).includes(id)) {
          const index = newArr?.findIndex(
            obj => obj?.data?.uploadVideoId === id,
          );
          newArr[index] = data;
        }
        dispatch(updateListAnalysisData(newArr));
        return newArr;
      });
    });
  });
}

export function setAnalysisData(
  analysisList,
  datas,
  setAnalysisListDatas,
  dispatch,
  setLoading,
) {
  setLoading(true);
  if (analysisList?.length > datas?.length) {
    const arrayNotInLstAnalysisData = analysisList?.filter(
      id => !datas.map(obj => obj?.data?.uploadVideoId)?.includes(id),
    );
    if (arrayNotInLstAnalysisData?.length > 0) {
      getAllAnalysisData(
        arrayNotInLstAnalysisData,
        setAnalysisListDatas,
        dispatch,
        setLoading,
      );
    }
  } else if (analysisList?.length < datas.length) {
    const valueNotInAnalysisList = datas?.find(
      val => !analysisList?.includes(val?.data?.uploadVideoId),
    );
    if (valueNotInAnalysisList) {
      const index = datas.indexOf(valueNotInAnalysisList);
      setAnalysisListDatas(prev => {
        const newArr = [...prev];
        newArr.splice(index, 1);
        dispatch(updateListAnalysisData(newArr));
        setLoading(false);
        return newArr;
      });
    }
  } else {
    getAllAnalysisData(
      analysisList,
      setAnalysisListDatas,
      dispatch,
      setLoading,
    );
  }
}

export function createJointAnglesData(jointAngles, direction) {
  if (!jointAngles) return null;

  const isRightSide = jointAngles?.side === RIGHT || jointAngles?.side === BOTH;
  const isLeftSide = jointAngles?.side === LEFT || jointAngles?.side === BOTH;

  function getOpacity(side) {
    if (direction === RIGHT) {
      return side === RIGHT ? 1 : 0.4;
    } else if (direction === LEFT) {
      return side === LEFT ? 1 : 0.4;
    }
    return 1;
  }

  return [
    {
      selected: isLeftSide,
      data: jointAngles?.left_knee,
      color: `rgba(255, 0, 0, ${getOpacity(LEFT)})`,
      disabled: !isLeftSide,
    },
    {
      selected: isRightSide,
      data: jointAngles?.right_knee,
      color: `rgba(255, 0, 0, ${getOpacity(RIGHT)})`,
      disabled: !isRightSide,
    },
    {
      selected: false,
      data: jointAngles?.left_hip,
      color: `rgba(255, 165, 0, ${getOpacity(LEFT)})`,
      disabled: !isLeftSide,
    },
    {
      selected: false,
      data: jointAngles?.right_hip,
      color: `rgba(255, 165, 0, ${getOpacity(RIGHT)})`,
      disabled: !isRightSide,
    },
    // {
    //   selected: false,
    //   data: jointAngles?.left_ankle,
    //   color: `rgba(255, 255, 0, ${getOpacity(LEFT)})`,
    //   disabled: !isLeftSide,
    // },
    // {
    //   selected: false,
    //   data: jointAngles?.right_ankle,
    //   color: `rgba(255, 255, 0, ${getOpacity(RIGHT)})`,
    //   disabled: !isRightSide,
    // },
    {
      selected: false,
      data: jointAngles?.left_elbow,
      color: `rgba(255, 20, 147, ${getOpacity(LEFT)})`,
      disabled: !isLeftSide,
    },
    {
      selected: false,
      data: jointAngles?.right_elbow,
      color: `rgba(255, 20, 147, ${getOpacity(RIGHT)})`,
      disabled: !isRightSide,
    },
  ];
}
