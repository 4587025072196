import React from "react";

import { useSelector } from "react-redux";

import TextComponent from "./TextComponent";

import {
  selectHeighComplementUnity,
  selectHeightUnity,
  selectUnityType,
} from "slices/unitySlice";

import { BODY_2 } from "constant/constants";
import { EN } from "constant/constants";

import { getHeightinCM } from "utils/utils";
import { mToftIn } from "utils/converter";

import { ReactComponent as Height } from "assets/svg/height.svg";

function HeightComponent({ height }) {
  const heightUnity = useSelector(selectHeightUnity);
  const heightUnityComplement = useSelector(selectHeighComplementUnity);

  const getHeight = !height
    ? 0
    : (heightUnity === "ft")
      ? mToftIn(height)
      : getHeightinCM(height);

  const styles = {
    textFt: {
      marginLeft: 1,
      marginRight: 0.5,
    },
    textInch: {
      marginLeft: 0,
      marginRight: 0.5,
    },
    textUnityFt: {
      margin: 0,
      marginTop: 2,
      marginRight: heightUnity === "ft" ? 0 : 2.5,
      color: "#737373",
    },
    textUnityInch: {
      margin: 0,
      marginTop: 2,
      marginRight: 2.5,
      color: "#737373",
    },
  };

  return (
    <>
      <Height />
      <TextComponent
        sx={styles.textFt}
        text={getHeight?.hasOwnProperty("ft") ? getHeight?.ft : getHeight}
        variant={BODY_2}
      />
      <TextComponent
        sx={styles.textUnityFt}
        text={heightUnity}
        variant={BODY_2}
      />
      {getHeight?.inch ? <>
        <TextComponent
          sx={styles.textInch}
          text={getHeight?.inch}
          variant={BODY_2}
        />
        <TextComponent
          sx={styles.textUnityInch}
          text={heightUnityComplement}
          variant={BODY_2}
        />
      </> : null
      }

    </>
  );
}

export default HeightComponent;
