import React from 'react';
import {animated, useSpring} from 'react-spring';

function FadinView({children}) {
  const styles = useSpring({
    from: {opacity: 0},
    to: {opacity: 1},
    config: {duration: 500},
  });

  return <animated.div style={styles}>{children}</animated.div>;
}

export default FadinView;
