import React, {useEffect} from 'react';

import Lottie from 'lottie-react';
import {useSelector} from 'react-redux';
import {getAuth} from 'firebase/auth';
import {httpsCallable} from 'firebase/functions';

import OnBoardingProfileCreationList from './OnBoardingProfileCreationList';

import TextComponent from 'components/atoms/TextComponent';
import {USER_LOCALE, onBoardingTranslate} from 'services/remoteConfig';
import {updateProfile} from 'services/userApi';

import {functions} from 'utils/firebaseInit';
import {replaceUserNameByActualName} from 'utils/utils';

import {accountCreationHeadline} from './constants/onBoardingText';
import {
  ATHLETE,
  BOLD,
  COACH,
  H4,
  PROFESSIONAL,
  REGISTRATION_DATA,
} from 'constant/constants';

import {mpTrack} from 'services/mixpanel';

function OnBoardingProfileCreation() {
  const userData = JSON.parse(localStorage.getItem(REGISTRATION_DATA));
  const userSlice = useSelector(state => state?.user);
  const usageSlice = useSelector(state => state.usage);

  useEffect(() => {
    mpTrack('Web App OnBoarding Profile Creation Screen Viewed');
  }, []);

  useEffect(() => {
    if (!userData) return;
    updateTracking();
  }, [userData]);

  async function updateTracking() {
    const user = getAuth().currentUser;
    const defaultProfile = {
      settings: {
        selectedLanguage: USER_LOCALE,
      },
    };

    let updatedProfile = {};

    if (userData?.userType === COACH) {
      updatedProfile = {
        type: userData?.userType || 'coach',
        typology: userData?.typology || 'PersonnalCoach',
        specialization: userData?.specialization || [],
      };
    } else if (userData?.userType === PROFESSIONAL) {
      updatedProfile = {
        type: userData?.userType || 'professional',
        specialization: userData?.specialization || [],
      };
    } else {
      updatedProfile = {
        gender: userData?.gender || 'man',
        type: userData?.userType || 'athlete',
        typology: userData?.typology || 'performer',
        level: userData?.level || 'beginner',
        timePerWeek: userData?.timePerWeek ? userData?.timePerWeek : 3,
        height: userData?.height || 160,
        weight: userData?.weight || 60,
        runningDate: userData?.runningDate || '',
      };
    }

    const finalProfile = {...defaultProfile, ...updatedProfile};

    updateProfile(finalProfile);
    if (
      usageSlice?.creditNumber <= 2 &&
      usageSlice?.monthlyPlan === false &&
      usageSlice?.yearlyPlan === false
    ) {
      const callInitProfileTypeUsage = httpsCallable(
        functions,
        'initProfileTypeUsage',
      );
      try {
        const result = await callInitProfileTypeUsage({
          hubspotId: userSlice?.hubspotId,
          userType: userData?.userType ? userData?.userType : 'athlete',
          userId: user.uid,
        });
        return result.data;
      } catch (error) {
        console.error('Error calling cloud function: ', error);
        throw error;
      }
    }
  }

  return (
    <div className="App">
      <div style={styles.container} />
      <TextComponent
        text={
          userData?.userType === ATHLETE
            ? replaceUserNameByActualName(
                onBoardingTranslate(
                  accountCreationHeadline[userData?.userType],
                ),
              )
            : onBoardingTranslate(accountCreationHeadline[userData?.userType])
        }
        variant={H4}
        weight={BOLD}
        sx={{
          textAlign: 'center',
        }}
      />
      <Lottie
        animationData={require('assets/animations/loading_runner.json')}
        loop={true}
        style={styles.runnerAnimation}
      />
      <OnBoardingProfileCreationList />
    </div>
  );
}
const styles = {
  container: {
    paddingTop: '5vh',
  },
  runnerAnimation: {
    margin: 'auto',
    width: '30%',
  },
};

export default OnBoardingProfileCreation;
