import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';
import Lottie from 'lottie-react';

import TextComponent from 'components/atoms/TextComponent';
import OnBoardingBottomArrow from 'screen/auth/registration/onBoarding/components/OnBoardingBottomArrow';

import {onBoardingTranslate} from 'services/remoteConfig';

import {BOLD, COACH, H3, H4, REGISTRATION_DATA} from 'constant/constants';

import {createTextObjectReusable} from 'utils/utils';

import {colors} from 'themes';
import {mpTrack} from 'services/mixpanel';

function OnBoardingCoachShare() {
  const navigate = useNavigate();
  const userType = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.userType;

  useEffect(() => {
    userType === COACH
      ? mpTrack('Web App OnBoarding Coach Share Analysis Screen Viewed')
      : mpTrack('Web App OnBoarding Profesional Share Analysis Screen Viewed');
  }, []);

  function handleArrowClicked() {
    navigate('/registration/good-video-tutorial');
  }

  function handlePreviousArrowClicked() {
    navigate('/registration/coach/analysis', {
      state: userType === COACH ? {value: 3} : {value: 2},
    });
  }

  return (
    <div style={styles.container}>
      <div style={styles.textContainer}>
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('CoachShareAnalysis'),
            {
              variant: H4,
            },
            {
              variant: H4,
              style: {textAlign: 'center', color: colors.SECONDARY},
            },
          )}
          variant={H3}
          weight={BOLD}
          center={true}
        />
      </div>
      <div style={styles.cardContainer}>
        <div
          style={{
            ...styles.shareCard,
            ...{
              backgroundImage: `url(${require('assets/images/shareAnalysis.jpg')})`,
            },
          }}>
          <Lottie
            animationData={require('assets/animations/tap.json')}
            loop={true}
            style={styles.fingerAnimation}
          />
        </div>
      </div>
      <OnBoardingBottomArrow onPress={handleArrowClicked} />
      <OnBoardingBottomArrow
        previous={true}
        onPress={handlePreviousArrowClicked}
      />
    </div>
  );
}

const styles = {
  cardContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '60vh',
  },
  container: {
    paddingTop: '5vh',
  },
  fingerAnimation: {
    float: 'right',
    marginTop: '5vh',
    width: '10%',
  },
  shareCard: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    minHeight: '40vh',
    width: '50vh',
  },
  textContainer: {
    margin: 'auto',
    width: '60%',
  },
};
export default OnBoardingCoachShare;
