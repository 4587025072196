import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useProjectChanges } from 'hooks/useProjectChanges';
import { useProfileChanges } from 'hooks/useProfileChanges';
import { useUsageChanges } from 'hooks/useUsageChanges';

import AllAnalysis from './components/AllAnalysis';
import NoVideos from './components/NoVideos';
import AthleteInfos from './components/AthleteInfos';
import TextComponent from 'components/atoms/TextComponent';
import ButtonComponent from 'components/atoms/ButtonComponent';
import ModalComponent from 'components/molecules/ModalComponent';
import WelcomeModal from 'components/atoms/WelcomeModal';

import { initProjects } from 'services/projectApi';
import { intercomInit } from 'services/intercom';
import { translate } from 'services/remoteConfig';
import { mpIdentify, mpTrack } from 'services/mixpanel';

import { store } from 'store';
import { analysisListData, analysisListState } from 'slices/analysisSlice';
import { selectAthleteSelected } from 'slices/athleteSlice';

import { setAnalysisData } from 'utils/analysis';
import { checkUserPermission } from 'utils/utils';

import { BLUE_BORDER, COACH, H4, ORANGE, PROFESSIONAL } from 'constant/constants';
import { updateProfile } from 'services/userApi';
import { updateProfileStore } from 'slices/userSlice';
import LoadingComponent from 'components/atoms/LoadingComponent';

export default function AnalysisList() {
  useProfileChanges();
  useUsageChanges();
  useProjectChanges();
  const user = store?.getState()?.user;

  const welcomeRef = useRef();

  const analysisList = useSelector(analysisListState);
  const datas = useSelector(analysisListData);
  const dispatch = useDispatch();

  const [analysisListDatas, setAnalysisListDatas] = useState(datas);
  const athleteSlice = useSelector(selectAthleteSelected);

  const [comparedAnalysis, setComparedAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('segmentToWork');
    mpTrack('Web App Analysis List Screen Viewed');
    if (user) {
      mpIdentify();
      intercomInit();
    }
    window.scrollTo(0, 0);
    initProjects();
  }, []);

  useEffect(() => {
    setAnalysisData(analysisList, datas, setAnalysisListDatas, dispatch, setLoading);
  }, [analysisList, datas?.length]);

  useEffect(() => {
    if (user?.onBoarded) return;
    if (checkUserPermission([COACH, PROFESSIONAL])) {
      welcomeRef?.current?.open();
    }
    updateProfile({ onBoarded: true });
    dispatch(updateProfileStore({ onBoarded: true }));
  }, []);

  if (loading && !datas) return <div style={{
    justifyContent: 'center', alignItems: 'center', height: "80vh", display: "flex",
  }}>
    <LoadingComponent size={125} />
  </div>

  if (analysisListDatas?.length === 0 && !athleteSlice) {
    return (
      <>
        {Object.keys(athleteSlice).length !== 0 ? <AthleteInfos /> : null}
        <NoVideos />;
      </>
    );
  }
  return (
    <div>
      {Object.keys(athleteSlice).length !== 0 ? <AthleteInfos /> : null}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 10,
          marginTop: 10,
        }}>
        <TextComponent
          variant={H4}
          text={translate('Analysis')}
          sx={{ marginLeft: 3 }}
        />
        <ButtonComponent
          type={BLUE_BORDER}
          title={translate('Compare')}
          onClick={() => {
            if (comparedAnalysis) {
              setComparedAnalysis(null);
            } else {
              setComparedAnalysis([]);
            }
            mpTrack('Web App Analysis Compare Button Clicked');
          }}
        />
        {comparedAnalysis ? (
          <ButtonComponent
            type={ORANGE}
            title={translate('Validate')}
            disabled={comparedAnalysis?.length !== 2}
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate('/compare-analysis', {
                state: { comparedAnalysis },
              });
            }}
          />
        ) : null}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <AllAnalysis
          analysisList={analysisListDatas}
          comparedAnalysis={comparedAnalysis}
          setComparedAnalysis={setComparedAnalysis}
        />
      </div>
      <ModalComponent ref={welcomeRef}>
        <WelcomeModal />
      </ModalComponent>
    </div>
  );
}
