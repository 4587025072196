import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import TextComponent from "components/atoms/TextComponent";
import GradientScoreSystem from "screen/analysis/AnalysisListCard/components/GradientScoreSystem";

import { translate } from "services/remoteConfig";

import { getAverageScore } from "utils/utils";

import { colors } from "themes";
import { PRONATION } from "constant/constants";

function AthleteRanking({ data }) {

    const [rank, setRank] = useState(null);
    const userItemSlice = useSelector((state) => state?.userItems);

    useEffect(() => {
        if (data?.length === 0 || userItemSlice?.lstItems?.length === 0) return;
        const analysisByAthlete = sortAnalysisByAthlete(data);

        const bestScoreByAthlete = getBestScoreByAthlete(analysisByAthlete);

        setRank(bestScoreByAthlete?.sort((a, b) => b?.bestScore - a?.bestScore));
    }, [data, userItemSlice]);

    function sortAnalysisByAthlete(data) {
        return userItemSlice?.lstItems?.map((item) => {
            const analysis = data?.filter(obj => obj?.data?.tag?.id === item?.id && obj?.analysis_type !== PRONATION);
            return {
                id: item?.id,
                analysis: analysis,
                name: item?.name
            }
        });
    }

    function getBestScoreByAthlete(array) {
        return array?.map((item) => {
            const allScores = item?.analysis?.map((obj) => {
                const jsonData = JSON.parse(localStorage.getItem(obj?.data?.uploadVideoId));
                return jsonData ? getAverageScore(Object.entries(jsonData?.analyse?.content)) : null;
            })?.filter(score => score !== null);

            const bestScore = allScores?.length > 0 ? Math.max(...allScores) : null;
            return { bestScore: bestScore, name: item?.name };
        })?.filter(item => item?.bestScore !== null && item?.name !== undefined);
    }

    if (data?.length === 0) return null;


    return (
        <div style={styles.card}>
            <TextComponent text={translate('AthleteRanking')} sx={styles.title} />
            <div style={styles.rankContainer}>
                {rank?.map((item, index) => {
                    return (
                        <div style={styles.rankLine}>
                            <TextComponent text={(index + 1) + '. ' + item?.name} sx={styles.fs08} />
                            <GradientScoreSystem scoreNumber={item?.bestScore} />
                        </div>
                    )
                })
                }
            </div>
        </div>
    );
}

const styles = {
    card: {
        width: '30%',
        height: '90%',
        backgroundColor: colors.APP_BACKGROUND,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    fs08: {
        fontSize: '0.8vw'
    },
    rankContainer: {
        overflowY: 'auto',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    rankLine: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1vh',
        width: '80%'
    },
    title: {
        fontSize: '1vw',
        marginBottom: '1vh',
        marginTop: '1vh',
        marginLeft: '1vw'

    }

}


export default AthleteRanking;