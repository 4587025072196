import { useEffect, useState } from "react";

import { collection, doc, onSnapshot } from "firebase/firestore";
import { useDispatch } from "react-redux";

import { setListAnalysis } from "slices/analysisSlice";

import { firestore } from "utils/firebaseInit";
import { store } from "store";

const PROJECT_COLLECTION = "projects";

export function useProjectChanges() {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setLoading(true);
    const user = store?.getState()?.user
    if (user?.userID === 'Unknown') return;
    try {
      onSnapshot(
        doc(collection(firestore, PROJECT_COLLECTION), user?.userID),
        (doc) => {
          const data = doc?.data();
          dispatch(setListAnalysis(data));
          setProjects(data);
          setLoading(false);
        }
      );
    } catch (error) {
      setError(error);
    }
  }, []);
  return { loading, error, projects };
}
