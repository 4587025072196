import {EXEMPLE_ANALYSIS} from 'constant/product';
import {collection, doc, getDoc, setDoc} from 'firebase/firestore';

import {auth, firestore} from 'utils/firebaseInit';
import {USER_LOCALE} from './remoteConfig';

const PROJECT_COLLECTION = 'projects';

export async function getProjects() {
  if (auth) {
    const userId = auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, PROJECT_COLLECTION);
      const ref = doc(collectionRef, userId);
      const docs = getDoc(ref);
      if ((await docs).exists()) {
        const data = (await docs).data().analysis;
        return data;
      } else return;
    } catch (error) {
      console.log('error in getProjects', error);
    }
  }
}

export async function deleteAnalysisFromProjects(analysisId) {
  if (auth) {
    const userId = auth?.currentUser?.uid;
    try {
      const collectionRef = collection(firestore, PROJECT_COLLECTION);
      const ref = doc(collectionRef, userId);
      const docs = getDoc(ref);
      if ((await docs).exists()) {
        const data = (await docs).data().analysis;
        data?.forEach((element, index) => {
          if (element === analysisId) {
            data?.splice(index, 1);
          }
        });
        setDoc(ref, {analysis: data});
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export async function deleteAnalysisFromUserList(userID, analysisId) {
  if (auth) {
    try {
      const collectionRef = collection(firestore, PROJECT_COLLECTION);
      const ref = doc(collectionRef, userID);
      const docs = getDoc(ref);
      if ((await docs).exists()) {
        const data = (await docs).data().analysis;
        data?.forEach((element, index) => {
          if (element === analysisId) {
            data?.splice(index, 1);
          }
        });
        setDoc(ref, {analysis: data});
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export async function addNewAnalysisInUserProject(analysisId, userId) {
  const user = auth?.currentUser;
  if (user) {
    try {
      addAnalysisInProjects(analysisId);
      if (userId) {
        addAnalysisInProjects(analysisId, userId);
      }
    } catch (e) {
      console.log('error in add', e);
    }
  }
}

export async function addAnalysisInProjects(analysisId, userId) {
  //need to check here
  const user = auth?.currentUser;
  let arr = [];
  if (user) {
    let docId;
    if (!userId) docId = user?.uid;
    else docId = userId;
    try {
      const collectionRef = collection(firestore, PROJECT_COLLECTION);
      const ref = doc(collectionRef, docId);
      const docs = getDoc(ref);
      const projs = (await docs).data();
      if (!projs) arr = [];
      else {
        arr = [...projs?.analysis];
      }
      if (!arr?.find(ele => ele === analysisId)) arr?.push(analysisId);
      setDoc(ref, {analysis: arr}, {merge: true});
    } catch (e) {
      console.log('error in add', e);
    }
  }
}

export async function initProjects() {
  const user = auth?.currentUser;
  if (user) {
    const collectionRef = collection(firestore, PROJECT_COLLECTION);
    const ref = doc(collectionRef, user?.uid);
    const docs = getDoc(ref);
    const projs = (await docs).data();
    const language = USER_LOCALE;
    if (!projs || !projs?.analysis) {
      setDoc(
        ref,
        {
          analysis: [EXEMPLE_ANALYSIS[language]],
        },
        {merge: true},
      );
    }
  }
}
