import React from 'react';
import { metricsConfig } from '../../config/metricsConfig';
import TextComponent from 'components/atoms/TextComponent';
import { BODY_1, H6 } from 'constant/constants';
import { images } from 'themes';

function AnalysisRightPanelMetrics({ displayData }) {
  const metricsContent = metricsConfig(displayData?.metricKey);
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {displayData?.metricKey === 'duty_factor' ? (
        <></>
      ) : (
        <img
          src={
            displayData?.metricKey === 'time_between_steps'
              ? images.METRICS_TIME_STEPS
              : displayData?.metricKey === 'steps_per_min'
              ? images.METRICS_NUMBER_STEPS
              : images.METRICS_GROUND_CONTACT_TIME
          }
          alt={'steps'}
          style={{
            width: '80%',
            alignSelf: 'center',
            marginTop: 10,
            marginBottom: 10
          }}
        />
      )}
      <TextComponent
        text={metricsContent?.translation}
        variant={H6}
        sx={{ marginTop: 1.5, marginLeft: 1 }}
      />{' '}
      <TextComponent
        text={metricsContent?.description}
        sx={{
          textAlign: 'left',
          color: 'white',
          marginTop: 2,
          marginRight: 2,
          lineHeight: 1.75,
          marginLeft: 1
        }}
        variant={BODY_1}
      />
    </div>
  );
}

export default AnalysisRightPanelMetrics;
