import { ReactComponent as AnalysisIcon } from 'assets/svg/analysisIcon.svg';
import { ReactComponent as AthletesIcon } from 'assets/svg/athletesIcon.svg';
import { ReactComponent as ProfileIcon } from 'assets/svg/profileIcon.svg';
import { ReactComponent as LeaderboardIcon } from 'assets/svg/podium.svg';

export const MENU_CONSTANT_COACH_LEADERBOARD = [
  {
    id: 1,
    name: 'Analysis',
    icon: AnalysisIcon,
    path: '/'
  },
  {
    id: 2,
    name: 'Leaderboard',
    icon: LeaderboardIcon,
    path: '/leaderboard'
  },
  {
    id: 3,
    name: 'Athletes',
    icon: AthletesIcon,
    path: '/athletes'
  },
  {
    id: 4,
    name: 'Profile',
    icon: ProfileIcon,
    path: '/profile'
  }
];

export const MENU_CONSTANT_COACH = [
  {
    id: 1,
    name: 'Analysis',
    icon: AnalysisIcon,
    path: '/'
  },
  {
    id: 2,
    name: 'Athletes',
    icon: AthletesIcon,
    path: '/athletes'
  },
  {
    id: 3,
    name: 'Profile',
    icon: ProfileIcon,
    path: '/profile'
  }
];

export const MENU_CONSTANT_PRO = [
  {
    id: 1,
    name: 'Analysis',
    icon: AnalysisIcon,
    path: '/'
  },
  {
    id: 2,
    name: 'Patients',
    icon: AthletesIcon,
    path: '/athletes'
  },
  {
    id: 3,
    name: 'Profile',
    icon: ProfileIcon,
    path: '/profile'
  }
];

export const MENU_CONSTANT_ATHLETE = [
  {
    id: 1,
    name: 'Analysis',
    icon: AnalysisIcon,
    path: '/'
  },
  {
    id: 2,
    name: 'Profile',
    icon: ProfileIcon,
    path: '/profile'
  }
];
