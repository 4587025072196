import React from 'react';

function AuthContainer({children}) {
  return (
    <div style={styles.container}>
      <video
        muted
        autoPlay={true}
        loop={true}
        controls={false}
        style={styles.video}>
        <source
          src={require('assets/videos/videoAuthScreen2.mp4')}
          type={'video/mp4'}
        />
      </video>
      <div style={styles.childrenContainer}>{children};</div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
  },
  video: {
    height: '100vh',
    backgroundColor: 'black',
    width: '60%',
    objectFit: 'cover',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  childrenContainer: {
    width: '40%',
    float: 'right',
    top: 0,
    right: 0,
    position: 'absolute',
    marginTop: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};
export default AuthContainer;
