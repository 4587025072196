import { collection, deleteDoc, doc } from "firebase/firestore";
import { firestore } from "utils/firebaseInit";

const COMMENT_COLLECTION = 'comments';

export function removeFromCommentCollection(id) {
    if (!id) return;
    console.log('Deleting analysis ', id);
      try {
        const collectionRef = (collection(firestore,COMMENT_COLLECTION))
        deleteDoc(doc(collectionRef, id));
      } catch (e) {
        console.log(e);
      }
    
    return;
  }