import { CircularProgress } from "@mui/material";
import React from "react";
import { colors } from "themes";

function LoadingComponent({ size = 40 }) {
  return (
    <div>
      <CircularProgress sx={styles.circularColor} size={size} />
    </div>
  );
}
const styles = {
  circularColor: {
    color: colors.SECONDARY,
  },
};

export default LoadingComponent;
