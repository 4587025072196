import React from "react";

import PersonnalInfos from "./PersonnalInfos";
import Statistics from "./Statistics";
import AthleteGraph from "./AthleteGraph";

import { SUCCESS } from "constant/constants";

function ProfileAthlete({ analysisListDatas, userSlice, loading }) {

    return (
        <div style={styles.container}>
            <div style={styles.subContainer}>
                <PersonnalInfos userSlice={userSlice} />
                <div style={styles.dashboard}>
                    <AthleteGraph analysisListDatas={analysisListDatas?.filter((obj) => obj?.exemple !== true && obj?.status === SUCCESS && obj?.data?.locked !== true)} loading={loading} />
                    <Statistics analysisListDatas={analysisListDatas?.filter((obj) => obj?.exemple !== true && obj?.status === SUCCESS && obj?.data?.locked !== true)} loading={loading} />
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        height: '92vh',
        alignItems: 'center'
    },
    dashboard: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '1vw'
    },
    subContainer: {
        width: '100%',
        height: '94%',
        display: 'flex',
        marginRight: '1vw',
        marginLeft: '1vw'
    },

}

export default ProfileAthlete;