import TextComponent from 'components/atoms/TextComponent';
import { BODY_1, BODY_2, BOLD } from 'constant/constants';
import React from 'react'
import { score_colors } from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import { translate } from 'services/remoteConfig';
import { colors } from 'themes';

function BodyContainerContentRecapDisplay({item}) {
    if(!item) return null;
    let score = item?.score
    return (
        <div style={styles.sliderContainer} >
            <div style={styles.sliderCard}>
                {item.recap ? (
                    <div>
                        <TextComponent
                            text={translate('Recap')}
                            sx={styles.text}
                            weight={BOLD}
                        />
                        {item.recap.map((recapItem, recapIndex) => (
                            <div style={styles.recapContainer} key={recapIndex}>
                                <div
                                    style={{
                                        ...styles.recapDotBackground,
                                        backgroundColor: score_colors[score].scoreBackground,
                                    }}
                                >
                                    <div
                                        style={{
                                            ...styles.recapDot,
                                            backgroundColor: score_colors[score].stroke,
                                        }}
                                    />
                                </div>
                                <TextComponent
                                    key={recapIndex}
                                    text={recapItem}
                                    sx={styles.recapText}
                                    variant={BODY_2}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>
                        <TextComponent
                            text={item.title}
                            sx={styles.text}
                            weight={BOLD}
                        />
                        <TextComponent text={item.text} variant={BODY_2} sx={{textAlign : 'left'}}/>
                    </div>
                )}
            </div>
        </div>
    );
}

const styles = {
    recapContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        marginTop: 5,
    },
    recapDot: {
        borderRadius: '50%',
        height: 5,
        width: 5,
    },
    recapDotBackground: {
        alignItems: 'center',
        borderRadius: '50%',
        display: 'flex',
        height: 12,
        justifyContent: 'center',
        width: 12,
    },
    recapText: {
        marginLeft: 1,
        textAlign : 'left',
    },
    sliderCard: {
        minHeight : '14rem',
        padding: 15,
        paddingBottom : 30,
        borderRadius: 1,
        width: '90%',
        backgroundColor : colors.BLACK_OPACITY_80,

    },
    sliderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems : 'center',
    },
    text: {
        marginBottom: 2,
        textAlign: 'left',
        fontWeight: BOLD,
    },
};

export default BodyContainerContentRecapDisplay