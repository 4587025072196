export const FR = '_fr';
export const EN = '_en';

export const ENGLISH = {code: 'en', value: 'English'};
export const FRENCH = {code: 'fr', value: 'Français'};
export const CHINESE = {code: 'zh', value: '中文'};
export const GERMAN = {code: 'de', value: 'Deutsch'};
export const SPANISH = {code: 'es', value: 'Español'};
export const ITALIAN = {code: 'it', value: 'Italiano'};
export const PORTUGUESE = {code: 'pt', value: 'Português'};

export const LANGUAGE_LOOKUP = {
  [ENGLISH.code]: ENGLISH.value,
  [FRENCH.code]: FRENCH.value,
  [CHINESE.code]: CHINESE.value,
  [GERMAN.code]: GERMAN.value,
  [SPANISH.code]: SPANISH.value,
  [ITALIAN.code]: ITALIAN.value,
  [PORTUGUESE.code]: PORTUGUESE.value,
};

export const PRONATION = 'pronation';

export const GREEN = 'green';
export const ORANGE = 'orange';
export const ORANGE_ROUNDED = 'orange_rounded';
export const RED = 'red';
export const CONTAINED = 'contained';
export const REVERSE = 'reverse';
export const BLUE_BORDER = 'blue_border';

export const H1 = 'h1';
export const H2 = 'h2';
export const H3 = 'h3';
export const H4 = 'h4';
export const H5 = 'h5';
export const H6 = 'h6';
export const BODY_1 = 'body1';
export const BODY_2 = 'body2';
export const BODY_3 = 'body3';
export const BODY_4 = 'body4';
export const BODY_5 = 'body5';
export const BODY_6 = 'body6';
export const BODY_7 = 'body7';
export const BODY_8 = 'body8';

export const LIGHT = 'light';
export const BOLD = 'bold';
export const MEDIUM = 'medium';

export const ERROR = 'error';

export const USERTYPE_PROFESSIONAL = 'professional';
export const USERTYPE_ATHLETE = 'athlete';
export const USERTYPE_COACH = 'coach';
export const USERTYPE_PATIENT = 'patient';

export const STANDARD = 'standard';

export const SUCCESS = 'success';
export const FAILED = 'failed';
export const PENDING = 'pending';

export const COACH = 'coach';
export const ATHLETE = 'athlete';
export const PROFESSIONAL = 'professional';

export const PATIENT = 'Patients';

export const REAR_FOOT = 'rearfoot';
export const FORE_FOOT = 'forefoot';
export const MID_FOOT = 'midfoot';

export const ANALYSIS = 'Analysis';
export const METRICS = 'Metrics';
export const GRAPH = 'Graph';
export const STYLE = 'Style';

export const PACE_METRIC_MIN_KM = 'min/km';
export const PACE_METRIC_MIN_MIL = 'min/mi';
export const PACE_METRIC_KM_H = 'km/h';
export const PACE_METRIC_MIL_H = 'mi/h';

export const DISTANCE_METRIC_KM = 'km';
export const DISTANCE_METRIC_MIL = 'mi';
export const DISTANCE_METRIC_FT = 'ft';

export const PERFORMER = 'performer';
export const LIFESTYLE = 'lifestyle';
export const INJURED = 'injured';

export const MAN = 'man';
export const WOMAN = 'woman';
export const OTHER = 'other';

export const BEGINNER = 'beginner';
export const INTERMEDIATE = 'intermediate';
export const ADVANCED = 'advanced';
export const ELITE = 'elite';

export const REGISTRATION_DATA = 'onBoardingUserData';

export const RUNNING = 'running';

export const ANALYSISTYPE_RUNNING = 'running';
export const ANALYSISTYPE_PRONATION = 'pronation';
export const ANALYSISTYPE_RUNNING_TREADMILL = 'running_treadmill';

export const PORTRAIT = 'portrait';
export const LANDSCAPE = 'landscape';

export const BODY_PARTS = ['Knee', 'Hip', 'Elbow'];

export const NONE = 'none';
export const BOTH = 'both';
export const LEFT = 'left';
export const RIGHT = 'right';
