import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import TextComponent from 'components/atoms/TextComponent';
import OnBoardingContainer from 'screen/auth/registration/onBoarding/components/OnBoardingContainer';
import OnBoardingBottomArrow from 'screen/auth/registration/onBoarding/components/OnBoardingBottomArrow';
import FadinView from 'components/atoms/FadinView';

import {onBoardingTranslate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {createTextObjectReusable} from 'utils/utils';

import {H4, REGISTRATION_DATA} from 'constant/constants';
import {onboardingAnalysisPresentation} from 'screen/auth/registration/onBoarding/constants/onBoardingText';

import {colors, images} from 'themes';

function OnBoardingAthleteAnalysisIntro() {
  const navigate = useNavigate();

  useEffect(() => {
    mpTrack('Web App OnBoarding Athlete Analysis Presentation Screen Viewed');
  }, []);

  function handleNextArrowClicked() {
    navigate('/registration/athlete/analysis-total-score');
  }

  function handleBackArrowClicked() {
    navigate('/registration/athlete/height-weight');
  }

  const userGender = JSON.parse(
    localStorage?.getItem(REGISTRATION_DATA),
  )?.gender;

  return (
    <OnBoardingContainer>
      <FadinView>
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate(
              onboardingAnalysisPresentation[userGender].first,
            ),
            {
              variant: H4,
            },
            {
              variant: H4,
              style: {textAlign: 'center', color: colors.SECONDARY},
            },
          )}
          center={true}
        />
        <div style={styles.contentContainer}>
          <img
            src={images.PERRINE_ONBOARDING}
            alt="Perrine onboarding"
            style={styles.image}
          />
          <TextComponent
            multipleText={createTextObjectReusable(
              onBoardingTranslate('AnalysisPresentationPerrine'),
              {
                variant: H4,
              },
              {
                variant: H4,
                style: {textAlign: 'center', color: colors.SECONDARY},
              },
            )}
            center={true}
          />
        </div>

        <OnBoardingBottomArrow onPress={handleNextArrowClicked} />
        <OnBoardingBottomArrow
          previous={true}
          onPress={handleBackArrowClicked}
        />
      </FadinView>
    </OnBoardingContainer>
  );
}

const styles = {
  contentContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    margin: 'auto',
    marginTop: 30,
    width: '60%',
  },
  image: {
    height: 200,
    width: 200,
    borderRadius: 100,
  },
};

export default OnBoardingAthleteAnalysisIntro;
