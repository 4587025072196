import React, { useEffect, useRef, useState } from "react";

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import TextComponent from "components/atoms/TextComponent";
import TextInput from "components/atoms/TextInput";
import HeightAndWeightTextInput from "components/template/HeightAndWeightTextInput";
import SuccessLottieComponent from "components/atoms/SuccessLottieComponent";
import ClickableTextComponent from "components/atoms/ClickableTextComponent";


import { translate } from "services/remoteConfig";
import { setUserAttributeOnAllPlatforms } from "services/tracking";
import uuid from "services/uuid";
import { addItem, deleteItem, updateItem } from "services/itemApi";

import { selectListItems } from "slices/userItemsSlice";
import {
  resetAthleteSelected,
  selectAthleteSelected,
  setAthleteSelected,
} from "slices/athleteSlice";

import { isPro } from "utils/utils";

import { BODY_1, CONTAINED, H6 } from "constant/constants";

import { colors } from "themes";

import { ReactComponent as AthletesIcon } from "assets/svg/athletesIcon.svg";

const yup = require("yup");

function AddAthleteCustom({ modify }) {

  const heightRef = useRef();
  const weightRef = useRef();
  const successRef = useRef();
  const itemList = useSelector(selectListItems);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const athleteCustomSchema = yup.object({
    name: yup.string().required(translate("NameRequired")),
  });
  const [open, setOpen] = useState(successRef?.current?.getOpen());

  const athleteSlice = useSelector(selectAthleteSelected);

  const [formValues, setFormValues] = useState({
    name: "",
  });

  useEffect(() => {
    if (!modify) return;
    setFormValues({
      name: athleteSlice?.name ? athleteSlice?.name : athleteSlice?.fullName,
    });
  }, []);

  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      await athleteCustomSchema?.validate(formValues, { abortEarly: false });
      return formValues;
    } catch (err) {
      if (err.inner) {
        const errors = {};
        err.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        setFormErrors(errors);
      }
    }
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    if (formErrors[e.target.name]) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: "",
      });
    }
  };

  async function handleButtonClick() {

    const validHeight = await heightRef?.current?.handleSubmit();
    const validWeight = await weightRef?.current?.handleSubmit();
    const validName = await handleSubmit();

    let item = {};
    if (validHeight && validWeight && validName?.name) {
      if (modify) {
        item = {
          id: athleteSlice?.id,
          name: validName?.name,
          height: validHeight,
          weight: validWeight,
          type: "patient",
          pending: false,
        };
      } else {
        item = {
          id: uuid(),
          name: validName?.name,
          height: validHeight,
          weight: validWeight,
          type: "patient",
          pending: false,
        };
      }
    } else return;

    if (Object.keys(athleteSlice).length === 0) {
      const found = itemList.find(element => element?.name === validName?.name);
      if (found) {
        setFormErrors({ name: translate("PatientAlreadyExists") });
        return
      }
      addItem(item).catch((error) => {
        console.log(error);
        return;
      });
      setUserAttributeOnAllPlatforms('number_of_athletes', itemList?.items?.length + 1)
      successRef?.current?.setOpen(true);
      setOpen(true);
    } else {
      updateItem(item).catch((error) => {
        console.log(error);
        return;
      });
      dispatch(setAthleteSelected(item));
      successRef?.current?.setOpen();
      setOpen(true);
    }
  }

  return (
    <>
      <SuccessLottieComponent
        ref={successRef}
        onComplete={() => {
          setTimeout(() => {
            setOpen(false);
            successRef?.current?.setClose();
            if (!modify) navigate("/athletes")
          }, 1000);
        }
        }
      />
      {!open ? (
        <>
          <div style={{ width: "90%" }}>
            <AthletesIcon height={50} width={50} fill="white" style={{ marginTop: "2vh", }} />
            <TextComponent
              sx={{ margin: 0, marginTop: 1, textAlign: "left" }}
              text={modify ? translate("Modify") : isPro() ? translate("CreatePatient") : translate("AddCustomAthlete")}
              variant={H6}
            />
            <div style={{ marginTop: 20, marginBottom: 30 }}>
              <div style={{ marginBottom: 20 }}>
                <TextInput
                  id="nameTextInput"
                  name="name"
                  label={translate("Name")}
                  value={formValues?.name}
                  onChange={handleChange}
                  schema={athleteCustomSchema}
                  error={!!formErrors?.name}
                  helperText={formErrors?.name}
                  image={require("assets/images/guy.png")}
                  handleSubmit={() => {
                    document.getElementById("heightTextInput").focus();
                  }}
                />
              </div>
            </div>
            <HeightAndWeightTextInput
              heightRef={heightRef}
              weightRef={weightRef}
            />
          </div>

          <Button
            variant={CONTAINED}
            sx={{
              width: "90%",
              minHeight: "5vh",
              backgroundColor: colors.SECONDARY,
              margin: "auto",
              marginTop: modify ? "4vh" : null,
              marginBottom: "4vh",
              "&:hover": {
                backgroundColor: colors.OCHY_BLUE,
                transition: "0.2s ease-in-out",
              },
              textTransform: "none",
            }}
            onClick={() => {
              handleButtonClick();
            }}
          >
            {modify ? translate("Edit") : translate("AddAthlete")}
          </Button>
          {modify ? (
            <ClickableTextComponent
              onPress={() => {
                deleteItem(athleteSlice).then((e) => {
                  if (e === undefined) {
                    dispatch(resetAthleteSelected());
                    navigate("/");
                  }
                });
              }}
              sx={{ color: colors.ERROR }}
              title={translate("Delete")}
              variant={BODY_1}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
}

export default AddAthleteCustom;
