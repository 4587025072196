import React from 'react';

import {Button, CircularProgress} from '@mui/material';
import {styled} from '@mui/system';

import {translate} from 'services/remoteConfig';

import {colors} from 'themes';

import {
  GREEN,
  ORANGE,
  RED,
  CONTAINED,
  REVERSE,
  BLUE_BORDER,
} from 'constant/constants';
import TextComponent from './TextComponent';

export default function ButtonComponent({
  type,
  onClick,
  title,
  disabled,
  sx,
  href,
  loading,
}) {
  if (type === ORANGE) {
    if (loading) {
      return (
        <OrangeButton
          variant={CONTAINED}
          sx={{...styles.orangeButton, ...sx}}
          onClick={onClick}
          disabled={disabled}
          href={href}>
          <CircularProgress size={20} color="inherit" />
        </OrangeButton>
      );
    } else
      return (
        <OrangeButton
          variant={CONTAINED}
          sx={{...styles.orangeButton, ...sx}}
          onClick={onClick}
          disabled={disabled}
          href={href}>
          {title}
        </OrangeButton>
      );
  }
  if (type === REVERSE) {
    return (
      <ReverseButton
        variant={CONTAINED}
        sx={{...styles.reverseButton, ...sx}}
        onClick={onClick}
        disabled={disabled}
        href={href}>
        {title}
      </ReverseButton>
    );
  }
  if (type === RED) {
    return (
      <RedButton variant={CONTAINED} sx={styles.redButton} onClick={onClick}>
        {title}
      </RedButton>
    );
  }
  if (type === GREEN) {
    return (
      <GreenButton
        variant={CONTAINED}
        sx={styles.greenButton}
        onClick={onClick}>
        {translate(title)}
      </GreenButton>
    );
  }
  if (type === BLUE_BORDER) {
    return (
      <Button sx={{...styles.blueBorder, ...sx}} onClick={onClick}>
        <TextComponent text={title} />
      </Button>
    );
  } else {
    return (
      <Button variant={CONTAINED} sx={styles.default} onClick={onClick}>
        {translate(title)}
      </Button>
    );
  }
}

const styles = {
  blueBorder: {
    marginLeft: 2,
    paddingRight: 4,
    paddingLeft: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '2px',
    borderRadius: 10,
    border: `3px solid ${colors.PRIMARY}`,
    '&:hover': {
      backgroundColor: colors.PRIMARY,
    },
  },
  default: {
    textTransform: 'none',
    borderRadius: 2,
    height: 25,
  },
  greenButton: {
    textTransform: 'none',
    backgroundColor: colors.VALID,
    borderRadius: 2,
    height: 25,
  },
  orangeButton: {
    backgroundColor: colors.SECONDARY,
    borderRadius: 10,
    textTransform: 'none',
    minWidth: '20vh',
  },
  redButton: {
    backgroundColor: colors.ERROR,
    borderRadius: 2,
    textTransform: 'none',
  },
  reverseButton: {
    backgroundColor: colors.BLACK,
    borderRadius: 2,
    textTransform: 'none',
    minWidth: '20vh',
    color: colors.WHITE,
  },
};

const GreenButton = styled(Button)({
  '&:hover': {
    backgroundColor: colors.VALID,
    color: colors.WHITE,
  },
});
const OrangeButton = styled(Button)({
  '&:hover': {
    backgroundColor: colors.OCHY_BLUE,
    transition: '0.2s ease-in-out',
  },
  '&:disabled': {
    backgroundColor: '#2E2E2E',
    color: colors.TEXT_GRAY,
  },
});
const ReverseButton = styled(Button)({
  '&:hover': {
    backgroundColor: colors.OCHY_BLUE,
    color: colors.WHITE,
    transition: '0.2s ease-in-out',
  },
});
const RedButton = styled(Button)({
  '&:hover': {
    backgroundColor: colors.RED,
    color: colors.WHITE,
  },
});
