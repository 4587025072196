import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import VideoComponent from '../AnalysisViewDetailsScreen/components/VideoComponent';
import {getAnalysisDataWithPanel, getOverstrideData} from 'services/analysis';
import {ANALYSIS, BODY_1, BODY_2, H5} from 'constant/constants';
import {getBestSegment, prioritySegmentToWork} from 'utils/utils';
import AnalysisCardContent from '../AnalysisViewDetailsScreen/components/AnalysisCardContent';
import RoundedScoreMeter from '../AnalysisListCard/components/RoundedScoreMeter';
import TextComponent from 'components/atoms/TextComponent';
import {score_colors} from '../AnalysisListCard/constants/analysisListConstants';
import {Button, Grid} from '@mui/material';
import CompareAnalysisSegments from './components/CompareAnalysisSegments';
import CompareAnalysisScreen from './components/CompareAnalysisScreen';

function CompareAnalysis() {
  const location = useLocation();
  const navigate = useNavigate();

  const [firstVideoFlip, setFirstVideoFlip] = useState(false);
  const [secondVideoFlip, setSecondVideoFlip] = useState(false);

  const firstAnalysis = location?.state?.comparedAnalysis[0]?.analysisData;
  const secondAnalysis = location?.state?.comparedAnalysis[1]?.analysisData;

  const firstAnalysisData = getAnalysisDataWithPanel(firstAnalysis, ANALYSIS);
  const secondAnalysisData = getAnalysisDataWithPanel(secondAnalysis, ANALYSIS);

  const fistAnalysisValueVideo = firstAnalysis?.videoUrl;
  const secondAnalysisValueVideo = secondAnalysis?.videoUrl;

  function handleVideoFlip(index) {
    if (index === 0) {
      setFirstVideoFlip(!firstVideoFlip);
    } else {
      setSecondVideoFlip(!secondVideoFlip);
    }
  }

  function handleAnalysisPressed(index) {
    navigate(
      `/analysis/${location?.state?.comparedAnalysis[index]?.analysis?.data?.uploadVideoId}`,
      {
        state: {
          analysis: location?.state?.comparedAnalysis[index]?.analysis,
          personalised: firstAnalysis?.personalised,
        },
      },
    );
  }
  return (
    <div style={{display: 'flex'}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '90vh',
          width: '47%',
        }}>
        <div style={{width: '100%'}}>
          <VideoComponent
            video={fistAnalysisValueVideo}
            flip={firstVideoFlip}
          />
        </div>
        <div style={{width: '100%'}}>
          <VideoComponent
            video={secondAnalysisValueVideo}
            flip={secondVideoFlip}
          />
        </div>
      </div>
      <div style={{width: '58%'}}>
        <div style={{height: '50%'}}>
          <CompareAnalysisScreen
            analysisData={firstAnalysisData}
            handleAnalysisPressed={() => {
              handleAnalysisPressed(0);
            }}
            handleVideoFlip={() => {
              handleVideoFlip(0);
            }}
          />
        </div>
        <CompareAnalysisScreen
          analysisData={secondAnalysisData}
          handleAnalysisPressed={() => {
            handleAnalysisPressed(1);
          }}
          handleVideoFlip={() => {
            handleVideoFlip(1);
          }}
        />
      </div>
    </div>
  );
}

export default CompareAnalysis;
