import React, {useEffect} from 'react';

import {onBoardingTranslate} from 'services/remoteConfig';
import {useNavigate} from 'react-router-dom';

import FadinView from 'components/atoms/FadinView';
import TextComponent from 'components/atoms/TextComponent';
import OnBoardingBottomArrow from 'screen/auth/registration/onBoarding/components/OnBoardingBottomArrow';
import OnBoardingButtonList from 'screen/auth/registration/onBoarding/components/OnBoardingButtonList';

import {mpIdentify, mpTrack} from 'services/mixpanel';

import {createTextObjectReusable} from 'utils/utils';

import {BOLD, COACH, H4, REGISTRATION_DATA} from 'constant/constants';
import {specializationsByUserType} from 'screen/auth/registration/onBoarding/constants/onBoardingButtonData';

import {colors} from 'themes';

function OnBoardingSpecialization() {
  const navigate = useNavigate();
  mpIdentify();

  const userType = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.userType;

  useEffect(() => {
    userType === COACH
      ? mpTrack(
          'Web App OnBoarding Coach Specialization Selection Screen Viewed',
        )
      : mpTrack(
          'Web App OnBoarding Professional Specialization Selection Screen Viewed',
        );
  }, []);

  function handleBackArrowPressed() {
    navigate('/registration/user-number');
  }

  return (
    <FadinView>
      <div style={styles.container}>
        <div style={styles.titleContainer}>
          <TextComponent
            multipleText={createTextObjectReusable(
              onBoardingTranslate('UserTypeHeadLine'),
              {
                variant: H4,
                weight: BOLD,
              },
              {
                variant: H4,
                weight: BOLD,
                style: {textAlign: 'center', color: colors.SECONDARY},
              },
            )}
            center={true}
          />
        </div>
        <div style={styles.buttonContainer}>
          <OnBoardingButtonList
            data={specializationsByUserType[userType]}
            mutlipleSelection={true}
          />
        </div>
      </div>
      <OnBoardingBottomArrow previous={true} onPress={handleBackArrowPressed} />
    </FadinView>
  );
}
const styles = {
  buttonContainer: {
    height: '100%',
    justifyContent: 'space-evenly',
    paddingTop: '5%',
    width: '100%',
  },

  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5%',
    width: '100%',
  },
};
export default OnBoardingSpecialization;
