import React from "react";

import { ReactComponent as PronationIcon } from "assets/svg/pronation.svg";
import { ReactComponent as SupinationIcon } from "assets/svg/supination.svg";
import { ReactComponent as NeutralIcon } from "assets/svg/neutral.svg";
import { translate } from "services/remoteConfig";
import { colors } from "themes";
import { BOLD, H1, H5, H6, LIGHT } from "constant/constants";
import TextComponent from "components/atoms/TextComponent";

function PronationAnalysisBottomCard({ analysisData, locked }) {
  const icon = {
    Supination: <SupinationIcon width="40" height="40" className="aCenter" />,
    Pronation: <PronationIcon width="40" height="40" className="aCenter" />,
    Neutral: <NeutralIcon width="40" height="40" className="aCenter" />,
  };

  if (locked) {
    return (
      <div style={styles.locked}
      >
        <TextComponent
          variant={H5}
          sx={{ color: colors.YELLOW }}
          text={translate('FreeAnalysis')}
        />
      </div>
    );
  }

  return (
    <div style={styles.cardSubmenu}>
      <div style={styles.footContainer}>
        <TextComponent text={translate(analysisData?.left_foot?.posture)} variant={H6} weight={LIGHT} />
        <div style={styles.leftFoot}>
          {icon[analysisData?.left_foot?.posture]}
        </div>
      </div>
      <div style={{ ...styles.footContainer, ...styles.jStart }}>
        <div style={styles.rightFoot}>
          {icon[analysisData?.right_foot?.posture]}
        </div>
        <TextComponent text={translate(analysisData?.right_foot?.posture)} variant={H6} weight={LIGHT} />
      </div>
    </div>
  );
}

const styles = {
  aCenter: {
    alignSelf: "center",
  },
  cardSubmenu: {
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    height: "100%",
    width: "100%",
    justifyContent: "space-between",
  },
  footContainer: {
    display: "flex",
    flex: 1,
    height: "100%",
    justifyContent: "flex-end",
    marginTop: "5px",
    verticalAlign: "middle",
  },
  footTitle: {
    alignSelf: "center",
    justifyContent: "center",
    color: colors.TEXT_WHITE, // Replace with your color variable

  },
  jStart: {
    justifyContent: "flex-start",
  },
  locked: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '100%'
  },
  leftFoot: {
    justifyContent: "center",
    transform: "scaleX(-1)",
  },
  rightFoot: {
    justifyContent: "center",
  },
};

export default PronationAnalysisBottomCard;
