import styled from '@emotion/styled';
import {Button} from '@mui/material';
import {ATHLETE, CONTAINED} from 'constant/constants';
import {useProfileChanges} from 'hooks/useProfileChanges';
import React from 'react';
import {isMobile} from 'react-device-detect';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  appleSSOLogin,
  createUserInDB,
  createUserInDBAndAddUsages,
  googleSsoLogin,
} from 'services/authApi';
import {mpTrack} from 'services/mixpanel';
import {translate, USER_LOCALE} from 'services/remoteConfig';
import {doesUserExist} from 'services/userApi';
import {updateProfileStore} from 'slices/userSlice';
import {colors} from 'themes';
import {auth} from 'utils/firebaseInit';

function SsoButtons({register = false, affiliate = false, callBack}) {
  useProfileChanges();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const renderSSOStyle = () => {
    if (isMobile && affiliate) {
      return {
        width: '45%',
        height: '5rem',
        borderRadius: '1rem',
        fontSize: '0.9rem',
        fontWeight: '600',
        color: 'black',
        textTransform: 'none',
        flexDirection: 'column',
        backgroundColor: '#E9E9E9',
      };
    } else return styles.buttonSSO;
  };

  function handleSSoPressed(user, data) {
    if (data && affiliate) {
      callBack();
      return;
    }
    if (data) {
      navigate('/');
    } else {
      if (affiliate) {
        createUserInDBAndAddUsages({
          emailAddress: auth.currentUser.email,
          fullName: auth.currentUser.displayName,
          onBoarded: false,
          registered: false,
          registerDate: new Date().toISOString(),
          platform: 'Web app user',
          language: USER_LOCALE,
          onlyUserType: [ATHLETE],
        }).then(() => {
          callBack();
        });
        return;
      }
      createUserInDB({
        emailAddress: auth.currentUser.email,
        fullName: auth.currentUser.displayName,
        onBoarded: false,
        registered: false,
        registerDate: new Date().toISOString(),
        platform: 'Web app user',
      }).then(() => {
        dispatch(
          updateProfileStore({
            emailAddress: auth.currentUser.email,
            fullName: auth.currentUser.displayName,
            userID: user?.uid,
          }),
        );
        mpTrack('Register Web Button Clicked success');
        mpTrack('Web App Register With Apple');
        navigate('/');
      });
    }
  }

  function hangleAppleSSOLogin() {
    mpTrack('Web App Apple Sign In Clicked');
    appleSSOLogin(user => {
      doesUserExist(user?.uid).then(data => {
        handleSSoPressed(user, data);
      });
    });
  }

  function handleGoogleSSOLogin() {
    mpTrack('Web App Google Sign In Clicked');
    googleSsoLogin(user => {
      console.log('user here');
      doesUserExist(user?.uid).then(data => {
        console.log('here handleSsoPRessed');
        handleSSoPressed(user, data);
      });
    });
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: affiliate ? 'row' : 'column',
        alignItems: 'center',
        justifyContent: affiliate ? 'space-evenly' : 'center',
        width: '100%',
      }}>
      <SsoButton
        variant={CONTAINED}
        sx={{
          ...styles.buttonSSO,
          ...{marginTop: affiliate ? 0 : '1rem'},
          ...renderSSOStyle(),
        }}
        onClick={handleGoogleSSOLogin}>
        <img
          src={require('assets/images/google.png')}
          alt="google"
          style={{height: 30, objectFit: 'cover'}}
        />
        {translate(register ? 'GoogleSignUpButton' : 'GoogleSignInButton')}
      </SsoButton>
      <SsoButton
        variant={CONTAINED}
        sx={{
          ...renderSSOStyle(),
          ...{marginTop: affiliate ? 0 : '1rem'},
        }}
        onClick={hangleAppleSSOLogin}>
        <img
          src={require('assets/images/apple.png')}
          alt="google"
          style={{height: 30, objectFit: 'cover'}}
        />
        {translate(register ? 'AppleSignUpButton' : 'AppleSignInButton')}
      </SsoButton>
    </div>
  );
}

const SsoButton = styled(Button)({
  '&:hover': {
    backgroundColor: colors.WHITE,
  },
});

const styles = {
  buttonSSO: {
    width: '45%',
    height: '3rem',
    borderRadius: '20rem',
    fontSize: '0.9rem',
    fontWeight: '600',
    color: 'black',
    textTransform: 'none',
    backgroundColor: '#E9E9E9',
  },
};
export default SsoButtons;
