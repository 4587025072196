import HeightComponent from "components/atoms/HeightComponent";
import TextComponent from "components/atoms/TextComponent";
import WeightComponent from "components/atoms/WeightComponent";
import { BODY_1, BODY_2 } from "constant/constants";
import { ReactComponent as Arrow } from "assets/svg/arrow-right-bold.svg";
import React, { useContext } from "react";
import "./styles/AthleteCard.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAthleteSelected, setAthleteSelected } from "slices/athleteSlice";

function AthleteCard({ item }) {

  const athleteSlice = useSelector(selectAthleteSelected);
  const dispatch = useDispatch();

  return (
    <Link to={`/analysis-athlete/${item?.name}`} style={{ textDecoration: "none" }}>
      <div
        className="athlete-card-container"
        style={athleteSlice?.id === item.id ? { backgroundColor: "#38393c" } : null}
        onClick={() => dispatch(setAthleteSelected(item))}
      >
        <div
          style={{
            width: "70%",
            marginLeft: 15,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <TextComponent
            sx={{ margin: 0, textTransform: "capitalize" }}
            text={item?.fullName}
            variant={BODY_1}
          />
          <TextComponent
            sx={{ margin: 0, color: "#A3A3A3", marginBottom: 0.5 }}
            text={item?.emailAddress}
            variant={BODY_2}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeightComponent height={item?.height} />
            <WeightComponent weight={item?.weight} />
          </div>
        </div>
        <div
          style={{
            width: "30%",
            alignContent: "center",
            display: "flex",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Arrow height={20} width={20} />
        </div>
      </div>
    </Link>
  );
}

export default AthleteCard;
