import React, {useEffect, useState} from 'react';

import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import ProgressCircle from 'components/atoms/ProgressCircle';

import {onBoardingTranslate} from 'services/remoteConfig';
import {updateProfile} from 'services/userApi';
import {mpTrack} from 'services/mixpanel';

import {updateProfileStore} from 'slices/userSlice';

function OnBoardingProfileCreationList() {
  const [progresses, setProgresses] = useState([0, 0, 0]);
  const [currentCircle, setCurrentCircle] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileCompletionMessage = [
    onBoardingTranslate('PofileCompletionHeadline'),
    onBoardingTranslate('PofileCompletionShoes'),
    onBoardingTranslate('PofileCompletionFinished'),
  ];

  useEffect(() => {
    handleStart();
  }, []);

  const handleStart = () => {
    updateProgress(currentCircle);
  };

  const updateProgress = index => {
    if (index >= progresses?.length) return;
    let value = 0;
    const interval = setInterval(() => {
      if (value < 100) {
        value += 10;
        setProgresses(prev => {
          const newProgresses = [...prev];
          newProgresses[index] = value;
          return newProgresses;
        });
      } else {
        clearInterval(interval);
      }
    }, 100);
  };

  const handleComplete = index => {
    if (index < progresses?.length - 1) {
      setCurrentCircle(index + 1);
      updateProgress(index + 1);
    } else {
      updateProfile({registered: true});
      dispatch(updateProfileStore({registered: true}));
      mpTrack('Web App Onboarding Completed');
      navigate('/');
    }
  };
  return (
    <div>
      {progresses.map((progress, index) => (
        <ProgressCircle
          key={index}
          progress={progress}
          onComplete={() => handleComplete(index)}
          text={profileCompletionMessage[index]}
        />
      ))}
    </div>
  );
}

export default OnBoardingProfileCreationList;
