import React from "react";

import TextComponent from "components/atoms/TextComponent";
import TermsNConditions from "screen/pp&tos/TermsNConditions";
import PrivacyPolicy from "screen/pp&tos/PrivacyPolicy";
import EditProfil from "../EditProfil";
import ContactSales from "screen/profile/settings/components/ContactSales";
import Settings from "./components/Settings";
import DeleteAccount from "./components/DeleteAccount";

import { translate } from "services/remoteConfig";

import { settingsConfig } from "constant/settingsConstant";
import { H6 } from "constant/constants";

function SettingsSelector({ name, onClick }) {
    const config = settingsConfig();
    switch (name) {
        case 'EditProfil':
            return <EditProfil />;
        case 'TermsConditions':
            return <TermsNConditions />;
        case 'PrivacyTitle':
            return <PrivacyPolicy />;
        case 'GetMoreAnalysis':
            return (
                <div style={styles.contactChat}>
                    <TextComponent text={translate('ContactChat')} variant={H6} />;
                </div>)
        case 'ContactSales':
            return <ContactSales />;
        case 'Settings':
            return <Settings config={config} onClick={onClick} />;
        case 'DeleteAccount':
            return <DeleteAccount />;
        default:
            return <></>;
    }
}

const styles = {
    contactChat: {
        display: 'flex',
        alignItems: 'center',
        width: '90%',
        marginTop: '20vh'
    }
}

export default SettingsSelector;

