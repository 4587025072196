import React, {useEffect, useState} from 'react';

import {useLocation, useNavigate} from 'react-router-dom';

import FadinView from 'components/atoms/FadinView';
import TextComponent from 'components/atoms/TextComponent';
import OnBoardingContainer from 'screen/auth/registration/onBoarding/components/OnBoardingContainer';
import OnBoardingBottomArrow from 'screen/auth/registration/onBoarding/components/OnBoardingBottomArrow';

import {onBoardingTranslate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {BOLD, H3, H4, LIGHT, REGISTRATION_DATA} from 'constant/constants';
import {onBoardingWelcomeTypologieText} from 'screen/auth/registration/onBoarding/constants/onBoardingText';

import {
  createTextObjectReusable,
  replaceUserNameByActualName,
} from 'utils/utils';

import {colors} from 'themes';

function OnBoardingAthleteTypologyWelcome() {
  const navigate = useNavigate();
  const location = useLocation();
  const [index, setIndex] = useState(location?.state?.value || 0);

  useEffect(() => {
    index === 0
      ? mpTrack('Web App OnBoarding Welcome Athlete Typologie Screen Viewed')
      : mpTrack('Web App OnBoarding Asking Athlete Info Screen Viewed');
  }, [index]);

  const userTypology = JSON.parse(
    localStorage.getItem(REGISTRATION_DATA),
  )?.typology;

  function handleArrowPressed() {
    index === 0 ? setIndex(1) : navigate('/registration/athlete/gender');
  }

  function handleBackArrowPressed() {
    if (index === 1) setIndex(0);
    else navigate('/registration/athlete/typology');
  }
  return (
    <OnBoardingContainer>
      {index === 0 ? (
        <FadinView>
          <TextComponent
            multipleText={createTextObjectReusable(
              onBoardingTranslate(
                onBoardingWelcomeTypologieText[userTypology][0],
              ),
              {
                variant: H4,
              },
              {
                variant: H4,
                style: {
                  textAlign: 'center',
                  color: colors.SECONDARY,
                },
              },
              {
                variant: H4,
                style: {textAlign: 'center'},
              },
            )}
            center={true}
          />
          <div style={styles.textContainer} />
          <TextComponent
            multipleText={createTextObjectReusable(
              onBoardingTranslate(
                onBoardingWelcomeTypologieText[userTypology][1],
              ),
              {
                variant: H4,
                weight: LIGHT,
              },
              {
                variant: H4,
                weight: BOLD,
                style: {
                  textAlign: 'center',
                  color: colors.SECONDARY,
                },
              },
              {
                variant: H4,
                weight: BOLD,
                style: {textAlign: 'center'},
              },
            )}
            variant={H3}
            center={true}
          />
        </FadinView>
      ) : (
        <FadinView>
          <TextComponent
            multipleText={createTextObjectReusable(
              onBoardingTranslate('AskingAthleteInfo') +
                '\n' +
                replaceUserNameByActualName(
                  onBoardingTranslate('AskingAthleteInfoSecondary'),
                ),
              {
                variant: H4,
              },
              {
                variant: H4,
                style: {textAlign: 'center', color: colors.SECONDARY},
              },
            )}
            variant={H3}
            weight={BOLD}
            center={true}
          />
        </FadinView>
      )}
      <OnBoardingBottomArrow onPress={handleArrowPressed} />
      <OnBoardingBottomArrow previous={true} onPress={handleBackArrowPressed} />
    </OnBoardingContainer>
  );
}
const styles = {
  textContainer: {
    marginTop: 40,
  },
};
export default OnBoardingAthleteTypologyWelcome;
