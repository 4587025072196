import React from "react";

import TextComponent from "components/atoms/TextComponent";

import { translate } from "services/remoteConfig";

import { H4 } from "constant/constants";

function NoCredits() {
  return (
    <div style={styles.container}>
      <TextComponent
        text={translate("NoCredit")+". "+ translate("ContactChat")}
        variant={H4}
        sx={styles.title}
      />
    </div>
  );
}

const styles = {
  container: {
    alignItems: "center",
    display: "flex",
    height: "80vh",
    justifyContent: "center",
    width : "70%",
    margin: "0 auto"
  },
  title: {
    
    color: "white",
    textAlign: "center",
  },
};

export default NoCredits;
