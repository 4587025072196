import React from "react";

import { translate } from "services/remoteConfig";

import TextComponent from "components/atoms/TextComponent";
import { BODY_1 } from "constant/constants";

function NoExercices() {
  return (
    <TextComponent text={translate("NoExercices")} variant={BODY_1} sx={{margin : 0}}/>

  );
}

export default NoExercices;
