import React from "react";

import TextComponent from "components/atoms/TextComponent";

import { createTextObjectReusable } from "utils/utils";

import { BOLD, H5 } from "constant/constants";

import { colors } from "themes";

function AnalysisRightPanelRunningStyles({ displayData }) {
    return (
        <div style={styles.container}>
            <TextComponent
                text={displayData?.stylesTitle}
                variant={H5}
            />
            <TextComponent
                multipleText={createTextObjectReusable(
                    displayData?.stylesExplanation,
                    { style: styles.explanationText },
                    {},
                    {
                        style: styles.stylesTitle,
                        weight: BOLD,
                    },
                )}
            />
        </div>
    )
}

const styles = {
    container: {
        margin: '2vh'
    },
    explanationText: {
        color: colors.WHITE_OPACITY_70,
        marginTop: 10,
    },
    stylesTitle: {
        color: colors.WHITE,
        marginTop: 10,
    },
}

export default AnalysisRightPanelRunningStyles;