import React from "react";

import { IconButton } from "@mui/material";

import arrow from "assets/svg/arrow-button-left.svg";

import "./styles/SliderArrow.css";

function SidePanArrowComponent({ onPress, isOpen }) {
  return (
    <IconButton onClick={onPress}>
      <img className="slider-arrow"
        src={arrow}
        alt="slider arrow"
        style={!isOpen ? { scale: "-1" } : null}
      />
    </IconButton>
  );
}

export default SidePanArrowComponent;
