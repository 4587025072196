import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from 'firebase/storage';
import {storage} from 'utils/firebaseInit';
import uuid from './uuid';
import {
  ANALYSISTYPE_RUNNING,
  ANALYSISTYPE_RUNNING_TREADMILL,
} from 'constant/constants';

export async function uploadFile(
  uri,
  onProgress,
  onError,
  onComplete,
  analysisData,
  fileType,
  userId,
) {
  try {
    let blob = undefined;
    if (uri.startsWith('content://')) {
      console.log('Loading video blob from ' + uri);
      blob = await getVideoBlob(uri);
      console.log('Done, content loaded.');
    } else {
      const response = await fetch(uri);
      blob = await response.blob();
    }

    const fileId = uuid();
    let destFilename;
    switch (analysisData?.type) {
      case ANALYSISTYPE_RUNNING:
        destFilename = `video-analysis-input/${fileId}.mp4`;
        break;
      case ANALYSISTYPE_RUNNING_TREADMILL:
        destFilename = `video-analysis-input/${fileId}.mp4`;
        break;
      case 'pronation':
        destFilename = `video-pronation-input/${fileId}.mp4`;
        break;
      case 'brand-logo':
        destFilename = `brand-logo/${userId}.png`;
        break;
      case 'profile_picture':
        destFilename = `profile_picture/${userId}.png`;
        break;
      default:
        destFilename = `video-analysis-input/${fileId}.mp4`;
    }

    const storageRef = ref(storage, destFilename);
    const uploadTask = uploadBytesResumable(storageRef, blob);

    if (fileType === 'image') {
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          function (snap) {
            const progress = (snap.bytesTransferred / snap.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
          },
          function error(err) {
            console.log('Error during upload:', err);
            reject(err);
          },
          function complete() {
            getDownloadURL(storageRef)
              .then(url => {
                console.log('Download URL:', url);
                resolve(url);
              })
              .catch(error => {
                console.error('Error getting download URL:', error);
                reject(error);
              });
          },
        );
      });
    } else {
      uploadTask.on(
        'state_changed',
        function (snap) {
          const progress = snap.bytesTransferred / snap.totalBytes;
          onProgress(progress);
        },
        function error(a) {
          onError(a);
        },
        async function complete() {
          // const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          //blob.close();
          //set ici
          onComplete(fileId, analysisData);
        },
      );
    }
  } catch (error) {
    console.log(
      JSON.stringify(error, ['message', 'arguments', 'type', 'name']),
    );
  }
}

export const getVideoBlob = uri => {
  // https://github.com/expo/expo/issues/2402#issuecomment-443726662
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError('Content load request failed'));
    };
    xhr.responseType = 'blob';
    xhr.open('GET', uri, true);
    xhr.send(null);
  });
};
