import {firestore} from 'utils/firebaseInit';
import {collection, doc, getDoc} from 'firebase/firestore';
import {USER_LOCALE} from './remoteConfig';

const UI_COLLECTION = 'ui';

export async function getExercisesList() {
  try {
    const language = USER_LOCALE;
    const collectionRef = collection(firestore, UI_COLLECTION);
    const ref = doc(collectionRef, `exercises_${language}`);
    const docs = getDoc(ref);
    if ((await docs).exists()) {
      const data = (await docs).data();
      return data;
    } else return;
  } catch (error) {
    console.log('error in getExercisesList', error);
  }
}

export async function getUserTypeNumber() {
  try {
    const collectionRef = collection(firestore, UI_COLLECTION);
    const ref = doc(collectionRef, 'userTypeNumber');
    const docs = getDoc(ref);
    if ((await docs).exists()) {
      const data = (await docs).data();
      return data;
    } else return;
  } catch (error) {
    console.log('error in getUserTypeNumber', error);
  }
}

export async function getGoodViedeoTutorial() {
  try {
    const collectionRef = collection(firestore, UI_COLLECTION);
    const ref = doc(collectionRef, 'goodVideoTutorial');
    const docs = getDoc(ref);
    if ((await docs).exists()) {
      const data = (await docs).data();
      return data[USER_LOCALE];
    } else return;
  } catch (error) {
    console.log('error in getGoodViedeoTutorial', error);
  }
}
