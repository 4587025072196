import TextComponent from "components/atoms/TextComponent";
import { BODY_1, H5 } from "constant/constants";
import React from "react";

function AnalysisRightPanelGraph({ displayData }) {
  return (
    <div>
      <TextComponent
        text={displayData?.graphTitle}
        variant={H5}
        sx={{ marginBottom: 2, marginTop: 2, marginLeft: 1 }}
      />
      <TextComponent
        text={displayData?.graphData}
        variant={BODY_1}
        sx={{ lineHeight: 1.75, marginLeft: 1 }}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          marginTop: "10%",
        }}
      >
        <img src={require("assets/images/poulaineIcon.png")} alt="graph" />
      </div>
    </div>
  );
}

export default AnalysisRightPanelGraph;
