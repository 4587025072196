import React, { forwardRef, useImperativeHandle, useState } from "react";

import { Fade, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import Lottie from "lottie-react";

import TextComponent from "components/atoms/TextComponent";
import ButtonComponent from "components/atoms/ButtonComponent";

import { translate } from "services/remoteConfig";
import { postMessageOnSlack } from "services/slack";

import { BODY_1, BOLD, MEDIUM, ORANGE } from "constant/constants";

import { colors } from "themes";

import { ReactComponent as Close } from "assets/svg/close.svg";
import wipUpload from "assets/animations/wipUpload.json";
import { mpTrack } from "services/mixpanel";

const ModalInProgress = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const [type, setType] = useState(null);
    const [clapped, setClapped] = useState(false);
    const userSlice = useSelector((state) => state?.user);

    function handleClose() {
        setOpen(false);
        setClapped(false);
    };

    function handleClap() {
        setClapped(true);
        mpTrack('Web App ' + type + 'Clapped')
        postMessageOnSlack(type, userSlice?.emailAddress, "clapped", userSlice);
    }

    useImperativeHandle(ref, () => ({
        getOpen() {
            return open;
        },
        open(type) {
            setType(type);
            setOpen(true);
        },
        setClose() {
            setOpen(false);
        },
    }));

    return (
        <Modal
            open={open}
            onClose={handleClose}
            slotProps={{
                backdrop: {
                    style: { backgroundColor: "transparent" }
                }
            }}
            sx={styles.modal}
        >
            <Fade in={open}>
                <div
                    style={styles.container}
                >
                    <div
                        style={styles.closeCross}
                        onClick={(event) => {
                            event?.preventDefault();
                            event?.stopPropagation();
                            handleClose()
                        }}
                    >
                        <Close stroke="white" width={50} height={50} />
                    </div>
                    <TextComponent
                        text={translate('FeatureNotAvailable')}
                        weight={BOLD}
                        sx={styles.title}
                    />
                    <div style={styles.rowLottie} >
                        <Lottie animationData={wipUpload} style={styles.wip} />
                        <TextComponent
                            text={translate('FeatureUnderProgress')}
                            weight={MEDIUM}
                            sx={styles.underProgress}
                        />
                    </div>
                    <TextComponent
                        text={translate('GiveClap')}
                        weight={MEDIUM}
                        sx={styles.giveClap}
                    />
                    {!clapped ? <ButtonComponent title={translate('Clap')} type={ORANGE} sx={styles.button} onClick={handleClap} /> :
                        <div style={styles.buttonValidated}>
                            <TextComponent
                                text={translate('ThankYouExclamation')}
                                variant={BODY_1} />
                        </div>
                    }
                </div>
            </Fade>
        </Modal>
    );
});

const styles = {
    button: {
        marginBottom: '2vh'
    },
    buttonValidated: {
        backgroundColor: colors.VALID_IMAGE,
        borderRadius: 30,
        height: "4vh",
        minWidth: "20vh",
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '2vh'
    },
    closeCross: {
        cursor: "pointer",
        position: "absolute",
        top: 0,
        right: 0,
    },
    container: {
        backgroundColor: colors.BLACK,
        width: "40vw",
        position: "relative",
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
    },
    giveClap: {
        marginBottom: '1vh',
        fontSize: '1.2vw'
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: '60vh',
    },
    rowLottie: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    title: {
        color: colors.SECONDARY,
        margin: '1vh',
        fontSize: '1.2vw',
        width: '90%',
    },
    underProgress: {
        marginLeft: '1vw',
        marginRight: '3vw',
        fontSize: '1.2vw'
    },
    wip: {
        width: '15vh',
        marginLeft: '3vw'
    }
}

export default ModalInProgress;
