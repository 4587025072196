import React from 'react';

import TextComponent from 'components/atoms/TextComponent';

import { translate } from 'services/remoteConfig';

import { BOLD } from 'constant/constants';

import { colors } from 'themes';

import { ReactComponent as ActiveIcon } from 'assets/svg/activeIcon.svg';

export default function StatusTag() {
    return (
        <div style={styles.container}>
            <ActiveIcon height={'1.2vw'} width={'1.2vw'} stroke={colors.WHITE} />
            <TextComponent sx={styles.text} text={translate('active')} weight={BOLD} />
        </div>
    );
}

const styles = {
    container: {
        backgroundColor: colors.VALID_IMAGE,
        borderRadius: '3vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 3,

    },
    text: {
        marginLeft: '2vh',
        marginRight: '2vh',
        fontSize: '1vw'
    },
};
