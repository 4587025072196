import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  creditNumber: 0,
  nTrial: 0,
  monthlyCreditNumber: 0,
  yearlyPlan: false,
  monthlyPlan: false,
  yearly10: false,
  yearly30: false,
  monthly10: false,
  monthly30: false,
};

export const usageSlice = createSlice({
  name: 'usage',
  initialState,
  reducers: {
    resetUsageStore: (state, action) => {
      state.creditNumber = 0;
      state.nTrial = 0;
      state.monthlyCreditNumber = 0;
      state.monthlyPlan = false;
      state.yearlyPlan = false;
      state.yearly10 = false;
      state.yearly30 = false;
      state.monthly10 = false;
      state.monthly30 = false;
    },

    setCreditNumber: (state, action) => {
      state.creditNumber = action?.payload;
    },
    setNTrial: (state, action) => {
      state.nTrial = action?.payload;
    },
    setMonthlyCreditNumber: (state, action) => {
      state.monthlyCreditNumber = action?.payload;
    },
    setYearlyPlan: (state, action) => {
      state.yearlyPlan = action?.payload;
    },
    setMonthlyPlan: (state, action) => {
      state.monthlyPlan = action?.payload;
    },
    updateUsageStore: (state, action) => {
      state.creditNumber = action?.payload?.creditNumber || 0;
      state.nTrial = action?.payload?.trial || 0;
      state.monthlyCreditNumber = action?.payload?.monthlyCreditNumber || 0;
      state.yearlyPlan = action?.payload?.yearlyPlan || false;
      state.monthlyPlan = action?.payload?.monthlyPlan || false;
      state.yearly10 = action?.payload?.yearly10 || false;
      state.yearly30 = action?.payload?.yearly30 || false;
      state.monthly10 = action?.payload?.monthly10 || false;
      state.monthly30 = action?.payload?.monthly30 || false;
    },
  },
});

export const {
  resetUsageStore,
  setCreditNumber,
  setNTrial,
  updateUsageStore,
  setMonthlyCreditNumber,
  setYearlyPlan,
  setMonthlyPlan,
} = usageSlice.actions;

export const selectCreditNumber = state => state?.usage?.creditNumber;
export const selectNTrial = state => state?.usage?.nTrial;
export const selectMonthlyCreditNumber = state =>
  state?.usage?.monthlyCreditNumber;
export const selectYearlyPlan = state => state?.usage?.yearlyPlan;
export const selectMonthlyPlan = state => state?.usage?.monthlyPlan;

export const selectUsage = state => state.usage;

export default usageSlice.reducer;
