import React, { useEffect, useState } from "react";

import { ToggleButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import TextComponent from "./TextComponent";

import {
  resetAthleteSelected,
  selectAthleteSelected,
} from "slices/athleteSlice";

import { translate } from "services/remoteConfig";

import { colors } from "themes";

function NavigationPanelButton({ id, icon: Icon, path, title }) {
  const [selected, setSelected] = useState();
  const [isHover, setIsHover] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const athleteSlice = useSelector(selectAthleteSelected);
  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  useEffect(() => {
    if (location?.pathname.includes("analysis-athlete") && athleteSlice) {
      setSelected(2);
    } else if (location?.pathname === path) setSelected(id);
    else if (path === "/" && location?.pathname?.includes("analysis"))
      setSelected(id);
    else setSelected();
  }, [location]);

  return (
    <ToggleButton
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        ...styles?.button,
        backgroundColor: selected === id ? colors?.SELECTED : null,
      }}
      sx={{
        "&:hover": {
          backgroundColor: colors?.HOVER,
        },
      }}
      onClick={() => {
        dispatch(resetAthleteSelected());
        setSelected(id);
        navigate(path);
      }}
    >
      <div style={styles.contentContainer}>
        <Icon
          height={20}
          width={20}
          fill={
            selected === id ? colors.WHITE : isHover ? "#D6D6D6" : "#858585"
          }
        />
      </div>
      <div style={{ ...styles.contentContainer, width: "70%" }}>
        <TextComponent
          text={translate(title)}
          sx={{
            color:
              selected === id ? colors.WHITE : isHover ? "#D6D6D6" : "#858585",
          }}
        />
      </div>
    </ToggleButton>
  );
}

const styles = {
  button: {
    alignItems: "center",
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    height: "75px",
    marginTop: 1,
    width: "96%",
  },
  contentContainer: {
    display: "flex",
    height: "6vh",
    alignItems: "center",
    width: "30%",
    justifyContent: "center",
    float: "left",
  },
};
export default NavigationPanelButton;
