import React, {useRef} from 'react';

import './styles/AthleteInfos.css';
import {translate} from 'services/remoteConfig';
import TextComponent from 'components/atoms/TextComponent';
import {BODY_2, H5} from 'constant/constants';
import SidePan from 'components/molecules/SidePan';
import AddAthleteCustom from 'screen/athlete/components/AddAthleteCustom';
import {useSelector} from 'react-redux';
import {selectAthleteSelected} from 'slices/athleteSlice';
import {images} from 'themes';
import HeightComponent from 'components/atoms/HeightComponent';
import WeightComponent from 'components/atoms/WeightComponent';
import ClickableTextComponent from 'components/atoms/ClickableTextComponent';

function AthleteInfos() {
  const sidePanRef = useRef();
  const athleteSlice = useSelector(selectAthleteSelected);

  return (
    <div className="athlete-infos-container">
      <img
        src={sidePanRef?.photo ? sidePanRef?.photo : images.ATHLETE_BASE}
        alt=""
        className="athlete-infos-image"
      />
      <div
        style={{
          marginLeft: 10,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}>
        <div>
          <TextComponent
            text={
              athleteSlice?.name ? athleteSlice?.name : athleteSlice?.fullName
            }
            variant={H5}
          />
          <TextComponent
            text={athleteSlice?.emailAddress}
            variant={BODY_2}
            sx={{color: '#737373'}}
          />
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <HeightComponent height={athleteSlice?.height} />
          <WeightComponent weight={athleteSlice?.weight} />
        </div>
      </div>

      {athleteSlice?.type === 'patient' ? (
        <div
          style={{
            position: 'absolute',
            right: 20,
            top: '50%',
            transform: 'translateY(-50%)',
          }}>
          <ClickableTextComponent
            title={translate('Edit')}
            onPress={() => {
              sidePanRef?.current?.open();
            }}
          />
        </div>
      ) : null}
      <div style={{width: '30%'}}>
        <SidePan
          ref={sidePanRef}
          onButtonPressed={() => {
            sidePanRef?.current?.close();
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '20%',
            }}>
            <AddAthleteCustom modify={true} />
          </div>
        </SidePan>
      </div>
    </div>
  );
}

export default AthleteInfos;
