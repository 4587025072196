import React, {useState} from 'react';

import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';

import ButtonComponent from 'components/atoms/ButtonComponent';
import TextComponent from 'components/atoms/TextComponent';
import TextInput from 'components/atoms/TextInput';

import {mpTrack} from 'services/mixpanel';
import {translate} from 'services/remoteConfig';
import {deleteProfile, deleteUserInfos} from 'services/userApi';

import {resetUnityStore} from 'slices/unitySlice';
import {resetAthleteSelected} from 'slices/athleteSlice';
import {resetUserItemStore} from 'slices/userItemsSlice';
import {resetUsageStore} from 'slices/usageSlice';
import {resetAnalysisStore} from 'slices/analysisSlice';
import {resetProfileStore} from 'slices/userSlice';

import {ORANGE} from 'constant/constants';

import {colors} from 'themes';

function DeleteAccount() {
  const [password, setPassword] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const userSlice = useSelector(state => state?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handlePasswordChange(e) {
    setPassword(e.target.value);
    if (e.target.value) setDisabled(false);
  }

  function handleLogout() {
    window.Intercom('shutdown');
    dispatch(resetProfileStore());
    dispatch(resetAnalysisStore());
    dispatch(resetUsageStore());
    dispatch(resetUserItemStore());
    dispatch(resetAthleteSelected());
    dispatch(resetUnityStore());
    localStorage.clear();
    navigate('/');
  }

  function resign() {
    const auth = getAuth();
    if (!userSlice?.emailAddress || !password) return;

    signInWithEmailAndPassword(auth, userSlice?.emailAddress, password)
      .then(() => {
        deleteUserInfos().then(() => {
          deleteProfile();
          mpTrack('Delete Account Confirmed');
          handleLogout();
        });
      })
      .catch(error => {
        var errorCode = error?.code;
        if (errorCode && errorCode === 'auth/wrong-password') {
          alert(translate('AuthErrorWrongPassword'));
        }
      });
  }
  return (
    <div style={styles.container}>
      <TextComponent text={translate('DeleteAccountAlert')} sx={styles.title} />
      <TextInput
        label={translate('Password')}
        sx={styles.brandNameInput}
        onChange={handlePasswordChange}
        type={'password'}
        name={'password'}
        focusedImage={require('assets/images/view.png')}
        image={require('assets/images/hide.png')}
      />
      <div style={styles.button}>
        <ButtonComponent
          type={ORANGE}
          disabled={disabled}
          title={translate('ConfirmDelete')}
          onClick={resign}
        />
      </div>
    </div>
  );
}

const styles = {
  brandNameInput: {
    marginTop: '2vh',
    width: '100%',
    height: '10%',
    borderRadius: '2vh',
    backgroundColor: colors.WHITE_OPACITY_20,
  },
  button: {
    marginTop: '2vh',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    width: '85%',
    marginTop: '10vh',
  },
  title: {
    fontSize: '1.5vw',
    marginBottom: '3vh',
  },
};

export default DeleteAccount;
