import React, {useEffect, useRef, useState} from 'react';

import {useNavigate} from 'react-router-dom';

import ButtonComponent from 'components/atoms/ButtonComponent';
import ErrorMessage from 'components/atoms/ErrorMessage';
import OnBoardingBottomArrow from 'screen/auth/registration/onBoarding/components/OnBoardingBottomArrow';
import OnBoardingAthleteHeightAndWeightTextInput from './components/OnBoardingAthleteHeightAndWeightTextInput';

import {translate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {ORANGE, REGISTRATION_DATA} from 'constant/constants';

function OnBoardingAthleteHeightAndWeight() {
  const weightRef = useRef();
  const heightRef = useRef();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    mpTrack('Web App OnBoarding Height And Weight Selection Screen Viewed');
  }, []);

  const handleSubmit = async e => {
    const validHeight = await heightRef?.current?.handleSubmit();
    const validWeight = await weightRef?.current?.handleSubmit();

    if (validHeight && validWeight) {
      const onBoardingUserData = JSON.parse(
        localStorage.getItem(REGISTRATION_DATA),
      );
      localStorage.setItem(
        REGISTRATION_DATA,
        JSON.stringify({
          ...onBoardingUserData,
          ...{height: validHeight, weight: validWeight},
        }),
      );
      mpTrack('Web App OnBoarding Height And Weight Validation Button Clicked');
      navigate('/registration/athlete/analysis-introduction');
    } else {
      setError(translate('ErrorCode'));
      return;
    }
  };

  function handleBackArrowPressed() {
    navigate('/registration/athlete/information');
  }

  return (
    <div style={styles.container}>
      <OnBoardingAthleteHeightAndWeightTextInput
        heightRef={heightRef}
        weightRef={weightRef}
      />
      <div style={styles.MT3}>
        <ErrorMessage message={error} />
      </div>
      <div style={styles.MT5}>
        <ButtonComponent
          type={ORANGE}
          title={translate('Validate')}
          onClick={handleSubmit}
        />
      </div>
      <OnBoardingBottomArrow previous={true} onPress={handleBackArrowPressed} />
    </div>
  );
}
const styles = {
  MT3: {
    marginTop: '1%',
  },
  MT5: {
    marginTop: '3%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    justifyContent: 'center',
    width: '100%',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  picker: {
    marginLeft: '5px',
    width: '10%',
  },
  textContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '90%',
    marginBottom: '5%',
  },
  text: {
    color: 'white',
    marginTop: '5%',
    textAlign: 'center',
    marginBottom: '1.5%',
  },
  text2: {
    color: 'lightGray',
    marginBottom: '10%',
    textAlign: 'center',
  },
  width100: {
    width: '100%',
  },
};
export default OnBoardingAthleteHeightAndWeight;
