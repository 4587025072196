import React from "react";

import TextComponent from "components/atoms/TextComponent";

import { translate, USER_LOCALE } from "services/remoteConfig";

import { BODY_1, H3 } from "constant/constants";
import { privacyPolicy } from "assets/pp&tos/privacypolicy/privacyPolicy";

function PrivacyPolicy() {
  const privacypolicyLanguage = privacyPolicy[USER_LOCALE];
  return (
    <div style={styles.container}>
      <div style={styles.textContainer}>
        <TextComponent text={translate("PrivacyTitle")} variant={H3} />
        <TextComponent
          sx={styles.text}
          variant={BODY_1}
          text={privacypolicyLanguage}
        />
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "85vh",
    overflowY: "auto",
  },
  textContainer: {
    width: "80%",
    marginTop: "5%",
  },
  text: {
    marginTop: 4,
  },
};
export default PrivacyPolicy;
