import React from "react";
import { styled, Switch } from "@mui/material";
import { colors } from "themes";

function SwitchComponent({ checked, onChange, color }) {
    const StyledSwitch = styled((props) => (
        <Switch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            {...props}
        />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transition: theme.transitions.create(['transform', 'color'], {
                duration: '500ms',
            }),
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: colors.WHITE,
                '& + .MuiSwitch-track': {
                    backgroundColor: color,
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
            transition: theme.transitions.create(['transform'], {
                duration: '500ms',
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: '500ms',
            }),
        },
    }));

    return <StyledSwitch checked={checked} onChange={onChange} />;
}

export default SwitchComponent;
