import React, { useEffect, useState } from 'react';
import './styles/ImageIdealPositionDouble.css'; // Assuming your CSS is in this file
import ImageIdealPositionAssets from './ImageIdealPositionAssets';
import { CONTAINED } from 'constant/constants';
import { colors } from 'themes';

import { ReactComponent as Ungrouped } from 'assets/svg/ungrouped.svg';
import { ReactComponent as Grouped } from 'assets/svg/grouped.svg';
import { Button } from '@mui/material';
import styled from '@emotion/styled';
import { getKeyName, toGoodKey } from 'utils/utils';

export default function ImageIdealPositionDouble({ analysisKey, angle }) {
  const [separated, setSeparated] = useState(false);

  useEffect(() => {
    setSeparated(false);
    setTimeout(() => {
      setSeparated(true);
    }, 1000);
  }, [analysisKey]);

  if (!analysisKey) return null;


  const perfectKey = toGoodKey(analysisKey, angle);

  function handleButtonClicked() {
    setSeparated(!separated);
  }



  return (
    <>
      {analysisKey === perfectKey ? (
        <div style={{ width: '50%', alignSelf: 'center' }}>
          <ImageIdealPositionAssets keyName={analysisKey} />
        </div>
      ) : (
        <>
          <div className="animatedImagesContainer">
            <div
              className={`animatedImage front ${separated ? 'moveRight' : ''}`}>
              <ImageIdealPositionAssets keyName={getKeyName(analysisKey, angle)} />
            </div>
            <div className={`animatedImage ${separated ? 'moveLeft' : ''}`}>
              <ImageIdealPositionAssets keyName={perfectKey} />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <GroupedButton
              variant={CONTAINED}
              sx={{
                width: 60,
                height: 35,
                borderRadius: 2,
                backgroundColor: colors?.APP_BACKGROUND,
              }}
              onClick={handleButtonClicked}>
              {!separated ? (
                <Ungrouped
                  color={colors.WHITE}
                  height={30}
                  width={30}
                  stroke={colors?.WHITE_OPACITY_80}
                  strokeWidth="2"
                />
              ) : (
                <Grouped
                  color={colors.WHITE}
                  height={30}
                  width={30}
                  stroke={colors?.WHITE_OPACITY_80}
                  strokeWidth="2"
                />
              )}
            </GroupedButton>
          </div>
        </>
      )}
    </>
  );
}

const GroupedButton = styled(Button)({
  '&:hover': {
    backgroundColor: colors.SECONDARY,
  },
});
