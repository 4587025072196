import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import FadinView from 'components/atoms/FadinView';
import TextComponent from 'components/atoms/TextComponent';
import OnBoardingBottomArrow from 'screen/auth/registration/onBoarding/components/OnBoardingBottomArrow';

import {onBoardingTranslate} from 'services/remoteConfig';

import {createTextObjectReusable} from 'utils/utils';

import {BOLD, H3, H4} from 'constant/constants';

import {colors} from 'themes';
import {mpTrack} from 'services/mixpanel';

function OnBoardingExercisesIntro() {
  const navigate = useNavigate();

  useEffect(() => {
    mpTrack('Web App OnBoarding Exercises List Introduction Screen Viewed');
  }, []);

  function handleArrowPressed() {
    navigate('/registration/athlete/analysis-exercises');
  }

  function handleBackArrowPressed() {
    navigate('/registration/athlete/analysis-segment', {
      state: {value: 1},
    });
  }

  return (
    <FadinView>
      <div style={styles.container}>
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('ExercicesIntroMessageOne') +
              '\n' +
              onBoardingTranslate('ExercicesIntroMessageTwo'),
            {
              variant: H4,
            },
            {
              variant: H4,
              style: {textAlign: 'center', color: colors.SECONDARY},
            },
          )}
          variant={H3}
          weight={BOLD}
          center={true}
        />
        <OnBoardingBottomArrow onPress={handleArrowPressed} />
        <OnBoardingBottomArrow
          previous={true}
          onPress={handleBackArrowPressed}
        />
      </div>
    </FadinView>
  );
}

const styles = {
  container: {
    height: '90vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
};

export default OnBoardingExercisesIntro;
