import React from "react";

import { Alert, Snackbar } from "@mui/material";

function SnackBarComponent({ message, open, handleClose, serverity }) {
  const DURATION = 6000;
  const FILLED = "filled";
  return (
    <div style={styles.container}>
      <Snackbar open={open} autoHideDuration={DURATION} onClose={handleClose}>
        <Alert onClose={handleClose} severity={serverity} variant={FILLED}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
const styles = {
  container : {
    zIndex : 1000
  },
}
export default SnackBarComponent;
