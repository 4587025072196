

const width = window.screen.width
const height = window.screen.height

const metrics = {
    screenWidth : width < height ? width : height,
    screenHeight : width < height ? height : width,
    width,
    height,
};

export default metrics
