import React, { useEffect, useRef, useState } from 'react';

import { Fade, Modal } from '@mui/material';

import VideoComponent from './components/VideoComponent';
import MultipleButton from 'components/molecules/MultipleButton';
import AnalysisContentSelector from './components/AnalysisContentSelector';
import AnalysisRightPanelUpdated from './components/analysisRightPanelUpdated/AnalysisRightPanelUpdated';
import AnalysisRightPanelWrapper from './components/analysisRightPanel/AnalysisRightPanelWrapper';
import ButtonComponent from 'components/atoms/ButtonComponent';
import TotalScore from './components/TotalScore';
import SidePan from 'components/molecules/SidePan';
import PDFSelectionList from './components/PDFSelectionList';
import TextComponent from 'components/atoms/TextComponent';

import { getAnalysisDataWithPanel, getOverstrideData } from 'services/analysis';
import { getExercisesList } from 'services/uiApi';
import { USER_LOCALE, translate } from 'services/remoteConfig';
import { mpTrack } from 'services/mixpanel';

import {
  checkUserPermission,
  getBestSegment,
  prioritySegmentToWork,
} from 'utils/utils';
import { isMobile } from 'react-device-detect';

import { ANALYSIS, ATHLETE, BLUE_BORDER, BOLD, H3, H5, LANDSCAPE, PORTRAIT } from 'constant/constants';
import {
  NAVIGATION_CONFIG,
  NAVIGATION_CONFIG_ATHLETE,
  RUNNINGSTYLES,
} from 'constant/analysisIdConstant';

import { colors } from 'themes';

import { ReactComponent as Close } from 'assets/svg/close.svg';

function RunningAnalysis({ jsonData, locked, modalRef, shared = false }) {
  const [analysisData, setAnalysisData] = useState(null);
  const [firstTime, setFirstTime] = useState(false);
  const [exercices, setExercices] = useState(undefined);
  const [analysisPanel, setAnalysisPanel] = useState(ANALYSIS);
  const [error, setError] = useState(null);
  const sidePanRef = useRef();

  const valueVideo = jsonData?.videoUrl;
  const data = getAnalysisDataWithPanel(jsonData, analysisPanel);
  const prioritySegments = prioritySegmentToWork(jsonData?.analyse?.content);
  const bestSegment = getBestSegment(jsonData?.analyse?.content);
  const overStrideContent = getOverstrideData(jsonData);
  const jsonVersion = jsonData?.json_version;

  const [videoOrientation, setVideoOrientation] = useState(LANDSCAPE);

  useEffect(() => {
    if (!isMobile) return;
    if (firstTime) {
      sidePanRef?.current?.open();
    } else {
      setFirstTime(true);
    }
  }, [analysisData]);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function handleExportPDF(locked) {
    if (locked) {
      modalRef?.current?.open('generatePDF');
    } else {
      mpTrack('Web App Export PDF button Clicked');
      setOpen(true);
    }
  }

  useEffect(() => {
    mpTrack('Web App Running Analysis Viewed');
    async function fetchData() {
      if (!jsonData) return;
      try {
        setAnalysisData(data[0]);
        const exercisesList = await getExercisesList();
        setExercices(exercisesList);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des exercices :",
          error,
        );
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    setFirstTime(false);
    if (!data) {
      setError('Error: no data found, please try again.');
      return;
    }
    if (analysisPanel === 'Metrics') {
      mpTrack('Web App Metrics Clicked');
      setAnalysisData({
        metricKey: 'time_between_steps',
        value: data?.time_between_steps,
      });
    } else if (analysisPanel === 'Graph') {
      mpTrack('Web App Graph Clicked');
      setAnalysisData({
        graphData: null,
        graphTitle: null,
      });
    } else if (analysisPanel === 'Style') {
      mpTrack('Web App Running Style Clicked');
      setAnalysisData({
        stylesExplanation: RUNNINGSTYLES[USER_LOCALE],
        stylesTitle: translate('RunningStyleModal'),
      });
    } else {
      mpTrack('Web App Analysis Pannel Clicked');
      if (localStorage.getItem('segmentToWork')) {
        const newData = data?.find(
          text => text?.type === localStorage.getItem('segmentToWork'),
        );
        setAnalysisData(newData);
      } else {
        setAnalysisData(
          locked ? data?.find(text => text?.type === bestSegment) : data[0],
        );
      }
    }
  }, [analysisPanel]);


  if (error)
    return (
      <div style={{ alignSelf: 'center', display: 'flex', flex: 1 }}>
        <TextComponent
          text={error}
          weight={BOLD}
          variant={H3}
          sx={{ color: 'white' }}
        />
      </div>
    );
  if (!data) return <></>;
  return (
    <div style={!isMobile ? { display: 'flex' } : {}}>
      <div style={!isMobile && videoOrientation === LANDSCAPE ? { width: '65%' } : !isMobile && videoOrientation === PORTRAIT ? { width: '75%', display: 'flex' } : {}}>
        <div style={{ width: videoOrientation === PORTRAIT ? '80%' : '100%' }}>
          <VideoComponent video={valueVideo} setOrientation={setVideoOrientation} />
        </div>

        {jsonVersion < 3 ? (
          <div
            style={{
              alignSelf: 'center',
              display: 'flex',
              flex: 1,
              height: 100,
              alignItems: 'center',
            }}>
            <TextComponent
              text={translate('ObsoleteAnalysis')}
              weight={BOLD}
              variant={H5}
              sx={{ color: 'white', alignSelf: 'center' }}
            />
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', ...(videoOrientation === PORTRAIT && { marginLeft: '1vw' }) }}>
            {!checkUserPermission([ATHLETE]) && !shared && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonComponent
                  type={BLUE_BORDER}
                  title={translate('ExportToPDF')}
                  onClick={() => handleExportPDF(locked)}
                  sx={{ height: '5vh', marginTop: '2vh', marginBottom: '2vh' }}
                />
              </div>
            )}

            <div>
              <MultipleButton
                config={
                  checkUserPermission([ATHLETE])
                    ? NAVIGATION_CONFIG_ATHLETE
                    : NAVIGATION_CONFIG
                }
                navigation={analysisPanel}
                setNavigation={setAnalysisPanel}
              />
            </div>

            {analysisPanel === ANALYSIS && (

              <TotalScore data={data} locked={locked} modalRef={modalRef} />
            )}

            <div>
              <AnalysisContentSelector
                data={data}
                panel={analysisPanel}
                setAnalysisData={setAnalysisData}
                overstrideData={overStrideContent}
                version={jsonVersion}
                prioritySegments={prioritySegments}
                bestSegment={bestSegment}
                locked={locked}
                modalRef={modalRef}
              />
            </div>
          </div>
        )}
      </div>

      {
        isMobile ? (
          <SidePan
            ref={sidePanRef}
            onButtonPressed={() => {
              sidePanRef?.current?.close();
            }}
            fullWidth={true}>
            <div style={{ width: '100%' }}>
              {jsonVersion > 3 ? (
                <AnalysisRightPanelUpdated
                  displayData={analysisData}
                  analysisTexts={data}
                  exercices={exercices}
                  version={jsonVersion}
                  overstrideData={overStrideContent}
                  locked={locked}
                  modalRef={modalRef}
                />
              ) : (
                <AnalysisRightPanelWrapper
                  displayData={analysisData}
                  analysisTexts={data}
                  exercices={exercices}
                  version={jsonVersion}
                  overstrideData={overStrideContent}
                />
              )}
            </div>
          </SidePan>
        ) : (
          <div style={{ width: videoOrientation === LANDSCAPE ? '35%' : '25%' }}>
            {jsonVersion > 3 ? (
              <AnalysisRightPanelUpdated
                displayData={analysisData}
                analysisTexts={data}
                exercices={exercices}
                version={jsonVersion}
                overstrideData={overStrideContent}
                locked={locked}
                modalRef={modalRef}
              />
            ) : (
              <AnalysisRightPanelWrapper
                displayData={analysisData}
                analysisTexts={data}
                exercices={exercices}
                version={jsonVersion}
                overstrideData={overStrideContent}
              />
            )}
          </div>
        )
      }

      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            style: { backgroundColor: 'transparent' },
          },
        }}
        sx={styles.modal}>
        <Fade in={open}>
          <div style={styles.container}>
            <div
              style={styles.closeCross}
              onClick={event => {
                event?.preventDefault();
                event?.stopPropagation();
                handleClose();
              }}>
              <Close stroke="white" width={50} height={50} />
            </div>
            <PDFSelectionList
              data={getAnalysisDataWithPanel(jsonData, ANALYSIS)}
              jsonData={jsonData}
              setOpen={setOpen}
            />
          </div>
        </Fade>
      </Modal>
    </div >
  );

}

const styles = {
  closeCross: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
  },

  container: {
    backgroundColor: colors.BLACK,
    width: '50vw',
    position: 'relative',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10vh',
  },
};

export default RunningAnalysis;
