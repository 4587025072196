import React from "react";

import TextComponent from "components/atoms/TextComponent";
import TextInput from "components/atoms/TextInput";

import { BODY_2, BOLD, H6 } from "constant/constants";

import { colors } from "themes";

function InputAndUnity({ title, value, onChange, unity, name }) {
    return (
        <div style={styles.container}>
            <TextComponent text={title} variant={BODY_2} weight={BOLD} />
            <div style={styles.textInput}>
                <TextInput
                    value={value}
                    onChange={onChange}
                    type={'number'}
                    name={name}
                />
                <div style={styles.unityContainer}>
                    <TextComponent text={unity} variant={H6} />
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        marginTop: '2vh'
    },
    textInput: {
        display: 'flex',
        marginTop: '1vh'
    },
    unityContainer: {
        width: '10vw',
        marginLeft: '1vw',
        borderRadius: 10,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: colors.BLACK,
        borderColor: colors.WHITE_OPACITY_20,
        borderWidth: '1px',
        borderStyle: 'solid',
    }
}


export default InputAndUnity;