import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { colors } from 'themes'; // Adjust the import path according to your project structure
import TextComponent from 'components/atoms/TextComponent';
import { RUNNING_PACE } from 'constant/uploadContant';

import { BODY_2, BOLD, COACH, PROFESSIONAL } from 'constant/constants';
import { kmPerhourToMinPerKm, milesPerHourToMinPerKm, minPerMilesToMinPerKm } from 'utils/converter';
import { checkUserPermission } from 'utils/utils';
import { translate } from 'services/remoteConfig';


function RunningPaceSelect({
  value: propValue,
  onChange,
  setPace,
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(propValue);
  const [selectedUnity, setSelectedUnity] = useState(0);

  const paceButton = [
    { label: 'min/km', value: 0 },
    { label: 'min/mil', value: 1 },
    { label: 'km/h', value: 2 },
    { label: 'mil/h', value: 3 }
  ];

  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === 'None') setPace('')
    else setPace(convertToMinKm(event.target.value))
    if (onChange) {
      onChange(event);
    }
  };

  const borderColor = () => {
    if (isFocused) return '2px solid white';
    else return '1px solid rgba(255, 255, 255, 0.7)';
  };

  function convertToMinKm(value) {
    if (selectedUnity === 0) return value;
    if (selectedUnity === 1) {
      return minPerMilesToMinPerKm(value);
    }
    if (selectedUnity === 2) {
      return kmPerhourToMinPerKm(value);
    }
    if (selectedUnity === 3) {
      return milesPerHourToMinPerKm(value);
    }
  }

  function getUnityArray() {
    if (selectedUnity === 0) return RUNNING_PACE?.mpkm;
    if (selectedUnity === 1) return RUNNING_PACE?.mpm;
    if (selectedUnity === 2) return RUNNING_PACE?.kmh;
    if (selectedUnity === 3) return RUNNING_PACE?.mph;
  }

  return (
    <>
      <div style={styles.container}>
        <Box
          style={styles.paceButtonContainer}
        >
          {paceButton.map((item, index) => (
            <Button
              key={index}
              onClick={() => setSelectedUnity(item.value)}
              sx={{
                ...styles.paceButton, ...{

                  backgroundColor:
                    selectedUnity === item.value
                      ? colors.SECONDARY_OPACITY_10
                      : colors.TRANSPARENT,
                  color:
                    selectedUnity === item.value
                      ? colors.SECONDARY
                      : colors.WHITE_OPACITY_60,

                  border: selectedUnity === item.value ? '1px solid #FF681D' : "",
                }
              }}
            >
              <Typography
                variant="body2"
                fontWeight={selectedUnity === item.value ? BOLD : 'normal'}
              >
                {item.label}
              </Typography>
            </Button>
          ))}
        </Box>
        <Box style={{
          marginBottom: 20,
        }}>
          <TextComponent text={checkUserPermission([COACH, PROFESSIONAL])
            ? translate('RunningPaceCoach')
            : translate('RunningPaceAthlete')} variant="body2" weight={BOLD} sx={{ color: colors.WHITE }} />
          <FormControl
            variant="standard"
            fullWidth
            placeholder='Running pace'
            sx={{
              ...styles.form, ...{
                border: borderColor(),
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.17)'
                }
              }
            }}
          >
            <Select
              value={value}
              onChange={handleChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              sx={styles.selector}
            >
              {getUnityArray().map((pace, index) => (
                <MenuItem key={index} value={pace} sx={{
                  backgroundColor: colors.APP_BACKGROUND,
                  '&:hover': {
                    backgroundColor: colors?.DARK_GRAY
                  }
                }}>
                  <TextComponent text={pace} variant={BODY_2} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>
    </>
  );
}

const styles = {
  container: {
    width: '100%'
  },
  form: {
    height: '40px',
    borderRadius: '5px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '8px'
  },
  error: {
    color: colors.ERROR
  },
  paceButton: {
    textTransform: 'none',
    borderRadius: '20px',
    paddingLeft: '25px',
    paddingRight: '25px',
    minWidth: '25%',
  },
  paceButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 35,
    justifyContent: 'space-evenly',
    marginBottom: 20,
    width: '100%'
  },
  selector: {
    width: '100%',
    height: '40px',
    justifyContent: 'center',
  },
};

export default RunningPaceSelect;
