import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import TextComponent from 'components/atoms/TextComponent';
import FadinView from 'components/atoms/FadinView';
import OnBoardingContainer from 'screen/auth/registration/onBoarding/components/OnBoardingContainer';
import OnBoardingBottomArrow from 'screen/auth/registration/onBoarding/components/OnBoardingBottomArrow';

import {onBoardingTranslate} from 'services/remoteConfig';
import {mpTrack} from 'services/mixpanel';

import {createTextObjectReusable} from 'utils/utils';

import {BOLD, H3, H4, LIGHT} from 'constant/constants';

import {colors} from 'themes';

function OnBoardingAthleteInfos() {
  const navigate = useNavigate();

  useEffect(() => {
    mpTrack('Web App OnBoarding Analysis Flow Welcome Screen Viewed');
  }, []);

  function handleArrowPressed() {
    navigate('/registration/athlete/height-weight');
  }
  function handleBackArrowPressed() {
    navigate('/registration/athlete/running-frequency');
  }

  return (
    <OnBoardingContainer>
      <FadinView>
        <TextComponent
          multipleText={createTextObjectReusable(
            onBoardingTranslate('PersonalisedAnalysisWelcomeText') +
              '\n' +
              '\n' +
              onBoardingTranslate('PersonalisedAnalysisWelcomeTextSecondary') +
              '\n' +
              '\n' +
              onBoardingTranslate('PersonalisedAnalysisWelcomeTextLast'),
            {
              variant: H4,
              weight: LIGHT,
            },
            {
              variant: H4,
              weight: LIGHT,
              style: {textAlign: 'center', color: colors.SECONDARY},
            },
            {
              variant: H4,
              weight: LIGHT,
              style: {textAlign: 'center'},
            },
          )}
          center={true}
          variant={H3}
          weight={BOLD}
        />
        <OnBoardingBottomArrow onPress={handleArrowPressed} />
        <OnBoardingBottomArrow
          previous={true}
          onPress={handleBackArrowPressed}
        />
      </FadinView>
    </OnBoardingContainer>
  );
}

export default OnBoardingAthleteInfos;
