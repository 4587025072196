import ButtonComponent from 'components/atoms/ButtonComponent';
import TextComponent from 'components/atoms/TextComponent';
import {BODY_1, H2, ORANGE} from 'constant/constants';
import React, {useState} from 'react';
import {USER_LOCALE, translate} from 'services/remoteConfig';
import {colors} from 'themes';

import {createUser, createUserInDB} from 'services/authApi';
import {useDispatch} from 'react-redux';
import {updateProfileStore} from 'slices/userSlice';

import './styles/Registration.css';
import {useNavigate} from 'react-router-dom';
import AuthContainer from '../components/AuthContainer';
import ClickableTextComponent from 'components/atoms/ClickableTextComponent';
import TextInputContainer from './components/TextInputContainer';
import TermsAndPrivacy from './components/TermsAndPrivacy';
import {mpTrack} from 'services/mixpanel';
import {useProfileChanges} from 'hooks/useProfileChanges';
import SsoButtons from '../components/SsoButtons';

const yup = require('yup');

function Registration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userSchema = yup.object({
    name: yup.string().required(translate('NameRequired')),
    email: yup
      .string()
      .email(translate('AuthErrorInvalidEmail'))
      .required(translate('EmailRequired')),
    password: yup
      .string()
      .required(translate('PasswordRequired'))
      .min(6, translate('AuthErrorWeakPassword')),
    repeatPassword: yup
      .string()
      .required(translate('RepeatPassword'))
      .oneOf([yup.ref('password')], translate('PasswordRepeatMatch')),
  });

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    password: '',
    repeatPassword: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = async e => {
    mpTrack('Register Web Button Clicked');
    if (e) e.preventDefault();
    try {
      await userSchema.validate(formValues, {abortEarly: false});
      createUser(formValues?.email, formValues?.password).then(res => {
        if (res !== undefined) {
          if (res?.user?.uid === undefined) {
            setFormErrors({
              email: res,
            });
            return;
          }
          dispatch(
            updateProfileStore({
              userID: res?.user?.uid,
              emailAddress: formValues?.email,
              fullName: formValues?.name,
              registered: false,
            }),
          );
          createUserInDB({
            emailAddress: formValues?.email,
            fullName: formValues?.name,
            registered: false,
            platform: 'Web app user',
            language: USER_LOCALE,
          });
        }
        navigate('/');
        mpTrack('Register Web Button Clicked success');
      });
    } catch (err) {
      if (err.inner) {
        const errors = {};
        err.inner.forEach(error => {
          errors[error?.path] = error?.message;
        });
        setFormErrors(errors);
      }
    }
  };

  const handleChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    if (formErrors[e.target.name]) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: '',
      });
    }
  };

  return (
    <AuthContainer>
      <div style={styles.textAlignCenter}>
        <TextComponent
          text={translate('RegisterHere')}
          variant={H2}
          type={'big'}
          sx={{marginBottom: 3}}
        />
        <TextComponent
          text={translate('RegisterSubTitle')}
          variant={BODY_1}
          weight={'400'}
        />
      </div>
      <TextInputContainer
        formValues={formValues}
        formErrors={formErrors}
        handleChange={handleChange}
        userSchema={userSchema}
        handleSubmit={handleSubmit}
      />
      <TermsAndPrivacy />
      <div style={styles.buttonContainer}>
        <ButtonComponent
          type={ORANGE}
          title={translate('RegisterHere')}
          onClick={handleSubmit}
        />
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div
          style={{
            height: 2,
            width: '40%',
            marginRight: 10,
            backgroundColor: colors?.WHITE_OPACITY_20,
          }}
        />
        <TextComponent text={translate('Or')} />
        <div
          style={{
            height: 2,
            width: '40%',
            marginLeft: 10,
            backgroundColor: colors?.WHITE_OPACITY_20,
          }}
        />
      </div>
      <SsoButtons register={true} />
      <div
        style={{
          marginTop: '0.5%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <TextComponent
          text={translate('AccountExists')}
          sx={{marginRight: 1}}
        />
        <ClickableTextComponent
          title={translate('LoginHere')}
          onPress={() => {
            navigate('/Login');
          }}
          option={{color: colors.SECONDARY}}
        />
      </div>
    </AuthContainer>
  );
}
const styles = {
  buttonContainer: {
    marginBottom: '5%',
    marginTop: '5%',
  },
  text: {
    marginBottom: -1,
    marginLeft: 0,
    marginTop: 4,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  width80: {
    width: '80%',
  },
};
export default Registration;
