import React from "react";

import { Typography } from "@mui/material";

import { colors } from "themes";

function ClickableTextComponent({ onPress, title, option, sx, variant }) {
  const styles = {
    button: {
      color: option?.color ? option?.color : "white",
      fontWeight: option?.weight ? option?.weight : "normal",
      textDecoration: option?.underline ? "underline" : "none",
      whiteSpace: "nowrap",
      "&:hover": {
        textDecoration: "underline",
        backgroundColor: colors.TRANSPARENT,
        cursor: "pointer",
      },
    },
  };
  return (
    <Typography
      sx={{ ...styles.button, ...sx }}
      variant={variant ? variant : "body2"}
      onClick={onPress}
    >
      {title}
    </Typography>
  );
}

export default ClickableTextComponent;
