import React from 'react';

import {useNavigate} from 'react-router-dom';

import {ReactComponent as Arrow} from 'assets/svg/arrow-right-bold.svg';

import {colors} from 'themes';

function OnBoardingBottomArrow({onPress, previous = false, analysis = false}) {
  const navigate = useNavigate();
  function handleGoBack() {
    navigate(-1);
  }

  function handleStyle() {
    if (analysis) {
      return styles.containerNextArrowAnalysis;
    }
    if (previous) {
      return styles.containerPreviousArrow;
    } else {
      return styles.containerNextArrow;
    }
  }
  return (
    <div
      style={handleStyle()}
      onClick={previous === true && !onPress ? handleGoBack : onPress}>
      <Arrow
        color={colors.WHITE}
        height={60}
        width={60}
        strokeWidth={5}
        stroke={colors.WHITE}
        style={{cursor: 'pointer', transform: previous ? 'rotate(180deg)' : ''}}
      />
    </div>
  );
}

const styles = {
  containerNextArrowAnalysis: {
    bottom: 20,
    position: 'absolute',
    right: '30%',
  },
  containerNextArrow: {
    bottom: 20,
    position: 'absolute',
    right: 70,
  },
  containerPreviousArrow: {
    bottom: 20,
    position: 'absolute',
    left: 20,
  },
};

export default OnBoardingBottomArrow;
