import React, {useState} from 'react';

import Lottie from 'lottie-react';
import {useSelector} from 'react-redux';

import TextComponent from 'components/atoms/TextComponent';
import TextInput from 'components/atoms/TextInput';
import ButtonComponent from 'components/atoms/ButtonComponent';

import {translate} from 'services/remoteConfig';
import {createMessageOnSlack} from 'services/slack';

import {createTextObjectReusable} from 'utils/utils';

import {BODY_1, BODY_2, BOLD, H4, H6, LIGHT, ORANGE} from 'constant/constants';

import {colors} from 'themes';

import successLottie from 'assets/animations/success.json';
import OchyTeam from 'assets/images/ochy_team.png';

function ContactSales({big = false}) {
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const userSlice = useSelector(state => state?.user);

  const handleEmailChange = e => {
    setEmail(e?.target?.value);
    setErrorMessage(null);
    if (e?.target?.value && message) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleMessageChange = e => {
    setMessage(e?.target?.value);
    if (e?.target?.value && email) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSubmit = () => {
    if (email && !email?.match(/^\S+@\S+$/)) {
      setErrorMessage('AuthErrorInvalidEmail');
      return;
    }
    createMessageOnSlack(message, 'contactSales', email, userSlice);
    setSuccess(true);
  };

  if (success) {
    return (
      <div style={styles.success}>
        <TextComponent
          text={translate('MessageSend')}
          variant={H4}
          weight={BOLD}
          sx={styles.messageSend}
        />
        <Lottie
          animationData={successLottie}
          style={styles.lottie}
          onLoopComplete={() => {
            setSuccess(false);
          }}
        />
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <img
        style={{...styles.image, width: big ? '40%' : '100%'}}
        src={OchyTeam}
        alt="Ochy_Team"
      />
      <div style={styles.titleContainer}>
        <TextComponent
          multipleText={createTextObjectReusable(
            translate('ContactSalesTeamTitle'),
            {weight: BOLD, variant: H6, style: {color: colors.WHITE}},
            {weight: BOLD, variant: H6, style: {color: colors.SECONDARY}},
          )}
        />
        <TextComponent
          text={translate('ContactSalesTeamSubTitle')}
          variant={BODY_2}
          weight={LIGHT}
          sx={styles.textLight}
        />
      </div>
      <div style={styles.inputsContainer}>
        <TextInput
          label={translate('YourEmail')}
          onChange={handleEmailChange}
        />
        <TextInput
          label={translate('Message')}
          type={'longtext'}
          onChange={handleMessageChange}
        />
        <ButtonComponent
          type={ORANGE}
          title={translate('Send')}
          sx={styles.button}
          disabled={disabled}
          onClick={handleSubmit}
        />
        {errorMessage && (
          <TextComponent
            text={translate(errorMessage)}
            variant={BODY_1}
            weight={LIGHT}
            sx={styles.error}
          />
        )}
      </div>
    </div>
  );
}

const styles = {
  button: {
    width: '10vw',
    height: '5vh',
    marginTop: '2vh',
    marginBottom: '10vh',
  },
  container: {
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
  },
  error: {
    color: 'red',
    textAlign: 'center',
  },
  image: {
    height: '20vh',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center top',
    borderRadius: 10,
  },
  inputsContainer: {
    marginTop: '2.5vh',
    marginBottom: '5vh',
    width: '20vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  lottie: {
    width: '20vw',
    height: '20vh',
  },
  messageSend: {
    color: colors.WHITE,
    marginBottom: '10vh',
  },
  success: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10vh',
  },
  textLight: {
    color: colors.TEXT_LIGHT,
  },
  titleContainer: {
    justifyContent: 'center',
    marginTop: 10,
    textAlign: 'center',
  },
};

export default ContactSales;
