import React, {useState, useEffect} from 'react';
import {useSpring, animated} from 'react-spring';
import './styles/ProgressCircle.css';
import {ReactComponent as Checked} from 'assets/svg/checkMarkOrange.svg';
import TextComponent from './TextComponent';

const ProgressCircle = ({progress, onComplete, text}) => {
  const [isComplete, setIsComplete] = useState(false);

  const {value} = useSpring({
    from: {value: 0},
    to: {value: progress},
    config: {duration: 1000},
    onRest: () => {
      if (progress >= 100) {
        setIsComplete(true);
        onComplete && onComplete();
      }
    },
  });

  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = value.to(
    val => circumference - (val / 100) * circumference,
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
      }}>
      <div
        style={{
          display: 'flex',
          width: '20%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div className="progress-circle-container">
          <svg width="30" height="30" viewBox="0 0 120 120">
            <circle
              cx="60"
              cy="60"
              r={radius}
              stroke="#38393C"
              strokeWidth="10"
              fill="none"
            />
            <animated.circle
              cx="60"
              cy="60"
              r={radius}
              stroke="#FF681D"
              strokeWidth="10"
              fill={isComplete ? '#FF681D' : 'none'}
              strokeDasharray={circumference}
              strokeDashoffset={strokeDashoffset}
              transform="rotate(-90 60 60)"
            />
          </svg>
          {isComplete && (
            <Checked
              height={25}
              width={25}
              style={{zIndex: 10, position: 'absolute'}}
            />
          )}
        </div>
        <TextComponent
          text={text}
          variant="body2"
          weight="medium"
          sx={{marginLeft: 2, width: '50%'}}
        />
      </div>
    </div>
  );
};

export default ProgressCircle;
