import React from "react";

import TextComponent from "components/atoms/TextComponent";
import RecapAnalysisCard from "./components/RecapAnalysisCard";
import RecapResultsCard from "./components/RecapResultsCard";
import AthleteRanking from "./components/AthleteRanking";
import LoadingComponent from "components/atoms/LoadingComponent";

import { translate } from "services/remoteConfig";

import { checkUserPermission } from "utils/utils";

import { ATHLETE, PRONATION } from "constant/constants";

import { colors } from "themes";

function Statistics({ analysisListDatas, loading }) {
    const runningAnalysis = analysisListDatas?.filter(obj => obj?.analysis_type !== PRONATION);

    return (
        <div style={styles.container}>
            <TextComponent text={translate('Statistics')} sx={styles.title} />
            {analysisListDatas?.length === 0 ?
                <div style={styles.noAnalysis}>
                    <TextComponent text={translate('NoVideos')} sx={styles.title} />
                </div>
                : loading ?
                    <div style={styles.loading}>
                        <LoadingComponent />
                    </div> :
                    <div style={styles.cardsContainer}>
                        <RecapAnalysisCard totalAnalysis={analysisListDatas?.length} pronationAnalysis={runningAnalysis?.length} />
                        <RecapResultsCard data={runningAnalysis} />
                        {checkUserPermission([ATHLETE]) ? null : <AthleteRanking data={runningAnalysis} />}
                    </div>
            }

        </div>
    );
}

const styles = {
    cardsContainer: {
        width: '100%',
        height: '80%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    container: {
        width: '100%',
        backgroundColor: 'black',
        borderRadius: 10,
        height: '40%',
        marginTop: '1vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80%'
    },
    noAnalysis: {
        width: '100%',
        height: '80%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    title: {
        color: colors.TEXT_GRAY,
        marginLeft: '1vw',
        fontSize: '1.2vw'
    },

}

export default Statistics;
