import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import TextComponent from "components/atoms/TextComponent";

import { selectBrandLogo, selectBrandName } from "slices/userSlice";
import { resetAthleteSelected } from "slices/athleteSlice";

import Logo from "assets/svg/whiteLogo.svg";

import { colors } from "themes";

function AppLogo() {
  const brandLogo = useSelector(selectBrandLogo);
  const brandName = useSelector(selectBrandName);
  const dispatch = useDispatch();

  return (
    <Link
      to={"/"}
      onClick={() => {
        dispatch(resetAthleteSelected());
      }}
      style={styles.linkNoStyle}
    >
      {brandLogo && brandName ? (
        <div style={styles.container}>
          <img src={brandLogo} style={styles.logo} alt="Logo" />
          <TextComponent text={brandName} sx={styles.text} />
        </div>
      ) : (
        <img src={Logo} alt="Logo" />
      )}
    </Link>
  );
}

const styles = {
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: '6vh',
    justifyContent: "center",
  },
  logo: {
    height: '3.5vw',
    weigth: '3.5vw',
  },
  linkNoStyle: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: '100%',
  },
  text: {
    color: colors.WHITE,
    fontWeight: "bold",
    fontSize: '1vw',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
};
export default AppLogo;
