import React from "react";

import { ReactComponent as ScoreGood } from "assets/svg/score_good.svg";
import { ReactComponent as ScoreBad } from "assets/svg/score_bad.svg";
import { ReactComponent as ScoreOkay } from "assets/svg/score_okay.svg";
import { ReactComponent as ScoreFoot } from "assets/svg/score_foot.svg";
import { ReactComponent as HeadIcon } from "assets/svg/headIcon.svg";
import { ReactComponent as FootIcon } from "assets/svg/footIcon.svg";
import { ReactComponent as TrunkIcon } from "assets/svg/backIcon.svg";
import { ReactComponent as ArmIcon } from "assets/svg/armsIcon.svg";
import { ReactComponent as FrontLegIcon } from "assets/svg/leg-frontIcon.svg";
import { ReactComponent as BackLegIcon } from "assets/svg/leg-backIcon.svg";
import { ReactComponent as BottomBarScore } from 'assets/svg/bottomBarScore.svg';


import { score_colors } from "../constants/analysisListConstants";

function RoundedScoreMeter({ scoreNumber, scoreText, item, size }) {
  function getScoreIcon(scoreNumber, scoreText) {
    if (item === "RightAnkle") {
      return <ScoreFoot height={size} width={size} style={styles.score} />;
    } else if (
      scoreNumber < 0.33 ||
      (!scoreNumber && scoreText === "score_bad")
    ) {
      return <ScoreBad height={size} width={size} style={styles.score} />;
    } else if (
      scoreNumber < 0.66 ||
      (!scoreNumber && scoreText === "score_okay")
    ) {
      return <ScoreOkay height={size} width={size} style={styles.score} />;
    } else {
      return <ScoreGood height={size} width={size} style={styles.score} />;
    }
  }


  function calculatedSize(divider) {
    return typeof size === 'number' ? size / divider : `${parseFloat(size) / divider}vw`;
  }

  function svgStyle() {
    return {
      width: calculatedSize(2),
      height: calculatedSize(2),
      position: "absolute",
      display: "flex",
      alignSelf: "center",
    };
  }
  function getBodyPart(item, score) {
    if (item === "RightAnkle") {
      return (
        <FootIcon
          stroke={"#C8C8C8"}
          fill="#767676"
          strokeWidth={1.5}
          style={svgStyle()}
        />
      );
    }
    if (item === "head" || item === "RightEar") {
      return (
        <HeadIcon
          fill={
            !scoreNumber
              ? score_colors[scoreText]?.fill
              : scoreNumber < 0.33
                ? score_colors["score_bad"]?.fill
                : scoreNumber < 0.66
                  ? score_colors["score_okay"]?.fill
                  : score_colors["score_good"]?.fill
          }
          stroke={
            !scoreNumber
              ? score_colors[scoreText]?.stroke
              : scoreNumber < 0.33
                ? score_colors["score_bad"]?.stroke
                : scoreNumber < 0.66
                  ? score_colors["score_okay"]?.stroke
                  : score_colors["score_good"]?.stroke
          }
          style={{ ...svgStyle(), height: calculatedSize(3), width: calculatedSize(3) }}
        />
      );
    }
    if (item === "trunk" || item === "RightHip") {
      return (
        <TrunkIcon
          fill={
            !scoreNumber
              ? score_colors[scoreText].fill
              : scoreNumber < 0.33
                ? score_colors["score_bad"]?.fill
                : scoreNumber < 0.66
                  ? score_colors["score_okay"]?.fill
                  : score_colors["score_good"]?.fill
          }
          stroke={
            !scoreNumber
              ? score_colors[scoreText].stroke
              : scoreNumber < 0.33
                ? score_colors["score_bad"]?.stroke
                : scoreNumber < 0.66
                  ? score_colors["score_okay"]?.stroke
                  : score_colors["score_good"]?.stroke
          }
          style={svgStyle()}
        />
      );
    }
    if (item === "arm" || item === "RightElbow") {
      return (
        <ArmIcon
          fill={
            !scoreNumber
              ? score_colors[scoreText].fill
              : scoreNumber < 0.33
                ? score_colors["score_bad"]?.fill
                : scoreNumber < 0.66
                  ? score_colors["score_okay"]?.fill
                  : score_colors["score_good"]?.fill
          }
          stroke={
            !scoreNumber
              ? score_colors[scoreText]?.stroke
              : scoreNumber < 0.33
                ? score_colors["score_bad"]?.stroke
                : scoreNumber < 0.66
                  ? score_colors["score_okay"]?.stroke
                  : score_colors["score_good"]?.stroke
          }
          style={{ ...svgStyle(), height: calculatedSize(2), width: calculatedSize(2) }}
        />
      );
    }
    if (item === "forwardCycle" || item === "RightKnee") {
      return (
        <FrontLegIcon
          fill={
            !scoreNumber
              ? score_colors[scoreText]?.fill
              : scoreNumber < 0.33
                ? score_colors["score_bad"]?.fill
                : scoreNumber < 0.66
                  ? score_colors["score_okay"]?.fill
                  : score_colors["score_good"]?.fill
          }
          stroke={
            !scoreNumber
              ? score_colors[scoreText]?.stroke
              : scoreNumber < 0.33
                ? score_colors["score_bad"]?.stroke
                : scoreNumber < 0.66
                  ? score_colors["score_okay"]?.stroke
                  : score_colors["score_good"]?.stroke
          }
          style={svgStyle()}
        />
      );
    }
    if (item === "backCycle" || item === "LeftKnee") {
      return (
        <BackLegIcon
          fill={
            !scoreNumber
              ? score_colors[scoreText]?.fill
              : scoreNumber < 0.33
                ? score_colors["score_bad"]?.fill
                : scoreNumber < 0.66
                  ? score_colors["score_okay"]?.fill
                  : score_colors["score_good"]?.fill
          }
          stroke={
            !scoreNumber
              ? score_colors[scoreText]?.stroke
              : scoreNumber < 0.33
                ? score_colors["score_bad"]?.stroke
                : scoreNumber < 0.66
                  ? score_colors["score_okay"]?.stroke
                  : score_colors["score_good"]?.stroke
          }
          style={svgStyle()}
        />
      );
    }
    if (item === 'score') {
      return (
        <BottomBarScore
          fill={
            scoreNumber > 0.66
              ? score_colors.score_good.stroke
              : scoreNumber < 0.33
                ? score_colors.score_bad.stroke
                : score_colors.score_okay.stroke
          }
          style={svgStyle()}
        />
      );
    }
  }
  function CircleDotSVG({ percentage }) {
    if (!percentage) return <></>;
    function getPointOnCircle(cx, cy, r) {
      const angle = percentage * (415 - 125) * (Math.PI / 180);
      const offset = 125 * (Math.PI / 180);
      const x = cx + r * Math.cos(angle + offset);
      const y = cy + r * Math.sin(angle + offset);
      return { x, y };
    }
    const { x, y } = getPointOnCircle(50, 50, 34);
    return (
      <svg
        style={{ position: "absolute" }}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx={x}
          cy={y}
          r="7"
          fill={
            percentage < 0.33
              ? score_colors["score_bad"].stroke
              : percentage < 0.66
                ? score_colors["score_okay"].stroke
                : score_colors["score_good"].stroke
          }
          stroke="black"
          strokeWidth={2}
        />
      </svg>
    );
  }
  return (
    <div style={styles.container}>
      {getScoreIcon(scoreNumber, scoreText)}
      <CircleDotSVG percentage={scoreNumber} />
      {getBodyPart(item, scoreText)}
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  score: {
    alignSelf: "center",
    display: "flex",
  },
};

export default RoundedScoreMeter;
