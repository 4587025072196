export const NAVIGATION_CONFIG_ATHLETE = [
  {
    title: 'Analysis',
  },
  {
    title: 'Style',
  },
  {
    title: 'Metrics',
  },
];

export const NAVIGATION_CONFIG = [
  {
    title: 'Analysis',
  },
  {
    title: 'Metrics',
  },
  {
    title: 'Graph',
  },
  {
    title: 'Style',
  },
];

export const RUNNINGSTYLES = {
  en: 'There are 5 differents running styles :\n\nThis style is characterized by an intermediate step frequency, a short stance phase, and a long leg oscillation phase. The running motion allows for a more efficient redistribution of stored elastic energy, converting it into high propulsion forces.\n\nDefined by a high step frequency and a short stance phase, this style emphasizes limited horizontal propulsion and excessive vertical propulsion, leading to a distinct hopping motion.\n\nThis running style features a relatively low step frequency with a moderate stance phase. The primary focus is on horizontal propulsion rather than vertical, resulting in a flight time slightly shorter than the maximum possible flight time.\n\n Characterized by an intermediate step frequency, this style boasts a long stance phase and a short leg oscillation phase. Beneficial at lower speeds, it is ideal for conditions requiring reduced vertical impacts.\n\nThis style showcases an intermediate step frequency and a balanced ratio between the stance and oscillation phases. A distinctive feature is the likely high knee flexion at the point of ground contact.\n\nSource: Van Oeveren, B. T., de Ruiter, C. J., Beek, P. J., & van Dieën, J. H. (2021). The biomechanics of running and running styles: a synthesis. Sports biomechanics, 1-39.\n\n',
  fr: "Il existe 5 styles de course différents :\n\nStyle de course caractérisé par une fréquence de pas intermédiaire, une phase d'appuie courte et une longue phase d'oscillation de la jambe. Ce style de course permet une meilleur redistribution de l'énergie élastique emmagasiner pendant la phase d'appuie sous forme de forces de propulsions élevées.\n\nStyle de course caractérisé par une fréquence de pas élevée et une phase d'appuie courte. Ce style de course sugère une propulsion horizontale limitée pendant la phase d'appuie et une propulsion verticale trop élevée.\n\nStyle de course caractérisé par une fréquence de pas relativement faible et phase d'appuie moyenne. Ce style de course favorise une force de propulsion orienté plutôt vers l'horizontal que la vertical, le temps de vol étant légèrement plus court que le temps de vol maximal possible.\n\n Style de course caractérisé par une fréquence de pas intermédiaire, une phase d'appuie longue et une phase d'oscillation courte. Ce style de course peut-être bénéfique à vitesses plus faibles ou dans des conditions de course requierant de plus faibles impacts verticaux.\n\nStyle de course caractérisé par une fréquence de pas et un rapport entre pahse d'appuie phase d'oscillation intermédaires. Ce style de course est probablement caractérisé par une flexion du genou élevée au moment du contact au sol.\n\nSource: Van Oeveren, B. T., de Ruiter, C. J., Beek, P. J., & van Dieën, J. H. (2021). The biomechanics of running and running styles: a synthesis. Sports biomechanics, 1-39.\n\n",
  zh: '有 5 种不同的跑步方式：\n\n弹跳 ：一种跑步方式，其特点是步频适中、压腿阶段短、摆腿阶段长。这种跑步方式可以更好地重新分配以高推进力形式储存在脚部的弹性能量。\n\n跳 ：跑步方式的特点是步频高、压腿阶段短。这种跑法表明在压腿阶段水平推进力有限，而垂直推进力过大。\n\n猎豹 ：运行方式的特点是步频相对较低，压力相位适中。这种比赛方式更倾向于使用水平而非垂直的推进力。飞行时间比最大可能飞行时间略短。\n\n背负 ：跑步方式的特点是步频适中、压腿阶段长、摆动阶段短。在速度较低或需要较低垂直冲击力的跑步条件下，这种跑步方式会很有益处。\n\n大猩猩 ：以步频和地面接触时间与中间飞行时间之比为特征的跑步方式。这种跑步方式的特点可能是膝盖在接触地面时高度弯曲。\n\n',
  de: 'Es gibt 5 verschiedene Laufstile:\n\n[[Bounce]]: Dieser Stil ist gekennzeichnet durch eine mittlere Schrittfrequenz, eine kurze Standphase und eine lange Beinoszillationsphase. Die Laufbewegung ermöglicht eine effizientere Umverteilung der gespeicherten elastischen Energie, die in hohe Antriebskräfte umgewandelt wird.\n\n[[Hop]]: Definiert durch eine hohe Schrittfrequenz und eine kurze Standphase, betont dieser Stil eine begrenzte horizontale Antriebskraft und eine übermäßige vertikale Antriebskraft, was zu einer ausgeprägten Hüpfbewegung führt.\n\n[[Push]]: Dieser Laufstil weist eine relativ niedrige Schrittfrequenz mit einer moderaten Standphase auf. Der Hauptfokus liegt auf horizontaler Antriebskraft statt vertikaler, was zu einer Flugzeit führt, die etwas kürzer als die maximal mögliche Flugzeit ist.\n\n[[Stick]]: Charakterisiert durch eine mittlere Schrittfrequenz, zeichnet sich dieser Stil durch eine lange Standphase und eine kurze Beinoszillationsphase aus. Vorteilhaft bei niedrigeren Geschwindigkeiten, ist er ideal für Bedingungen, die reduzierte vertikale Aufprälle erfordern.\n\n[[Sit]]: Dieser Stil zeigt eine mittlere Schrittfrequenz und ein ausgeglichenes Verhältnis zwischen Stand- und Oszillationsphasen. Ein charakteristisches Merkmal ist die wahrscheinlich hohe Kniebeugung beim Bodenkontakt.\n\nQuelle: Van Oeveren, B. T., de Ruiter, C. J., Beek, P. J., & van Dieën, J. H. (2021). Die Biomechanik des Laufens und Laufstile: eine Synthese. Sportbiomechanik, 1-39.',
  pt: 'Existem 5 estilos diferentes de corrida:\n\n[[Bounce]]: Estilo de corrida caracterizado por uma frequência intermediária de passos, uma fase de apoio curta e uma longa fase de oscilação da perna. Este estilo de corrida permite uma melhor redistribuição da energia elástica armazenada durante a fase de apoio, sob a forma de forças de propulsão elevadas.\n\n[[Hop]]: Estilo de corrida caracterizado por uma alta frequência de passos e uma fase de apoio curta. Este estilo de corrida sugere uma propulsão horizontal limitada durante a fase de apoio e uma propulsão vertical excessivamente elevada.\n\n[[Push]]: Estilo de corrida caracterizado por uma frequência de passos relativamente baixa e uma fase de apoio média. Este estilo de corrida favorece uma força de propulsão mais orientada para o horizontal do que para o vertical, com um tempo de voo ligeiramente mais curto do que o tempo de voo máximo possível.\n\n[[Stick]]: Estilo de corrida caracterizado por uma frequência de passos intermediária, uma fase de apoio longa e uma fase de oscilação curta. Este estilo de corrida pode ser benéfico em velocidades mais baixas ou em condições de corrida que requerem impactos verticais mais baixos.\n\n[[Sit]]: Estilo de corrida caracterizado por uma frequência de passos e uma relação entre a fase de apoio e a fase de oscilação intermediárias. Este estilo de corrida é provavelmente caracterizado por uma flexão elevada do joelho no momento do contato com o solo.\n\nFonte: Van Oeveren, B. T., de Ruiter, C. J., Beek, P. J., & van Dieën, J. H. (2021). A biomecânica da corrida e estilos de corrida: uma síntese. Biomecânica esportiva, 1-39.\n\n',
  es: 'Existen 5 estilos diferentes de carrera:\n\n[[Bounce]]: Estilo de carrera caracterizado por una frecuencia de pasos intermedia, una fase de apoyo corta y una larga fase de oscilación de la pierna. Este estilo de carrera permite una mejor redistribución de la energía elástica almacenada durante la fase de apoyo, en forma de fuerzas de propulsión elevadas.\n\n[[Hop]]: Estilo de carrera caracterizado por una alta frecuencia de pasos y una fase de apoyo corta. Este estilo de carrera sugiere una propulsión horizontal limitada durante la fase de apoyo y una propulsión vertical excesivamente elevada.\n\n[[Push]]: Estilo de carrera caracterizado por una frecuencia de pasos relativamente baja y una fase de apoyo media. Este estilo de carrera favorece una fuerza de propulsión más orientada hacia lo horizontal que hacia lo vertical, siendo el tiempo de vuelo ligeramente más corto que el tiempo de vuelo máximo posible.\n\n[[Stick]]: Estilo de carrera caracterizado por una frecuencia de pasos intermedia, una fase de apoyo larga y una fase de oscilación corta. Este estilo de carrera puede ser beneficioso a velocidades más bajas o en condiciones de carrera que requieren impactos verticales más bajos.\n\n[[Sit]]: Estilo de carrera caracterizado por una frecuencia de pasos y una relación entre la fase de apoyo y la fase de oscilación intermedias. Este estilo de carrera se caracteriza probablemente por una alta flexión de la rodilla en el momento del contacto con el suelo.\n\nFuente: Van Oeveren, B. T., de Ruiter, C. J., Beek, P. J., & van Dieën, J. H. (2021). La biomecánica de la carrera y estilos de carrera: una síntesis. Biomecánica deportiva, 1-39.\n\n',
  it: "Esistono 5 stili di corsa diversi:\n\n[[Bounce]]: Stile di corsa caratterizzato da una frequenza di passi intermedia, una fase di appoggio corta e una lunga fase di oscillazione della gamba. Questo stile di corsa consente una migliore redistribuzione dell'energia elastica immagazzinata durante la fase di appoggio, sotto forma di forze di propulsione elevate.\n\n[[Hop]]: Stile di corsa caratterizzato da un'alta frequenza di passi e una fase di appoggio corta. Questo stile di corsa suggerisce una propulsione orizzontale limitata durante la fase di appoggio e una propulsione verticale eccessivamente elevata.\n\n[[Push]]: Stile di corsa caratterizzato da una frequenza di passi relativamente bassa e una fase di appoggio media. Questo stile di corsa favorisce una forza di propulsione orientata più verso l'orizzontale che verso il verticale, con un tempo di volo leggermente più corto rispetto al tempo di volo massimo possibile.\n\n[[Stick]]: Stile di corsa caratterizzato da una frequenza di passi intermedia, una fase di appoggio lunga e una fase di oscillazione corta. Questo stile di corsa può essere utile a velocità più basse o in condizioni di corsa che richiedono impatti verticali più contenuti.\n\n[[Sit]]: Stile di corsa caratterizzato da una frequenza di passi e un rapporto tra fase di appoggio e fase di oscillazione intermedie. Questo stile di corsa è probabilmente caratterizzato da una flessione elevata del ginocchio al momento del contatto con il suolo.\n\nFonte: Van Oeveren, B. T., de Ruiter, C. J., Beek, P. J., & van Dieën, J. H. (2021). La biomeccanica della corsa e degli stili di corsa: una sintesi. Biomeccanica sportiva, 1-39.\n\n",
};
