import React from "react";

import TextComponent from "components/atoms/TextComponent";
import SettingsButtonHeightAndWeight from "./SettingsButtonHeightAndWeight";
import SettingsButtonLanguage from "./SettingsButtonLanguage";
import SettingsButton from "./SettingsButton";

import { H6 } from "constant/constants";

function SettingsCategory({ button, onClick, name }) {
    const handleClick = (elementName) => {
        onClick(elementName);
    };
    return (
        <>
            <TextComponent variant={H6} text={name} sx={{ marginTop: '2vh' }} />
            {button?.map((item, index) => {
                if (item?.switch)
                    return (<SettingsButtonHeightAndWeight key={index} element={item} />);
                else if (item?.picker)
                    return (<SettingsButtonLanguage key={index} element={item} />);
                else
                    return (<SettingsButton key={index} element={item} onClick={() => handleClick(item?.name)} />);
            })}
        </>
    );
}


export default SettingsCategory;