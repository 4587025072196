import React, { useRef, useState } from 'react';
import './styles/SuccessCard.css';
import { sendAnalysis } from 'services/mail';
import TextComponent from 'components/atoms/TextComponent';
import TextInput from 'components/atoms/TextInput';
import { translate } from 'services/remoteConfig';
import ButtonComponent from 'components/atoms/ButtonComponent';
import { BODY_1, ORANGE } from 'constant/constants';
import { colors } from 'themes';
import SuccessLottieComponent from 'components/atoms/SuccessLottieComponent';
import { getAverageScore } from 'utils/utils';

const yup = require('yup');

function ShareModalContent({ analysis, shareRef }) {
  const successRef = useRef();
  const [open, setOpen] = useState(successRef?.current?.getOpen());
  const localData = JSON.parse(
    localStorage?.getItem(analysis?.data?.uploadVideoId),
  );

  const userSchema = yup.object({
    email: yup
      .string()
      .email(translate('AuthErrorInvalidEmail'))
      .required(translate('EmailRequired')),
  });

  const [formValues, setFormValues] = useState({
    name: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = async e => {
    if (e) e.preventDefault();
    try {
      await userSchema.validate(formValues, { abortEarly: false });
      const averageScore = getAverageScore(
        Object?.entries(
          localData?.json_version === 4
            ? localData?.analyse?.content
            : localData[0]?.analyse?.content,
        ),
      );
      var to = [];
      to.push(formValues?.email);
      sendAnalysis(
        averageScore,
        analysis?.video_url,
        analysis?.thumbnail,
        analysis?.data?.uploadVideoId,
        to,
        analysis?.name,
        null,
      );
      successRef?.current?.setOpen(true);
      setOpen(true);
    } catch (err) {
      if (err.inner) {
        const errors = {};
        err.inner.forEach(error => {
          errors[error?.path] = error?.message;
        });
        setFormErrors(errors);
      }
    }
  };

  const handleChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    if (formErrors[e.target.name]) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: '',
      });
    }
  };
  return (
    <>
      <SuccessLottieComponent
        ref={successRef}
        onComplete={() => {
          setTimeout(() => {
            setOpen(false);
            successRef?.current?.setClose();
            shareRef?.current?.close();
          }, 1000);
        }}
      />

      {!open ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <TextComponent
            text={translate('ShareAnalysis')}
            variant="h4"
          />
          <TextComponent
            text={translate('ShareAnalysisSubTitle')}
            variant={BODY_1}
            sx={{
              marginTop: '1%',
              color: colors.TEXT_GRAY,
              textAlign: 'center',
              width: '80%',
            }}
          />
          <div style={{ width: '80%', marginTop: '5%' }}>
            <TextInput
              id="emailTextInput"
              name="email"
              title={translate('EmailAdress')}
              label={translate('EmailAdress')}
              value={formValues?.email}
              onChange={handleChange}
              schema={userSchema}
              error={!!formErrors?.email}
              helperText={formErrors?.email}
              image={require('assets/images/mail.png')}
              handleSubmit={() => {
                handleSubmit();
              }}
            />
          </div>
          <ButtonComponent
            sx={{ marginTop: '5%', marginBottom: '10%' }}
            title={translate('Send')}
            type={ORANGE}
            onClick={() => {
              handleSubmit();
            }}
          />
        </div>
      ) : null}
    </>
  );
}

export default ShareModalContent;
