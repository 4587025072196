import {collection, deleteDoc, doc, getDoc, setDoc} from 'firebase/firestore';

import {auth, firestore} from 'utils/firebaseInit';
import {USER_LOCALE} from 'services/remoteConfig';
import {getDownloadURL, getStorage, ref} from 'firebase/storage';
import {ANALYSISTYPE_RUNNING_TREADMILL, FRENCH} from 'constant/constants';
import {getAuth} from 'firebase/auth';

const ANALYSIS_COLLECTION = 'analysis';
const API_URL = process.env.REACT_APP_API_KEY;

export async function getAnalysisData(id) {
  // if (auth) {
  try {
    const docRef = doc(firestore, ANALYSIS_COLLECTION, id);
    const docSnap = await getDoc(docRef);
    if (docSnap?.exists()) {
      return docSnap?.data();
    } else {
      console.log('No such document!');
    }
  } catch (error) {
    console.log('error in getAnalysisData', error);
  }
}

export async function startVideoAnalysis(id, data) {
  const analysisType =
    data?.analysisType === ANALYSISTYPE_RUNNING_TREADMILL
      ? 'running'
      : data?.analysisType;

  let body = {
    id: id,
    height: data?.height ?? -1,
    weight: data?.weight ?? -1,
    language: USER_LOCALE,
    source: 'webApp',
    analysis_type: analysisType ?? 'running',
    treadmill:
      data?.analysisType === ANALYSISTYPE_RUNNING_TREADMILL ? true : false,
    pace: data?.pace ?? '',
  };

  console.log(body);
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('User is not authenticated');
    }
    const idToken = await currentUser.getIdToken();

    console.log('oueoeuuoeuo', API_URL);

    const response = await fetch(API_URL + '/analysis/start', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        token: `${idToken}`,
      },
      body: JSON.stringify(body),
    });

    const responseText = await response.text();
    return responseText;
  } catch (error) {
    console.error('Error in Start Analysis : ', error);
    return null;
  }
}

export async function getVideoAnalysisStatus(id) {
  // const body = `status=${id}`;
  // try {
  //   const response = await fetch(API_URL, {
  //     method: "post",
  //     body: body,
  //     referrerPolicy: "unsafe-url",
  //   });
  //   const responseJson = await response?.json();
  //   return responseJson;
  // } catch (error) {
  //   console.error(error.message);
  //   return null;
  // }
}

export async function deleteAnalysis(id) {
  if (!id) return;
  if (auth) {
    try {
      const collectionRef = collection(firestore, ANALYSIS_COLLECTION);
      await deleteDoc(doc(collectionRef, id));
    } catch (e) {
      console.log(e);
    }
  }
  return;
}

export async function updateAnalysis(data, analysisId) {
  if (!analysisId) return;
  if (auth) {
    try {
      const collectionRef = collection(firestore, ANALYSIS_COLLECTION);
      const ref = doc(collectionRef, analysisId);
      setDoc(ref, data, {merge: true});
      return ref;
    } catch (e) {
      console.log(e);
    }
  }
}

export async function createNewAnalysis(data, tag) {
  const user = auth?.currentUser;
  if (user) {
    data.owner = user?.uid;
    if (tag) data.tag = tag;
    else data.tag = {};
    const newAnalysis = {
      data: data,
      date: new Date().toISOString(),
    };
    try {
      const collectionRef = collection(firestore, ANALYSIS_COLLECTION);
      const ref = doc(collectionRef, data?.uploadVideoId);
      setDoc(ref, newAnalysis, {merge: true});
    } catch (e) {
      console.log('error in createNewAnalysis ', e);
    }
  }
}

export async function getDowloadUrlFromGsUrl(gsUrl) {
  const storage = getStorage();
  const url = await getDownloadURL(ref(storage, gsUrl)).catch(error => {
    console.log('error', error);
  });
  if (url) return url;
}
