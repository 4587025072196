import ButtonComponent from "components/atoms/ButtonComponent";
import ClickableTextComponent from "components/atoms/ClickableTextComponent";
import TextComponent from "components/atoms/TextComponent";
import TextInput from "components/atoms/TextInput";
import { ORANGE } from "constant/constants";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendForgotPasswordEmail } from "services/authApi";
import { translate } from "services/remoteConfig";
import { colors } from "themes";

function ForgotPassword() {
  const navigate = useNavigate();
  const yup = require("yup");
  const [formValues, setFormValues] = useState({
    email: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const emailSchema = yup.object({
    email: yup.string().email(translate("AuthErrorInvalidEmail")).required(translate("EmailRequired")),
  });

  function onMailSendSuccessful() {
    navigate("/");
  }

  function onMailSendFailed(message) {
    setFormErrors(message);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await emailSchema?.validate(formValues, { abortEarly: false });

      sendForgotPasswordEmail(
        formValues?.email,
        onMailSendSuccessful,
        onMailSendFailed
      );
    } catch (err) {
      if (err.inner) {
        const errors = {};
        err.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        setFormErrors(errors);
      }
    }
  };
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    if (formErrors[e.target.name]) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: "",
      });
    }
  };

  return (
    <div
      style={styles.container}
    >
      <div
        style={styles.inputContainer}
      >
        <img src={require("assets/images/ForgotPasswordIcon.png")} alt="logo" />
        <TextComponent
          text={translate('ForgotPassword')}
          variant={"h3"}
          sx={{ color: "white", marginTop: "5%", marginBottom: "5%" }}
        />
        <TextComponent
          text={translate('SendResetLink')}
          variant={"body1"}
          sx={{ color: "lightGray", marginBottom: "10%" }}
        />
        <TextInput
          name="email"
          label={translate("EMail")}
          value={formValues?.email}
          onChange={handleChange}
          schema={emailSchema}
          error={!!formErrors?.email}
          helperText={formErrors?.email}
          image={require("assets/images/mail.png")}
        />

        <div style={{ marginTop: "10%", marginBottom : "4%" }}>
          <ButtonComponent
            type={ORANGE}
            title={translate("Submit")}
            onClick={handleSubmit}
          />
        </div>
      </div>
      <div
        style={styles.bottomContainer}
      >
        <TextComponent text={translate("AccountExists")} sx={{marginRight : 1}} />
        <ClickableTextComponent
          title={translate("Login")}
          onPress={() => {
            navigate("/");
          }}
          option={{ color: colors.SECONDARY }}
        />
      </div>
    </div>
  );
}

const styles = {
  container: {
    height: "90vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  inputContainer: {
    marginTop: "0.5%",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  bottomContainer : {
    marginTop: "0.5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}

export default ForgotPassword;
